import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "faqEditar",
    components: {
        tabelaBase,
    },
    props: {
        idFaq: { type: Number, default: 0 },
    },
    data() {
        return {
            faq: {
                idFaq: Number(),
                pergunta: String(),
                resposta: String(),
                ativo: Boolean(),
            },
            status: [
                { value: true, text: "Ativo" },
                { value: false, text: "Inativo" },

            ]

        };
    },
    computed: {},
    async beforeMount() {
        if (this.$route.query.idFaq && this.$route.query.idFaq != 0) {
            this.faq.idFaq = +this.$route.query.idFaq;
            await this.obterDadosFaq();
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    methods: {
        async obterDadosFaq() {
            this.$loading(true);

            await http
                .get(`/faq/obterFaq/${this.faq.idFaq}`)
                .then(
                    (success) => {
                        if (success.data) {
                            this.faq = success.data.dadosFaq;
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async editarDadosFaq() {
            this.$loading(true);

            await http
                .post(`/faq/editarFaq`, this.faq)
                .then(
                    (success) => {
                        if (success) {
                            this.$snotify.success("FAQ editada com sucesso");
                            window.location.href = "/painel/admin/faq-admin";
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async criarFaq() {
            this.$loading(true);
            var dados = this.faq

            await http
                .post(`/faq/criarFaq`, dados)
                .then(
                    (success) => {
                        if (success) {
                            this.$snotify.success("Faq criada com sucesso");
                            window.location.href = "/painel/admin/faq-admin";
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        voltarTela() {
            window.location.href = "/painel/admin/faq-admin";
        }
    }
}