import "./Graduacao.scss";
import http from "@/_helper/api-services";

export default {
    name: "Graduacao",
    props: ["propBarraStatus"],
    data() {
        return {
            dias: 0,
            valor: 0,
            dadosBarraStatus: {},
            pontos: null,
        };
    },
    watch: {
        propBarraStatus(el) {
            if (el.idUsuario == null || el.idUsuario == undefined)
                this.obterBarraStatus();
            else this.dadosBarraStatus = el;
        },
    },
    beforeMount() {
        this.dias = 9;
        this.valor = 270.2;
        this.obterBarraStatus();
        this.obterPontuacao();
    },
    methods: {
        obterBarraStatus() {
            this.$loading(true);
            http.get("/Dashboard/obterBarraStatus/").then(
                (response) => {
                    this.dadosBarraStatus = response.data;
                    this.$loading(false);
                },
                () => {
                    this.$snotify.error("Erro ao obter dados.");
                }
            );
        },
        obterPontuacao() {
            this.$loading(true);
            http.get("/Dashboard/obterPontuacao/").then(
                (response) => {
                    this.pontos = response.data;
                    this.$loading(false);
                },
                () => {
                    this.$snotify.error("Erro ao obter dados.");
                }
            );
        },
    },
};
