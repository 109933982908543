import "./financeiro.scss";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import ganhosSaidas from "@/components/GanhosSaidas/ganhosSaidas.vue";
import tabelaMovimentacoes from "@/components/TabelaMovimentacoes/tabelaMovimentacoes.vue";
import AdicionarSaldo from "@/components/AdicionarSaldo/adicionarSaldo.vue";
import solicitarSaque from "@/components/SolicitarSaque/solicitarSaque.vue";
import historicoSaque from "@/components/HistoricoSaque/historicoSaque.vue";
import ResumoCompras from "@/components/ResumoCompras/resumoCompras.vue";

export default {
    name: "financeiro",
    data() {
        return {
            aux: 0,
        };
    },
    components: {
        BarraIndicacao,
        ganhosSaidas,
        tabelaMovimentacoes,
        AdicionarSaldo,
        solicitarSaque,
        historicoSaque,
        ResumoCompras,
    },
    mounted() {
        // this.$loading(false);
    },
    methods: {
        reloadHistorico() {
            this.aux++;
        },
    },
};