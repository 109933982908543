import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "FaqAdmin",
    components: {
        tabelaBase,
    },
    data() {
        return {
            faq: {
                items: [],
                title: " ",
                fields: [
                    {
                        key: "pergunta",
                        label: "Pergunta",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "dataCadastro",
                        label: "Data de criação",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value)
                    },
                    {
                        key: "ultimaAtualizacao",
                        label: "Última atualização",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value)
                    },
                    {
                        key: "ativo",
                        label: "Status",
                        sortable: false,
                        class: "text-center",
                        formatter: (value) => (value ? "Ativo" : "Inativo"),
                        class: "text-center",
                    },
                    {
                        key: "acoes",
                        label: "Acões",
                    },
                ],
                exportExcel: false,
                perPage: 10,
                pageOptions: [10, 20, 50],
                sortBy: "pergunta",
                sortDesc: false,
            },
            filtro: {
                pergunta: String(),
                resposta: String(),
                idStatus: null,
            },
            statusOptions: [
                { value: null, text: "Todos" },
                { value: true, text: "Ativo" },
                { value: false, text: "Inativo" },
            ],
        };
    },
    beforeMount() {
        this.obterFaq();
    },
    methods: {
        obterFaq() {
            this.$loading(true);
            http
                .get("/faq/buscarFaq")
                .then(
                    (success) => {
                        if (success.data) {
                            this.faq.items = success.data;
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
                );
            }
        },
        editarFaq(idFaq) {
            window.location.href = "/painel/admin/faq-editar?idFaq=" + idFaq;
        },
        filtroFaq() {
            this.$loading(true);
            http.post("/faq/filtrarFaq", this.filtro)
                .then(
                    (success) => {
                        if (success && success.data) {
                            this.faq.items = success.data;
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        }
    }
}