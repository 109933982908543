import './Seguranca.scss'
import http from "@/_helper/api-services.js";

export default {
    name: 'Seguranca',
    props: ['dadosPessoais', 'erroForm'],
    data() {
        return {
            estados: [{ text: "Selecione o estado", value: null }]
        }
    },
    mounted() {
        
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();
            this.$emit("submit", "dados-pessoais");
        },        
        ChecaForm()
        {
            if (!this.dadosPessoais.celular ||
                this.removeNonNumeric(this.dadosPessoais.celular).length < 10) {
                this.$snotify.error("Informe o telefone.");
                return false;
            }
        },
        removeNonNumeric(text) {
            if (!text)
                return "";

            return text.match(/\d+/g).join("");
        }
    }
}