import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

import "./Tutoriais.css";

export default {
  name: "Tutoriais",
  components: {
    tabelaBase,
  },
  data() {
    return {
      tutoriais: {
        items: [],
        title: " ",
        fields: [
          {
            key: "nome",
            label: "Nome",
            sortable: true,
            class: "text-center",
          },
          {
            key: "descricao",
            label: "Descricao",
            sortable: true,
            class: "text-center",
          },
          {
            key: "url",
            label: "URL",
            sortable: true,
            class: "text-center",
          },
          {
            key: "dataCadastro",
            label: "Data de criação",
            sortable: true,
            class: "text-center",
            formatter: (value) => this.formatDate(value),
          },
          {
            key: "dataAtualizacao",
            label: "Última atualização",
            sortable: true,
            class: "text-center",
            formatter: (value) => this.formatDate(value),
          },
          {
            key: "ativo",
            label: "Status",
            sortable: false,
            class: "text-center",
            formatter: (value) => (value ? "Ativo" : "Inativo"),
            class: "text-center",
          },
          {
            key: "acoes",
            label: "Ações",
          },
        ],
        sortBy: "nome",
        sortDesc: false,
        exportExcel: false,
        perPage: null,
      },
    };
  },
  beforeMount() {
    this.obterTutoriais();
  },
  methods: {
    obterTutoriais() {
      this.$loading(true);

      http
        .get("/tutorial/ObterTutoriais")
        .then(
          (success) => {
            if (success.data) {
              this.tutoriais.items = success.data;
            }
          },
          (error) => {
            if (error.response && error.response.data)
              this.$snotify.error(error.response.data.message);
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    formatDate(data) {
      if (data) {
        let dateTime = new Date(data);
        return (
          dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
        );
      }
    },
    editarTutorial(idTutorial) {
      window.location.href = "/painel/admin/config/editar-tutorial?idTutorial=" + idTutorial;
    },
  },
};
