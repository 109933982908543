import { usuarioService } from "@/_helper/usuario-service.js";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };
const acessoRemoto = localStorage.getItem("userAdmin") != null ? true : false;

export const autenticacao = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { user, senha }) {
            commit("loginRequest", { user });

            return usuarioService.login(user, senha).then(
                (user) => {
                    commit("loginSuccess", user);
                    return user;
                },
                (error) => {
                    commit("loginFailure", error);
                    throw error;
                }
            );
        },
        logout({ commit }) {
            usuarioService.logout();
            commit("logout");
        },
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        },
    },
    getters: {
        getLogin(user) {
            return user;
        },
        isAcessoRemoto() {
            return acessoRemoto;
        },
    },
};
