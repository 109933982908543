import http from '@/_helper/api-services'

export default {
    name: 'GerenciarGrupos',
    data() {
        return {
            fields: [
                { key: "nome", label: "Nome grupo", sortable: false, class: 'text-center' },
                { key: "ativo", label: "Status", sortable: false, class: 'text-center' },
                { key: "opcoes", label: "Opções", sortable: false, class: 'text-center' }
            ],
            fieldsMenus: [
                { key: "texto", label: "Nome", sortable: false, },
                { key: "vendoMenu", label: "Permissão", sortable: false, class: 'text-center' }
            ],
            listGrupos: [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            nomeGrupo: String(),
            menusGrupo: [],
            grupoSelecionado: {}
        }
    },
    components: {
    },
    mounted() {
        this.$loading(false);
        this.listarGrupos();
    },
    methods: {
        listarGrupos() {
            this.$loading(true);
            this.listGrupos = [];

            http.get('/Grupo/listarGrupos').then((responde) => {
                responde.data.forEach(item => {
                    this.listGrupos.push({
                        nome: item.descricao,
                        idGrupo: item.idGrupo,
                        ativo: item.ativo
                    })
                });
                this.$loading(false);
            });
        },
        listarMenus(idGrupo) {
            this.$loading(true);
            this.grupoSelecionado = this.listGrupos.filter(f => f.idGrupo == idGrupo)[0];
            this.menusGrupo = [];

            http.get('/Grupo/buscarMenusPorGrupo/' + idGrupo).then((response) => {
                response.data.forEach((item) => {
                    this.menusGrupo.push({
                        idMenu: item.idMenu,
                        texto: item.texto,
                        vendoMenu: item.vendoMenu
                    });

                    item.subMenus.forEach((item) => {
                        this.menusGrupo.push({
                            idMenu: item.idMenu,
                            texto: "............... " + item.texto,
                            vendoMenu: item.vendoMenu,
                            idMenuPai: item.idMenuPai
                        })
                    });
                });

                this.$bvModal.show('modal-grupo-menus');
                this.$loading(false);
            });
        },
        ativarDesativarGrupo(idGrupo) {
            this.$loading(true);
            let obj = {
                idGrupo: idGrupo
            };

            http.update('/Grupo/ativaDesativaGrupo', obj).then((response) => {
                this.$snotify.success(response.data.message);

                this.listGrupos.filter(f => f.idGrupo == idGrupo)[0].ativo = response.data.status;
                this.$loading(false);
            });
        },
        cadastrarGrupo() {
            this.$loading(true);

            let obj = {
                descricao: this.nomeGrupo
            }

            http.post('/Grupo/cadastrarGrupo', obj).then((response) => {
                this.$snotify.success(response.data.message);
                this.listarGrupos();
                this.nomeGrupo = '';
                this.$bvModal.hide('modal-grupo');
                this.$loading(false);
            });
        },
        ativarOuDesativarPermissao(menu) {
            this.$loading(true);
            let listGrupoMenuViewModel = [];
            let pai = null;

            if (menu.idMenuPai) {
                pai = this.menusGrupo.filter(f => f.idMenu == menu.idMenuPai)[0];

                if (!pai.vendoMenu) {
                    listGrupoMenuViewModel.push({
                        idGrupo: this.grupoSelecionado.idGrupo,
                        idMenu: pai.idMenu
                    });
                }
            }

            listGrupoMenuViewModel.push({
                idGrupo: this.grupoSelecionado.idGrupo,
                idMenu: menu.idMenu
            });

            http.post('/Grupo/ativarDesativarPermissao', listGrupoMenuViewModel).then((response) => {
                this.$snotify.success(response.data.message);
                menu.vendoMenu = !menu.vendoMenu;
                this.verificaPaiEfilhos(pai, menu);

                this.$loading(false);
            });
        },
        verificaPaiEfilhos(pai, menu) {
            if (pai) {
                if (!pai.vendoMenu)
                    pai.vendoMenu = true;
            }

            if (!menu.vendoMenu) {
                this.menusGrupo.filter(f => f.idMenuPai == menu.idMenu).forEach((item) => {
                    item.vendoMenu = false;
                });
            }
        }
    }
}