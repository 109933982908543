import "./appFooter.scss";
import http from "@/_helper/api-services.js";
import Carrossel from "@/components/Carrossel/Carrossel.vue";

export default {
  name: "appFooter",
  components: {
    Carrossel,
  },
  data() {
    return {
      BASE_URL: process.env.BASE_URL,
      year: new Date(Date.now()).getFullYear(),
      modalOpen: false,
      dadosCarrossel: {
        anunciantes: Array(),
        corFundo: String(),
        ativo: false,
      },
      showBanner: false,
      dontShowBanner: [
        "/recusa-privacidade",
        "/privacidade",
        "/quem-somos",
        "/como-funciona",
        "/empresa",
        "/faq",
        "/mais-vendas",
        "/finalizar-credenciamento",
        "/anunciante",
        "/Anunciante",
        "/app",
        "/material-apoio",
      ],
    };
  },
  props: ["adminPages"],
  watch: {
    adminPages() {
      setTimeout(() => this.adjustFooterPadding(), 350);
    },
    $route(to, from) {
      if (
        this.dontShowBanner.filter((b) => to.path.indexOf(b) != -1).length > 0
      )
        this.showBanner = false;
      else this.showBanner = true;
    },
  },
  async beforeMount() {
    window.addEventListener("resize", this.adjustFooterPadding);
    await this.getCarrossel();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustFooterPadding);
  },
  mounted() {
    setTimeout(() => this.adjustFooterPadding(), 500);
  },
  methods: {
    adjustFooterPadding() {
      this.$emit(
        "adjustFooterPadding",
        this.$refs.footerElement.clientHeight + (!this.adminPages ? 20 : 0)
      );
    },
    async registrarCookie() {
      const dadosUser = this.$store.getters["autenticacao/getLogin"].user;
      if (dadosUser) {
        // Registra na conta que os termos foram aceitos

        await http
          .post("/user/aceitarcookies/")
          .then(
            () => {},
            (error) => {
              for (const erro of http.mensagensErro(error)) {
                this.$snotify.error(erro);
              }
            }
          )
          .finally(() => {
            this.$loading(false);
          });
      }

      $cookies.set("policy-accepted", true);
      window.location.href = "/";
      window.location.reload();
    },
    checkarCookie() {
      return "true" == $cookies.get("policy-accepted");
    },
    recusarCookie() {
      $cookies.set("policy-accepted", false);
      window.location.href = "/recusa-privacidade";
      window.scrollTo(0, 0);
    },
    redirectPolitica() {
      window.open("/QB-LGPD.pdf", "_blank");
    },
    redirectAppStore() {
      window.open("https://apps.apple.com/app/id1611356636");
    },
    redirectPlayStore() {
      window.open(
        "https://play.google.com/store/apps/details?id=br.com.quantabank"
      );
    },
    async getCarrossel() {
      await http.get(`/carrossel/obterCarrossel/2`).then(
        (success) => {
          this.dadosCarrossel.anunciantes = success.data.anunciantes;
          this.dadosCarrossel.corFundo = success.data.corFundo;
          this.dadosCarrossel.ativo = success.data.ativo;
          this.dadosCarrossel.nome = success.data.nome;
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },
  },
};
