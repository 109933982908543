import "./listagemAssinatura.scss";
import boxAssinatura from "@/components/BoxAssinatura/boxAssinatura.vue";
import http from "@/_helper/api-services.js";
import carousel from "vue-owl-carousel";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import ResumoAssinaturas from "@/components/ResumoAssinaturas/resumoAssinaturas.vue";

export default {
  name: "listagemAssinatura",
  components: {
    boxAssinatura,
    carousel,
    tabelaBase,
    ResumoAssinaturas,
  },
  data() {
    return {
      categorys: [],
      paymentForms: Object(),
      configCarousel: {
        autoHeight: true,
        responsive: {
          0: {
            items: 1,
            nav: false,
            margin: 5,
            loop: false,
          },
          768: {
            items: 2,
            nav: false,
            margin: 10,
            loop: false,
          },
          992: {
            items: 3,
            nav: true,
            margin: 15,
            loop: false,
          },
          1200: {
            items: 4,
            nav: true,
            margin: 25,
            loop: false,
          },
        },
      },
    };
  },
  props: ["planoAtual"],
  computed: {
    categorysWithProducts() {
      return this.categorys.filter((c) => c.produtos && c.produtos.length > 0);
    },
    categoryListRearranged() {
      let selected = this.categorysWithProducts.filter(
        (c) => c.selected == true
      )[0];
      if (!selected) {
        this.categorys[
          this.categorys.findIndex(
            (c) => c.idCategoria == this.categorysWithProducts[0].idCategoria
          )
        ].selected = true;
        return this.categorysWithProducts;
      } else {
        let newArray = [selected];
        this.categorysWithProducts.forEach((c) => {
          if (selected.idCategoria != c.idCategoria) newArray.push(c);
        });
        return newArray;
      }
    },
  },
  async beforeMount() {
    await this.getCategorysWithProducts();
    await this.getPaymentsForms();
  },
  methods: {
    rearrangeCategoryList(idCategoria) {
      this.categorys.forEach((c) => {
        if (c.idCategoria == idCategoria) c.selected = true;
        else c.selected = false;
      });
    },
    async getCategorysWithProducts() {
      this.$loading(true);
      await http
        .get("/produto/buscarPlanoAssinatura")
        .then(
          (success) => {
            if (success.data) {
              console.log("success.data", success.data);
              this.categorys = success.data;
              success.data.forEach((d) => (d.selected = false));
              this.categorys = success.data;
            }
          },
          (error) => {
            for (const erro of http.mensagensErro(error)) {
              this.$snotify.error(erro);
            }
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    async getPaymentsForms() {
      await http.get("/compra/obterMetodosDeCompra").then(
        (success) => {
          this.paymentForms = success.data;
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },

    refreshPage() {
      this.getCategorysWithProducts(); // Chamando getCategorysWithProducts()
    },
  },
};
