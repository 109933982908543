import "./relatorioCashback.scss";
import http from "@/_helper/api-services.js";
import { validationMixin } from "vuelidate";

export default {
  name: "RelatorioCashback",
  data() {
    return {
      keyGrafico: 0,
      seriesUsuarios: [],
      chartUsuariosOptions: {
        chart: {
          type: "area",
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["rgba(71, 180, 141, 1)"],
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 0,
        },
      },
    };
  },
  beforeMount() {
    this.$loading(true);
    this.getRelatorioCashback()
      .then(() => this.$loading(false))
      .catch(() => this.$loading(false));
  },
  methods: {
    formatValue(value) {
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    getRelatorioCashback() {
      return http.get("/compra/relatorioMensalCashback").then(
        (success) => {
          function minDate(date1, date2) {
            if (date1 == null) {
              return date2;
            }
            if (date2 == null) {
              return date1;
            }
            if (date2 < date1) {
              return date2;
            }
            return date1;
          }

          function maxDate(date1, date2) {
            if (date1 == null) {
              return date2;
            }
            if (date2 == null) {
              return date1;
            }
            if (date2 > date1) {
              return date2;
            }
            return date1;
          }

          const monthNames = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ];

          const tipoGrafico = {
            Cashback: "Cashback",
            "Cashback pago pela Quanta Shop": "Pago pela Quanta Shop",
            "Distribuição de Cashback": "Distribuição de cashback",
            "Distribuição de Cashback Pago pela Quanta Shop":
              "Pago pela Quanta Shop",
            "Cashback loja física": "Cashback",
            "Cashback loja física pago pela Quanta Shop": "Pago pela Quanta Shop",
            "Distribuição compra em loja física": "Distribuição de Cashback",
            "Distribuição compra em loja física pago pela Quanta Shop":
              "Pago pela Quanta Shop",
            "Pagamento para o Credenciador": "Bônus de credenciamento",
            "Pagamento para o Credenciador pago pela Quanta Shop":
              "Pago pela Quanta Shop",
            "Distribuição de pagamento para o Credenciador":
              "Distribuição de bônus de credenciamento",
            "Distribuição de pagamento para o Credenciador pago pela Quanta Shop":
              "Pago pela Quanta Shop",
            "Distribuido para Quanta Shop": "Distribuido para Quanta Shop",
            "Pago pela Quanta Shop": "Pago pela Quanta Shop",
            "Processando Cashback": "Processando",
            "Processando Cashback credenciado": "Processando",
          };

          const series = {};
          for (let tipo in tipoGrafico) {
            series[tipo] = [];
          }

          const startDate = success.data
            .map((value) => value.data)
            .reduce(minDate);
          const endDate = success.data
            .map((value) => value.data)
            .reduce(maxDate);

          const dates = [];
          for (
            let year = startDate.getFullYear();
            year <= endDate.getFullYear();
            year++
          ) {
            for (
              let month =
                year == startDate.getFullYear() ? startDate.getMonth() : 1;
              (year < endDate.getFullYear() && month <= 12) ||
              month <= endDate.getMonth();
              month++
            ) {
              dates.push(new Date(year, month - 1, 1));

              for (let tipo in tipoGrafico) {
                series[tipo][index] = 0;
              }
            }
          }

          this.chartUsuariosOptions.xaxis.categories = dates.map((value) => {
            return `${monthNames[value.getMonth()]}/${value.getFullYear()}`;
          });

          let index = 0;
          for (let date of dates) {
            let dateValues = success.data.find(
              (f) => date.getTime() == f.data.getTime()
            );
            if (dateValues == null || dateValues == undefined) {
              dateValues = { lancamentos: [] };
            }

            for (let value of dateValues.lancamentos) {
              if (value.statusPagamento == "Pago pela Quanta Shop") {
                if (value.tipoLancamento == null) {
                  tipo = "Pago pela Quanta Shop";
                } else {
                  tipo = value.tipoLancamento + " pago pela Quanta Shop";
                }
              } else if (value.tipoLancamento == null) {
                tipo = "Processando " + value.descricaoTipoPedido;
              } else {
                tipo = value.tipoLancamento;
              }

              series[tipo][index] += value;
            }
            index++;
          }

          for (tipo in series) {
            const seriesGrafico = {};
            if (seriesGrafico[tipo]) {
              // agrupando tipos para o grafico
              for (let i = 0; i < series[tipo]; series) {
                seriesGrafico[tipo][i] += series[tipo][i];
              }
            } else {
              seriesGrafico[tipo] = series[tipo];
            }

            this.seriesUsuarios.push({
              name: tipo,
              data: series[tipo],
            });
          }

          // this.chartUsuariosOptions.yaxis.max =
          //     Math.max(
          //         ...success.data.map((d) => {
          //             return d.quantidade;
          //         })
          //     );
          this.keyGrafico++;

          return success;
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }

          return error;
        }
      );
    },
  },
};
