import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "MaterialApoioAdmin",
    components: {
        tabelaBase,
    },
    props: {
        idMaterial: { type: Number, default: 0 },
    },
    data() {
        return {
            mapoio: {
                idMaterial: Number(),
                items:[],
                title:" ",
                fields: [
                    {
                        key: "nome",
                        label: "Nome",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "descricao",
                        label: "Descrição",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "dataCadastro",
                        label: "Data de Criação",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "ultimaAtualizacao",
                        label: "Data de Modificação",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "ativo",
                        label: "Status",
                        sortable: false,
                        class: "text-center",
                        formatter: (value) => (value ? "Ativo" : "Inativo"),
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                    }
                ],
                exportExcel: false,
                perPage: 10,
                pageOpitons: [10,20,50],
                sortBy: "nome",
                sortDesc: false,
            }
        };
    },
    beforeMount(){
        this.obterMaterial();
        if (this.$route.query.idMaterial  && this.$route.query.idMaterial != 0) {
            this.mapoio.idMaterial = +this.$route.query.idMaterial;    
        }
    },
    methods: {
        obterMaterial() {
            this.$loading(true);
            http
            .get("/materialapoio/buscarMaterial")
            .then(
                (success) => {
                    if(success.data) {
                        this.mapoio.items = success.data;
                    }
                },
                (error) => {
                    if(error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            )
            .finally(() => {
                this.$loading(false);
            });
        },
        formatDate(data) {
            if (data) {
              let dateTime = new Date(data);
              return (
                dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
              );
            }
          },
        editarMaterial(idMaterial){
            window.location.href = "/painel/admin/material-apoio-editar?idMaterial=" + idMaterial;
        },
        deletarMaterial(idMaterial){
            this.$loading(true);
            http
            .delete(`/materialapoio/deletarMaterial/${idMaterial}`)
            .then(
                () => {
                    this.$snotify.success(
                        "Material excluido com sucesso."
                    );
                    location.reload();
                },
                () => {
                    this.$snotify.error("Erro ao excluir material.");
                }
            )
            .finally(() => {
                this.$loading(false);
            });
        },
    }
}