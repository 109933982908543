import http from "@/_helper/api-services.js";

export default {
    name: "tabelaPremiacoes",
    data() {
        return {
            fields: [
                { key: 'nome', label: 'Graduação', sortable: true, sortDirection: 'desc' },
                { key: 'vme', label: 'VME', sortable: true, class: 'text-center' },
                { key: 'pontos', label: 'Pontos', sortable: true, class: 'text-center' },
                { key: 'meusPontos', label: 'Meus Pontos', sortable: true, class: 'text-center' },
                { key: 'premio', label: 'Prêmio', sortable: true, class: 'text-center' }],
            items: [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc'
        };
    },
    props: ["cols", "sm", "md", "lg", "xl", "showTitle"],
    beforeMount() {
        this.getGraduacoes();
    },
    methods: {
        getGraduacoes() {
            this.$loading(true);
            http.get("/geral/obterGraduacoesPontoUsuario").then(success => {
                success.data.graduacoes.forEach(item => {
                    this.items.push({
                        nome: item.nome,
                        vme: item.percentualPremiacao + '%',
                        pontos: item.pontos,
                        meusPontos: item.meusPontos,
                        premio: item.premio,
                        _rowVariant: item.idGraduacao == success.data.idGraduacaoLogado ? 'row-active-custom' : ''
                    });
                });

                this.$loading(false);
            });
        }
    }
};