import "./cadastroComunicado.scss";
import http from "@/_helper/api-services.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
    name: "cadastroComunicados",
    data() {
        return {
            comunicado: {
                idMensagem: null,
                titulo: String(),
                mensagem: String(),
                graduacoes: [],
                arquivo: null,
                base64Arquivo: String(),
                dataInicio: new Date(),
                dataFim: new Date(new Date().setHours(23, 59)),
                urlImagem: String(),
            },
            graduacoes: [],
            todasGraduacoes: false,
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            customToolbar: [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                [{ header: 1 }, { header: 2 }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link", "formula"],
                [{ direction: "rtl" }],
                ["clean"],
            ],
        };
    },
    components: {
        DatePicker,
    },
    watch: {
        "comunicado.graduacoes"() {
            if (this.comunicado.graduacoes.length == this.graduacoes.length)
                this.todasGraduacoes = true;
            else this.todasGraduacoes = false;
        },
        "comunicado.arquivo"(e) {
            let reader = new FileReader();
            reader.readAsDataURL(e);
            reader.onload = () => {
                this.comunicado.base64Arquivo = reader.result;
            };
        },
        todasGraduacoes() {
            if (this.todasGraduacoes) {
                this.graduacoes.forEach((g) => {
                    if (
                        this.comunicado.graduacoes.filter(
                            (cg) => cg == g.idGraduacao
                        ).length == 0
                    )
                        this.comunicado.graduacoes.push(g.idGraduacao);
                });
            } else {
                if (this.comunicado.graduacoes.length == this.graduacoes.length)
                    this.comunicado.graduacoes = [];
            }
        },
    },
    async beforeMount() {
        await this.getGraduacoes();
    },
    async mounted() {
        if (this.$route.params.idMensagem) await this.getComunicado();
        this.$loading(false);
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();
            this.$loading(true);

            let data = {
                titulo: this.comunicado.titulo,
                texto: this.comunicado.mensagem,
                mensagemGraduacao: this.comunicado.graduacoes.map((g) => {
                    return { idGraduacao: g };
                }),
                dataInicio: this.comunicado.dataInicio,
                dataFim: this.comunicado.dataFim,
                base64Arquivo: this.comunicado.base64Arquivo,
                urlArquivo: this.comunicado.urlImagem,
            };
            if (!this.comunicado.idMensagem)
                http.post("/comunicado", data)
                    .then(
                        (success) => {
                            if (success && success.data)
                                this.$snotify.success(success.data.message);

                            this.$router.push("/painel/admin/comunicados");
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            else {
                data.idMensagem = this.comunicado.idMensagem;
                http.update("/comunicado", data)
                    .then(
                        (success) => {
                            if (success && success.data)
                                this.$snotify.success(success.data.message);

                            this.$router.push("/painel/admin/comunicados");
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        // async handleReset() {
        //     if (this.comunicado.idMensagem) {
        //         this.$loading(true);
        //         this.comunicado.titulo = "";
        //         this.comunicado.mensagem = "";
        //         this.comunicado.graduacoes = [];
        //         this.comunicado.dataInicio = new Date();
        //         this.comunicado.dataFim = new Date();
        //         await this.getComunicado();
        //         this.$loading(false);
        //     } else {
        //         this.comunicado.titulo = "";
        //         this.comunicado.mensagem = "";
        //         this.comunicado.graduacoes = [];
        //         this.comunicado.arquivo = null;
        //     }
        // },
        async getGraduacoes() {
            await http.get("/admin/listaGraduacoes").then(
                (success) => {
                    if (success && success.data) this.graduacoes = success.data;
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async getComunicado() {
            await http
                .get(
                    `/comunicado/obterComunicado/${this.$route.params.idMensagem}`
                )
                .then(
                    (success) => {
                        if (success && success.data) {
                            this.comunicado.idMensagem =
                                success.data.idMensagem;
                            this.comunicado.titulo = success.data.titulo;
                            this.comunicado.mensagem = success.data.texto;
                            this.comunicado.graduacoes =
                                success.data.graduacoes;
                            this.comunicado.dataInicio = new Date(
                                success.data.dataInicio
                            );
                            this.comunicado.dataFim = new Date(
                                success.data.dataFim
                            );
                            this.comunicado.urlImagem = success.data.urlArquivo;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                );
        },
        excluirImagem() {
            this.$loading(true);
            http.update("/comunicado/ExcluirImagem", {
                idMensagem: this.comunicado.idMensagem,
            })
                .then(
                    () => {
                        this.comunicado.urlImagem = "";
                        this.$bvModal.hide("confirmar-excluir-imagem");
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
    },
};
