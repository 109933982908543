import http from "@/_helper/api-services";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import Graduacao from "@/components/Graduacao/Graduacao.vue";
import TreeviewItem from "@/components/TreeviewItem/TreeviewItem.vue";
import "./minhaRede.scss";

export default {
  name: "minhaRede",
  data() {
    return {
      listDados: [],
      dadosUsuario: null,
      filter: String(),
      exportFields: {
        Nome: "nome",
        Login: "login",
        Graduação: { field: "graduacao.nome" },
        Celular: "celular",
        "Produto Ativo": "produtoAtivo",
        "E-mail": "email",
      },
      exportTable: [],
    };
  },
  components: {
    BarraIndicacao,
    Graduacao,
    TreeviewItem,
  },
  computed: {
    listaFilter: function () {
      return this.listDados.filter(
        (f) => f.nome.toLowerCase().indexOf(this.filter.toLowerCase()) != -1
      );
    },
  },
  filters: {
    dataFormatada: function (valor) {
      return moment(String(valor)).format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.listarDados();
  },
  methods: {
    listarDados() {
      this.$loading(true);
      http.get("/user/diretosUsaurioLogado").then((responde) => {
        responde.data.forEach((item) => {
          this.listDados.push({
            idUsuario: item.idUsuario,
            nome: item.nome,
            login: item.login,
            graduacao: item.graduacao,
            celular: item.celular,
            email: item.email,
            urlImg: item.urlImg,
            temFilhos: item.temFilhos,
            produtoAtivo: item.produtoAtivo,
            pontuacao: item.pontuacao,
          });
        });
        this.exportTable = [];
        for (let i in this.listDados) {
          this.exportTable.push(this.listDados[i]);
        }

        this.$loading(false);
      });
    },
  },
};
