import "./MenuMobileAdmin.scss";
import http from "@/_helper/api-services.js";
import Drawer from "vue-simple-drawer";

export default {
  name: "MenuMobileAdmin",
  components: {
    Drawer,
  },
  data() {
    return {
      menus: {},
      dadosUser: {},
      resumoValores: {},
      open: false,
      align: "left",
      innerOpen: false,
      mask: false,
      maskClosable: false,
      close: true,
    };
  },
  watch: {
    idUsuario(el) {
      if (el) {
        var menu = localStorage.getItem("menu");
        if (menu) {
          this.menus = JSON.parse(menu).filter((u) => !u.rotaPublica);
        }
      }
    },
  },
  props: ["idUsuario", "exibir", "emAcessoRemoto"],
  mounted() {
    var menu = localStorage.getItem("menu");
    if (menu) {
      this.menus = JSON.parse(menu).filter((u) => !u.rotaPublica);
    }
    this.dadosUser = this.$store.getters["autenticacao/getLogin"].user;
    this.obterResumoValores();
  },
  methods: {
    ativarMenu(index, subMenu) {
      let close = false;
      if (!subMenu) {
        if (this.menus[index].show) close = true;

        this.menus.forEach((menu) => {
          menu.show = false;
        });

        if (!close) this.menus[index].show = true;
      } else {
        if (
          this.menus[index].subMenus &&
          this.menus[index].subMenus[subMenu].show
        )
          close = true;

        this.menus[index].subMenus[subMenu].show = false;

        if (!close) {
          this.menus[index].show = true;
          this.menus[index].subMenus[subMenu].show = true;
        }
      }
    },
    toggle() {
      this.open = !this.open;
      if (!this.menus) {
        var menu = localStorage.getItem("menu");
        this.menus = JSON.parse(menu).filter((u) => !u.rotaPublica);
        this.dadosUser = this.$store.getters["autenticacao/getLogin"].user;
        this.obterResumoValores();
      }
    },
    closeDrop() {
      this.open = false;
    },
    obterResumoValores() {
      const loggedIn = localStorage.getItem("user");
      if (loggedIn) {
        http.get("/user/getSaldo/").then((responde) => {
          this.resumoValores = responde.data;
        });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
