import FormCredenciamento from "@/components/FormCredenciamento/FormCredenciamento.vue";

export default {
    name: "maisVendas",
    data() {
        return {};
    },
    components: {
        FormCredenciamento
    },
    mounted() {
        let user = this.$store.getters['autenticacao/getLogin'].user;
        if(user != null) this.$router.push("/");
    }
}