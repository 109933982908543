import http from "@/_helper/api-services";

export const usuarioService = {
    login(login, senha) {
        const dados = {
            login,
            senha,
        };

        return http
            .login("/UsuarioLogin/autenticacao", dados)
            .then((user) => {
                if (user.data.token) {
                    localStorage.setItem("user", JSON.stringify(user.data));
                    localStorage.setItem("showComunicado", "true");
                    
                    $cookies.set("policy-accepted", user.data.termosDeAceite);
                }
                return user.data;
            })
            .catch((error) => {
                throw error;
            });
    },
    loginGoogle(code, redirectUri) {
        const dados = {
            code,
            redirectUri,
        };

        return http
            .login("/UsuarioLogin/autenticacaoGoogle", dados)
            .then((user) => {
                if (user.data.token) {
                    localStorage.setItem("user", JSON.stringify(user.data));
                    localStorage.setItem("showComunicado", "true");
                    
                    $cookies.set("policy-accepted", user.data.termosDeAceite);
                }
                return user.data;
            })
            .catch((error) => {
                throw error;
            });
    },
    register(user) {
        return http.post("/user/registrar", user).then(
            (success) => {
                return success;
            },
            (error) => {
                throw error;
            }
        );
    },
    googleRegister(form) {
        return http.post("/user/registrarGoogle", form).then(
            (success) => {
                return success;
            },
            (error) => {
                throw error;
            }
        );
    },
    resetPassword(email) {
        return http.get(`/usuariologin/esqueciminhasenha/${email}`).then(
            (success) => success,
            (error) => {
                throw error;
            }
        );
    },
    validateToken(token) {
        return http.post(`/usuariologin/abrirtoken/${token}`).then(
            (success) => success,
            (error) => {
                throw error;
            }
        );
    },
    updatePassword(data) {
        return http.post("/UsuarioLogin/alterarSenha", data).then(
            (success) => success,
            (error) => {
                throw error;
            }
        );
    },
    checkSponsor(sponsor) {
        return http.get(`/user/verificarPatrocinador/${sponsor}`).then(
            (success) => success,
            (error) => {
                throw error;
            }
        );
    },
    confirmEmail(token) {
        return http.post(`/UsuarioLogin/confirmarConta/${token}`).then(
            (success) => success,
            (error) => {
                throw error;
            }
        );
    },
    resendEmailConfirmation(login) {
        return http
            .post("/UsuarioLogin/reenviarEmailConfirmacao", { login })
            .then(
                (success) => success,
                (error) => {
                    throw error;
                }
            );
    },
    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("menu");
        localStorage.removeItem("userAdmin");
    },
};

export default usuarioService;
