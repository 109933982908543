import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "MaterialApoioEditar",
    components: {
        tabelaBase,
    },
    props: {
        idMaterial: { type: Number, default: 0 },
    },
    data() {
        return {
            mapoio: {
                idMaterial: Number(),
                nome: String(),
                descricao: String(),
                ativo: Boolean(),
                base64: String()
            },
            status: [
                { value: true, text: "Ativo" },
                { value: false, text: "Inativo" },
            ]

        };
    },
    computed: {},
    async beforeMount() {
        if (this.$route.query.idMaterial && this.$route.query.idMaterial != 0) {
            this.mapoio.idMaterial = +this.$route.query.idMaterial;
            await this.obterDadosMaterial();
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    methods: {
        async obterDadosMaterial() {
            this.$loading(true);

            await http
                .get(`/materialapoio/obterMaterial/${this.mapoio.idMaterial}`)
                .then(
                    (success) => {
                        if (success.data) {
                            this.mapoio = success.data.dadosMaterial;
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async editarDadosMaterial() {
            this.$loading(true);

            await http
                .post(`/materialapoio/editarMaterial`, this.mapoio)
                .then(
                    (success) => {
                        if (success) {
                            this.$snotify.success("Material de apoio editado com sucesso");
                            window.location.href = "/painel/admin/material-apoio-admin";
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async criarMaterial() {
            this.$loading(true);
            var dados = this.mapoio

            await http
                .post(`/materialapoio/criarMaterial`, dados)
                .then(
                    (success) => {
                        if (success) {
                            this.$snotify.success("Material de apoio criado com sucesso");
                            window.location.href = "/painel/admin/material-apoio-admin";
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        voltarTela() {
            window.location.href = "/painel/admin/material-apoio-admin";
        },
        teste(file) {
            var reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.onload = () => {
                this.mapoio.base64 = reader.result;
            };
        }
    }
}