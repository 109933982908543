import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "PainelCarrossel",
    components: {
        tabelaBase,
    },
    data() {
        return {
            carrosseis: {
                items: [],
                title: " ",
                fields: [
                    {
                        key: "nome",
                        label: "Nome",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "dataCriacao",
                        label: "Data de criação",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "ultimaAtualizacao",
                        label: "Última atualização",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "ativo",
                        label: "Status",
                        sortable: false,
                        class: "text-center",
                        formatter: (value) => (value ? "Ativo" : "Inativo"),
                        class: "text-center",
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                    },
                ],
                sortBy: "nome",
                sortDesc: false,
                exportExcel: false,
                perPage: null,
            },
        };
    },
    async beforeMount() {
        await this.obterCarrosseis();
    },
    methods: {
        async obterCarrosseis() {
            this.$loading(true);

            http.get("/carrossel/listarCarrosseis/")
                .then(
                    (success) => {
                        if (success.data) {
                            this.carrosseis.items = success.data;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async criarParceiro() {
            window.location.href = "/painel/parceiros/novo-parceiro";
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        editCarrossel(idCarrossel) {
            window.location.href =
                "/painel/admin/carrosseis/editar-carrossel?idCarrossel=" +
                idCarrossel;
        },
    },
};
