import http from "@/_helper/api-services.js";

export default {
  name: "PainelComerciante",
  data() {
    return {
      saldo: 0,
      faturasAbertas: 0,
      faturasPagas: 0,
      totalEntradas: 0,
      totalSaidas: 0,
      totalVendas: 0,
      totalFaturas: 0,
      fieldsUltimasVendas: [
        // { key: "usuario", label: "Usuário", class: "text-center" },
        // { key: "patrocinador", label: "Patrocinador", class: "text-center" },
        {
          key: "dataPedido",
          label: "Data venda",
          class: "text-center",
        },
        {
          key: "forma",
          label: "Forma de pagamento",
          class: "text-center",
        },
        {
          key: "percentual",
          label: "Percentual de cashback",
          class: "text-center",
          formatter: (val) => (val ? `${val}%` : ""),
        },
        {
          key: "valorPedido",
          label: "Valor total",
          class: "text-center",
          formatter: (value) => this.formatValue(value),
        },
      ],
      listUltimasVendas: [],
      botaoVisivel: false,
      fieldsFaturas: [
        {
          key: "mesReferencia",
          label: "Mês referência",
          class: "text-center",
        },
        {
          key: "dataPagamento",
          label: "Data Pagamento",
          class: "text-center",
          formatter: (val) => {
            if (!val) return "";
            return new Date(val).toLocaleDateString();
          },
        },
        { key: "status", label: "Status", class: "text-center" },
        {
          key: "valor",
          label: "Valor",
          class: "text-center",
          formatter: (val) => {
            if (!val) return "";
            return val.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        { key: "boleto", label: "Boleto", class: "text-center" },
      ],
      itemsFaturas: [],
    };
  },
  async beforeMount() {
    this.verificaPerfil();
    this.$loading(true);
    await this.getResumoConta();
    await this.getUltimasVendas();
    await this.getFaturas();
    this.$loading(false);
  },
  methods: {
    async getUltimasVendas() {
      await http.get("/credenciamento/ObterUltimasVendas").then((success) => {
        success.data.forEach((pedido) => {
          this.listUltimasVendas.push({
            valorPedido: pedido.valorPedido,
            percentual: pedido.percentual,
            forma: pedido.forma,
            dataPedido: pedido.dataPedido
              ? new Date(pedido.dataPedido).toLocaleDateString()
              : "",
          });
        });
      });
    },
    formatValue(value) {
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    getGraficoUsuarios() {
      http.get("/Comerciante/RelatorioCadastrosMes").then(
        () => {},
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },
    verificaPerfil() {
      http.get("/Credenciamento/verificaPerfil").then(
        (response) => {
          this.botaoVisivel = response.data;
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },
    getQuantidadeUsuariosPorGraduacao() {
      http.get("/Admin/TotalUsuariosGraduacao").then(
        (success) => {
          this.listGraduacao = success.data;
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },
    async getResumoConta() {
      await http.get("/credenciamento/ObterResumoConta").then((success) => {
        this.totalEntradas = success.data.totalEntradas;
        this.totalVendas = success.data.totalVendas;
        this.totalSaidas = success.data.totalSaidas;
        this.totalFaturas = success.data.valorTotalFaturas;
        this.faturasAbertas = success.data.valorFaturasAbertas;
        this.faturasPagas = success.data.valorFaturasPagas;
        this.saldo = success.data.totalEntradas - success.data.totalSaidas;
      });
    },
    async getFaturas() {
      await http
        .post("/fatura/ObterFaturas", {
          pagina: 1,
          quantidadePorPagina: 10,
        })
        .then((success) => {
          let dataForTable = [];
          success.data.faturasFiltradas.forEach((fatura) => {
            let status = "";
            if (fatura.expired && !fatura.dataPagamento) {
              status = "Vencida";
            } else if (fatura.urlBoleto) {
              if (!fatura.dataPagamento) status = "Aguardando pagamento";
              else status = "Paga";
            } else status = "Em aberto";
            if (
              status == "Em aberto" ||
              status == "Vencida" ||
              status == "Aguardando pagamento"
            ) {
              dataForTable.push({
                mesReferencia: `${String(
                  "0" + (new Date(fatura.dataInicio).getMonth() + 1)
                ).padStart(-2)}/${new Date(fatura.dataInicio).getFullYear()}`,
                dataPagamento: fatura.dataPagamento,
                status,
                valor: fatura.valor,
                boleto: fatura.urlBoleto,
                expirada: fatura.expired,
                codigo: fatura.codigoBoleto,
              });
            }
          });

          this.itemsFaturas = dataForTable;
        });
    },
  },
};
