var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tabelaBase',_vm._b({scopedSlots:_vm._u([{key:"cell(pago)",fn:function(data){return [(data.item.pago && data.item.ativo && !data.item.cancelado)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Pedido Ativo")]):(!data.item.pago && !data.item.ativo && !data.item.cancelado)?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Inativo")]):(data.item.pago && data.item.ativo && data.item.cancelado)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Pedido Cancelado")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Cancelado")])]}},{key:"cell(acoes)",fn:function(data){return [_c('b-link',{staticClass:"link-donwload ml-1",attrs:{"to":`/painel/detalhamento-compra?codigo=${encodeURIComponent(
        data.item.codigo
      )}`,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"pointer",attrs:{"title":"Detalhes do pedido","icon":['fas', 'info-circle']}})],1),(data.item.acoes.some((acao) => acao == 'soliciarCancelamento'))?_c('b-link',{staticClass:"link-donwload ml-1",attrs:{"to":`/painel/suporte/solicitacao?tipoContato=3&codigo=${encodeURIComponent(
        data.item.codigo
      )}&idPedido=${encodeURIComponent(data.item.idPedido)}`}},[_c('font-awesome-icon',{staticClass:"pointer",attrs:{"title":"Solicitar cancelamento","icon":['fas', 'times-circle']}})],1):_vm._e(),(data.item.acoes.some((acao) => acao == 'solicitarReabertura'))?_c('b-link',{staticClass:"link-donwload ml-1",attrs:{"to":`/painel/suporte/solicitacao?tipoContato=4&codigo=${encodeURIComponent(
        data.item.codigo
      )}&idPedido=${encodeURIComponent(data.item.idPedido)}}`}},[_c('font-awesome-icon',{staticClass:"pointer link-donwload",attrs:{"title":"Solicitar reabertura","icon":['fas', 'recycle'],"mask":['fas', 'circle'],"transform":"shrink-3","inverse":""}})],1):_vm._e()]}}])},'tabelaBase',_vm.minhasAssinaturas,false))
}
var staticRenderFns = []

export { render, staticRenderFns }