import http from '@/_helper/api-services';
import './Home.scss'
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import Graduacao from "@/components/Graduacao/Graduacao.vue";
import tabelaMeusDiretos from "@/components/TabelaMeusDiretos/tabelaMeusDiretos.vue";
import tabelaPremiacoes from "@/components/TabelaPremiacoes/TabelaPremiacoes.vue";
import tabelaRank from "@/components/TabelaRank/TabelaRank.vue";

export default {
    name: 'Home',
    data() {
        return {
            dadosUsuario: {},
            dadosBarraProgresso: {},
            teste: Array()
        }
    },
    components: {
        BarraIndicacao,
        Graduacao,
        tabelaMeusDiretos,
        tabelaPremiacoes,
        tabelaRank
    },
    beforeMount() {
        this.obterDadosUsuario();
        this.obterDadosBarraProgresso();
    },
    methods: {
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/obterBarraStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
                this.$loading(false);
            });
        },
        obterDadosBarraProgresso() {
            this.$loading(true);
            http.get('/Dashboard/limitesDeGanhos/').then((response) => {
                this.dadosBarraProgresso = response.data;
                this.$loading(false);
            });
        },
        onCopy(e) {
            this.$snotify.success("Link copiado com sucesso.");
        },
        onError(e) {
            this.$snotify.error("Ocorreu um erro inesperado.");
        },
    }
}