<template>
  <b-container class="bg-white text-dark p-3 rounded">
    <b-row>
      <b-col
        v-for="(produto, index) in produtos"
        :key="index"
        cols="12"
        xl="6"
        class="d-flex align-items-stretch mb-4 product"
      >
        <div>
          <div class="text-center">
            <b-img
              :src="produto.merchant_image_url"
              :alt="produto.product_name"
              fluid
              class="mb-3 rounded"
              style="
                max-height: 200px;
                background-color: #f5f5f5;
                border: 1px solid #f5f5f5;
              "
            ></b-img>
          </div>
          <b-card-body
            class="d-flex flex-column align-items-center justify-content-center text-center"
          >
            <h5 class="mb-2 text-uppercase">
              {{ truncateProductName(produto.product_name) }}
            </h5>
            <p class="material mb-1">
              {{ truncateProductName(produto.brand_name) }}
            </p>
            <h4 class="price mb-3 text-green">
              {{ produto.search_price | formatCurrency }}
            </h4>
            <a :href="produto.aw_deep_link" target="_blank" class="mt-auto">
              <span class="btn green-button-2 btn-block mt-1">
                <font-awesome-icon
                  :icon="['fa', 'shopping-cart']"
                  class="mr-2"
                />
                Comprar
              </span>
            </a>
          </b-card-body>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import http from "@/_helper/api-services.js";
export default {
  components: {},
  async beforeMount() {
    this.$loading(true);
    await this.buscarProdutos();
    this.$loading(false);
  },
  data() {
    return {
      produtos: [],
    };
  },
  methods: {
    async buscarProdutos() {
      this.$loading(true);
      await http.get("/v2/dashboard/get-products").then(
        (success) => {
          this.produtos = success.data.data;
          this.$loading(false);
        },
        () => {
          this.$snotify.error("Erro ao buscar os produtos");
          this.$loading(false);
        }
      );
      this.$loading(false);
    },
    truncateProductName(name) {
      return name.length > 28 ? name.slice(0, 28) : name;
    },
  },
};
</script>

<style scoped>
.product-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-card h5 {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.stars {
  color: #f39c12;
  font-weight: bold;
}

.reviews {
  margin-left: 5px;
  color: #888;
  font-size: 14px;
}

.material {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.price {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.green-button-2 {
  background-color: #1e5d68 !important;
  color: white !important;
  border: 0;
  border-radius: 6px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 26px 7px;
}
.card {
  min-height: 88px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: none;
  background-color: white;
  border: 1px solid #eaebed;
  border-radius: 8px;
  box-shadow: 0 1px 2px #010f1c1a;
  transition: all 0.3s ease-out 0s;
  font-family: "Poppins", sans-serif !important;
}

.card:hover {
  box-shadow: 0 12px 14px #010f1c0f;
}

.text-green {
  color: #1e5d68;
}

@media (min-width: 1367px) {
  .product:first-child {
    border-right: 1px solid #f5f5f5;
    border-bottom: none;
  }
}

@media (max-width: 1366px) {
  .product:first-child {
    border-right: none;
    border-bottom: 1px solid #f5f5f5;
  }
}
</style>
