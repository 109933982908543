import http from "@/_helper/api-services";
import "./MinhasCompras.scss";
import DatePicker from "vue2-datepicker";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import "vue2-datepicker/index.css";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "MinhasCompras",
    data() {
        return {
            locale: "pt",
            search: {
                produto: String(),
                valor: null,
                cashback: null,
                data: null,
                status: null,
                ordenacao: null,
            },
            tabelaMinhasCompras: {
                fields: [
                    {
                        key: "produto",
                        label: "Descrição",
                        sortable: true,
                    },
                    {
                        key: "valor",
                        label: "Valor da compra",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "cashbackReceber",
                        label: "Cashback a receber",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "data",
                        label: "Data Compra",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "detalhes",
                        label: "Detalhes",
                        sortable: true,
                        class: "text-center",
                    },
                ],
                items: [],
                exportFields: {
                    Descrição: "produto",
                    Valor: "valor",
                    "Cashback a receber": "cashbackReceber",
                    "Data Compra": "data",
                    Status: "status",
                },
                exportTable: [],
                sortBy: String(),
                sortDesc: true,
                exportExcel: true,
                perPage: 10,
                title: "Minhas Compras",
            },
            fieldsDetalhes: [
                {
                    key: "descricao",
                    label: "Descrição",
                    sortable: true,
                },
                {
                    key: "dataAtualizacao",
                    label: "Data Atualização",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "status",
                    label: "Status",
                    sortable: true,
                    class: "text-center",
                },
            ],
            fieldsDistribuicao: [
                {
                    key: "login",
                    label: "Login",
                    sortable: true,
                },
                {
                    key: "valor",
                    label: "Valor",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "descricao",
                    label: "Descrição",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "data",
                    label: "Data Lançamento",
                    sortable: true,
                    class: "text-center",
                },
            ],
            listDados: [],
            listTipoFiltro: [{ value: 0, text: "Todos" }],
            totalRows: 1,
            currentPage: 1,
            perPage: 50,
            pageOptions: [10, 20, 50, 100],
            sortBy: String(),
            sortDesc: true,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            filtroBusca: {
                descricao: String(),
                dataInicio: null,
                dataFim: null,
                idStatus: 0,
            },
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            detalhesSelecionado: Array(),
            listaDistribuicao: Array(),
            exportFields: {
                Descrição: "produto",
                Valor: "valor",
                "Cashback a receber": "cashbackReceber",
                "Data Compra": "data",
                Status: "status",
                Detalhes: "detalhes",
            },
            exportTable: [],
        };
    },
    watch: {
        async sortBy() {
            switch (this.$data.sortBy) {
                case "produto":
                    this.search.ordenacao = 0;
                    break;
                case "valor":
                    this.search.ordenacao = 1;
                    break;
                case "cashbackReceber":
                    this.search.ordenacao = 2;
                    break;
                case "data":
                    this.search.ordenacao = 3;
                    break;
                case "status":
                    this.search.ordenacao = 4;
                    break;
            }

            await this.buscarPedidos();
        },
    },
    components: {
        DatePicker,
        BarraIndicacao,
        tabelaBase,
    },
    mounted() {
        var dataAtual = new Date();
        var primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        var ultimoDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);

        this.filtroBusca.dataInicio = primeiroDiaDoMes;
        this.filtroBusca.dataFim = ultimoDiaDoMes;

        this.buscarPedidos();
    },
    methods: {
        buscarPedidos() {
            this.$loading(true);
            this.tabelaMinhasCompras.items = [];
            http.post("/Pedidos/listaPedidosAfiliados", this.filtroBusca)
                .then(
                    (response) => {
                        if (response && response.status == 200) {
                                const data = Array.isArray(response.data) ? response.data : [];

                                data.forEach((item) => {
                                    let descricao = "";
                                    if (item.idUsuarioComerciante && item.transacao) {
                                        descricao = item.transacao.descricao;
                                    }
                                    else if (item.pedidoDetalhe[0]) {
                                        descricao = item.pedidoDetalhe[0].descricao;
                                    }
                                    else if (item.transacao) {
                                        item.transacao.descricao;
                                    }

                                    let status = "Pendente";
                                    if (item.idUsuarioComerciante && item.transacao) {
                                        if (item.transacao.statusViewModel.idStatus == 7) {
                                            status = "Pendente";
                                        }
                                        else {
                                            status = item.transacao.statusViewModel.nome;
                                        }
                                    } else if (item.pedidoDetalhe[0]) {
                                        status = item.pedidoDetalhe[item.pedidoDetalhe.length - 1].status.nome;
                                    }

                                    this.tabelaMinhasCompras.items.push({
                                        idPedido: item.idPedido,
                                        transacao: item.transacao,
                                        codigoPedido: item.codidoPedido,
                                        produto: descricao,
                                        data: item.dataPedido,
                                        valor: item.valorPedido,
                                        status: status,
                                        detalhes: item.pedidoDetalhe,
                                        cashback: item.cashback
                                            ? item.cashback.toLocaleString("pt-br", {
                                                style: "currency",
                                                currency: "BRL",
                                            })
                                            : "----",
                                        cashbackReceber: item.cashbackReceber
                                            ? item.cashbackReceber.toLocaleString("pt-br", {
                                                style: "currency",
                                                currency: "BRL",
                                            })
                                            : "----",
                                    });
                                });
                                this.tabelaMinhasCompras.exportTable = this.tabelaMinhasCompras.items;
                        }
                    },
                )
                .catch(error => {
                    let errorMessage = "Ocorreu um erro desconhecido";

                    if (error.response && error.response.data) {
                        if (error.response.data.message)
                            errorMessage = error.response.data.message;
                    } else if (error.message)
                        errorMessage = error.message;

                    this.$snotify.error(errorMessage);
                })
                .finally(() => {
                    this.$loading(false);
                });
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        formatDate(data) {
            let dateTime = new Date(data);
            return (
                dateTime.toLocaleDateString() +
                " " +
                dateTime.toLocaleTimeString()
            );
        },
        abrirModal(pedido) {
            this.detalhesSelecionado = pedido.detalhes;
            let idPedido = pedido.idPedido;
            this.listaDistribuicao = [];

            if (pedido.transacao) {
                this.buscarDistribuicaoCashback(idPedido);
            }

            this.$bvModal.show("modalDetalhes");
        },
        buscarDistribuicaoCashback(idPedido) {
            this.$loading(true);
            http.get(`/Pedidos/obterDistribuicaoCashback/${idPedido}`)
                .then((response) => {
                    response.data.forEach((item) => {
                        this.listaDistribuicao.push({
                            login: item.login,
                            data: this.formatDate(item.dataLancamento),
                            descricao: item.descricao,
                            valor: item.valor.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            }),
                        });
                    });
                })
                .finally(() => {
                    this.$loading(false);
                });
        },
    },
};
