export const transporteDados = {
  namespaced: true,
  state: {
    isRegisterOpen: false,
  },
  mutations: {
    openRegister(state) {
      state.isRegisterOpen = !state.isRegisterOpen;
    },
  },
  actions: {
    open({ dispatch, commit }) {
      commit("openRegister");
    },
  },
  getters: {},
};
