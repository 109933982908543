import http from "@/_helper/api-services.js";

export default {
    name: "formCategoria",
    props: {
        idCategoria: { type: Number, default: 0 },
    },
    data() {
        return {
            opcoesPai: [],
            categoria: {
                idCategoria: 0,
                nome: String,
                ativo: Boolean,
                idCategoriaPai: null,
            },
            dataCriacao: null,
            dataAtualizacao: null,
        };
    },
    computed: {},
    async beforeMount() {
        await this.obterDadosCricao();
    },
    methods: {
        async obterDadosCricao() {
            this.$loading(true);
            await http
                .get("/Categoria/obterDadosCategorias")
                .then((success) => {
                    let data = success.data.categoriaPaiOptions;
                    data.forEach(
                        (item) => {
                            this.opcoesPai.push({
                                value: item.key,
                                text: item.value,
                            });
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    );
                });

            this.categoria.nome = "";
            this.categoria.ativo = true;

            if (this.$props.idCategoria > 0) {
                await http
                    .get("/Categoria/obterCategoria/" + this.$props.idCategoria)
                    .then(
                        (success) => {
                            this.categoria.nome = success.data.nome;
                            this.categoria.ativo = success.data.ativo;
                            this.categoria.idCategoriaPai =
                                success.data.idCategoriaPai;
                            this.categoria.idCategoria =
                                success.data.idCategoria;
                            this.categoria.ativo = success.data.ativo;
                            this.dataCriacao = this.formatDate(
                                success.data.dataCriacao
                            );
                            this.dataAtualizacao = this.formatDate(
                                success.data.dataAtualizacao
                            );
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    );
            }
            this.$loading(false);
        },
        async handleSubmit() {
            this.$loading(true);
            if (this.$props.idCategoria == 0) {
                await http
                    .post("/Categoria/criarCategoria", this.categoria)
                    .then(
                        (success) => {
                            this.$snotify.success(
                                "Categoria criada com sucesso."
                            );
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    );
            } else {
                this.categoria.idCategoria = +this.$props.idCategoria;
                await http
                    .post("/Categoria/atualizarCategoria", this.categoria)
                    .then(
                        (success) => {
                            this.$snotify.success(
                                "Categoria atualizada com sucesso."
                            );
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    );
            }
            this.$loading(false);
        },
        voltarTela() {
            window.location.href = "/painel/admin/categorias";
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
    },
};
