import http from "@/_helper/api-services.js";
import "./SuporteAdmin.scss";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
  name: "SuporteAdmin",
  data() {
    return {
      solicitacoes: {
        fields: [
          {
            key: "loginUsuario",
            label: "Login usuário",
            sortable: true,
            sortDirection: "desc",
            class: "text-center small",
          },
          {
            key: "loginPatrocinador",
            label: "Login patrocinador",
            sortDirection: "desc",
            class: "text-center small",
          },
          {
            key: "dataSolicitacao",
            label: "Data Solicitação",
            sortable: true,
            class: "text-center small",
            formatter: (value) =>
              `${new Date(value).toLocaleDateString()} ${new Date(
                value
              ).toLocaleTimeString()}`,
          },
          {
            key: "dataAtualizacao",
            label: "Data Atualização",
            sortable: true,
            class: "text-center small",
            formatter: (value) =>
              value
                ? `${new Date(value).toLocaleDateString()} ${new Date(
                  value
                ).toLocaleTimeString()}`
                : "",
          },
          {
            key: "status",
            label: "Status",
            sortable: true,
            class: "text-center small",
          },
          {
            key: "tipo",
            label: "Tipo",
            sortable: true,
            class: "text-center small",
          },
          {
            key: "acoes",
            label: "Ações",
            class: "text-center small",
          },
        ],
        items: [],
        sortBy: "dataSolicitacao",
        sortDesc: true,
        exportExcel: false,
      },
      fields: [
        {
          key: "idSuporte",
          label: "#",
          sortable: true,
          sortDirection: "desc",
          class: "d-none",
        },
        {
          key: "historicoSuporte",
          label: "#",
          sortable: true,
          sortDirection: "desc",
          class: "d-none",
        },
        {
          key: "numeroPedido",
          label: "Nº pedido",
          sortable: true,
          sortDirection: "desc",
          class: "small",
        },
        {
          key: "login",
          label: "Login",
          sortable: true,
          sortDirection: "desc",
          class: "small",
        },
        {
          key: "nome",
          label: "Nome",
          sortable: true,
          sortDirection: "desc",
          class: "small",
        },
        { key: "celular", label: "Telefone", sortable: false, class: "small" },
        { key: "email", label: "E-mail", sortable: true, class: "small" },
        { key: "siteCompra", label: "Loja", sortable: false, class: "small" },
        {
          key: "observacao",
          label: "Descrição",
          sortable: false,
          class: "d-none",
        },
        {
          key: "valorPedido",
          label: "Valor",
          sortable: true,
          class: "text-center small",
          formatter: (value) =>
            value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
        },
        {
          key: "dataCompra",
          label: "Data Pedido",
          sortable: true,
          class: "text-center small",
          formatter: (value) => `${new Date(value).toLocaleDateString()}`,
        },
        {
          key: "dataSolicitacao",
          label: "Data Solicitação",
          sortable: true,
          class: "text-center small",
          formatter: (value) =>
            `${new Date(value).toLocaleDateString()} ${new Date(
              value
            ).toLocaleTimeString()}`,
        },
        {
          key: "urlComprovante",
          label: "Comprovante",
          class: "text-center small",
          sortable: false,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "text-center small",
        },
        {
          key: "observacaoAdmin",
          label: "Observação",
          sortable: true,
          class: "text-center",
        },
        {
          key: "opcoes",
          label: "Opções",
          sortable: true,
          class: "text-center small",
        },
      ],
      exportFields: {
        Login: "login",
        Nome: "nome",
        Telefone: "celular",
        "E-mail": "email",
        Loja: "siteCompra",
        Descrição: "observacao",
        Valor: "valorPedido",
        "Data Pedido": "dataCompra",
        "Data Solicitação": "dataSolicitacao",
        Comprovante: "urlComprovante",
        Status: {
          field: "status.nome",
        },
        Observação: "observacaoAdmin",
      },
      items: [],
      exportTable: [],
      sortBy: "",
      sortDesc: false,
      filtro: {
        loginPatrocinador: String(),
        loginUsuario: String(),
        idStatus: null,
        idTipo: null,
        codigoPedido: String(),
        dataInicioIncio: null,
        dataInicioFim: null,
        dataAtualizacaoInicio: null,
        dataAtualizacaoFim: null,
        page: 1,
        perPage: 50,
      },
      tipoOptions: [
        { value: null, text: "Todos" },
        { value: 1, text: "Contato" },
        { value: 2, text: "Cashback não pago" },
        { value: 3, text: "Cancelamento do parcelamento" },
        { value: 4, text: "Reabertura do parcelamento" },
      ],
      statusOptions: [{ value: null, text: "Todos" }],
      pageOptions: [10, 20, 50],
      showIconsToChangePage: false,
      totalPages: 1,
      totalRegistros: 0,
      imagemComprovante: String(),
      observacao: String(),
      acao: String(),
      idSuporteSelecionado: null,
      idStatusSelecionado: null,
      observacaoAdmin: String(),
    };
  },
  components: {
    tabelaBase,
  },
  computed: {
    totalPagesToDisplay() {
      if (this.totalPages <= 5) {
        this.showIconsToChangePage = false;
        return this.totalPages;
      } else {
        this.showIconsToChangePage = true;

        let paginasSobrando = this.totalPages - this.filtro.page;

        if (paginasSobrando == 0) {
          let retorno = [];
          for (let i = this.totalPages - 2; i <= this.totalPages; i++)
            retorno.push(i);
          return retorno;
        } else if (paginasSobrando == this.totalPages - 1) return [1, 2, 3];
        else
          return [this.filtro.page - 1, this.filtro.page, this.filtro.page + 1];
      }
    },
  },
  watch: {
    "filtro.perPage"() {
      this.filtro.page = 1;
      this.getSuporte();
    },
  },
  async beforeMount() {
    this.getStatusList();
    await this.getSuporte();
  },
  methods: {
    dataMenos30() {
      var result = new Date();
      result.setDate(result.getDate() + -30);
      return result;
    },
    dataAtual() {
      var result = new Date();
      result.setDate(result.getDate());
      return result;
    },
    async getSuporte() {
      this.$loading(true);
      await http
        .post(`/Admin/obterSolicitacoes`, this.filtro)
        .then((success) => {
          this.solicitacoes.items = success.data;
          this.solicitacoes.exportTable = [];
          success.data.forEach((item) => {
            this.exportTable.push({
              login: item.loginUsuario,
              nome: item.nome,
              email: item.email,
              loja: item.loja,
              telefone: item.telefone,
              descricao: item.descricao,
              valor: item.valor,
              dataPedido: item.dataPedido,
              dataSolicitacao: item.dataSolicitacao,
              comprovante: item.comprovante,
              status: item.status.nome,
              observacao: item.observacao,
              tipo: item.tipo,
            });
          });
        });
      this.$loading(false);
    },
    getSuporteOld() {
      this.$loading(true);
      this.filtro.page = 1;
      http
        .post("/Admin/obterSolicitacoesSuporte", this.filtro)
        .then((success) => {
          this.totalPages = success.data.totalPages;
          this.totalRegistros = success.data.totalRegistros;
          this.items = success.data.data;
          this.exportTable = success.data.data;
          this.$loading(false);
        });
    },
    getStatusList() {
      http.get("/geral/status").then(
        (success) => {
          success.data.forEach((item) => {
            if (item.idStatus == 1 || item.idStatus == 2 || item.idStatus == 3)
              this.statusOptions.push({
                value: item.idStatus,
                text: item.nome,
              });
          });
        },
        () => {
          this.$snotify.error("Erro ao obter status.");
        }
      );
    },
    abrirModal(url, observacao) {
      this.$bvModal.show("modal-comprovante");
      this.imagemComprovante = url;
      this.observacao = observacao;
    },
    abrirModalAcao(idSuporte, acao, idStatus) {
      this.idSuporteSelecionado = idSuporte;
      this.idStatusSelecionado = idStatus;
      this.acao = acao;
      this.$bvModal.show("modal-acao");
    },
    salvarAlteracao() {
      this.$loading(true);
      let obj = {
        idSuporte: this.idSuporteSelecionado,
        idStatus: this.idStatusSelecionado,
        observacaoAdmin: this.observacaoAdmin,
      };

      http.post("/Admin/atualizarStatusSuporte", obj).then(
        (success) => {
          this.$snotify.success(success.data);
          this.$bvModal.hide("modal-acao");
          this.observacaoAdmin = "";
          this.getSuporte();
        },
        () => {
          this.$snotify.error("Erro ao atualizar suporte!");
        }
      );
      this.$loading(false);
    },
    alterarPagina(pagina) {
      this.filtro.page = pagina;
      this.getSuporte();
    },
    verificaUrl(url) {
      return url.indexOf(".pdf") !== -1;
    },
    formatDate(date, showTime) {
      if (!showTime) return new Date(date).toLocaleDateString();
      else
        return `${new Date(date).toLocaleDateString()} ${new Date(
          date
        ).toLocaleTimeString()}`;
    },
  },
};
