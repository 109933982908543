import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "EditarCarrossel",
    components: {
        tabelaBase,
    },
    data() {
        return {
            contador: 0,
            carrossel: {
                idCarrossel: 0,
                nome: String(),
                titulo: String(),
                corFundo: "#32a852",
                dataAtualizacao: null,
                dataCriacao: null,
                ativo: false,
            },
            map: {
                idCarrossel: null,
                idAnunciante: null,
                dataEntrada: null,
                dataSaida: null,
            },
            opcoesAnunciantes: [],
            opcoesCategoriasFiltro: [],
            opcoesLojas: [],
            filtro: {
                nomeAnunciante: String(),
                idCategoria: null,
            },
            anunciantes: {
                items: [],
                title: "Anunciantes",
                fields: [
                    {
                        key: "ordem",
                        label: "Ordem",
                        sortable: false,
                        class: "text-center",
                    },
                    {
                        key: "nomeAnunciante",
                        label: "Anunciante",
                        sortable: false,
                        class: "text-center",
                    },
                    {
                        key: "afiliado",
                        label: "Afiliação",
                        sortable: false,
                        class: "text-center",
                    },
                    {
                        key: "dataEntrada",
                        label: "Data de entrada",
                        sortable: false,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "dataSaida",
                        label: "Data de saída",
                        sortable: false,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                        sortable: false,
                        class: "text-right w-25",
                    },
                ],
                sortBy: "ordem",
                sortDesc: false,
                exportExcel: false,
                perPage: null,
            },
            idMapeamentoExclusao: 0,
            swatches: [
                ["#FF0000", "#AA0000", "#550000"],
                ["#FFFF00", "#AAAA00", "#555500"],
                ["#00FF00", "#00AA00", "#005500"],
                ["#00FFFF", "#00AAAA", "#005555"],
                ["#0000FF", "#0000AA", "#000055"],
            ],
        };
    },
    async beforeMount() {
        this.map.idCarrossel = +this.$route.query.idCarrossel;
        await this.obterDadosCarrossel();
    },
    methods: {
        async obterDadosCarrossel() {
            this.$loading(true);

            await http
                .get("/carrossel/" + this.map.idCarrossel)
                .then(
                    (success) => {
                        if (success.data) {
                            this.anunciantes.items = success.data.mapeamentos;
                            this.carrossel.nome = success.data.nome;
                            this.carrossel.titulo = success.data.titulo;
                            this.carrossel.corFundo = success.data.corFundo;
                            this.carrossel.ativo = success.data.ativo;
                            this.carrossel.dataAtualizacao =
                                success.data.dataAtualizacao;
                            this.carrossel.dataCriacao =
                                success.data.dataCriacao;
                            this.carrossel.descricao = success.data.descricao;
                            this.carrossel.idCarrossel = this.map.idCarrossel;
                            this.contador = success.data.mapeamentos.length;
                            success.data.anunciantes.forEach((item) => {
                                this.opcoesLojas.push({
                                    value: item.key,
                                    text: item.value,
                                });
                            });
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async alterarFundo() {
            this.$loading(true);
            await http
                .post("/carrossel/atualizar/", this.carrossel)
                .then(
                    (success) => {
                        this.$snotify.success(
                            "Carrossel atualizado com sucesso!"
                        );
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async adicionarAnunciante() {
            this.$loading(true);
            await http
                .post("/carrossel/MapearAnunciante/", this.map)
                .then(
                    (success) => {
                        this.anunciantes.items.push(success.data);
                        this.contador = this.anunciantes.items.length;

                        this.$snotify.success(
                            "Anunciante mapeada com sucesso!"
                        );
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        modalDelete(idMap) {
            this.idMapeamentoExclusao = idMap;
            this.$bvModal.show("confirmDelete");
        },
        async subirMapeamento(idMap) {
            this.$loading(true);
            http.post(`/carrossel/subiranunciante/${idMap}`)
                .then(
                    (success) => {
                        this.$snotify.success(
                            "Mapeamento alterado com sucesso."
                        );
                        this.obterDadosCarrossel();
                    },
                    () => {
                        this.$snotify.error("Erro ao alterar mapeamento.");
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async descerMapeamento(idMap) {
            this.$loading(true);
            http.post(`/carrossel/desceranunciante/${idMap}`)
                .then(
                    (success) => {
                        this.$snotify.success(
                            "Mapeamento alterado com sucesso."
                        );
                        this.obterDadosCarrossel();
                    },
                    () => {
                        this.$snotify.error("Erro ao alterar mapeamento.");
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async removerMapeamento() {
            this.$loading(true);
            http.delete(
                `/carrossel/DeletarMapeamento/${this.idMapeamentoExclusao}`
            )
                .then(
                    () => {
                        this.$snotify.success(
                            "Mapeamento excluido com sucesso."
                        );
                        this.obterDadosCarrossel();
                    },
                    () => {
                        this.$snotify.error("Erro ao excluir mapeamento.");
                    }
                )
                .finally(() => {
                    this.$loading(false);
                    this.$bvModal.hide("confirmDelete");
                });
        },
        async filtrarMapeamentos() {
            this.$loading(true);

            await http
                .post("/carrossel/filtrarMapeamentos/", this.filtro)
                .then(
                    (success) => {
                        if (success.data) {
                            this.mapeamentos.items = success.data;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        compararDatas() {
            if (this.map.dataEntrada && this.map.dataSaida) {
                let dateTime1 = new Date(this.map.dataEntrada);
                let dateTime2 = new Date(this.map.dataSaida);
                return dateTime1 < dateTime2;
            }

            return true;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString();
            }
        },
        removeEspecialCharacter(texto) {
            return texto.replace(
                /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                ""
            );
        },
        voltarTela() {
            window.location.href = "/painel/parceiros";
        },
    },
};
