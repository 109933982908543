import "./BoxLoja.scss";
import http from "@/_helper/api-services";

export default {
  name: "BoxLoja",
  props: {
    idAnunciante: { type: Number, default: 0 },
    idCredenciamento: { type: Number, default: 0 },
    nome: { type: String, default: "" },
    cashbackMin: { type: Number, default: 0 },
    cashbackMax: { type: Number, default: 0 },
    cashback: { type: Number, default: 0 },
    tipoCashback: { type: String, default: "" },
    imagemUrl: { type: String, default: "" },
    urlAnuncio: { type: String, default: true },
  },
  data() {
    return {};
  },
  methods: {
    validaImagem(imagem) {
      return imagem.includes("bigcash");
    },
    formatNome() {
      if (window.innerWidth > 1300 && this.nome.length > 20) {
        return this.nome.substring(0, 20) + "...";
      } else if (window.innerWidth < 750 && this.nome.length > 14) {
        return this.nome.substring(0, 12) + "...";
      } else if (window.innerWidth < 800 && this.nome.length > 20) {
        return this.nome.substring(0, 13) + "...";
      } else {
        return this.nome;
      }
    },
    GetUrlProgram(url) {
      if (url) {
        return url.replace(
          "[IdUsuario]",
          this.$store.getters["autenticacao/getLogin"].user.id);
      }
      else if (this.idCredenciamento) {
        return "/anunciante?idAnunciante=" + this.idCredenciamento;
      }
      else {
        return null;
      }
    },
    calculaMargemCashback() {
      let porcentagem = false;

      switch (this.tipoCashback) {
        case "percentage":
        case 2:
          porcentagem = true;
          break;
        case "ammount":
          porcentagem = false;
          break;
      }

      let formatar = (valor) => porcentagem ? `${valor}%` : ("R$" + valor);

      if (this.cashback) {
        return "Até " + formatar(this.cashback) + " de cashback";
      }
      else if (this.cashbackMin) {
        if (this.cashbackMax && this.cashbackMax != this.cashbackMin) {
          return "De " + formatar(this.cashbackMin) + " a " + formatar(this.cashbackMax) + " de cashback";
        }
        else {
          return "Até " + formatar(this.cashbackMin) + " de cashback";
        }
      }
      else if (this.cashbackMax) {
        return "Até " + formatar(this.cashbackMax) + " de cashback";
      }
      else return "Dinheiro de volta";
    },
    imagemTipoLoja() {
      if (this.idAnunciante) {
        if (this.banner) {
          return require("@/assets/imgs/loja-online-branco.png");
        } else {
          return require("@/assets/imgs/loja-online-verde.png");
        }
      }
      else if (this.idCredenciamento) {
        if (this.banner) {
          return require("@/assets/imgs/loja-fisica-branco.png");
        } else {
          return require("@/assets/imgs/loja-fisica-verde.png");
        }
      }
    },
  },
};
