import http from '@/_helper/api-services';
import "./BoxPerformance.scss";

export default {
    name: "BoxPerformance",
    props: {
        imgUrl: { type: String, default: "", },
        texto: { type: String, default: "", },
        nome: { type: String, default: "", },
        login: { type: String, default: "", },
        nomeProduto: { type: String, default: "", },
        diretos: { type: Number, default: 0, },
        valorGerado: { type: String, default: "", },
        idUsuario: { type: String, default: "" },
        pontuacao: { type: Number, default: 0 },
    },
    data() {
        return {
            urlErrada: "https://onneroyal.blob.core.windows.net/imagens/user-default.svg",
        };
    },
    components: {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
        detalharRedeUsuario(idUsuario) {
            window.location.href = "/painel/desempenho-detalhado?idUsuario=" + idUsuario;
        },
    }
};