import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import Compress from "compress.js";
const compress = new Compress();

export default {
  name: "formPlanos",
  components: {
    tabelaBase,
  },
  props: {
    idAnunciante: { type: String, default: 0 },
  },
  data() {
    return {
      historico: {
        items: [],
        title: "Histórico",
        fields: [
          {
            key: "dataAtualizacao",
            label: "Data da atualização",
            sortable: true,
            class: "text-center",
          },
          {
            key: "texto",
            label: "Atualização",
            class: "text-center",
          },
          {
            key: "nome",
            label: "Usuário",
            class: "text-center",
          },
        ],
        sortBy: "dataAtualizacao",
        sortDesc: true,
        exportExcel: false,
        perPage: null,
      },
      ativo: [
        { value: true, text: "Ativo" },
        { value: false, text: "Inativo" },
      ],
      visibilidade: [
        { value: true, text: "Visível" },
        { value: false, text: "Invisível" },
      ],
      loja: {
        ativo: Boolean(),
        idAnunciante: Number(),
        nome: String(),
        // visivel: Boolean(),
        url: String(),
        conexao: String(),
        // cashback: Number(),
        prioridade: Number(),
        dataAtualizacao: null,
        base64: String(),
      },
      imagemRequest: {
        base64: String(),
        nome: String(),
        diretorio: "logos-empresas",
      },
      imageShow: String(),
      dataCriacao: null,
      dataAtualizacao: null,
      mainProps: { width: 150, height: 150}
    };
  },
  computed: {},
  async beforeMount() {
    if (this.$props.idAnunciante != 0 && this.$props.idAnunciante != null) {
      await this.obterDadosPlano();
    }
  },
  methods: {
    async obterDadosPlano() {
      this.$loading(true);

      await http
        .get(`/anunciante/obterAnunciante/${this.$props.idAnunciante}`)
        .then(
          (success) => {
            if (success.data) {
              this.loja = success.data.dadosAnunciante;
              
              this.loja.conexao = success.data.dadosAnunciante.conexao ? "Awin" : "Afilio",
                this.dataAtualizacao = this.formatDate(
                  success.data.dadosAnunciante.dataAtualizacao
                );
            }
          },
          (error) => {
            if (error.response && error.response.data)
              this.$snotify.error(error.response.data.message);
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    editarDadosloja() {
      this.$loading(true);
       http
        .post(`/anunciante/editarAnunciante/${this.$props.idAnunciante}`, this.loja)
        .then(
          (success) => {
            
            if (success) {
              this.$snotify.success("Loja editada com sucesso");
            }
          },
          (error) => {
            if (error.response && error.response.data)
              this.$snotify.error("Tamanho de imagem não permitido");
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    async uploadImagem() {
      this.$loading(true);
      await http.post("/Config/UploadImagem", this.imagemRequest).then(
        (success) => {
          this.$snotify.success("Imagem salva com sucesso.");
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
      this.$loading(false);
    },

    onSelectFile(evt) {
      let input = this.$refs.fileInput;
      const files = input.files;
      
      const img = new Image();
      img.src = URL.createObjectURL(files[0]);

      img.onload = () =>{
        const width = img.width;
        const height = img.height;

        if(width != height){
          this.$snotify.error("Logo inválida: Arquivo deve ter as mesmas dimensões. Por exemplo 500 x 500");
          input.value = "";
        }
      }

      img = new Image();
      img.src = URL.createObjectURL(files[0]);

      img.onload = () =>{
        const width = img.width;
        const height = img.height;

        if(width != height){
          this.$snotify.error("Logo inválida: Arquivo deve ter as mesmas dimensões. Por exemplo 500 x 500");
          input.value = "";
        }

      }

      if (files && files[0]) {
        if (files[0].size > 1024 * 1024) {
            this.$snotify.error(
                "Logo inválida: Arquivo maior do que o permitido (1MB)"
            );
            input.value = "";
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.imageData = e.target.result;
            };
            reader.readAsDataURL(files[0]);
            this.$emit("input", files[0]);

            compress
                .attach("#logo", {
                    size: 1,
                    quality: 0.75,
                })
                .then((data) => {
                  this.loja.base64 = data[0].data;
                    this.imageShow = data[0].prefix + data[0].data;
                });
        }
    }
    },
    deletarImagem() {
      this.loja.url = null
      
      this.editarDadosloja()
    },
    voltarTela() {
      window.location.href = "/painel/admin/config/lojas";
    },
    formatDate(data) {
      if (data) {
        let dateTime = new Date(data);
        return (
          dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
        );
      }
    },
  },
};
