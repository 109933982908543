import './FotoPerfil.scss'
import BaseImageInput from '@/components/BaseImageInput/BaseImageInput.vue';

export default {
    name: 'FotoPerfil',
    props: ['fotoPerfil'],
    data() {
        return {
        }
    },
    components: {
        BaseImageInput
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();
            this.$emit("submit", "foto-perfil");
        }
    }
}