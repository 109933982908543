import http from "@/_helper/api-services.js";

export default {
  name: "tabelaMeusDiretos",
  data() {
    return {
      fields: [
        {
          key: "login",
          label: "Login",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "graduacao",
          label: "Graduação",
          sortable: true,
          class: "text-center",
        },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
          class: "text-center",
        },
        {
          key: "dataCadastro",
          label: "Data Cadastro",
          sortable: true,
          class: "text-center",
        },
      ],
      listDados: [],
      totalRows: 1,
      currentPage: 1,
      filter: String(),
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
    };
  },
  props: [
    "showFilters",
    "showTitle",
    "showPagination",
    "cols",
    "sm",
    "md",
    "lg",
    "xl",
  ],
  mounted() {
    this.listarDados();
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    listarDados() {
      http.get("/user/obterUltimosDiretos").then(
        (success) => {
          success.data.forEach((item) => {
            this.listDados.push({
              login: item.login,
              graduacao: item.graduacao != null ? item.graduacao.nome : "",
              email: item.email,
              dataCadastro: this.formatDate(item.dataCadastro),
            });
          });
        },
        () => {
          this.$snotify.error("Erro ao obter dados.");
        }
      );
    },
    formatTelefone(valor) {
      valor = valor.replace(/\D/g, "");
      valor = valor.replace(/^(\d{2})(\d)/g, "($1) $2");
      valor = valor.replace(/(\d)(\d{4})$/, "$1-$2");
      return valor;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(date, showTime) {
      if (!showTime) return new Date(date).toLocaleDateString();
      else
        return `${new Date(date).toLocaleDateString()} - ás ${new Date(
          date
        ).toLocaleTimeString()}`;
    },
  },
};
