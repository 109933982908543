<template>
    <b-form-group label="Código do pedido">
        <b-form-select
            v-model="value.idPedido"
            :options="selectPedidos"
            required />
    </b-form-group>
</template>    

<script>
export default {
    name: 'inputPedido',
    props: {
        value: Object,
        codigo: String,
        listaPedidos: Array,
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        },
    },
    data() {
        return {
            selectPedidos: [],
        }
    },
    beforeMount() {
        if (!this.listaPedidos && this.codigo) {
            this.selectPedidos = [
                { text: this.codigo, value: this.value.idPedido } 
            ];
        }
        else if (this.listaPedidos) {
            selectPedidos = this.listaPedidos
        }
    }
}
</script>
