import http from "@/_helper/api-services";
import buscaCep from "@/_helper/busca-cep";
import "./Endereco.scss";

export default {
    name: "Endereco",
    props: ["dadosEndereco", "erroForm"],
    data() {
        return {
            estadoSelecionado: null,
            estados: [{ value: null, text: "Estado", key: "Uf" }],
            cidades: [{ value: null, text: "Cidade" }],
            cidadeCep: String(),
        };
    },
    components: {},
    mounted() {
        this.buscarEstados();
        this.setarEstado();
    },
    methods: {
        buscarEstados() {
            http.get("/Geral/buscaEstado").then((response) => {
                response.data.forEach((element) => {
                    this.estados.push({
                        value: element.idEstado,
                        text: element.nome,
                        key: element.uf,
                    });
                });
            });
        },
        buscarCidades(setarCidade, cidade) {
            this.cidades = [{ value: null, text: "Cidade" }];
            this.$props.dadosEndereco.idCidade = null;
            this.$loading(true);

            if (this.estadoSelecionado) {
                http.get("/Geral/buscaCidade/" + this.estadoSelecionado)
                    .then(
                        (response) => {
                            response.data.forEach((element) => {
                                if (setarCidade && element.nome == cidade)
                                    this.$props.dadosEndereco.idCidade =
                                        element.idCidade;

                                this.cidades.push({
                                    value: element.idCidade,
                                    text: element.nome,
                                });
                            });
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        buscarCep() {
            if (
                this.$props.dadosEndereco.cep &&
                this.$props.dadosEndereco.cep.length == 9
            ) {
                this.$loading(true);
                buscaCep
                    .get(this.$props.dadosEndereco.cep)
                    .then(
                        (response) => {
                            if (response.data.erro) {
                                this.$snotify.error("CEP não encontrado");
                                return;
                            }
                            this.$props.dadosEndereco.rua =
                                response.data.logradouro;
                            this.$props.dadosEndereco.bairro =
                                response.data.bairro;
                            let data = this.estados.filter(
                                (f) => f.key == response.data.uf
                            );
                            this.estadoSelecionado = data[0].value;

                            this.buscarCidades(true, response.data.localidade);
                            this.$loading(false);
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        setarEstado() {
            if (this.$props.dadosEndereco.cidade) {
                this.estadoSelecionado = this.$props.dadosEndereco.cidade.estado.idEstado;
                this.setarCidadeSelecionada();
            }
        },
        setarCidadeSelecionada(setarCidade, cidade) {
            this.cidades = [{ value: null, text: "Cidade" }];
            this.$loading(true);

            if (this.estadoSelecionado) {
                http.get("/Geral/buscaCidade/" + this.estadoSelecionado)
                    .then(
                        (response) => {
                            response.data.forEach((element) => {
                                this.cidades.push({
                                    value: element.idCidade,
                                    text: element.nome,
                                });
                            });
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        handleSubmit(event) {
            event.preventDefault();
            this.$emit("submit", "endereco");
        },
    },
};
