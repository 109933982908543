import 'animate.css';
import 'leaflet/dist/leaflet.css';
import http from "@/_helper/api-services";
import "./LojaCredenciada.scss";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';

export default {
    name: "LojaCredenciada",
    components: {
        LMap,
        LTileLayer,
        LMarker,
    },
    data() {
        return {
            anunciante: {
                idAnunciante: 0,
                nome: String(),
                percentualCashback: Number,
                logoUrl: String,
                telefone: String,
                rua: String,
                numero: String,
                bairro: String,
                cidade: String,
                estado: String,
                cep: String,
                complemento: String,
            },
            urlAndroid: String,
            urlApple: String,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 13,
            center: [-15.793889, -47.882778],
            markerLatLng: [-15.793889, -47.882778]
        };
    },
    async beforeMount() {
        this.$loading(true);
        this.anunciante.idAnunciante = this.$route.query.idAnunciante;
        
        await this.obterDadosAnunciante();
        await this.consultarCEP();
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        async obterDadosAnunciante() {
            if (this.anunciante.idAnunciante) {
                await http
                    .get(
                        "/Credenciamento/obterDadosLojaCredenciada/" +
                            this.anunciante.idAnunciante
                    )
                    .then(
                        (response) => {
                            this.anunciante.nome =
                                response.data.estabelecimento;
                            this.anunciante.percentualCashback =
                                response.data.percentualCashback;
                            this.anunciante.logoUrl = response.data.logoUrl;
                            this.anunciante.rua = response.data.rua;
                            this.anunciante.numero = response.data.numero;
                            this.anunciante.bairro = response.data.bairro;
                            this.anunciante.cidade = response.data.cidade;
                            this.anunciante.estado = response.data.estadoUf;
                            this.anunciante.cep = response.data.cep;
                            this.anunciante.complemento =
                                response.data.complemento;
                            this.anunciante.latitude = response.data.latitude;
                            this.anunciante.longitude = response.data.longitude;
                            this.anunciante.telefone = response.data.telefone;

                            this.urlAndroid = response.data.urlAndroid;
                            this.urlApple = response.data.urlApple;
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    );
            }
        },
        montarEndereco() {
            return (
                this.anunciante.rua +
                ", " +
                this.anunciante.numero +
                " - " +
                this.anunciante.bairro +
                ", " +
                this.anunciante.cidade +
                " - " +
                this.anunciante.estado +
                ", " +
                this.anunciante.cep
            );
        },
        montarUrlEndereco() {
            // return "http://maps.google.com/maps/place/" + this.anunciante.nome.replace(/ /g,'+') + "/@="+ this.anunciante.latitude + "," + this.anunciante.longitude + "z=17";
            // return "https://maps.google.com/?ll=" + this.anunciante.latitude + "," + this.anunciante.longitude;
            return (
                "https://maps.google.com/?q=" +
                this.anunciante.cidade +
                "," +
                this.anunciante.bairro +
                "," +
                this.anunciante.rua +
                "," +
                this.anunciante.numero +
                "," +
                this.anunciante.cep
            );
        },
        redirectMapa() {
            window.open(this.montarUrlEndereco());
        },
        redirectAndroid() {
            window.open(this.urlAndroid);
        },
        redirectApple() {
            window.open(this.urlApple);
        },
        montarCashback() {
            return "Até " + this.anunciante.percentualCashback + "% de cashback";
        },
        montarTelefone() {
            // Remove caracteres não numéricos
            let telefoneFormatado = this.anunciante.telefone.replace(/\D/g, "");
            
            // Verifica o comprimento do número de telefone
            if (telefoneFormatado.length === 10) {
                // Formato para números de 10 dígitos (00) 0000-0000
                return `(${telefoneFormatado.substring(0, 2)}) ${telefoneFormatado.substring(2, 6)}-${telefoneFormatado.substring(6)}`;
            } else if (telefoneFormatado.length === 11) {
                // Formato para números de 11 dígitos (00) 00000-0000
                return `(${telefoneFormatado.substring(0, 2)}) ${telefoneFormatado.substring(2, 7)}-${telefoneFormatado.substring(7)}`;
            } else {
                // Retorna um valor indicando que o telefone não é válido
                return "Telefone inválido";
            }
        },
        removeEspecialCharacter() {
            this.login = this.login.replace(
                /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                ""
            );
        },
        copiarEndereco() {
            const el = document.createElement("textarea");
            el.value = this.montarEndereco();
            el.setAttribute("readonly", "");
            el.style.position = "absolute";
            el.style.left = "-9999px";
            document.body.appendChild(el);
            const selected =
                document.getSelection().rangeCount > 0
                    ? document.getSelection().getRangeAt(0)
                    : false;
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }

            this.$snotify.success("Endereço copiado.");
        },
        copiarTelefone() {
            const el = document.createElement("textarea");
            el.value = this.montarTelefone();
            el.setAttribute("readonly", "");
            el.style.position = "absolute";
            el.style.left = "-9999px";
            document.body.appendChild(el);
            const selected =
                document.getSelection().rangeCount > 0
                    ? document.getSelection().getRangeAt(0)
                    : false;
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }

            this.$snotify.success("Número de telefone copiado.");
        },
        imagemAndroid() {
            return require("@/assets/imgs/google_play.jpeg");
        },
        imagemIphone() {
            return require("@/assets/imgs/app_store.jpeg");
        },
        voltarTela() {
            window.location.href = "/";
        },
        async consultarCEP() {
            this.$loading(true);

            if (!this.anunciante.cep) {
                this.$loading(false);
                return;
            }

            const CEP = this.anunciante.cep.replace(/\D/g, '');
            const urlCEP = `https://brasilapi.com.br/api/cep/v2/${CEP}`;

            fetch(urlCEP)
                .then(async response => {
                    const json = await response.json();

                    if (response.ok)
                        return json;
                    else {
                        this.$snotify.error("Não foi possível realizar a consulta do CEP. Preeencha os dados manualmente.");
                        return;
                    }
                })
                .then(async data => {
                    if (data) {
                        const latitude = data.location.coordinates.latitude;
                        const longitude = data.location.coordinates.longitude;

                        if (latitude && longitude) {
                            this.center = [];
                            this.markerLatLng = [];
                            this.center = [latitude, longitude];
                            this.markerLatLng = [latitude, longitude];
                        } else {
                            const endereco = `${data.street}, ${data.neighborhood}, ${data.city}, ${data.state}, Brasil`

                            await this.fetchLocationData(endereco);
                        }
                    }
                })
                .catch(error => {
                    this.$snotify.error("Não foi possível realizar a consulta do CEP. Preeencha os dados manualmente.");
                })
                .finally(() => {
                    this.$loading(false);
                });
        },
        async fetchLocationData(address) {
            const apiKey = '5a882080157b49e59b925f96afcd6511';
            const formattedAddress = encodeURIComponent(address);
            const url = `https://api.opencagedata.com/geocode/v1/json?key=${apiKey}&q=${formattedAddress}&pretty=1&no_annotations=1`;

            try {
                const response = await fetch(url);
                if (response.ok) {
                    const json = await response.json();

                    if (json && json.results && json.results.length > 0) {
                        const location = json.results[0].geometry;
                        const latitude = location.lat;
                        const longitude = location.lng;

                        this.center = [];
                        this.markerLatLng = [];
                        this.center = [latitude, longitude];
                        this.markerLatLng = [latitude, longitude];
                    }
                }
            } catch (error) {

            }
        },
        iniciarConversa(){
            const url = `https://wa.me/55${this.anunciante.telefone.replace(/\D/g, '')}`
            window.open(url, '_blank');
        }
    },
};
