import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";

export default {
    
    name: "Suporte",
    data() {
        return {
            solicitacoes:{
                fields: [
                    // {
                    //     key: "idSuporte",
                    //     label: "#",
                    //     sortable: true,
                    //     sortDirection: "desc",
                    //     class: "d-none",
                    // },
                    // {
                    //     key: "siteCompra",
                    //     label: "Loja",
                    //     sortable: false,
                    //     class: "small",
                    // },
                    // {
                    //     key: "historicoSuporte",
                    //     label: "#",
                    //     sortable: true,
                    //     sortDirection: "desc",
                    //     class: "d-none",
                    // },
                    // {
                    //     key: "observacao",
                    //     label: "Descrição",
                    //     sortable: false,
                    //     class: "d-none",
                    // },
                    // {
                    //     key: "valorPedido",
                    //     label: "Valor",
                    //     sortable: true,
                    //     class: "text-center small",
                    //     formatter: (value) =>
                    //         value.toLocaleString("pt-br", {
                    //             style: "currency",
                    //             currency: "BRL",
                    //         }),
                    // },
                    // {
                    //     key: "dataCompra",
                    //     label: "Data Pedido",
                    //     sortable: true,
                    //     class: "text-center small",
                    //     formatter: (value) =>
                    //         `${new Date(value).toLocaleDateString()}`,
                    // },
                    {
                        key: "dataSolicitacao",
                        label: "Data Solicitação",
                        sortable: true,
                        class: "text-center small",
                        formatter: (value) =>
                            `${new Date(value).toLocaleDateString()} ${new Date(
                                value
                            ).toLocaleTimeString()}`,
                    },
                    {
                        key: "dataAtualizacao",
                        label: "Data Atualização",
                        sortable: true,
                        class: "text-center small",
                        formatter: (value) =>
                            value
                                ? `${new Date(
                                    value
                                ).toLocaleDateString()} ${new Date(
                                    value
                                ).toLocaleTimeString()}`
                                : "",
                    },
                    // {
                    //     key: "urlComprovante",
                    //     label: "Comprovante",
                    //     class: "text-center small",
                    //     sortable: false,
                    // },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true,
                        class: "text-center small",
                    },
                    {
                        key: "tipo",
                        label: "Tipo",
                        sortable: true,
                        class: "text-center small",
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                        class: "text-center small",
                    },
                    // {
                    //     key: "observacaoAdmin",
                    //     label: "Observação",
                    //     sortable: true,
                    //     class: "text-center",
                    // },
                ],
                items: [],
                sortBy: "",
                sortDesc: false,
                exportExcel: false,
            },
            filtro: {
                login: String(),
                idStatus: null,
                idTipo: null,
                codigoPedido: String(),
                dataInicioIncio: null,
                dataInicioFim: null,
                dataAtualizacaoInicio: null,
                dataAtualizacaoFim: null,
                page: 1,
                perPage: 10,
            },
            options: [{ value: null, text: "Todos" }],
            imagemComprovante: String(),
            observacao: String(),
            acao: String(),
            idSuporteSelecionado: null,
            idStatusSelecionado: null,
            observacaoAdmin: String(),
            exportFields: {
                Loja: "siteCompra",
                "#": "historicoSuporte",
                Descrição: "observacao",
                Valor: "valorPedido",
                "Data Pedido": "dataCompra",
                "Data Solicitação": "dataSolicitacao",
                "Data Atualização": "dataAtualizacao",
                Comprovante: "urlComprovante",
                Status: "status",
                Observação: "observacaoAdmin",
            },
            exportTable: [],
            tipoOptions: [
                { value: null, text: "Todos" },
                { value: 1, text: "Contato" },
                { value: 2, text: "Cashback não pago" },
                { value: 3, text: "Cancelamento do parcelamento" },
                { value: 4, text: "Reabertura do parcelamento" },
            ],
            statusOptions: [{ value: null, text: "Todos" }],
        };
    },
    components: {
        BarraIndicacao,
        tabelaBase
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPages <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPages;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPages - this.search.page;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPages - 2; i <= this.totalPages; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPages - 1)
                    return [1, 2, 3];
                else
                    return [
                        this.search.page - 1,
                        this.search.page,
                        this.search.page + 1,
                    ];
            }
        },
    },
    beforeMount() {
        this.getSuporte();
        this.getStatusList()
    },
    methods: {
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        dataAtual() {
            var result = new Date();
            result.setDate(result.getDate());
            return result;
        },
        getSuporte() {
            this.$loading(true);
            http.post(`/Contato/obterMinhasSolicitacoes/`, this.filtro).then((success) => {
                
                this.solicitacoes.items = success.data;
                this.solicitacoes.exportTable = [];
                for (let i in success.data) {
                    this.exportTable.push(success.data[i]);
                }
                this.$loading(false);
            });
        },
        getStatusList() {
            http.get("/geral/status").then(success => {
                success.data.forEach(item => {
                    if (item.idStatus == 1 || item.idStatus == 2 || item.idStatus == 3)
                        this.statusOptions.push({
                            value: item.idStatus,
                            text: item.nome
                        })
                });
            }, () => {
                this.$snotify.error("Erro ao obter status.");
            })
        },
        abrirModal(url, observacao) {
            this.$bvModal.show("modal-comprovante");
            this.imagemComprovante = url;
            this.observacao = observacao;
        },
        verificaUrl(url) {
            return url.indexOf(".pdf") !== -1;
        },
        formatDate(date, showTime) {
            if (!showTime) return new Date(date).toLocaleDateString();
            else
                return `${new Date(date).toLocaleDateString()} ${new Date(
                    date
                ).toLocaleTimeString()}`;
        },
    },
};
