<template>
  <b-container class="bg-white">    
    <b-row class="mt-3">
      <b-col cols="12" class="text-center">
        <carousel
        :perPage="1"
        :autoplay="true"
        :autoplayHoverPause="true"
        :autoplayTimeout="4000"
        :loop="true"        
        >
          <slide>              
            <b-container class="bg-warning text-dark p-3 rounded">
              <b-row>
                <!-- Imagem do usuário -->
                <b-col cols="3" class="text-center">
                  <b-avatar 
                    src="https://link-to-user-image"
                    size="8rem"
                    variant="light"
                    class="border border-warning p-1">
                  </b-avatar>
                  <b-icon 
                    icon="award" 
                    variant="warning" 
                    font-scale="2" 
                    class="mt-2">
                  </b-icon>
                </b-col>

                <!-- Texto de Parabéns -->
                <b-col cols="9" class="d-flex flex-column justify-content-center">
                  <h5 class="mb-1">PARABÉNS A <span class="">CLEONICE RAMOS</span></h5>                  
                  <h5 class="mb-1">QUE ATINGIU O NÍVEL <span class="">PRATA!</span></h5>
                  <!-- <p class="mb-0">E GANHOU UM FINAL DE SEMANA NO</p>
                  <h5 class="font-weight-bold">SPA NASCER DO SOL!</h5> -->
                </b-col>
              </b-row>
            </b-container>
          </slide>
          <slide>
            <b-container class="bg-warning text-dark p-3 rounded">
              <b-row>
                <!-- Imagem do usuário -->
                <b-col cols="3" class="text-center">
                  <b-avatar 
                    src="https://link-to-user-image"
                    size="8rem"
                    variant="light"
                    class="border border-warning p-1">
                  </b-avatar>
                  <b-icon 
                    icon="award" 
                    variant="warning" 
                    font-scale="2" 
                    class="mt-2">
                  </b-icon>
                </b-col>

                <!-- Texto de Parabéns -->
                <b-col cols="9" class="d-flex flex-column justify-content-center">
                  <h5 class="mb-1">PARABÉNS A <span class="">MATHEUS MOTTA</span></h5>                  
                  <h5 class="mb-1">QUE ATINGIU O NÍVEL <span class="">OURO!</span></h5>
                  <!-- <p class="mb-0">E GANHOU UM FINAL DE SEMANA NO</p>
                  <h5 class="font-weight-bold">SPA NASCER DO SOL!</h5> -->
                </b-col>
              </b-row>
            </b-container>
          </slide>
          <slide>
            <b-container class="bg-warning text-dark p-3 rounded">
              <b-row>
                <!-- Imagem do usuário -->
                <b-col cols="3" class="text-center">
                  <b-avatar 
                    src="https://link-to-user-image"
                    size="8rem"
                    variant="light"
                    class="border border-warning p-1">
                  </b-avatar>
                  <b-icon 
                    icon="award" 
                    variant="warning" 
                    font-scale="2" 
                    class="mt-2">
                  </b-icon>
                </b-col>

                <!-- Texto de Parabéns -->
                <b-col cols="9" class="d-flex flex-column justify-content-center">
                  <h5 class="mb-1">PARABÉNS A <span class="">ERIC BAUMBACH</span></h5>                  
                  <h5 class="mb-1">QUE ATINGIU O NÍVEL <span class="">PLATINA!</span></h5>
                  <!-- <p class="mb-0">E GANHOU UM FINAL DE SEMANA NO</p>
                  <h5 class="font-weight-bold">SPA NASCER DO SOL!</h5> -->
                </b-col>
              </b-row>
            </b-container>
          </slide>
        </carousel>
      </b-col>
    </b-row>
  </b-container>
</template>
  <script>
    import { Carousel, Slide } from 'vue-carousel';
      
    export default {
      components: {
        Carousel,
        Slide,
      },
      methods: {
        // onSwiper(swiper) {
        //   console.log(swiper);
        // },
        // onSlideChange() {
        //   console.log('slide change');
        // },
      },
    };
  </script>
  <style scoped>
  .bg-warning {
    background-color: #fff4e3 !important;
    border-radius: 8px;
  }
  
  .text-success {
    color: #76b82a !important;
  }
  
  .text-warning {
    color: #e68a00 !important;
  }
  
  .font-weight-bold {
    font-weight: 700;
  }
  </style>