import "./formLogin.scss";
import usuarioService from "@/_helper/usuario-service.js";
import http from "@/_helper/api-services.js";

export default {
  name: "formLogin",
  data() {
    return {
      user: String(),
      password: String(),
      remember: false,
    };
  },
  mounted() {
    if (localStorage.getItem("remember")) {
      let dados = JSON.parse(localStorage.getItem("remember"));
      this.user = dados.user;
    }
  },
  methods: {
    openModalSelecione() {
      this.$bvModal.show("modalSelecionarTipo");
      this.$refs.loginPop.display = "none";
    },
    handleSubmit(event) {
      this.$loading(true);
      event.preventDefault();
      const { dispatch } = this.$store;

      dispatch("autenticacao/login", {
        user: this.user,
        senha: this.password,
      })
        .then(
          () => {
            window.location.href = "/";
            window.location.reload();
          },
          (error) => {
            for (const erro of http.mensagensErro(error)) {
              this.$snotify.error(erro);
            }
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    resendEmailConfirmation() {
      this.$snotify.clear();
      usuarioService.resendEmailConfirmation(this.user).then(
        (success) => {
          this.$snotify.success(success.data.message, {
            position: "rightTop",
            timeout: 8000,
          });
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },
    switchTo(action) {
      this.$root.$emit("bv::hide::popover");
      this.$emit("switchAction", action);
    },
    openRegister() {
      this.$store.dispatch("transporteDados/open");
    },
  },
};
