import moment from 'moment';
import 'moment/locale/pt-br';

export function formatDate(value) {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

export function formatCurrency(value) {
  if (value === null || value === undefined) return '';

  value = parseFloat(value);

  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
}

export function formatPhone(valor) {
  if (!valor) return '';

  valor = valor.replace(/\D/g, '');

  if (valor.length <= 8) {
    return `(${valor.slice(0, 2)}) ${valor.slice(2, 6)}-${valor.slice(6, 10)}`;
  }

  if (valor.length === 8) {
    valor = '9' + valor;
  }

  valor = valor.slice(0, 11);

  return `(${valor.slice(0, 2)}) ${valor.slice(2, 3)}${valor.slice(3, 7)}-${valor.slice(7, 11)}`;
};

export function breakLine(value, length) {
  if (!value || length <= 0) return value;

  let result = '';
  for (let i = 0; i < value.length; i += length) {
    result += value.slice(i, i + length) + '\n';
  }

  return result.trim();
};

export function truncateText(value, length) {
  if (!value) return '';
  if (value.length <= length) return value;
  
  return value.slice(0, length) + '...';
};