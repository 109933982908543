<template>
   <!-- <div id="app" :class="{'flexivel': adminPages, 'comerciante': perfil == 'C'}" ref="appElement" v-bind:style="{ 'padding-bottom': paddingBottom }"> -->

   <div id="app" :class="{ flexivel: adminPages, comerciante: perfil == 'C' }" ref="appElement">

      <vue-snotify></vue-snotify>

      <HeaderBar v-if="exibir" :emAcessoRemoto="emAcessoRemoto" :loggedIn="userLoggedIn" :adminPages="adminPages"></HeaderBar>

      <section class="" :class="{'conteudo-geral': !adminPages, 'conteudo-geral-admin': adminPages}">
         <div class="flex-geral">
            <div class="p-0 box-menu-lateral" v-if="adminPages">
               <appMenu :emAcessoRemoto="emAcessoRemoto" v-bind:smallerThanLg="smallerThanLg" />
            </div>

            <div class="box-conteudo p-2" :class="{'conteudo-logado': adminPages}">
               <router-view v-on:logoutUser="logoutUser" />
            </div>
         </div>
      </section>
      
      <appFooter v-if="exibir" :adminPages="adminPages" v-on:adjustFooterPadding="adjustFooterPadding"/>
   </div>
</template>

<style>
body {
   background-color: #EEE;
}
</style>

<script>
import HeaderBar from "@/components/HeaderBar/HeaderBar.vue";
import appMenu from "@/components/Menu/appMenu.vue";
import appFooter from "@/components/AppFooter/appFooter.vue";

export default {
   name: "app",
   components: {
      HeaderBar,
      appMenu,
      appFooter,
   },
   data() {
      return {
         adminPages: false,
         exibir: false,
         idUsuario: null,
         nomeUsuario: "",
         dismissCountDown: 5,
         paddingBottom: String(),
         smallerThanLg: false,
         userLoggedIn: false,
         emAcessoRemoto: false,
         perfil: "E",
         hideHeaderFooter: [
            // "/login",
            // "/cadastro",
            // "/forgot-password",
            // "/reset-password",
         ]
      };
   },
   computed: {
      alert() {
         return this.$store.state.alert;
      },
   },
   watch: {
      $route(to, from) {
         if (to.path.indexOf("/painel") != -1 && !this.userLoggedIn)
            this.$router.push("/");
         if (to.path.indexOf("/painel") != -1) this.adminPages = true;
         else this.adminPages = false;

         if (window.innerWidth <= 991) this.smallerThanLg = true;
         else this.smallerThanLg = false;
      },
   },
   beforeMount() {
      if (
        this.hideHeaderFooter.filter(p => this.$route.path.indexOf(p) != -1)
        .length > 0
        )
        this.exibir = false;
      else this.exibir = true;

      this.$root.$on("alterar-perfil", this.alterarPerfil);

      let dadosUsuario = this.$store.getters["autenticacao/getLogin"].user;
      let acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
      if (dadosUsuario && acessoRemoto) this.emAcessoRemoto = acessoRemoto;
      if (dadosUsuario == null && this.$route.path.indexOf("/painel") != -1)
         this.$router.push("/");
      else if (dadosUsuario != null) {
         this.idUsuario = dadosUsuario.id;
         this.nomeUsuario = dadosUsuario.username;
         this.userLoggedIn = true;
         this.perfil = dadosUsuario.comerciante ? dadosUsuario.perfil : "E";
      }

      if (this.$route.path.indexOf("/painel") != -1) this.adminPages = true;
      else this.adminPages = false;
   },
   mounted() {
     
   },
   methods: {
      adjustFooterPadding(heightFooter) {
         this.paddingBottom = `${heightFooter}px`;
      },
      logoutUser() {
         this.idUsuario = null;
         this.nomeUsuario = "";
         this.userLoggedIn = false;
      },
      alterarPerfil(perfil) {
         this.perfil = perfil;
      },
   },
};
</script>