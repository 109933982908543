import http from "@/_helper/api-services.js";
import { Carousel, Slide } from 'vue-carousel';
import ConvideSeusAmigosModal from '@/components/JornadaMetaMinuto/ConvideSeusAmigos.vue';
import QuantaAmizade from '@/components/JornadaMetaMinuto/QuantaAmizade.vue';
import TopEstabelecimentos from '@/components/JornadaMetaMinuto/TopEstabelecimentos.vue';
import Objetivos from '@/components/JornadaMetaMinuto/Objetivos.vue';
import MetaMinuto from '@/components/JornadaMetaMinuto/MetaMinuto.vue';
import Videos from '@/components/JornadaMetaMinuto/Videos.vue';
import Mapa from '@/components/JornadaMetaMinuto/Mapa.vue';
import CarrosselRede from '@/components/JornadaMetaMinuto/CarrosselRede.vue';
import Produtos from '@/components/JornadaMetaMinuto/Produtos.vue';
import './style.css';
import Ecossistema from "../Admin/Ecossistemas/CriarEcossistema/Ecossistema";
import InfoVideo from "@/components/JornadaMetaMinuto/InfoVideo.vue";

export default {
    components: {
        Carousel,
        Slide,
        ConvideSeusAmigosModal,
        QuantaAmizade,
        TopEstabelecimentos,
        Objetivos,
        MetaMinuto,
        Videos,
        Mapa,
        CarrosselRede,
        Produtos,
        InfoVideo
    },
    setup() {

    },
    async beforeMount() {
        this.$loading(true);      
        await this.buscarTotalizadores();
        this.$loading(false);
    },
    data() {
        return {
            totalizadores:{
                rede: 0,
                saldo: 0,
                ecossistemas: 0,
                pontos:0
            }
        }
    },
    methods: {
        async buscarTotalizadores(){
            this.$loading(true);
            await http.get("/v2/dashboard/get-totalizers-user").then(
                (success) => {                    
                    this.totalizadores = success.data.data; 
                    this.$loading(false);
                },
                () => {
                    this.$snotify.error("Erro ao buscar os totalizadores");
                    this.$loading(false);

                }
            );
            this.$loading(false);
        },
    }
}