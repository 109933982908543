<template>
    <div>
        <b-form-group class="mb-4" label="Data da compra">
            <date-picker size="sm"  class="w-100 h-100" placeholder="Data da compra" v-model="value.dataCompra" :lang="lang" format="DD/MM/YYYY" />
            <input type="hidden" name="dataCompra" v-model="value.dataCompra" required>
        </b-form-group>
        <b-form-group class="mb-4">
            <input type="text" v-model="value.siteCompra" required placeholder="Site da compra" />
        </b-form-group>
        <b-form-group class="mb-4">
            <input type="text" v-model="value.numeroPedido" maxlength="50" required placeholder="Número do pedido" />
        </b-form-group>
        <b-form-group class="mb-4" label="Valor do pedido">
            <input type="number" step="0.01" v-model="value.valorPedido" required placeholder="Valor do pedido" />
        </b-form-group>
        <b-form-group>
            <div class="flex-grow-1 upload-image d-flex align-items-center">
                <b-form-file placeholder="Anexar nota fiscal" class="w-100" v-model="value.arquivo" accept="image/x-png, image/jpeg, application/pdf" browse-text="Escolher"></b-form-file>
            </div>
        </b-form-group>
        <b-form-group>
            <textarea rows="4" v-model="value.observacao" required placeholder="Observação"></textarea>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: 'inputCashback',
    props: {
        value: Object,
        lang: Object,
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        },
    }
}
</script>
