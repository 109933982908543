import "./assinatura.scss";
import http from "@/_helper/api-services.js";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import BoxAssinatura from "@/components/BoxAssinatura/boxAssinatura.vue";
import ListagemAssinatura from "@/components/ListagemAssinatura/listagemAssinatura.vue";

export default {
  name: "assinatura",
  components: {
    BarraIndicacao,
    ListagemAssinatura,
    BoxAssinatura
  },
  data() {
    return {
      planoAtivo: null,
      history: []
    };
  },
  async beforeMount() {
    await Promise.all([this.getPlanoAtivoUsuario(), this.getSubscriptionHistory()]);
  },
  filters: {
    formatDateTime(value) {
      if (!value) return '---';

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };

      const dateTimeFormat = new Intl.DateTimeFormat('pt-BR', options).format(new Date(value));
      
      return dateTimeFormat.replace(',', '');
    }
  },
  methods: {
    async getPlanoAtivoUsuario() {
      await http.get("/user/obterPlanoAtivo").then(
        (success) => {
          if (success.data) this.planoAtivo = success.data.produto;
        },
        () => {
          this.$snotify.error("Erro ao obter plano ativo.");
        }
      );
    },

    async getSubscriptionHistory() {
      try {
        this.history = [];
        const response = await http.get("/v2/subscriptions/subscriptions-history");
        console.log(response.data);
        if (response.data.success)
          this.history = response.data.data;
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message || "Erro ao obter histórico das assinaturas.";
          this.$snotify.error(errorMessage);
        } else
          this.$snotify.error("Erro ao obter histórico das assinaturas.");
      }
    }
  },
};
