import http from "@/_helper/api-services.js";

export default {
    name: "AprovarCupomFiscal",
    data() {
        return {
            filtroVenda: {
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                tipoPagamento: null,
                pagina: 1,
                quantidadePorPagina: 50,
                ordenacao: null,
                asc: null,
                situacao: 1,
                nomeLoginDocumento: null,
            },
            pageOptions: [5, 10, 15, 20, 50],
            totalRows: 0,
            totalPages: 0,
            opcoesTipoPagamento: [
                { text: "Selecione o tipo de pagamento", value: null },
                { value: 14, text: "Cartão de crédito" },
                { value: 13, text: "Cartão de débito" },
                { value: 11, text: "Dinheiro" },
                { value: 15, text: "PIX" },
                { value: 10, text: "Saldo" },
            ],
            opcoesSituacao: [
                { text: "Selecione uma situação", value: null },
                { value: null, text: "Todos" },
                { value: 1, text: "Aguardando aprovação" },
                // { value: 6, text: "Aprovados" },
                { value: 5, text: "Reprovados" },
            ],
            fieldsUltimasVendas: [
                {
                    key: "selected",
                    label: "Selecionar",
                    sortable: false,
                    class: "text-center select-col",
                },
                {
                    key: "comprador",
                    label: "Cliente",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "data",
                    label: "Data venda",
                    sortable: true,
                    class: "text-center",
                    // formatter: (val) => {
                    //     const date = new Date(val);
                    //     const formattedDate = date.toLocaleDateString();
                    //     const formattedTime = date.toLocaleTimeString();
                    //     return `${formattedDate} ${formattedTime}`;
                    // },
                },
                // {
                //     key: "pagamento",
                //     label: "Forma de pagamento",
                //     sortable: true,
                //     class: "text-center",
                // },
                // { key: "descricao", label: "Descrição", class: "text-center" },
                {
                    key: "valor",
                    label: "Valor pago pelo usuário",
                    sortable: true,
                    class: "text-center",
                    formatter: (val) => this.formatValue(val),
                },
                // {
                //     key: "valorRecebido",
                //     class: "text-center",
                //     sortable: true,
                //     formatter: (val) => this.formatValue(val),
                // },
                // {
                //     key: "valorCashbackUsuario",
                //     class: "text-center",
                //     sortable: true,
                //     formatter: (val) => this.formatValue(val),
                // },
                // {
                //     key: "percentualCashback",
                //     class: "text-center",
                //     sortable: true,
                //     formatter: (val) => (val ? `${val}%` : ""),
                // },
                // {
                //     key: "comprovante",
                //     class: "text-center",
                //     label: "Comprovante",
                // },
                {
                    key: "status",
                    class: "text-center",
                    label: "Status",
                },
                {
                    key: "acoes",
                    class: "text-center",
                    label: "Ações",
                },
            ],
            listUltimasVendas: [],
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            sortBy: 'data',
            sortDesc: true,
            showIconsToChangePage: false,
            exportFields: {
                Login: "comprador",
                Data: "data",
                Pagamento: "pagamento",
                Valor: "valor",
                "Valor recebido": "valorRecebido",
                "Valor cashback usuário": "valorCashbackUsuario",
                "Percentual Cashback": "percentualCashback",
            },
            exportTable: [],
            itemCount: 0,
            fotoComprovante: null,
            justificativa: null,
            informarCliente: false,
            tokenModal: null,
            aprovacaoModal: null, 
            statusModal: null, 
            justificativaModal: null, 
            informarClienteModal: false,
            titleModal: null,
            formaPagamentoModal: null,
            valorPagoUsuarioModal: null,
            percentualCashbackModal: null,
            valorCashbackUsuarioModal: null,
            selected: [],
            selectedTokens: []
        };
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPages <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPages;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPages - this.filtroVenda.pagina;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPages - 2; i <= this.totalPages; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPages - 1)
                    return [1, 2, 3];
                else
                    return [
                        this.filtroVenda.pagina - 1,
                        this.filtroVenda.pagina,
                        this.filtroVenda.pagina + 1,
                    ];
            }
        },
        computedFields(){
            console.log()
            if(this.filtroVenda.situacao == 1){
                return this.fieldsUltimasVendas;
            }
            else{
                let fieldWithoutSelect = this.fieldsUltimasVendas.slice(1);
                return fieldWithoutSelect;
            }
        },
    },
    async beforeMount() {
        this.$loading(true);
        this.filtroVenda.dataInicio = this.getFirstDayOfMonth();
        this.filtroVenda.dataFim = this.getLastDayOfMonth();

        //await this.getTodasVendas();
        await this.buscarCupom();
        this.$loading(false);
    },
    watch: {
        async "filtroVenda.pagina"() {
            await this.buscarCupom(true);
        },
        async "filtroVenda.quantidadePorPagina"() {
            await this.buscarCupom(true);
        },
        async sortDesc() {
            this.filtroVenda.asc = this.$data.sortDesc;
            await this.buscarCupom(true);
        },
        async sortBy() {
            switch (this.$data.sortBy) {
                case "usuario":
                    this.filtroVenda.ordenacao = 0;
                    break;
                case "data":
                    this.filtroVenda.ordenacao = 1;
                    break;
                case "pagamento":
                    this.filtroVenda.ordenacao = 2;
                    break;
                case "valor":
                    this.filtroVenda.ordenacao = 3;
                    break;
                case "valorRecebido":
                    this.filtroVenda.ordenacao = 4;
                    break;
                case "valorCashbackUsuario":
                    this.filtroVenda.ordenacao = 5;
                    break;
                case "percentualCashback":
                    this.filtroVenda.ordenacao = 6;
                    break;
            }

            await this.buscarCupom(true);
        },
    },
    methods: {
        getFirstDayOfMonth() {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth(), 1);
        },
        getLastDayOfMonth() {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth() + 1, 0);
        },
        formatValue(value) {
            if (!value) return "";
            return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        formatDateTime(value) {
            if (!value) return "";
            const date = new Date(value);
            const formattedDate = date.toLocaleDateString("pt-BR", {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            const formattedTime = date.toLocaleTimeString("pt-BR", {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            return `${formattedDate} ${formattedTime}`;
        },
        modalDelete(value, aprovacao, status) {
            console.log(value);
            this.fotoComprovante = value.comprovante
            this.percentualCashbackModal = value.percentualCashback ? `${value.percentualCashback}%` : "",
            this.valorPagoUsuarioModal = value.valor ? this.formatValue(value.valor) : "",
            this.valorCashbackUsuarioModal = value.valorCashbackUsuario ? this.formatValue(value.valorCashbackUsuario) : "",
            this.formaPagamentoModal = value.pagamento,
            this.tokenModal = value.token,
            this.aprovacaoModal = aprovacao, 
            this.statusModal= status, 
            this.justificativaModal= this.justificativa, 
            this.informarClienteModal= this.informarCliente,
            this.$bvModal.show("confirmDelete");
        },
        modalRecusar(token, aprovacao, status){
            this.$bvModal.show("modal-recusar");
            this.tokenModal = token,
            this.aprovacaoModal = aprovacao, 
            this.statusModal= status, 
            this.justificativaModal= this.justificativa, 
            this.informarClienteModal= this.informarCliente,
            this.titleModal = status == 5 ? "Deseja realmente recusar este comprovante?":"Deseja realmente excluir este comprovante?"
        },
        modalRecusarHide(){
            this.$bvModal.hide("modal-recusar");            
        },
        async getTodosCupoms() {
            return this.exportTable;
        },
        async buscarCupom(needLoading) {
            if (needLoading) this.$loading(true);

            await http.post("/Credenciamento/obterCupomCashbackUsuario", this.filtroVenda)
                .then((success) => {
                    const vendas = success.data.data;
                    let dataForTable = [];
                    this.totalRows = success.data.totalRegistros;
                    this.totalPages = success.data.totalPages;

                    vendas.forEach((item) => {
                        dataForTable.push({
                            comprador: item.comprador,
                            data: item.dataCompra
                                ? this.formatDateTime(item.dataCompra)
                                : "",
                            // data: item.dataCompra,
                            pagamento: item.meioPagamento,
                            valor: item.valor
                                ? this.formatValue(item.valor)
                                : "",
                            valorRecebido: item.valorRecebido
                                ? this.formatValue(item.valorRecebido)
                                : "",
                            percentualCashback: item.percentualCashback * 100,
                            valorCashbackUsuario: item.valorCashbackUsuario
                                ? this.formatValue(item.valorCashbackUsuario)
                                : "",
                            descricao: item.descricao,
                            comprovante: item.comprovanteCompra,
                            token: item.token,
                            status: item.status
                        });
                    });
                    this.listUltimasVendas = dataForTable;
                    this.itemCount = dataForTable.length;
                    this.exportTable = dataForTable;                    
                })
                .catch(error => {
                    this.$snotify.error(error);
                });

            if (needLoading) this.$loading(false);
        },
        async aprovarCupom(token, aprovacao, status, justificativa, informarCliente) {
            console.log(token);
            console.log(aprovacao);
            console.log(status);
            console.log(justificativa);
            console.log(informarCliente);
            
            this.$loading(true);
            const aprovarCupom = {};
            aprovarCupom.token = token;
            aprovarCupom.aprovado = aprovacao;
            aprovarCupom.status = status;
            aprovarCupom.justificativa = justificativa;
            aprovarCupom.informarCliente = informarCliente == "true" ? true : false;

            await http.post("/Venda/aprovarCupom", aprovarCupom).then(
                (success) => {
                    if(aprovacao){
                        this.$snotify.success("Cupom aprovado com sucesso");
                    }
                    else if(status == 14){
                        this.$snotify.success("Cupom excluido com sucesso");
                    }
                    else{
                        this.$snotify.success("Cupom reprovado com sucesso");
                    }
                    this.$loading(false);
                    setTimeout(() => {
                        location.reload();
                    }, 2000);

                },
                () => {
                    this.$snotify.error("Erro ao aprovar cupom");
                    this.$loading(false);

                }
            );
            this.$loading(false);
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        alterarPagina(page) {
            this.filtroVenda.pagina = page;
        },
        startDownload() { },
        finishDownload() { },
        onRowSelected(items) {
            this.selected = items;
            this.selectedTokens = this.selected.map(item => ({
                token: item.token,
                aprovado: true,
                status: 6,
                informarCliente: false
            }));
            console.log(this.selectedTokens);
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows();
            this.selectedTokens = this.selected.map(item => item.token);
            console.log(this.selectedTokens);
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },

        async aprovarComprasSelecionados(){            
            this.$loading(true);
            await http.post("/Venda/aprovarCupoms", this.selectedTokens).then(
                (success) => {                    
                    this.$snotify.success("Cupoms aprovados com sucesso");                                                            
                    this.$loading(false);
                    setTimeout(() => {
                        location.reload();
                    }, 2000);
                },
                () => {
                    this.$snotify.error("Erro ao aprovar cupons");
                    this.$loading(false);

                }
            );
            this.$loading(false);
        },

        showMsgBoxTwo() {
            if(this.selectedTokens.length == 0){
                this.$snotify.error("Selecione ao menos 1 cupom para utilizar essa função");                
            }
            else{
                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Tem certeza que deseja aprovar todos as compras selecionadas?', {
                  title: 'Confirmar selecionadas',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'success',
                  okTitle: 'SIM',
                  cancelTitle: 'NÃO',
                  footerClass: 'p-2',
                  hideHeaderClose: false,
                  centered: true
                })
                  .then(value => {
                    console.log(value);
                    if(value == true && this.selectedTokens.length > 0){
                        this.aprovarComprasSelecionados();
                    }                                
                  })
                  .catch(err => {
                    // An error occurred
                  })
            }
          }
    },
};
