import "./ganhosSaidas.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "ganhosSaidas",
    data() {
        return {
            valores: {
                valorCashback: 0,
                valorResidualCashback: 0,
                valorResidualAdesao: 0,
                valorCashbackDobrado: 0,
                valorBonusCompressaoDinamica: 0,
                valorAdesao: 0,
                valorBonusCredenciamento: 0,
                valorBonusResidualCredenciamento: 0,
                totalEntradas: 0,
                totalSaidas: 0,
                bloqueado: 0,
                valorSaque: 0,
                outrasEntradas: 0,
                outrasSaidas: 0,
                saldoRetido: 0,
            },
            itemSelecionado: String(),
            fields: [{
                key: "descricao",
                label: "Descrição",
                sortable: true,
                class: "textoModal text-center w-100",
            },
            {
                key: "dataLancamento",
                label: "Data",
                sortable: true,
                class: "textoModal  text-center",
                formatter: (value) => {
                    if (value)
                        return `${new Date(
                            value
                        ).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`;
                    return "";
                },
            },
            {
                key: "valor",
                label: "Valor",
                sortable: true,
                class: "textoModal  text-center",
            },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 50],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            listaLancamentos: [],
        };
    },
    props: ["showCashIn", "showCashOut"],
    beforeMount() {
        this.buscarSaldos();
    },
    methods: {
        buscarSaldos() {
            this.$loading(true);
            http.get("/Extrato/obterSaldoPorTipo").then(
                (response) => {
                    this.valores = response.data;
                },
                (error) => {
                    //this.$snotify.error("Erro ao obter dados.");
                    // this.$snotify.error(error);
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
            this.$loading(false);
        },
        getValorFormatado(valor) {
            if (valor === undefined || valor === null) {
                return this.getValorFormatado(0);
            }
            if (typeof valor !== "number") {
                return this.getValorFormatado(0);
            }

            return valor.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        openModal(nome, tipo) {
            this.itemSelecionado = nome;
            this.buscarLancamentos(tipo);
            this.$bvModal.show("modal-lancamentos");
        },
        buscarLancamentos(tipo) {
            this.$loading(true);
            this.listaLancamentos = [];
            http.get("/Extrato/obterLancamentos/" + tipo).then(
                (response) => {
                    this.listaLancamentos = response.data;
                    this.totalRows = this.listaLancamentos ? this.listaLancamentos.length : 0;
                    this.currentPage = 1;
                },
                (error) => {
                    this.$snotify.error("Erro ao obter dados.");
                }
            );
            this.$loading(false);
        },
    },
};