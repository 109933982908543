import "./BoxApp.scss";
import http from '@/_helper/api-services';

export default {
    name: "BoxApp",
    props: {
        imgUrl: { type: String, default: "", },
        texto: { type: String, default: "", },
        nome: { type: String, default: "", },
        estrelas: { type: Array, default: () => { return []; }, },
    },
	data() {
		return {
        };
	},
	components: {
    },
    beforeMount(){
    },
    mounted() {
	},
    methods:{
        
    }
}