<template>
  <b-container class="bg-white">
    <div class="row">
      <div class="col-12 col-sm-2 col-md-2 col-lg-1 col-xl-1 d-flex align-items-center justify-content-center">
        <img src="@/assets/imgs/comece.png" alt="" class="" style="width: 3.5rem;" />
      </div>
      <div
        class="col-12 col-sm-10 col-md-10 col-lg-11 col-xl-11 d-flex flex-column align-items-center align-items-md-start justify-content-center">
        <h5 class="font-weight-bold display-md-4 text-green">
          Comece por aqui
        </h5>
        <p class="text-sm-justify">
          Explore nossa plataforma com os vídeos abaixo. Cada um deles detalha uma parte essencial de como ganhar por
          minuto, ajudando você a aproveitar ao máximo todas as funcionalidades disponíveis. Vamos juntos transformar
          suas compras e indicações em uma fonte de renda contínua!
        </p>
      </div>
    </div>
    <b-row class="mt-3">
      <b-col cols="12" class="text-center">
        <carousel :perPage="3" :perPageCustom="[
          [0, 1],
          [768, 2],
          [1024, 3],
        ]" :autoplay="true" :autoplayHoverPause="true" :autoplayTimeout="4000" :loop="true">
          <slide>
            <div class="embed-container">
              <img src="@/assets/imgs/bem-vindo.jpg" alt="Capa do Vídeo" class="cover-image">
              <b-embed src="https://www.youtube.com/embed/oQdZeEagCfU?si=hL1BgsJ5SDje8qzg" type="iframe" aspect="16by9"
                allowfullscreen class="embed-video"></b-embed>
            </div>
          </slide>
          <slide>
            <div class="embed-container">
              <img src="@/assets/imgs/como-funciona.jpg" alt="Capa do Vídeo" class="cover-image">
              <b-embed src="https://www.youtube.com/embed/R4QFuyN9yAo?si=hlhSEAgUTzXH17dE" type="iframe" aspect="16by9"
                allowfullscreen class="embed-video"></b-embed>
            </div>
          </slide>
          <slide>
            <div class="embed-container">
              <img src="@/assets/imgs/como-ganhar.jpg" alt="Capa do Vídeo" class="cover-image">
              <!-- <b-embed src="https://www.youtube.com/embed/CFDNYh_Iigs" type="iframe" aspect="16by9"
                allowfullscreen class="embed-video"></b-embed> -->
            </div>
          </slide>
        </carousel>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      images: [
        {
          src: "https://link-to-image1",
          checked: true,
        },
        {
          src: "https://link-to-image2",
          checked: false,
        },
        {
          src: "https://link-to-image3",
          checked: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.center-icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.check-icon {
  top: 10px;
  right: 10px;
}

.text-muted {
  color: #6c757d !important;
}

.VueCarousel-slide {
  margin: 0 5px;
}

.VueCarousel-slide:first-child,
.VueCarousel-slide:last-child {
  margin: 0;
}

.title-color {
  color: #98c73a !important;
}

.embed-container {
  position: relative;
  width: 100%;
  background-color: #1e5d68;
}

.cover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}

.embed-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  opacity: 0;
  /* Torna o embed invisível enquanto a imagem está visível */
}

.embed-container:hover .embed-video {
  opacity: 1;
  /* Torna o vídeo visível ao passar o mouse */
}
</style>
