import { render, staticRenderFns } from "./Lancamentos.vue?vue&type=template&id=be04421c&scoped=true&"
import script from "./Lancamentos.js?vue&type=script&lang=js&"
export * from "./Lancamentos.js?vue&type=script&lang=js&"
import style0 from "./Lancamentos.vue?vue&type=style&index=0&id=be04421c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be04421c",
  null
  
)

export default component.exports