import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "PainelParceiros",
    components: {
        tabelaBase,
    },
    data() {
        return {
            parceiros: {
                items: [],
                title: "Parceiros",
                fields: [
                    {
                        key: "nome",
                        label: "Nome",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "descricao",
                        label: "Descrição",
                        class: "text-center",
                    },
                    {
                        key: "celular",
                        label: "Celular",
                        class: "text-center",
                    },
                    {
                        key: "dataCriacao",
                        label: "Data de criação",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "dataAtualizacao",
                        label: "Última atualização",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "ativo",
                        label: "Status",
                        sortable: true,
                        formatter: (value) => (value ? "Ativo" : "Inativo"),
                        class: "text-center",
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                    },
                ],
                sortBy: "dataAtualizacao",
                sortDesc: true,
                exportExcel: false,
                perPage: null,
            },
            filtro: {
                nome: String(),
                celular: String(),
                ativo: null,
                dataCriacaoInicio: null,
                dataCriacaoFim: null,
                dataAtualizacaoInicio: null,
                dataAtualizacaoFim: null,
                descricao: String(),
            },
            idParceiroExclusao: 0,
            opcoesStatus: [
                { text: "Todos", value: null },
                { text: "Ativos", value: true },
                { text: "Inativos", value: false },
            ],
        };
    },
    async beforeMount() {
        await this.obterParceiros();
    },
    methods: {
        async obterParceiros() {
            this.$loading(true);

            http.post("/parceiros/obterParceiros/", this.filtro)
                .then(
                    (success) => {
                        if (success.data) {
                            this.parceiros.items = success.data;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async criarParceiro() {
            window.location.href = "/painel/parceiros/novo-parceiro";
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        modalDelete(idParceiro) {
            this.idParceiroExclusao = idParceiro;
            this.$bvModal.show("confirmDelete");
        },
        excluirParceiro() {
            this.$loading(true);
            http.delete(`/parceiros/ExcluirParceiro/${this.idParceiroExclusao}`)
                .then(
                    (success) => {
                        this.$snotify.success("Parceiro excluido com sucesso.");
                        this.obterParceiros();
                    },
                    () => {
                        this.$snotify.error("Erro ao excluir parceiro.");
                    }
                )
                .finally(() => {
                    this.$loading(false);
                    this.$bvModal.hide("confirmDelete");
                });
        },
        editParceiro(idParceiro) {
            window.location.href =
                "/painel/parceiros/editar-parceiro?idParceiro=" + idParceiro;
        },
    },
};
