import "./MenuMobile.scss";
import Drawer from "vue-simple-drawer";

export default {
    name: "MenuMobile",
    components: {
        Drawer,
    },
    data() {
        return {
            open: false,
            align: "left",
            innerOpen: false,
            mask: false,
            maskClosable: false,
            close: true,
        };
    },
    props: ["loggedIn"],
    methods: {
        toggle() {
            this.open = !this.open
        },
        closeDrop() {
            this.open = false;
        },
    },
}