import http from "@/_helper/api-services";
import usuarioService from "@/_helper/usuario-service.js";

export default {
    name: "FormGoogle",
    components: {},
    props: {
        code: { type: String, default: 0 },
    },
    data() {
        return {
            code: "",
            infos: {
                code: String(),
                redirectUri: `${location.origin}/login-social/google`,
            },
            teste: "",
        };
    },
    watch: {
        $route() {
            this.recarrega();
        },
    },
    async beforeMount() {
        this.$loading(true);
        this.infos.code = this.$route.query.code;
        await this.loginGoogle();
    },
    methods: {
        async loginGoogle() {
            await usuarioService
                .loginGoogle(this.infos.code, this.infos.redirectUri)
                .then(
                    (response) => {
                        this.$loading(false);
                        this.$router.push("/");
                        window.location.reload();
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                        this.$loading(false);
                        this.$router.push("/");
                        // window.location.reload();
                    }
                );
        },
    },
};
