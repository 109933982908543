import http from "@/_helper/api-services.js";

export default {
    name: "formEcossistema",
    props: {
        idEcossistema: { type: Number, default: 0 },
    },
    data() {
        return {
            opcoesPai: [],
            ecossistema: {
                idEcossistema: 0,
                nome: null,
                ativo: Boolean,                
            },
            dataCriacao: null,
            // dataAtualizacao: null,
        };
    },
    computed: {},
    async beforeMount() {
        if (this.$props.idEcossistema > 0) {
            await this.obterDadosEcossistema();
        }
    },
    methods: {
        async obterDadosEcossistema() {
            this.$loading(true);                        
            await http
                .get("/Ecossistema/obterEcossistema/" + this.$props.idEcossistema)
                .then(
                    (success) => {
                        console.log(success);
                        this.ecossistema.nome = success.data.nome;
                        // this.categoria.ativo = success.data.ativo;
                        // this.categoria.idCategoriaPai =
                        //     success.data.idCategoriaPai;
                        // this.categoria.idCategoria =
                        //     success.data.idCategoria;
                        this.ecossistema.ativo = success.data.ativo;
                        this.ecossistema.regiao = success.data.regiao;
                        this.dataCriacao = this.formatDate(
                            success.data.dataCadastro
                        );
                        // this.dataAtualizacao = this.formatDate(
                        //     success.data.dataAtualizacao
                        // );
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                );            
            this.$loading(false);
        },
        async handleSubmit() {
            this.$loading(true);
            if (this.$props.idEcossistema == 0) {
                await http
                    .post("/Ecossistema/criarEcossistema", this.ecossistema)
                    .then(
                        (success) => {
                            this.$snotify.success(
                                "Ecossistema criado com sucesso."
                            );

                            setTimeout(() => {
                                this.voltarTela();
                            }, 1000);
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    );
            } else {
                this.ecossistema.idEcossistema = +this.$props.idEcossistema;
                await http
                    .post("/Ecossistema/Ecossistema/" + this.$props.idEcossistema, this.ecossistema)
                    .then(
                        (success) => {
                            this.$snotify.success(
                                "Ecossistema atualizado com sucesso."
                            );
                            setTimeout(() => {
                                this.voltarTela();
                            }, 1000);
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    );
            }
            this.$loading(false);
        },
        voltarTela() {
            window.location.href = "/painel/admin/ecossistemas";
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
    },
};
