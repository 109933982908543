import http from "@/_helper/api-services.js";
import "./pagamentos.scss";

export default {
    name: "pagamentos",
    data() {
        return {
            search: {
                login: String(),
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                ativo: true,
                idStatus: 4,
                quantidade: 5,
                page: 1,
            },
            resumoSaque: {
                aprovadosSemana: 0,
                aprovadosTotal: 0,
                pendentesSemana: 0,
                valorTaxa: 0,
            },
            listStatus: [],
            fields: [
                { key: "selecionado", label: "", sortable: false },
                {
                    key: "login",
                    label: "Login",
                    sortable: true,
                    sortDirection: "desc",
                },
                {
                    key: "dataSolicitacao",
                    label: "Data Saque",
                    sortable: true,
                    formatter: (value) => {
                        if (value)
                            return `${new Date(
                                value
                            ).toLocaleDateString()} ${new Date(
                                value
                            ).toLocaleTimeString()}`;
                        return "";
                    },
                },
                {
                    key: "valor",
                    label: "Valor",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `$ ${value.toFixed(2)}`;
                    },
                },
                {
                    key: "taxaSaque",
                    label: "Taxa de saque",
                    sortable: true,
                    formatter: (value) => {
                        return (value ? value : 0) + "%";
                    },
                },
                {
                    key: "valorReal",
                    label: "Valor a receber",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `$ ${value.toFixed(2)}`;
                    },
                },
                { key: "status", label: "Status", sortable: true },
            ],
            list: [],
            totalPages: 0,
            showIconsToChangePage: false,
            perPage: 10,
            pageOptions: [10, 20, 50],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            action: "",
            filterOn: [],
            todosSelecionados: false,
            usuarioConfiguracao: {
                idUsuarioConfiguracao: 0,
                nomeUsuario: String(),
                idUsuario: String(),
                taxaSaque: Number(),
            },
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            pagamentoManual: {
                idSaque: 0,
                urlTransacao: String(),
            },
            postAction: {
                confirmPassword: String(),
                selecionados: [],
            },
            exportFields: {
                Login: "login",
                "Data Saque": "dataSolicitacao",
                Valor: "valor",
                "Taxa de saque": "taxaSaque",
                "Valor a receber": "valorReal",
                Status: "status",
            },
            exportTable: [],
        };
    },
    beforeMount() {
        this.buscarStatusSaque();
        this.buscarResumoSaque();
        this.buscarSaques();
    },
    mounted() {
        this.$loading(false);
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPages <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPages;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPages - this.search.page;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPages - 2; i <= this.totalPages; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPages - 1)
                    return [1, 2, 3];
                else
                    return [
                        this.search.page - 1,
                        this.search.page,
                        this.search.page + 1,
                    ];
            }
        },
    },
    watch: {
        perPage() {
            this.search.page = 1;
            this.buscarSaques();
        },
        "search.page"() {
            this.buscarSaques();
        },
    },
    methods: {
        aprovarSelecionados() {
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
            } else {
                this.openModal("aprovar");
            }
        },
        cancelarSelecionados() {
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
            } else {
                this.openModal("cancelar");
            }
        },
        selecionarTodos() {
            this.list.forEach((l) => {
                if (l.idStatus == 4) l.selecionado = !this.todosSelecionados;
            });
        },
        buscarSaques(event) {
            if (event) {
                event.preventDefault();
                this.search.page = 1;
            }
            this.search.quantidade = this.perPage;
            this.$loading(true);
            http.post("/saque/listarSaquesAdmin", this.search).then(
                (success) => {
                    this.list = [];
                    if (success && success.data) {
                        this.totalPages = success.data.totalPaginas;
                        success.data.lista.forEach((d) => {
                            this.list.push({
                                selecionado: false,
                                dataSolicitacao: d.dataSolicitacao,
                                dataProcessado: d.dataProcessado,
                                idSaque: d.idSaque,
                                status: d.status,
                                login: d.login,
                                nome: d.nome,
                                valor: d.valor,
                                valorReal: d.valorReal,
                                processado: d.processado,
                                enderecoBTC: d.enderecoBTC,
                                taxaSaque: d.taxaSaque,
                                tipo: d.tipo,
                                aprovador: d.aprovador,
                                historico: d.historico,
                                dataAprovacao: d.dataAprovacao,
                                idStatus: d.idStatus,
                            });
                        });
                        this.exportTable = [];
                        for (let i in this.list) {
                            this.exportTable.push(this.list[i]);
                        }
                        this.totalRows = success.data.length;
                    }
                    this.$loading(false);
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        buscarStatusSaque() {
            this.$loading(true);
            http.get("/Saque/obterStatusSaque").then((response) => {
                this.listStatus.push({
                    value: 0,
                    text: "Todos",
                });
                response.data.forEach((item) => {
                    this.listStatus.push({
                        value: item.value,
                        text: item.key,
                    });
                });
                this.$loading(false);
            });
        },
        buscarResumoSaque() {
            this.$loading(true);
            http.get("/saque/obterResumoSaque").then((response) => {
                this.resumoSaque.aprovadosSemana =
                    response.data.aprovadosSemana;
                this.resumoSaque.aprovadosTotal = response.data.aprovadosTotal;
                this.resumoSaque.pendentesSemana =
                    response.data.pendentesSemana;
                this.resumoSaque.valorTaxa = response.data.valorTaxa;
                this.$loading(false);
            });
        },
        confirmarAcao() {
            this.postAction.selecionados = [];
            let url = "/saque/cancelarSaques";
            if (this.action == "aprovar") url = "/saque/aprovarSaques";
            this.list
                .filter((c) => c.selecionado === true)
                .forEach((l) => {
                    this.postAction.selecionados.push({
                        idSaque: l.idSaque,
                    });
                });
            this.$loading(true);
            http.post(url, this.postAction)
                .then(
                    () => {
                        if (this.action === "aprovar") {
                            this.$snotify.success("Saques aprovados!");
                        } else {
                            this.$snotify.success("Saques cancelados!");
                        }
                        this.buscarSaques();
                        this.buscarResumoSaque();
                        this.fecharModal();
                    },
                    (error) => {
                        // this.fecharModal();
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => this.$loading(false));
        },
        fecharModal() {
            // this.
            this.$bvModal.hide("modal-confirm");
        },
        openModal(tipo) {
            this.action = tipo;
            this.$bvModal.show("modal-confirm");
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        valorFormatado(value) {
            return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        valorTotal() {
            let selecionados = this.list.filter((c) => c.selecionado === true);
            if (selecionados != null && selecionados.length > 0) {
                let soma = 0;
                selecionados.forEach((s) => {
                    soma += s.valor;
                });
                return this.valorFormatado(soma);
            }
            return this.valorFormatado(0);
        },
    },
};
