<template>
    <div v-if="isLocalhost" class="alert alert-warning rounded-0 w-100" role="alert">
        Você está em ambiente de desenvolvimento
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLocalhost: false
        };
    },
    mounted() {
        const allowedHosts = ['localhost', 'hlog.quantashop.com.br', 'nova-home.quantashop.com.br', 'scan-go.quantashop.com.br'];

        this.isLocalhost = allowedHosts.includes(window.location.hostname);
    }
};
</script>

<style>
.z-index {
    z-index: 9999;
}

.w-10 {
    width: 10%;
}
</style>