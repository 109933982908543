<template>
    <b-form-group>
        <textarea rows="4" v-model="value.mensagem" required placeholder="Mensagem"></textarea>
    </b-form-group>
</template>    

<script>
export default {
    name: 'inputContato',
    props: {
        value: Object,
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        },
    },
}
</script>
