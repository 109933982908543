import "./finalizarCredenciamento.scss";
import http from "@/_helper/api-services";

export default {
    name: "finalizarCredenciamento",
    data() {
        return {
            newUser: {
                idCredenciamento: String(),
                nome: String(),
                senha: String(),
                password: String(),
                confirmEmail: String(),
                login: String(),
                telefone: String(),
            },
            telefoneSemDDD: String(),
            DDD: String(),
        };
    },
    computed: {
        emailValid() {
            if (this.newUser.email == this.newUser.confirmEmail) return true;
            return false;
        },
    },
    beforeMount() {
        this.newUser.idCredenciamento = this.$route.params.idCredenciamento;
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        async handleSubmit(event) {
            event.preventDefault();
            this.$loading(true);

            this.newUser.telefone = (this.DDD + this.telefoneSemDDD)
                .replace(" ", "")
                .replace("(", "")
                .replace(")", "")
                .replace("-", "");

            http.post("/Credenciamento/FinalizarCadastro", this.newUser)
                .then(
                    () => {
                        this.$snotify.success(
                            "Pré cadastro efetuado com sucesso."
                        );
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => this.$loading(false));
        },
    },
};
