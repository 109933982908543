<template>
  <div>
    <a href="javascript:void(0)" @click.prevent="openModal" ref="btnShow">
      <img src="@/assets/imgs/comece.png" alt="" class="info-icon" />
    </a>

    <b-modal :id="modalId" :title="title" centered hide-footer size="lg">
      <b-embed
        type="iframe"
        aspect="16by9"
        :src="videoUrl"
        allowfullscreen
      ></b-embed>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    videoUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalId: this.generateUniqueId(),
    };
  },
  methods: {
    generateUniqueId() {
      return `modal-${Math.random().toString(36).substr(2, 9)}-${Date.now()}`;
    },
    openModal() {
      this.$root.$emit('bv::show::modal', this.modalId, '#btnShow')
    },
  },
};
</script>

<style lang="css" scoped>
.info-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 20px;
  z-index: 1000;
}
</style>
