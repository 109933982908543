import "./Privacidade.scss";

export default {
    name: "Privacidade",
    data() {
        return {
            
        };
    },
    mounted(){
        
    },
    computed: {
        
    },
    methods: {
        
    }
}