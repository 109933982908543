<template>
  <b-container class="bg-white">
    <!-- Header -->
    <b-row class="align-items-center mb-4">
      <b-col cols="12" sm="2" md="2" lg="1" xl="1" class="text-center mb-3 mb-sm-0">
        <img src="@/assets/imgs/loja.png" class="img-fluid" alt="Loja" />
      </b-col>
      <b-col cols="12" sm="10" md="10" lg="11" xl="11">
        <h5 class="font-weight-bold display-md-4 text-green text-small-screen">
          Encontre as lojas parceiras mais próximas de você!
        </h5>
        <p class="text-sm-justify text-small-screen">
          Descubra rapidamente os parceiros locais que oferecem cashback na sua região. Nosso mapa interativo exibe as
          opções mais próximas, facilitando sua jornada para economizar e ganhar dinheiro enquanto faz compras e indica
          amigos. Clique nos marcadores para ver detalhes e aproveitar as ofertas exclusivas!
        </p>
      </b-col>
    </b-row>

    <!-- Mapa com Localizações -->
    <div class="rounded border">
      <l-map style="height: 50vh; min-height: 300px" :zoom="zoom" :center="center" :minZoom="minZoom" :maxZoom="maxZoom"
        @update:center="updateCenter" @update:zoom="updateZoom" ref="map">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      </l-map>
    </div>
  </b-container>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-fullscreen";
import defaultImage from "@/assets/imgs/loja.png";
import whatsappIcon from "@/assets/imgs/whatsapp.png";
import targetIcon from "@/assets/imgs/re-center.png";
import mapsIcon from "@/assets/imgs/maps.png";
import http from "@/_helper/api-services.js";
import _ from "lodash";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      location: null,
      gettingLocation: false,
      errorStr: null,
      url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 17,
      maxZoom: 17,
      minZoom: 12,
      fullscreenControl: true,
      center: [-22.9068, -43.1729],
      initialCenter: [-22.9068, -43.1729],
      customIcon: L.icon({
        iconUrl: defaultImage,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38],
      }),
      isLoading: false,
      debouncedBuscarLojas: null,
    };
  },
  created() {
    this.getLocation();
    this.debouncedBuscarLojas = _.debounce(this.buscarLojas, 300);
  },
  mounted() {
    this.$nextTick(() => {
      const map = this.$refs.map;
      if (map && map.mapObject) {
        const button = L.control({ position: "topleft" });

        button.onAdd = () => {
          const btn = L.DomUtil.create("button", "leaflet-bar");
          btn.innerHTML = `<img src="${targetIcon}" alt="Voltar ao centro" style="width: 20px; height: 20px;">`;
          btn.style.backgroundColor = "white";
          btn.style.width = "34px";
          btn.style.height = "34px";
          btn.style.borderRadius = "3px";

          btn.onclick = this.returnToCenter;
          return btn;
        };

        button.addTo(map.mapObject);

        L.control
          .fullscreen({
            position: "topleft",
            title: {
              false: "Exibir em tela cheia",
              true: "Sair da tela cheia",
            },
          })
          .addTo(map.mapObject);
      }
    });
  },
  methods: {
    getLocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }

      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(
        this.onLocationSuccess,
        this.onLocationError
      );
    },
    onLocationSuccess(pos) {
      this.gettingLocation = false;
      this.location = pos;
      this.center = [pos.coords.latitude, pos.coords.longitude];
      this.initialCenter = [pos.coords.latitude, pos.coords.longitude];
      this.buscarLojas();
    },
    onLocationError(err) {
      this.gettingLocation = false;
      this.errorStr = err.message;
      this.buscarLatLng();
    },
    updateCenter(newCenter) {
      this.center = newCenter;
    },
    returnToCenter() {
      this.center = this.initialCenter;
      this.$refs.map.mapObject.setView(this.initialCenter, this.zoom);
    },
    updateZoom(newZoom) {
      this.zoom = newZoom;
      this.debouncedBuscarLojas();
    },
    metresPerPixel() {
      const map = this.$refs.map.mapObject;

      if (!map) {
        console.error("Mapa não está disponível.");
        return 1;
      }

      const southEastPoint = map.getBounds().getSouthEast();
      const northEastPoint = map.getBounds().getNorthEast();
      const mapHeightInMetres = southEastPoint.distanceTo(northEastPoint);
      const mapHeightInPixels = map.getSize().y;

      const metresPerPixel = parseInt(mapHeightInMetres / mapHeightInPixels);
      return metresPerPixel === 0 ? 1 : metresPerPixel;
    },
    async buscarLojas() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.lojas = [];
      try {
        const map = this.$refs.map.mapObject;

        let params = {
          latitude: map.getBounds().getCenter().lat,
          longitude: map.getBounds().getCenter().lng,
          raio: this.metresPerPixel(),
        };
        const { data } = await http.get(
          "/v2/dashboard/get-shops-nearby",
          params
        );

        this.lojas = data.data;

        map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            map.removeLayer(layer);
          }
        });

        this.lojas.forEach((shop) => {
          L.marker([shop.latitude, shop.longitude])
            .setIcon(this.customIcon)
            .addTo(map)
            .bindPopup(
              "<strong style='display: block; text-align: center; margin-bottom: 10px;'>" +
              shop.name +
              "</strong>" +
              `<span style='text-align: center; display: block;'>${shop.street}, ${shop.number}, ${shop.neighborhood}</span>` +
              `<span style='text-align: center; display: block; margin-bottom: 10px;'>${shop.city}-${shop.state}</span>` +
              "Está a aproximadamente <strong>" +
              shop.formatted_distance +
              "</strong> de você" +
              `<a href="https://www.google.com/maps/place/${shop.street}, ${shop.number}, ${shop.neighborhood}, ${shop.city}, ${shop.state}" style="text-align: left; display: block; margin-top: 10px;" target="_blank">` +
              `<img src="${mapsIcon}" alt="Google Maps" style="width: 15px; height: 15px; margin-right: 5px;">` +
              "Ver no Google Maps" +
              `</a>` +
              `<a href="https://wa.me/${shop.phone}" style="text-align: left; display: block; margin: 10px 0;" target="_blank">` +
              `<img src="${whatsappIcon}" alt="WhatsApp" style="width: 15px; height: 15px; margin-right: 5px;">` +
              this.$options.filters.formatPhone(shop.phone) +
              `</a>`
            );
        });
      } catch (error) {
        console.error("Erro ao buscar lojas próximas:", error);
        this.$snotify.error(
          "Erro ao buscar lojas próximas. Por favor, tente novamente."
        );
      } finally {
        this.isLoading = false;
      }
    },
    async buscarLatLng() {
      if (this.isLoading) return;

      this.isLoading = true;

      try {
        const map = this.$refs.map.mapObject;

        const { data } = await http.get("/v2/dashboard/get-user-lat-lng");
        const pos = data.data;
        this.center = [pos.latitude, pos.longitude];
        this.initialCenter = [pos.latitude, pos.longitude];
      } catch (error) {
        console.error("Erro ao buscar localização do usuário:", error);
        this.$snotify.error(
          "Erro ao buscar localização do usuário. Por favor, tente novamente."
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.text-success {
  color: #76b82a !important;
}
</style>

<style>
.ranking-icon {
  width: 3.5 rem;
}

.leaflet-map-pane> :not(.leaflet-marker-pane):not(.leaflet-popup-pane) {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.text-green {
  color: #1e5d68;
}

h5.text-green {
  font-size: 1.3rem;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .display-md-4 {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media (max-width: 576px) {
  .text-small-screen {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .text-sm-justify {
    text-align: justify;
  }
}

.title-color {
  color: #98c73a !important;
}
</style>
