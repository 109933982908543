import tabelaGraduacoes from "@/components/TabelaGraduacoes/tabelaGraduacoes.vue";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import graduacao from "@/components/Graduacao/Graduacao.vue";

export default {
    name: "financeiro",
    data() {
        return {};
    },
    components: {
        tabelaGraduacoes,
        BarraIndicacao,
        graduacao
    },
    mounted() {
        
    },
    methods: {

    }
}