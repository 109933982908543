import http from "@/_helper/api-services.js";

export default {
    name: "TelaApp",
    data() {
        return {
            imagensCarrossel: Array,
            imagensSlider: Array,
        };
    },
    components: {},
    computed: {},
    watch: {},
    async mounted() {},
    methods: {
        async GetDadosTelaApp() {
            this.$loading(true);
            await http.get("/Config/ObterDadosTelaApp").then(
                (success) => {
                    this.totalPages = success.data.totalPages;
                    this.items = success.data.faturasFiltradas;
                    this.itemCount = success.data.quantidadeTotal;
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
            this.$loading(false);
        },
    },
};
