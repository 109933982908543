import dadosBancarios from "@/components/DadosBancarios/DadosBancarios.vue";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
export default {
    name: "contasBancarias",
    data() {
        return {};
    },
    components: {
        BarraIndicacao,
        dadosBancarios
    }
}