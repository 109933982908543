import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

// import "./PedidoPlanos.scss";

export default {
    name: "UsuarioPlano",
    data() {
        return {
            dadosUsuario: {
                nome: String,
                login: String,
                produtoAtivo: String
            },
            planoSelecionado: String,
            confirmacaoAcao: false,
            planoSelecionadoAcao: false,
            opcoesProduto: [],
            produtos: [],
            request: {
                login: String,
                idProduto: Number,
                valorPedido: Number,
                reaisPorPonto: Number,
                numeroParcelas: Number,
                numeroParcelasPagas: Number,
                dataReferencia: Date,
                gerarPontos: true,
                distribuirNaRede: true
            },
        };
    },
    components: {
        tabelaBase
    },
    computed: {},
    async beforeMount() {
        this.$loading(true);
        await this.obterDadosProduto();
        this.$loading(false);
    },
    methods: {
        async obterDadosProduto() {
            http.get("/produto/obterDadosAtivacao/" + this.$route.query.login).then(
                (success) => {
                    this.dadosUsuario = success.data.dadosUsuario;
                    this.produtos = success.data.opcoes;

                    this.produtos.forEach((item) => {
                        this.opcoesProduto.push({
                            value: item.idProduto,
                            text: item.nome + " - " + this.formatMoney(item.valor),
                            valorProduto: item.valor
                        });
                    });
                },
                (error) => {
                    this.$snotify.error("Erro ao obter pedido.");
                }
            );
        },
        voltarTela() {
            window.location.href = "/painel/admin/usuarios";
        },
        async handleSubmit() {
            this.request.login = this.$route.query.login;
            this.request.numeroParcelas = +this.request.numeroParcelas
            this.request.numeroParcelasPagas = +this.request.numeroParcelasPagas
            this.request.reaisPorPonto = +this.request.reaisPorPonto
            this.request.valorPedido = +this.request.valorPedido

            http.post("/pedidos/gerarPedidoManual/", this.request).then(
                (success) => {
                    this.$snotify.success("Pedido gerado com sucesso.");
                    this.fecharModal();

                    window.location.href = "/painel/admin/usuarios";
                },

                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );

        },
        modalPagar(idPagamento) {
            this.confirmacaoAcao = false;
            this.updatePagamento.idPagamento = idPagamento;
            this.updatePagamento.acao = 1;
            this.$bvModal.show("confirm-pagamento");
        },
        modalRefresh(idPagamento) {
            this.confirmacaoAcao = false;
            this.updatePagamento.idPagamento = idPagamento;
            this.updatePagamento.acao = 2;
            this.$bvModal.show("confirm-refresh");
        },
        modalDelete(idPagamento) {
            this.confirmacaoAcao = false;
            this.updatePagamento.idPagamento = idPagamento;
            this.updatePagamento.acao = 3;
            this.$bvModal.show("confirm-delete");
        },
        modalUpdatePedido() {
            if (this.planoSelecionadoAcao) {
                this.confirmacaoAcao = false;
                this.$bvModal.show("confirm-update-pedido");
            }
        },
        fecharModal() {
            this.$bvModal.hide("confirm-update-pedido");
        },
        onSelectProduto(event) {
            this.request.idProduto = event;
            this.request.numeroParcelas = 1;
            this.request.valorPedido = this.produtos.find(c => c.idProduto == event).valor;
            this.request.reaisPorPonto = this.produtos.find(c => c.idProduto == event).reaisPorPonto;
            this.planoSelecionado = this.produtos.find(c => c.idProduto == event).nome;
            this.planoSelecionadoAcao = true;
        },
        formatMoney(value) {
            return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
    },
};