import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "formPlanos",
    components: {
        tabelaBase,
    },
    props: {
        idConfiguracao: { type: String, default: 0 },
    },
    data() {
        return {
            dados: {
                ativo: Boolean(),
                idConfiguracao: Number(),
                descricao: String(),
                valor: String(),
            },
        };
    },
    computed: {},
    async beforeMount() {
        if (this.$props.idConfiguracao != 0 && this.$props.idConfiguracao != null) {
            await this.obterDadosPlano();
        }
    },
    methods: {
        async obterDadosPlano() {
            this.$loading(true);

            await http
                .get(`/config/obterConfig/${this.$props.idConfiguracao}`)
                .then(
                    (success) => {
                        if (success.data) {
                            this.dados = success.data.dadosConfig;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async editarConfig() {
            this.$loading(true);
            await http
                .post(`/config/editarConfig/`, this.dados)
                .then(
                    (success) => {
                        if (success) {
                            this.$snotify.success("Configuração editada com sucesso");
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },

        voltarTela() {
            this.$router.push("/painel/admin/configuracao");
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
    },
};