import "./confirmEmail.js";
import usuarioService from "@/_helper/usuario-service.js";

export default {
    name: "confirmEmail",
    data() {
        return {};
    },
    mounted() {
        if (this.$route.params.tokenConfirmar) {
            usuarioService.confirmEmail(this.$route.params.tokenConfirmar).then(
                () => {
                    this.$snotify.success("Email validado com sucesso.", {
                        position: "rightTop",
                    });
                    this.$router.push("/");
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                    this.$router.push("/");
                    // if (typeof error.response.data.emailConfirmado == "boolean" && !error.response.data.emailConfirmado)
                    //     this.$snotify.html(
                    //         `
                    //          <div class="snotify-icon snotify-icon--error"></div>
                    //          <div class="snotifyToast__body">${error.response.data.message}</div>
                    //          `, {
                    //         buttons: [
                    //             { text: "Reenviar email", action: this.resendEmailConfirmation, bold: true },
                    //             { text: "Ok", action: null, bold: true }
                    //         ],
                    //         timeout: 0,
                    //         type: "error",
                    //         position: "rightTop"
                    //     });
                }
            );
        }
    },
};
