import "./RecusaPrivacidade.scss";

export default {
    name: "RecusaPrivacidade",
    data() {
        return {
            
        };
    },
    mounted(){
        
    },
    computed: {
        
    },
    methods: {
        aceitarTermos()
        {
            $cookies.set("policy-accepted", true);
            window.location.href = "";
            window.location.reload();
        },
        getFile() {
            window.open("/QB-LGPD.pdf", "_blank");
        }
    }
}