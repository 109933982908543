import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "configuração",
    components: {
        tabelaBase,
    },
    data() {
        return {
            dados: {
                items: [],
                title: " ",
                fields: [
                    {
                        key: "descricao",
                        label: "Descricao",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "valor",
                        label: "Valor",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                    },
                ],
                sortBy: "descricao",
                sortDesc: false,
                exportExcel: false,
                perPage: null,
            },
        };
    },
    beforeMount() {
        this.obterConfiguracoes();
    },
    methods: {
        async obterConfiguracoes() {
            this.$loading(true);

            await http
                .get(`/config/ObterConfiguracoes/`)
                .then(
                    (success) => {
                        if (success.data) {
                            this.dados.items = success.data;
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        editarConfig(idConfiguracao) {
            this.$router.push("/painel/admin/configuracao/editar-configuracao?idConfiguracao=" + idConfiguracao);
        },
    }

}