import "./BannerMobile.scss";
import carousel from "vue-owl-carousel";

export default {
	name: "BannerMobile",
	data() {
		return {};
	},
	components: {
		carousel
	}
}