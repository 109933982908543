<template>
  <div class="container bg-white">
    <div class="row" v-if="loading">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-green" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-12 col-sm-2 col-md-2 col-lg-1 col-xl-1 d-flex align-items-center justify-content-center">
        <img src="@/assets/imgs/ranking.png" alt="" class="ranking-icon" />
      </div>
      <div
        class="col-12 col-sm-10 col-md-10 col-lg-11 col-xl-11 d-flex flex-column align-items-center align-items-md-start justify-content-center">
        <h5 class="font-weight-bold display-md-4 text-green">
          Destaques da sua equipe
        </h5>
        <p class="text-sm-justify">
          Veja quem está brilhando entre os indicados da sua equipe e inspire-se com seus ganhos por minuto!
        </p>
      </div>
    </div>

    <div class="row" v-if="amigos.length > 0">
      <div class="col-12 col-md-6" v-for="(amigo, index) in amigos" :key="index">
        <div class="card mb-3">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-12">
              <h5 class="text-center mt-4 font-weight-bold text-green name">
                {{ amigo.Nome | truncateText(28) }}
              </h5>
              <div class="progress blue">
                <span class="progress-top">
                  <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                  <span class="progress-bar"></span>
                </span>
                <div class="progress-value">
                  <span class="b-avatar rounded-circle" style="width: 80px; height: 80px">
                    <span class="b-avatar-img">
                      <img alt="avatar" :src="amigo.URLIMG" @error="onImageError" />
                    </span>
                  </span>
                </div>
              </div>
              <div class="text-center">
                <h6 class="font-weight-bold text-green">@{{ amigo.Login }}</h6>
                <h6 class="text-green">{{ amigo.Plano }}</h6>

                <h6 class="my-1 badge badge-gold d-inline-block" v-if="amigo.Assinante">
                  Assinante Quanta Plus
                </h6>

                <h6 class="my-1 text-white badge badge-danger d-inline-block" v-else>
                  Ainda não é assinante Quanta Plus
                </h6>

                <div>
                  <button type="button" class="mt-2 btn btn-success rounded-pill">
                    <a :href="`https://wa.me/55${amigo.Celular}`" target="_blank">
                      <span class="text-white">
                        <font-awesome-icon :icon="['fab', 'whatsapp']" class="icon-whatsapp" />
                        WhatsApp
                      </span>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <h6 class="mb-3 font-weight-bold text-green">Performance</h6>
            <hr />

            <div class="performance-item">
              <span class="performance-label">Equipe</span>
              <span class="line"></span>
              <span class="performance-value text-green">{{ amigo.Rede }}</span>
            </div>

            <div class="performance-item">
              <span class="performance-label">Credenciamentos</span>
              <span class="line"></span>
              <span class="performance-value text-green">{{
                amigo.Credenciamentos
              }}</span>
            </div>
          </div>

          <div class="mt-2 text-right">
            <label v-if="amigo.DiferencaDias > 0" class="font-size-12 font-italic">Última compra há {{ amigo.DiferencaDias }} dias</label>
            <label v-else class="font-size-12 font-italic">Ainda não realizou compras</label>
          </div>
        </div>
      </div>
    </div>

    <div class="highlighted-message row" v-else>
      Você ainda não tem uma rede, mas isso está prestes a mudar!
      Conecte-se com amigos, colegas ou profissionais da sua área para começar a
      construir sua rede e explorar quem está brilhando. Quanto mais você
      interagir e se conectar, mais você verá o impacto positivo disso na sua
      experiência aqui. Comece agora a adicionar pessoas e descubra o potencial
      incrível que uma rede sólida pode trazer! 🚀
    </div>
  </div>
</template>

<script>
import http from "@/_helper/api-services.js";
import defaultImage from "@/assets/imgs/quanta-shop.jpg";

export default {
  data: () => ({
    amigos: [],
    loading: false,
    defaultImage,
  }),

  async created() {
    await this.buscarTop5();
  },

  methods: {
    async buscarTop5() {
      this.loading = true;
      this.amigos = [];
      try {
        const { data } = await http.get("/v2/dashboard/get-top5-connections");
        this.amigos = data.data;
      } catch (error) {
        console.error("Erro ao buscar o top 5:", error);
        this.$snotify.error(
          "Erro ao buscar o top 5. Por favor, tente novamente."
        );
      } finally {
        this.loading = false;
      }
    },

    onImageError(event) {
      event.target.src = this.defaultImage;
    },
  },
};
</script>

<style scoped>
.ranking-icon {
  width: 3.5rem;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  border: 3px solid #1e5d68;
  background-color: #1e5d68;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.avatar img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  object-position: center;
}

.text-green {
  color: #1e5d68;
}

h5.text-green {
  font-size: 1.5rem;
  line-height: 1.2;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-12 {
  font-size: 0.75rem;
}

@media (max-width: 412px) {
  ol {
    padding-left: 20px;
  }
}

@media (max-width: 576px) {
  .text-small-screen {
    text-align: center;
  }

  .text-sm-justify {
    text-align: justify;
  }
}

@media (min-width: 768px) {
  .display-md-4 {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

.card {
  width: 100%;
  height: auto;
  border: none;
  border-radius: 8px;
  background-color: rgb(250, 254, 254);
  padding: 10px 20px;
  box-shadow: 0 1px 2px #010f1c1a;
}

.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-top {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-top .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  transform-origin: center left;
  transform: rotate(270deg);
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  transform-origin: center right;
  transform: rotate(90deg);
}

.progress .progress-value {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  text-align: center;
  position: absolute;
  top: 0rem;
  left: 0;
  z-index: 99;
}

.progress.blue .progress-bar {
  border-color: #5aad60;
}

/* Additional progress bars */
.progress-container {
  display: flex;
  flex-direction: column;
}

.progress-item {
  display: flex;
  align-items: center;
}

.progress-text {
  width: 100%;
  text-align: left;
}

.progress-label {
  width: 20%;
  text-align: center;
  font-weight: 800;
}

.progress-bar-container {
  width: 100%;
  height: 2px;
  background-color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-linework {
  width: 100%;
  height: 100%;
  background-color: #1e5d68;
}

.progress-bar-career {
  width: 100%;
  height: 100%;
  background-color: #1e5d68;
}

.badge-gold,
.badge-danger {
  min-width: 220px;
  height: 30px;
  display: flex;
  align-content: center;
}

.badge-gold {
  background: linear-gradient(to right, #bf953f, #b38728, #fcf6ba);
  color: #000000;
}

.badge-danger {
  background-image: linear-gradient(45deg,
      #ef5350,
      #e57373,
      #c62828) !important;
  color: white;
}

.performance-item {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.performance-item span.performance-label {
  flex-shrink: 0;
  /* min-width: 135px; */
}

.performance-item span.performance-value {
  flex-shrink: 0;
  /* min-width: 55px; */
  text-align: center;
}

.line {
  flex-grow: 1;
  border-bottom: 2px dotted #1e5d68;
  margin: 5px 10px 6px 10px;
}

.name {
  font-size: 1rem !important;
}

@media only screen and (max-width: 600px) {
  .name {
    font-size: 0.675rem !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .name {
    font-size: 0.775rem !important;
  }
}

@media only screen and (min-width: 993px) {
  .name {
    font-size: 0.875rem !important;
  }
}

.b-avatar .b-avatar-img img {
  width: auto !important;
  height: 100%;
  max-height: auto;
  border-radius: inherit;
  -o-object-fit: cover;
  object-fit: contain;
}

.title-color {
  color: #98c73a !important;
}

.highlighted-message {
  background-color: #f0f8ff;
  /* Cor de fundo suave para destacar */
  border-left: 5px solid #1e5d68;
  /* Barra lateral para destaque */
  padding: 20px;
  /* Espaçamento interno */
  margin: 20px 0;
  /* Espaçamento externo */
  border-radius: 8px;
  /* Cantos arredondados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra para profundidade */
  font-family: Arial, sans-serif;
  /* Fonte padrão */
  color: #333;
  /* Cor do texto */
}

.highlighted-message h2 {
  color: #1e5d68;
  /* Cor do título */
  margin-top: 0;
}

.highlighted-message p {
  margin: 0;
  line-height: 1.6;
  /* Melhora a legibilidade */
}

.highlighted-message button {
  background-color: #1e5d68;
  /* Cor do botão */
  color: #fff;
  /* Cor do texto do botão */
  border: none;
  /* Remove a borda padrão */
  padding: 10px 20px;
  /* Espaçamento interno do botão */
  border-radius: 4px;
  /* Cantos arredondados do botão */
  cursor: pointer;
  /* Muda o cursor para indicar que é clicável */
  font-size: 16px;
  /* Tamanho da fonte do botão */
  margin-top: 10px;
  /* Espaçamento acima do botão */
}

.highlighted-message button:hover {
  background-color: #45a049;
  /* Cor do botão ao passar o mouse */
}
</style>
