import "./compras.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "compras",
    data() {
        return {
            search: {
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                idStatus: null,
                login: String(),
                loginPatrocinador: String(),
                loginCredenciado: String(),
                page: 1,
                perPage: 10,
                orderDesc: true,
                ordenacao: null,
            },
            selecionadosAcao: [],
            list: [],
            totalPages: 0,
            showIconsToChangePage: false,
            pageOptions: [10, 20, 50],
            sortBy: "dataPedido",
            status: [
                {
                    text: "Todos",
                    value: null,
                },
            ],
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            fields: [
                { key: "login", label: "Login", sortable: true },
                {
                    key: "loginPatrocinador",
                    label: "Patrocinador",
                    sortable: true,
                },
                {
                    key: "dataPedido",
                    label: "Data pedido",
                    sortDirection: "desc",
                    sortable: true,
                    formatter: (value) =>
                        `${new Date(value).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`,
                },
                {
                    key: "valorPedido",
                    label: "Valor",
                    sortable: true,
                    formatter: (value) =>
                        value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        }),
                },
                {
                    key: "cashback",
                    label: "Cashback",
                    sortable: true,
                    formatter: (value) =>
                        value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        }),
                },
                {
                    key: "status",
                    label: "Status",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "atualizacao",
                    label: "Data Atualização",
                    class: "text-center",
                    sortable: true,
                    formatter: (value) =>
                        `${new Date(value).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`,
                },
                {
                    key: "loja",
                    label: "Loja",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "afiliado",
                    label: "Afiliado",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "acoes",
                    label: "Ações",
                    class: "text-center",
                    sortable: false
                },
            ],
            exportFields: {
                Login: "login",
                Patrocinador: "loginPatrocinador",
                "Data pedido": "dataPedido",
                Valor: "valorPedido",
                Status: "status",
                "Data Atualização": "atualizacao",
                Loja: "loja",
                Afiliado: "afiliado",
            },
            exportTable: [],
            remover: {},
            confirmarAcao: false,
            aprovar: [],
            valorCashback: 0,
            acao: '',
            progressoAcao: 0,
        };
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPages <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPages;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPages - this.search.page;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPages - 2; i <= this.totalPages; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPages - 1)
                    return [1, 2, 3];
                else
                    return [
                        this.search.page - 1,
                        this.search.page,
                        this.search.page + 1,
                    ];
            }
        },
    },
    watch: {
        async "search.perPage"() {
            this.search.page = 1;
            this.$loading(true);
            await this.getPedidos();
            this.$loading(false);
        },
        async "search.page"() {
            this.$loading(true);
            await this.getPedidos();
            this.$loading(false);
        },
        async "search.orderDesc"() {
            this.$loading(true);
            await this.getPedidos();
            this.$loading(false);
        },
        async sortBy() {
            switch (this.$data.sortBy) {
                case "login":
                    this.search.ordenacao = 0;
                    break;
                case "loginPatrocinador":
                    this.search.ordenacao = 1;
                    break;
                case "dataPedido":
                    this.search.ordenacao = null;
                    break;
                case "valorPedido":
                    this.search.ordenacao = 3;
                    break;
                case "produto":
                    this.search.ordenacao = 4;
                    break;
                case "status":
                    this.search.ordenacao = 5;
                    break;
            }
            this.$loading(true);
            await this.getPedidos();
            this.$loading(false);
        },
    },
    async beforeMount() {
        this.$loading(true);
        await this.getPedidos();
        await this.getStatus();
        this.$loading(false);
    },
    methods: {
        async getPedidos(event) {
            if (event) {
                if (event) {
                    event.preventDefault();
                }
                this.$loading(true);
                this.search.page = 1;
            }
            this.selecionarTodos(false);
            await http
                .post("/Pedidos/listaPedidosAfiliadosAdmin", this.search)
                .then(
                    (success) => {
                        this.totalPages = success.data.totalPages;
                        this.list = success.data.data;

                        this.exportTable = [];
                        for (let i in success.data.data) {
                            this.exportTable.push(success.data.data[i]);
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    if (event) this.$loading(false);
                });
        },
        async getStatus() {
            await http.get("/geral/status").then(
                (success) => {
                    success.data.forEach((s) => {
                        this.status.push({
                            text: s.nome,
                            value: s.idStatus,
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        podeAprovar(pedido) {
            const statusParaAprovar = [
                'Em processamento',
                'Em aprovação',
                'Aguardando pagamento de fatura',
                'Aguardando cashback',
                'Aguardando fatura credenciado',
                'Aguardando pagamento',
            ];
            return statusParaAprovar.some(s => s == pedido.status);
        },
        podeRemover(pedido) {
            return pedido.status != 'Processado' &&
                pedido.status != 'Finalizado' &&
                pedido.status != 'Pago pela Quanta Shop';
        },
        selecionarTodos(checked) {
            const selecionados = this.selecionadosAcao;
            const list = this.list;

            while (selecionados.length) {
                selecionados.pop();
            }
            if (checked) {
                list
                    .filter(f => this.podeAprovar(f) && this.podeRemover(f))
                    .forEach(f => {
                        selecionados.push(f);
                    });
            }
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        modalAprovar(compras) {
            this.confirmarAcao = false;
            if (Array.isArray(compras)) {
                this.aprovar = compras.filter(this.podeAprovar);
                this.valorCashback = compras.reduce(
                    (previous, current) => previous + current.cashback,
                    0)
                    .toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });
            }
            else {
                this.aprovar = [compras];
                this.valorCashback = compras.cashback.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                });
            }
            this.$bvModal.show("confirm-aprovar");
        },
        modalRemover(compra) {
            this.confirmarAcao = false;
            this.remover = compra;
            this.$bvModal.show("remover-pedido");
        },
        async aprovarCompras() {
            this.acao = 'Pagar compras';
            this.progressoAcao = 0;
            this.$bvModal.hide("confirm-aprovar");
            this.$bvModal.show("progresso-acao");
            this.selecionadosAcao = this.aprovar;

            try {
                for (this.progressoAcao = 0;
                    this.progressoAcao < this.selecionadosAcao.length;
                    this.progressoAcao++
                ) {
                    await this.aprovarCompra(
                        this.selecionadosAcao[this.progressoAcao].idPedido);
                }

                this.getPedidos();
                this.$bvModal.hide("progresso-acao");
            } catch (e) {
                this.$snotify.error('Não foi possível completar a operação' +
                    ' para todas as compras.');
            }
        },
        async aprovarCompra(idPedido) {
            this.$loading(true);
            try {
                const success = await http.post("/compra/aprovarCashback",
                    {
                        idPedido: idPedido
                    });
                this.$snotify.success(success.data);
                this.$loading(false);
            } catch (error) {
                for (const erro of http.mensagensErro(error)) {
                    this.$snotify.error(erro);
                    this.$loading(false);
                }
                throw error;
            }
        },
        removerCompra(idPedido, confirmarRemover) {
            this.$loading(true);
            http.post("/compra/removerCompra",
                {
                    idPedido,
                    confirmarRemover
                }).then(
                    (success) => {
                        this.getPedidos();
                        this.$snotify.success(success.data);
                        this.$bvModal.hide("remover-pedido");
                        this.$loading(false);
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                            this.$loading(false);
                        }
                    }
                );
        },
        fecharProgresso() {
            if (this.progressoAcao > 0) {
                this.getPedidos();
            }
            this.$bvModal.hide("progresso-acao");
        }
    },
};
