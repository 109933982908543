import http from "@/_helper/api-services.js";
export default {
    name: "redirecionar",
    computed: {
        token() {
            return this.$route.query.token;
        }
    },
    mounted() {
        const token = this.token;
        if (token) {
            this.validarToken(token);
        }
    },
    methods: {
        validarToken(token) {
            console.log(token);
            http.get(`/usuarioLogin/redirect`, {}, {
                Authorization: `${token}`
            }).then(
                (response) => {
                    localStorage.clear();
                    const userData = response.data;
                    localStorage.setItem("user", JSON.stringify(userData));
                    window.location.href = "/painel";
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                    this.$router.push("/");
                }
            );
        }
    }
}