import "./boxSelecionarTipoCadastro.scss";
import formCadastro from "@/components/FormCadastro/formCadastro.vue";
import FormCredenciamento from "@/components/FormCredenciamento/FormCredenciamento.vue";
export default {
  name: "boxSelecionarTipoCadastro",
  data() {
    return {
      tipoSelecionado: "empreendedor",
    };
  },
  methods: {
    switchTo(action) {
      this.$root.$emit("bv::hide::popover");
      this.$emit("switchAction", action);
    },
    selecionar(ev, tipo) {
      ev.preventDefault();
      this.tipoSelecionado = tipo;
    },
  },
  components: {
    formCadastro,
    FormCredenciamento,
  },
};
