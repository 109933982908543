import "./FaqLogado.scss";
import http from "@/_helper/api-services";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";

export default {
    name: "Faq",
    data() {
        return {
            pesquisa: String(),
            listaFaq: [],
        };
    },
    components: {
        BarraIndicacao
    },
    mounted() {
        this.buscarFaq();
    },
    computed: {
        consultarPergunta: function () {
            return this.listaFaq.filter(
                (f) =>
                    f.pergunta
                        .toLowerCase()
                        .includes(this.pesquisa.toLowerCase()) ||
                    f.resposta
                        .toLowerCase()
                        .includes(this.pesquisa.toLowerCase())
            );
        },
    },
    methods: {
        async buscarFaq() {
            await http
                .get("/faq/buscarFaq")
                .then(
                    (response) => {
                        response.data.forEach((element) => {
                            this.listaFaq.push({
                                idFaq: element.idFaq,
                                pergunta: element.pergunta,
                                resposta: element.resposta,
                            });
                        });
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
    },
};
