import http from "@/_helper/api-services.js";

export default {
    name: "tabelaGraduacoes",
    data() {
        return {
            fields: [{ key: 'nivel', label: 'Nível', sortable: false, class: 'text-center' },
            { key: 'nome', label: 'Nome', sortable: false, sortDirection: 'desc' },
            { key: 'diretos', label: 'Diretos Necessários', sortable: false, class: 'text-center' },
            { key: 'pontos', label: 'Pontos', sortable: false, class: 'text-center' },
            { key: 'meusPontos', label: 'Meus Pontos', sortable: false, class: 'text-center' },
            { key: 'requisitos', label: 'Requisitos Adicionais', sortable: false, class: 'text-center' }],
            items: [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            itemSelecionado:{
                nomeGraduacao: String(),
                requisitos: Array()
            }
        };
    },
    props: ["cols", "sm", "md", "lg", "xl", "showTitle"],
    async beforeMount() {
        await this.getGraduacoes();
    },
    methods: {
        async getGraduacoes() {
            this.$loading(true);
            await http.get("/geral/obterGraduacoesDetalhes").then(success => {
                success.data.graduacoes.forEach(item => {
                    this.items.push({
                        nome: item.nome,
                        nivel: item.nivel,
                        pontos: item.pontos,
                        meusPontos: success.data.pontosUsuario,
                        idGraduacao: item.idGraduacao,
                        diretos: item.quantidadeDiretos,
                        requisitos: item.graduacaoRequisitos,
                        _rowVariant: item.idGraduacao == success.data.idGraduacaoLogado ? 'row-active-custom' : ''
                    });
                });
            }).finally(() => this.$loading(false));
        },
        abrirModalDetalhes(nome, requisitos){
            this.itemSelecionado.nomeGraduacao = nome;
            this.itemSelecionado.requisitos = requisitos;

            this.$bvModal.show('modalRequisitos');
        }
    }
};