<template>
  <!-- <div class="not-found">
    <div class="div-error">
      <h5>Ops!</h5>
      <p>Página não encontrada ou não existente!</p>
    </div>
  </div>-->
  <b-container class="py-5">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12" class="text-center">
            <h2 class="font-weight-bold">Ops!</h2>
            <p class="mb-0">Página não encontrada ou não existente!</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "notfound",
  data() {
    return {};
  },
  mounted() {
    this.$loading(false);
  }
};
</script>