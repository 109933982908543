import "./HeaderBar.scss";
import http from "@/_helper/api-services.js";
import Banner from "@/components/Banner/banner.vue";
import Drawer from "vue-simple-drawer";
import appMenu from "@/components/Menu/appMenu.vue";
import BannerMobile from "@/components/BannerMobile/BannerMobile.vue";
import formCadastro from "@/components/FormCadastro/formCadastro.vue";
import formLogin from "@/components/FormLogin/formLogin.vue";
import formRecuperarSenha from "@/components/FormRecuperarSenha/formRecuperarSenha.vue";
import formAlterarSenha from "@/components/FormAlterarSenha/formAlterarSenha.vue";
import FormCredenciamento from "@/components/FormCredenciamento/FormCredenciamento.vue";
import formSuporte from "@/components/formSuporte/formSuporte.vue";
import Carrossel from "@/components/Carrossel/Carrossel.vue";
import MenuMobile from "@/components/MenuMobile/MenuMobile.vue";
import MenuMobileAdmin from "@/components/MenuMobileAdmin/MenuMobileAdmin.vue";
import boxSelecionarTipoCadastro from "@/components/BoxSelecionarTipoCadastro/boxSelecionarTipoCadastro.vue";
import Environment from "@/components/Environment/Index"
import assinatura from "@/views/Assinatura/assinatura";

export default {
    name: "HeaderBar",
    data() {
        return {
            showInstallButton: false,
            showInstallButtonIOS: false,
            isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
            modalOpenIos: false,
            open: false,
            align: "left",
            innerOpen: false,
            mask: false,
            maskClosable: false,
            close: true,
            dadosCarrossel: {
                anunciantes: Array(),
                corFundo: String(),
                ativo: false,
            },
            dadosSegundoCarrossel: {
                anunciantes: Array(),
                corFundo: String(),
                ativo: false,
            },
            dadosUser: Object(),
            modalAction: String(),
            modalOpen: false,
            notifications: Array(),
            announcements: Array(),
            showingNotifications: false,
            showingAnnouncements: false,
            notificationOpened: Object(),
            showAllNotificaton: false,
            totalGanhos: 0,
            tokenAlterarSenha: String(),
            loginPatrocinador: String(),
            showBanner: true,
            dontShowBanner: [
                "/recusa-privacidade",
                "/privacidade",
                "/quem-somos",
                "/como-funciona",
                "/empresa",
                "/faq",
                "/mais-vendas",
                "/finalizar-credenciamento",
                "/anunciante",
                "/Anunciante",
                "/app",
                "/material-apoio",
            ],
            shareWhatsAppMessage: ''                
        };
    },
    props: ["loggedIn", "adminPages", "emAcessoRemoto"],
    watch: {
        adminPages() {
            if (this.$props.adminPages) {
                this.getNotifications();
                if (localStorage.getItem("showComunicado") == "true")
                    this.getAnnouncements();
            } else if (this.$props.loggedIn) {
                this.getSaldo();
            }
        },
        $route(to, from) {
            if (
                !this.dadosUser.termosDeAceite &&
                window.location.href.includes("painel") &&
                !this.checkarCookie()
            ) {
                this.$bvModal.show("modal-notifications");
            }

            if (this.$props.adminPages) this.getNotifications();
            if (
                this.dontShowBanner.filter((b) => to.path.indexOf(b) != -1).length > 0
            )
                this.showBanner = false;
            else this.showBanner = true;
        },
        loggedIn() {
            if (this.$props.loggedIn) {
                let retorno = this.$store.getters["autenticacao/getLogin"].user;
                if (retorno.data && retorno.status) this.dadosUser = retorno.data;
                else this.dadosUser = retorno;
            }
        },
    },
    components: {
        Banner,
        Drawer,
        appMenu,
        BannerMobile,
        formCadastro,
        formLogin,
        formRecuperarSenha,
        formAlterarSenha,
        FormCredenciamento,
        formSuporte,
        Carrossel,
        MenuMobile,
        MenuMobileAdmin,
        boxSelecionarTipoCadastro,
        Environment,
    },
    ready: function () {
        window.beforeunload = function () {
            if (!this.dadosUser.termosDeAceite && !checkarCookie()) {
                this.$bvModal.show("modal-notifications");
            }
        };
    },
    computed: {
        notificationsNotRead() {
            return this.notifications.filter((n) => !n.lidaFront);
        },
        notificationsFiltered() {
            return this.notifications.filter(
                (n) =>
                    (n.lidaFront && this.showAllNotificaton) ||
                    (!n.lidaFront && !this.showAllNotificaton)
            );
        },
        googleLoginUri() {
            return (
                "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=" +
                encodeURIComponent(location.origin + "/login-social/google") +
                "&prompt=consent&response_type=code&client_id=1078310793621-450mdiqeov6fp2rj66smesc3qmio1c6p.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline"
            );
        },
    },
    async beforeMount() {
        await this.getCarrossel();
        this.$root.$on("switchAction", this.switchAction);
    },
    beforeMount() {
        if (this.$route.params && this.$route.params.token) {
            this.modalOpen = true;
            this.modalAction = "resetPassword";
            this.tokenAlterarSenha = this.$route.params.token;
        } else if (this.$route.params && this.$route.params.loginPatrocinador) {
            this.modalOpen = true;
            this.modalAction = "register";
            this.loginPatrocinador = this.$route.params.loginPatrocinador;
        }
        if (this.dontShowBanner.filter((b) => this.$route.path.indexOf(b) != -1).length > 0)
            this.showBanner = false;


        if (this.loggedIn)
            this.getSaldo();
    },
    mounted() {
        if (this.loggedIn) {
            this.dadosUser = this.$store.getters["autenticacao/getLogin"].user;

            if (
                !this.dadosUser.termosDeAceite &&
                window.location.href.includes("painel") &&
                !this.checkarCookie()
            ) {
                this.$bvModal.show("modal-notifications");
            }

            if (this.$props.adminPages) this.getNotifications();

            this.shareWhatsAppMessage = `Quanta Shop é uma plataforma digital que te ajuda a economizar e gerar renda através do consumo inteligente e fidelização. Você compra nas lojas credenciadas e recebe parte do valor gasto de volta, o chamado cashback.
                              
Quer saber mais? Clique nesse link que meu assistente virtual vai tirar todas as suas dúvidas.
https://consumidor.quantashop.com.br/?par=${this.dadosUser.login}`
        };

        // Verifica se o aplicativo já está instalado no iOS
        if (this.isIOS && window.navigator.standalone) {
            this.showInstallButtonIOS = false;
        } else if (this.isIOS) {
            this.showInstallButtonIOS = true;
        } else if (window.navigator.standalone) {
            this.showInstallButton = false;
        } else {
            // Adicione o evento antes do prompt de instalação (apenas para navegadores que suportam)
            window.addEventListener('beforeinstallprompt', this.handleInstallPrompt);
        }
    },

    beforeDestroy() {
        window.removeEventListener('beforeinstallprompt', this.handleInstallPrompt);
    },

    methods: {
        copiarLinkAssistenteVirtual(link) {
            navigator.clipboard.writeText(link).then(() => {
                this.$snotify.success("Link copiado com sucesso.");
            }).catch(err => {
                this.$snotify.error("Ocorreu um erro inesperado.");
            });
        },
        redirectToSubscription() {
            this.$bvModal.hide('modal-assistente-virtual');
            this.$router.push('/painel/assinatura');
        },
        share() {
            if (navigator.share) {
                navigator.share({
                  title: "Assistente virtual do Quanta Shop",
                  text: this.shareWhatsAppMessage,
                  //url: `https://consumidor.quantashop.com.br/?par=${this.dadosUser.login}`,
                })
                .then(() => {
                  // handle success
                  //console.log("Shared content successfully.")
                })
                .catch((error) => {
                  // Error handling
                  //console.log("Error while sharing.", error)
                });
              } 
              // Handle fallback for non-supported browsers
              else {
                // you can have you own implementation here
                alert("Yout browser is not supporting WebShare API.");
              }
        },
        shareOnWhatsApp() {
            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(this.shareWhatsAppMessage)}`;
            window.open(whatsappUrl, '_blank');
        },
        shareOnInstagram() {
            const instagramUrl = `https://www.instagram.com/?url=${encodeURIComponent('https://quantashop.com.br/register/IDQuanta')}`;
            window.open(instagramUrl, '_blank');
        },
        shareOnMessenger() {
            const message = encodeURIComponent(this.shareMessage);
            const messengerUrl = `fb-messenger://share?link=${message}`;
            window.open(messengerUrl, '_blank');
        },
        shareOnTikTok() {
            const tiktokUrl = `https://www.tiktok.com/share?url=${encodeURIComponent('https://quantashop.com.br/register/IDQuanta')}`;
            window.open(tiktokUrl, '_blank');
        },
        handleInstallPrompt(event) {
            event.preventDefault();
            this.showInstallButton = true;
            this.installPromptEvent = event;
        },

        showInstallInstructions() {
            if (this.isIOS) {
                this.modalOpenIos = true;
            }
        },
        installApp() {
            if (this.installPromptEvent) {
                this.installPromptEvent.prompt();
                this.installPromptEvent.userChoice.then((choiceResult) => {
                    this.showInstallButton = false;
                    this.installPromptEvent = null;
                });
            }
        },

        toggle() {
            this.open = !this.open;
            if (!this.menus) {
                var menu = localStorage.getItem("menu");
                this.menus = JSON.parse(menu).filter((u) => !u.rotaPublica);
                this.dadosUser = this.$store.getters["autenticacao/getLogin"].user;
                this.obterResumoValores();
            }
        },
        closeDrop() {
            this.open = false;
        },
        getSaldoFormatado() {
            return this.totalGanhos.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        switchAction(action) {
            this.modalOpen = true;
            this.modalAction = action;
        },
        getNotifications() {
            http.get("/comunicado/ObterNotificacoesNaoLidas").then(
                (success) => {
                    if (success.data) {
                        success.data.forEach((n) => (n.lidaFront = n.lida));
                        this.notifications = success.data;
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        getAnnouncements() {
            http.get("/comunicado/ObterComunicadosPorGraduacao").then(
                (success) => {
                    if (success.data) this.announcements = success.data;
                    if (this.announcements.length > 0) {
                        this.announcements[0].visible = true;
                        this.notificationOpened = this.announcements[0];
                        this.showingAnnouncements = true;
                        this.$bvModal.show("modal-notifications");
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        formatDate(date, showTime) {
            if (!showTime) return new Date(date).toLocaleDateString();
            else
                return `${new Date(date).toLocaleDateString()} - às ${new Date(
                    date
                ).toLocaleTimeString()}`;
        },
        changeNotification(index) {
            if (this.showingAnnouncements) {
                this.announcements.forEach((a) => (a.visible = false));
                if (this.notificationOpened == this.announcements[index])
                    this.notificationOpened = null;
                else {
                    this.announcements[index].visible = true;
                    this.notificationOpened = this.announcements[index];
                }
            } else {
                this.notifications.forEach((a) => (a.visible = false));
                if (this.notificationOpened == this.notificationsFiltered[index])
                    this.notificationOpened = null;
                else {
                    this.notificationsFiltered[index].visible = true;
                    this.notificationOpened = this.notificationsFiltered[index];
                }
                if (!this.notificationsFiltered[index].lida)
                    this.markNotificationAsRead(this.notifications[index].idMensagem);
            }
        },
        openModalNotification(index) {
            this.$root.$emit("bv::hide::popover");
            this.showAllNotificaton = false;
            this.showingAnnouncements = false;
            this.showingNotifications = true;
            this.notificationOpened = this.notificationsNotRead[index];
            this.$bvModal.show("modal-notifications");
            if (!this.notificationsNotRead[index].lida)
                this.markNotificationAsRead(this.notificationOpened.idMensagem);
        },
        handleModalClose() {
            if (this.showingAnnouncements) localStorage.removeItem("showComunicado");
            else
                this.notifications.forEach((notification) => {
                    if (notification.lida) notification.lidaFront = true;
                });
        },
        markNotificationAsRead(idMensagem) {
            http
                .update("/comunicado/MarcarNotificacaoComoLida", {
                    idMensagem,
                })
                .then((success) => {
                    this.notifications.forEach((notification) => {
                        if (notification.idMensagem == idMensagem) notification.lida = true;
                    });
                });
        },
        openNotificationsWithAll() {
            this.$root.$emit("bv::hide::popover");
            this.showAllNotificaton = true;
            this.notificationOpened = null;
            this.$bvModal.show("modal-notifications");
        },
        async getSaldo() {
            try {
                this.$loading(true);
                const user = localStorage.getItem("user");
                let success = null;
                if (user) {
                    var result = await http.get("/user/getSaldo");
                    this.totalGanhos = result.data.saldo;
                }
            } catch (error) {
                for (const erro of http.mensagensErro(error)) {
                    this.$snotify.error(erro);
                }
            } finally {
                this.$loading(false);
            }
        },
        logoutSessaoRemota() {
            localStorage.setItem("user", localStorage.getItem("userAdmin"));
            localStorage.removeItem("userAdmin");
            localStorage.removeItem("menu");
            this.$router.push("/painel/admin/usuarios");
            window.location.reload();
        },
        alterarPerfil(perfil) {
            if (perfil != this.dadosUser.perfil) {
                this.$loading(true);
                http
                    .get(`/user/alterarPerfil/${perfil}`)
                    .then(
                        (success) => {
                            this.dadosUser.perfil = perfil;
                            this.$root.$emit("alterar-menu", perfil);
                            this.$root.$emit("alterar-perfil", perfil);

                            if (perfil == "C") this.$router.push("/painel/comerciante");
                            else this.$router.push("/painel");
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        registrarCookie() {
            $cookies.set("policy-accepted", true);
            http.post(`/user/aceitarcookies/`).then((success) => { });
            this.$bvModal.hide("modal-notifications");
            window.location.reload();
        },
        recusarCookie() {
            $cookies.set("policy-accepted", false);
            this.$bvModal.hide("modal-notifications");
            window.location.href = "/recusa-privacidade";
            whindow.scrollTo(0, 0);
        },
        checkarCookie() {
            return "true" == $cookies.get("policy-accepted");
        },
        fechaModal() {
            $cookies.set("policy-accepted", false);
            this.$bvModal.hide("modal-notifications");
            window.open("/QB-LGPD.pdf", "_blank");
        },
        async getCarrossel() {
            await http.get(`/carrossel/obterCarrossel/1`).then(
                (success) => {
                    this.dadosCarrossel.anunciantes = success.data.anunciantes;
                    this.dadosCarrossel.corFundo = success.data.corFundo;
                    this.dadosCarrossel.ativo = success.data.ativo;
                    this.dadosCarrossel.nome = success.data.nome;
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );

            await http.get(`/carrossel/obterCarrossel/3`).then(
                (success) => {
                    this.dadosSegundoCarrossel.anunciantes = success.data.anunciantes;
                    this.dadosSegundoCarrossel.corFundo = success.data.corFundo;
                    this.dadosSegundoCarrossel.ativo = success.data.ativo;
                    this.dadosSegundoCarrossel.nome = success.data.nome;
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
    },
};
