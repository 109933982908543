import "./TutoriaisUsuario.scss";
import http from "@/_helper/api-services.js";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";

export default {
    name: "Tutoriais Usuario",
    data() {
        return {
            tutoriais: {
                items: [],
            }
        }

    },
    components: {
        BarraIndicacao
    },
    beforeMount() {
        this.obterTutoriais();
    },
    methods: {
        obterTutoriais() {
            this.$loading(true);

            http
                .get("/tutorial/ObterTutoriais")
                .then(
                    (success) => {
                        if (success.data) {
                            this.tutoriais.items = success.data;
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
    }
}