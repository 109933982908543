import "./portal.scss";
import http from "@/_helper/api-services";
import Carrossel from "@/components/Carrossel/Carrossel.vue";
import BoxLoja from "@/components/BoxLoja/BoxLoja.vue";
import BoxFiltroPortal from "@/components/BoxFiltroPortal/BoxFiltroPortal.vue";

export default {
  name: "portal",
  components: {
    Carrossel,
    BoxLoja,
    BoxFiltroPortal,
  },
  data() {
    return {
      policy: false,
      priceFilter: [0, 0],
      listaAnunciantes: Array(),
      categorias: Array(),
      filtro: {
        descricao: String(),
        quantidade: 50,
        page: 1,
        totalPaginas: 10,
        tipoAnunciante: Array(),
      },
      filtroCategoria: {
        nome: String(),
      },
      tiposFiltro: [
        {
          tipo: 0,
          ativo: false,
        },
        {
          tipo: 1,
          ativo: false,
        },
        {
          tipo: 3,
          ativo: true,
        },
      ],
    };
  },
  watch: {
    "filtro.descricao": function (element) {
      if (!element) {
        this.obterAnuncios();
      }
    },
    "filtroCategoria.nome": function (element) {
      if (!element) {
        this.obterCategorias();
      }
    },
    policy: function (value) { },
  },
  computed: {
    totalPagesToDisplay() {
      if (this.filtro.totalPaginas <= 5) {
        this.showIconsToChangePage = false;
        return this.filtro.totalPaginas;
      } else {
        this.showIconsToChangePage = true;

        let paginasSobrando =
          this.filtro.totalPaginas - this.filtro.page;

        if (paginasSobrando == 0) {
          let retorno = [];
          for (
            let i = this.filtro.totalPaginas - 2;
            i <= this.filtro.totalPaginas;
            i++
          )
            retorno.push(i);
          return retorno;
        } else if (paginasSobrando == this.filtro.totalPaginas - 1)
          return [1, 2, 3];
        else
          return [
            this.filtro.page - 1,
            this.filtro.page,
            this.filtro.page + 1,
          ];
      }
    },
  },
  mounted() {
    this.obterAnuncios();
    this.obterCategorias();
  },
  methods: {
    priceFormatter(value) {
      return `R$ ${value.toFixed(2)}`;
    },
    async obterAnuncios() {
      this.$loading(true);
      await http.post("/Anunciante/obterAnuncios/", this.filtro).then(
        (response) => {
          this.listaAnunciantes = [];

          response.data.anunciantes.forEach((item) => {
            item.banner = false;
            this.listaAnunciantes.push(item);
          });

          this.filtro.totalPaginas = response.data.totalPaginas;
          this.$loading(false);
        }
      );
      this.$loading(false);
    },
    buscarAnunciantes() {
      this.filtroCategoria.nome = "";
      this.filtro.page = 1;
      this.filtro.tipoFiltro = [];
      this.filtro.idCategoria = null;
      this.obterAnuncios();
      //window.scrollTo(0, 550);
    },
    async filtrarPorTipo(tipoFiltro) {
      this.filtro.page = 1;
      this.tiposFiltro.forEach(item => {
        item.ativo = item.tipo === tipoFiltro;
      });

      this.filtro.tipoAnunciante = [];

      if (tipoFiltro !== 3) {
        this.filtro.tipoAnunciante.push(tipoFiltro);
        await this.obterAnuncios();
      } else {
        this.filtro.idCategoria = null;
        await this.obterAnuncios();
      }
    },

    calculaMargemCashback(anunciante) {
      var retorno = "Dinheiro de volta";

      if (
        anunciante.tipoCashback &&
        anunciante.tipoCashback == "percentage"
      ) {
        if (anunciante.cashbackMin == anunciante.cashbackMax) {
          retorno = anunciante.cashbackMin + "% de cashback";
        } else if (anunciante.cashbackMin < anunciante.cashbackMax) {
          if (anunciante.cashbackMin == 0) {
            retorno =
              "Até " + anunciante.cashbackMax + "% de cashback";
          } else {
            retorno =
              "De " +
              anunciante.cashbackMin +
              "% a " +
              anunciante.cashbackMax +
              "% de cashback";
          }
        }
      } else if (
        anunciante.tipoCashback &&
        anunciante.tipoCashback == "amount"
      ) {
        if (anunciante.cashbackMin == anunciante.cashbackMax) {
          retorno = "R$ " + anunciante.cashbackMin + " de volta";
        } else if (anunciante.cashbackMin < anunciante.cashbackMax) {
          if (anunciante.cashbackMin == 0) {
            retorno =
              "Até R$" + anunciante.cashbackMax + "de volta";
          } else {
            retorno =
              "De R$ " +
              anunciante.cashbackMin +
              " a R$ " +
              anunciante.cashbackMax +
              " de volta";
          }
        }
      }

      return retorno;
    },

    mudarPagina(index) {
      this.filtro.page = index;
      this.obterAnuncios();
      window.scrollTo(0, 550);
    },

    obterCategorias() {
      this.filtro.descricao = "";
      this.$loading(true);
      http.post("/Anunciante/obterCategorias/", this.filtroCategoria)
        .then(
          (response) => {
            this.categorias = response.data;
          },
          (error) => {
            for (const erro of http.mensagensErro(error)) {
              this.$snotify.error(erro);
            }
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    buscarPorCategoria(id) {
      this.filtro.descricao = "";
      this.filtroCategoria.nome = "";
      this.filtro.page = 1;
      if (this.filtro.idCategoria == id) {
        this.filtro.idCategoria = null;
        this.obterAnuncios();
      } else {
        this.filtro.idCategoria = id;
        this.obterAnuncios();
      }
    },

    GetUrlProgram(anuncianteId) {
      this.$loading(true);
      http.get("/Anunciante/gerarUrl/" + anuncianteId)
        .then(
          (response) => {
            window.open(response.data);
          },
          (error) => {
            if (error.response.status === 401)
              this.$snotify.error("Faça o login para acessar!");
            else {
              for (const erro of http.mensagensErro(error)) {
                this.$snotify.error(erro);
              }
            }

            window.scrollTo(0, 0);
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },

    validaImagem(imagem) {
      return imagem.includes("bigcash");
    },
  },
};