import http from "@/_helper/api-services.js";

export default {
    name: "lojasFisicas",
    data() {
        return {
            lojas: [],
            lojaInfo: {},
        };
    },
    async beforeMount() {
        this.$loading(true);
        await this.getCredenciamentos();
        this.$loading(false);
    },
    mounted() {
        // this.$loading(false);
    },
    methods: {
        async getCredenciamentos() {
            await http.get("/credenciamento/getCredenciamentos").then(
                (success) => {
                    this.lojas = success.data;
                },
                (error) => {
                    for (const erro of http.mensagensErro(
                        error,
                        "Ocorreu um erro ao buscar as lojas físicas"
                    )) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        openModalInfo(loja) {
            this.lojaInfo = loja;
            this.$bvModal.show("modalInfo");
            setTimeout(() => {
                this.loadMap();
            }, 500);
        },
        loadMap() {
            let map = new google.maps.Map(document.getElementById("mapLoja"), {
                center: {
                    lat: parseFloat(this.lojaInfo.latitude),
                    lng: parseFloat(this.lojaInfo.longitude),
                },
                zoom: 15,
                disableDefaultUI: true,
            });

            new google.maps.Marker({
                position: {
                    lat: parseFloat(this.lojaInfo.latitude),
                    lng: parseFloat(this.lojaInfo.longitude),
                },
                animation: google.maps.Animation.DROP,
                map: map,
            });
        },
    },
};
