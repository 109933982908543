import "./alterarDadosUsuario.scss";
import http from "@/_helper/api-services.js";
export default {
    name: "alterarDadosUsuario",
    data() {
        return {
            form: {
                idUsuario: String(),
                login: String(),
                email: String(),
                senha: String(),
                senhaConfirma: String(),
                loginPatrocinador: String(),
                celular: String(),
                nome: String(),
            },
        };
    },
    beforeMount() {
        this.form.idUsuario = this.$route.params.idUsuario;
        this.getUserData();
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        goBack() {
            return this.$router.go(-1);
        },
        getUserData() {
            http.get(`/admin/dadosUsuario/${this.form.idUsuario}`).then(
                (success) => {
                    if (success && success.data) {
                        this.form.login = success.data.login;
                        this.form.email = success.data.email;
                        this.form.loginPatrocinador =
                            success.data.loginPatrocinador;
                        this.form.celular = success.data.celular;
                        this.form.nome = success.data.nome;
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(
                        error,
                        "Erro ao encontrar o usuario"
                    )) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        handleSubmit(event) {
            this.$loading(true);
            event.preventDefault();

            http.post("/Admin/editarDadosUsuario", this.form)
                .then(
                    (success) => {
                        if (success && success.data)
                            this.$snotify.success(success.data.message);
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(
                            error,
                            "Erro ao encontrar o usuario"
                        )) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        handleReset() {
            this.form.login = String();
            this.form.email = String();
            this.form.senha = String();
            this.form.senhaConfirma = String();
            this.form.loginPatrocinador = String();
            this.form.celular = String();
            this.form.nome = String();
            this.getUserData();
        },
    },
};
