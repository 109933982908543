import http from "@/_helper/api-services.js";

export default {
    name: "FormParceiro",
    props: {
        idParceiro: { type: Number, default: 0 },
    },
    data() {
        return {
            parceiro: {
                nome: String(),
                celular: String(),
                descricao: String(),
                dataCriacao: null,
                dataAtualizacao: null,
                idParceiro: null,
            },
            dataAtualizacao: null,
            dataCriacao: null,
        };
    },
    async beforeMount() {
        if (this.$props.idParceiro > 0) {
            await this.obterDadosParceiro();
        }
    },
    methods: {
        async obterDadosParceiro() {
            this.$loading(true);

            await http
                .get("/parceiros/obterDadosParceiro/" + this.$props.idParceiro)
                .then(
                    (success) => {
                        if (success.data) {
                            this.parceiro.idParceiro = success.data.idParceiro;
                            this.parceiro.nome = success.data.nome;
                            this.parceiro.celular = success.data.celular;
                            this.parceiro.ativo = success.data.ativo;
                            this.parceiro.descricao = success.data.descricao;
                            this.parceiro.dataCriacao =
                                success.data.dataCriacao;
                            this.parceiro.dataAtualizacao =
                                success.data.dataAtualizacao;

                            this.dataCriacao = this.formatDate(
                                success.data.dataCriacao
                            );
                            this.dataAtualizacao = this.formatDate(
                                success.data.dataAtualizacao
                            );
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async handleSubmit() {
            this.$loading(true);

            this.parceiro.celular = this.removeEspecialCharacter(
                this.parceiro.celular
            );
            if (this.$props.idParceiro > 0) {
                // Update
                await http
                    .post("/parceiros/atualizarParceiro/", this.parceiro)
                    .then(
                        (success) => {
                            this.$snotify.success(
                                "Parceiro atualizado com sucesso!"
                            );
                            window.location.href = "/painel/parceiros";
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            } else {
                // Create

                this.parceiro.idParceiro = 0;
                this.parceiro.dataCriacao = new Date();
                this.parceiro.dataAtualizacao = new Date();
                await http
                    .post("/parceiros/criarParceiro/", this.parceiro)
                    .then(
                        (success) => {
                            this.$snotify.success(
                                "Parceiro criado com sucesso!"
                            );
                            window.location.href = "/painel/parceiros";
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        removeEspecialCharacter(texto) {
            return texto.replace(
                /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                ""
            );
        },
        voltarTela() {
            window.location.href = "/painel/parceiros";
        },
    },
};
