import http from "@/_helper/api-services";
import usuarioService from "@/_helper/usuario-service.js";

export default {
    name: "FormGoogle",
    components: {},
    props: {
        code: { type: String, default: 0 },
    },
    data() {
        return {
            code: String(),
            redirectUri: `${location.origin}/cadastro-social/google`,
        };
    },
    watch: {
        $route() {
            this.recarrega();
        },
    },
    async beforeMount() {
        this.$loading(true);
        await this.cadastroGoogle();
    },
    methods: {
        async cadastroGoogle(){
            this.$loading(true);
            var form = this.$cookies.get("Cadastro");
            form.code = this.$route.query.code;
            form.redirectUri = this.redirectUri;
            usuarioService
                .googleRegister(form)
                .then(
                    (success) => {
                        this.$loading(false);
                        // this.registrationSuccessful = true;
                        $cookies.remove("Cadastro");
                        this.$router.push("/");
                        // window.location.reload();
                        this.$snotify.success("Cadastro realizado com sucesso, realize o seu primeiro login.");
                    },
                    (error) => {
                        this.$loading(false);
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                        $cookies.remove("Cadastro");
                        this.$router.push("/");
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
    },
};
