import "./BoxFiltroPortal.scss";
import http from '@/_helper/api-services';

export default {
    name: "BoxFiltroPortal",
    props: {
        tipo: { type: Number, default: null, },
        ativo: {type: Boolean, default: true,}
    },
	data() {
		return {};
	},
	components: {
    },
    beforeMount(){
    },
    methods:{
        imagemTipoLoja()
        {
            if(this.tipo == 0)
            {
                return require("@/assets/imgs/loja-online.png")
            }
            else if(this.tipo == 1)
            {
                return require("@/assets/imgs/loja-fisica.png")
            }
            else if(this.tipo == 2)
            {
                return require("@/assets/imgs/loja-app.png")
            }
            else if(this.tipo == 3)
            {
                return require("@/assets/imgs/loja-promocao.png")
            }

            return require("@/assets/imgs/loja-todas.png")
        },
        textoTipoLoja()
        {
            if(this.tipo != null)
            {
                if(this.tipo == 0)
                {
                    return "Lojas online"
                }
                else if(this.tipo == 1)
                {
                    return "Lojas físicas"
                }
                else if(this.tipo == 2)
                {
                    return "Lojas no app"
                }
                else if(this.tipo == 3)
                {
                    return "Promoções"
                }
            }
            else
            {
                return "Todas as lojas"
            }
        },
        filtrar()
        {
            if(this.tipo != 3)
            {
                if(this.ativo == true){ this.ativo = false; }
                else {this.ativo = true;}

                this.$emit('click')
            }
        }
    }
}