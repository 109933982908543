import http from "@/_helper/api-services.js";

export default {
    name: "tabelaRank",
    data() {
        return {
            fields: [
                {
                    key: "login",
                    label: "Login",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "graduacao",
                    label: "Graduação",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "pontuacao",
                    label: "Pontuação",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "consumo",
                    label: "Consumo",
                    sortable: true,
                    class: "text-center",
                    formatter: (value) => {
                        return value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        });
                    },
                },
            ],
            listDados: [],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filterOn: [],
        };
    },
    props: ["cols", "sm", "md", "lg", "xl"],
    mounted() {
        this.listarDados();
    },
    methods: {
        listarDados() {
            http.get("/dashboard/obterRankUsuario").then(
                (response) => {
                    this.listDados = response.data;

                    let qtd = 8 - this.listDados.length;

                    if (qtd > 0) {
                        for (let i = 0; i < qtd; i++) {
                            this.listDados.push({
                                login: "------",
                                graduacao: "------",
                                pontuacao: "------",
                                consumo: 0,
                            });
                        }
                    }
                },
                () => {
                    this.$snotify.error("Erro ao obter dados.");
                }
            );
        },
    },
};
