import VueMultiselectComponent from 'vue-multiselect-component'
import http from "@/_helper/api-services.js";
import "./Lancamentos.scss";

export default {
    name: "lancamentosAdmin",
    props: {
    },
    components: {
        VueMultiselectComponent
    },
    filters: {
        formatDate(value) {
            if (!value) return '';
            return new Date(value).toLocaleDateString('pt-BR');
        },
        formatCurrency(value) {
            if (!value) return 'R$ 0,00';
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        }
    },
    computed: {
        totalValor() {
            return this.lancamentos.reduce((total, lancamento) => total + lancamento.Valor, 0);
        }
    },
    data() {
        return {
            loading: false,
            loadingReleases: true,
            params: {
                IdTransacao: null,
                Login: null,
                Nome: null,
                Estabelecimento: null,
                StartDate: '2024-01-01',
                EndDate: '2024-12-31',
                Tipos: ['57', '33', '52', '51'],
                Status: ['7', '1', '2', '3'],
                PageNumber: 1,
                PageSize: 10
            },
            pageSizeOptions: [10, 25, 50, 100],
            totalPages: 0,
            totalCount: 0,
            transacoes: {
                items: [],
            },
            lancamentos: [],
            typesOptions: [
                // { id: '-1', label: 'Todos' },
                { id: '57', label: 'Assinatura' },
                { id: '33', label: 'Cashback' },
                { id: '52', label: 'Cashback loja física' },
                { id: '51', label: 'Distribuição compra em loja física' }
            ],
            statusOptions: [
                // { id: '-1', label: 'Todos' },
                { id: '7', label: 'Aguardando pagamento de fatura' },
                { id: '3', label: 'Cancelado' },
                { id: '1', label: 'Em processamento' },
                { id: '2', label: 'Finalizado' },
            ]
        };
    },
    beforeDestroy() {
        this.destroyMutationObservers();
    },
    async mounted() {
        this.setInitialDateRange();
        await this.getTransactions();

        this.$nextTick(() => {
            this.setupMutationObservers();
        });
    },
    watch: {
        'transacoes.items': function (newVal, oldVal) {
            this.$nextTick(() => {
                this.destroyMutationObservers();
                this.setupMutationObservers();
            });
        }
    },
    methods: {
        clearFilters() {
            this.params = {
                IdTransacao: null,
                Login: null,
                Nome: null,
                Estabelecimento: null,
                StartDate: '2024-01-01',
                EndDate: '2024-12-31',
                Tipos: ['57', '33', '52', '51'],
                Status: ['7', '1', '2'],
                PageNumber: 1,
                PageSize: 25
            };

            this.setInitialDateRange()
        },
        setInitialDateRange() {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            const formattedStartDate = this.formatDateYYYYMMDD(firstDayOfMonth);
            const formattedEndDate = this.formatDateYYYYMMDD(lastDayOfMonth);

            this.params.StartDate = firstDayOfMonth;
            this.params.EndDate = lastDayOfMonth;
        },
        formatDateYYYYMMDD(date) {
            // Formata a data para YYYY-MM-DD
            return date.toISOString().split('T')[0];
        },
        setupMutationObservers() {
            const accordions = this.$el.querySelectorAll('.collapse');
            accordions.forEach((accordion) => {
                const observer = new MutationObserver(this.onMutation);
                observer.observe(accordion, {
                    attributes: true,
                    attributeFilter: ['class'],
                });
                accordion.__vueMutationObserver = observer;
            });
        },
        destroyMutationObservers() {
            const accordions = this.$el.querySelectorAll('.collapse');
            accordions.forEach((accordion) => {
                if (accordion.__vueMutationObserver) {
                    accordion.__vueMutationObserver.disconnect();
                    delete accordion.__vueMutationObserver;
                }
            });
        },
        onMutation(mutationsList) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    const target = mutation.target;

                    if (target.classList.contains('show') && !target.__hasShown) {
                        target.__hasShown = true;
                        this.onAccordionShown(target);
                    }
                }
            });
        },
        async onAccordionShown(target) {
            const transactionId = target.dataset.transactionId;
            // console.log('Accordion item opened:', target);
            // console.log('Transaction ID:', transactionId);

            await this.getReleases(transactionId);
        },
        async handlePageSizeChange() {
            await this.getTransactions();
        },
        scrollToTop() {
            if (this.$refs.transactionsList) {
                const element = this.$refs.transactionsList;
                const offset = -10; // Ajuste de 10 pixels
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset + offset;
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth'
                });
            }
        },
        async nextPage() {
            if (this.params.PageNumber < this.totalPages) {
                await this.fetchPageData(this.params.PageNumber + 1);
            }
        },
        async prevPage() {
            if (this.params.PageNumber > 1) {
                await this.fetchPageData(this.params.PageNumber - 1);
            }
        },
        setPage(pageNumber) {
            this.fetchPageData(pageNumber);
        },
        updatePagination(totalPages) {
            this.totalPages = totalPages;
        },
        async fetchPageData(pageNumber) {
            this.params.PageNumber = pageNumber;

            await this.getTransactions();

            this.$nextTick(() => {
                this.scrollToTop();
                this.destroyMutationObservers();
                this.setupMutationObservers();
            });
        },
        getBadgeClass(id) {
            switch (id) {
                case 1: // Em processamento
                    return 'badge-info';
                case 2: // Finalizado
                    return 'badge-secondary';
                case 3: // Cancelado
                    return 'badge-danger';
                case 4: // Em aprovação
                    return 'badge-light';
                case 5: // Recusado
                    return 'badge-dark';
                case 6: // Aprovado
                    return 'badge-primary';
                case 7: // Aguardando pagamento de fatura
                    return 'badge-warning';
                case 8: // Pago pelo Quanta Shop
                    return 'badge-success';
                default:
                    return 'badge-secondary';
            }
        },
        async getTransactions() {
            this.$loading(true);
            this.loading = true;

            let params = { ...this.params };

            if (Array.isArray(params.Tipos)) {
                if (params.Tipos.includes('-1') || params.Tipos.length === 0) {
                    params.Tipos = null;
                } else {
                    params.Tipos = params.Tipos.join(',');
                }
            }

            if (Array.isArray(params.Status)) {
                if (params.Status.includes('-1') || params.Status.length === 0) {
                    params.Status = null;
                } else {
                    params.Status = params.Status.join(',');
                }
            }

            await http
                .get("/v2/transactions/get-transactions", params)
                .then(
                    (success) => {
                        this.transacoes.items = success.data.data.transactions;
                        this.totalCount = success.data.data.totalCount;
                        this.updatePagination(parseInt(this.totalCount / this.params.PageSize) + 1)
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                    this.loading = false;

                    this.$nextTick(() => {
                        this.scrollToTop();
                        this.destroyMutationObservers();
                        this.setupMutationObservers();
                    });
                });
        },
        async getReleases(transactionId) {
            this.loadingReleases = true;

            await http
                .get("/v2/transactions/get-releases/" + transactionId)
                .then(
                    (success) => {
                        this.lancamentos = [];
                        this.lancamentos = success.data.data.sort((a, b) => a.ordemExibicao - b.ordemExibicao);
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.loadingReleases = false;
                });
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
                );
            }
        },
        formatCurrency(value) {
            if (!value) {
                return "";
            }

            const locale = "pt-BR";
            const currency = "BRL";

            const options = {
                style: "currency",
                currency: currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            };

            const formatter = new Intl.NumberFormat(locale, options);
            return formatter.format(value);
        },
        formatPercent(value) {
            if (!value) {
                return "";
            }

            const percent = value;
            const formattedPercent = percent.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

            return formattedPercent + "%";
        },
        getAnimationDelay(index) {
            const delay = index * 0.1;
            return {
                animationDelay: `${delay}s`
            };
        },
        padLeft(value, length) {
            return String(value).padStart(length, '0');
        },
    }
}