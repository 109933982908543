import "./solicitarSaque.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "solicitarSaque",
    data() {
        return {
            // withdrawalTypes: [{ text: "Tipo de saldo", value: null }],
            accountsToReceive: [{ text: "Selecione a conta", value: null }],
            accountsToReceiveBackup: [],
            accountSelected: null,
            form: {
                accountToReceive: null,
                withdrawalValue: null,
                // withdrawalType: null
            },
            activeButton: 'previous',
            progress: null,
            consumoMesAtual: 0,
            consumoMesAnterior: 0,
            consumoMesAtualFormatado: 0,
            consumoMesAnteriorFormatado: 0,
            configConsumo: 0,
            consumoProgress: 0,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                precision: 2,
                masked: false,
            },
        };
    },
    props: ["showHeader"],
    watch: {
        "form.accountToReceive"() {
            if (!this.$data.form.accountToReceive)
                this.$data.accountSelected = null;
            else
                this.$data.accountSelected = this.$data.accountsToReceiveBackup.filter(
                    (a) => a.idUsuarioBanco == this.$data.form.accountToReceive
                )[0];
        },
    },
    beforeMount() {
        // this.getWithdrawalTypes();
        this.getAccountsToReceive();
        this.getConsumo();
    },
    mounted() {
        this.setProgress(0);
    },
    methods: {
        goBack() {
            return this.$router.go(-1);
        },
        // getWithdrawalTypes() {
        //     http.get("/saque/listarTiposDeSaque").then(
        //         success => {
        //             if (success.data) {
        //                 let data = success.data;
        //                 Object.keys(data).forEach(nameIndex => {
        //                     this.withdrawalTypes.push({
        //                         text: nameIndex,
        //                         value: data[nameIndex]
        //                     });
        //                 });
        //             }
        //         },
        //         (error) => {
        //            for (const erro of http.mensagensErro(error)) {
        //                this.$snotify.error(erro);
        //            }
        //         }
        //     );
        // },
        getAccountsToReceive() {
            http.get("/UsuarioBanco/obterDadosBancarios").then(
                (success) => {
                    if (success.data) {
                        this.accountsToReceiveBackup = success.data;
                        this.accountsToReceiveBackup.forEach((d) => {
                            this.accountsToReceive.push({
                                text: d.nomeConta,
                                value: d.idUsuarioBanco,
                            });
                        });
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        handleSubmit(event) {
            event.preventDefault();
            this.$loading(true);
            http.post("/saque/solicitarSaque", {
                valor: parseFloat(this.form.withdrawalValue
                    .replace("R$", "")
                    .replace(".", "")
                    .replace(",", ".")
                ),
                idUsuarioBanco: this.form.accountToReceive,
            })
                .then(
                    (success) => {
                        this.$snotify.success(
                            "Pedido de saque realizado com sucesso."
                        );
                        this.$emit("saqueEmitido");
                        this.$root.$emit("atualizarSaldo");
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        handleReset() {
            this.form.accountToReceive = this.form.withdrawalValue = this.form.withdrawalType = null;
        },
        getConsumo() {
            http.get("/saque/obterConsumo").then(
                (success) => {
                    if (success.data) {
                        console.log(success.data);
                        this.consumoMesAnterior = success.data.consumoMesAnterior;
                        this.consumoMesAtual = success.data.consumoMesAtual;
                        this.consumoMesAnteriorFormatado = success.data.consumoMesAnteriorFormatado;
                        this.consumoMesAtualFormatado = success.data.consumoMesAtualFormatado;
                        this.configConsumo = success.data.configConsumo;
                        this.setProgress(this.consumoMesAnterior);
                        this.consumoProgress = this.consumoMesAnteriorFormatado
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        setActiveButton(button) {            
            this.activeButton = button;
            if(button == 'current'){
                this.setProgress(this.consumoMesAtual);
                this.consumoProgress = this.consumoMesAtualFormatado
            }
            else{
                this.setProgress(this.consumoMesAnterior);
                this.consumoProgress = this.consumoMesAnteriorFormatado
            }
        },
        setProgress(percent) {                        
            this.progress = percent;
        }
    },
};
