import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
// import "./FormPlanos.scss";

export default {
    name: "AdminTutorial",
    components: {
        tabelaBase,
    },
    props: {
        idTutorial: { type: String, default: "0" },
    },
    data() {
        return {
            historico: {
                items: [],
                title: "Histórico",
                fields: [
                    {
                        key: "dataAtualizacao",
                        label: "Data da atualização",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "texto",
                        label: "Atualização",
                        class: "text-center",
                    },
                    {
                        key: "nome",
                        label: "Usuário",
                        class: "text-center",
                    },
                ],
                sortBy: "dataAtualizacao",
                sortDesc: true,
                exportExcel: false,
                perPage: null,
            },
            status: [
                { value: true, text: "Ativo" },
                { value: false, text: "Inativo" },
            ],
            visibilidade: [
                { value: true, text: "Visível" },
                { value: false, text: "Invisível" },
            ],
            tutorial: {
                ativo: Boolean(),
                nome: String(),
                descricao: String(),
                url: String(),
            },
            dataCriacao: null,
            dataAtualizacao: null,
        };
    },
    computed: {},
    async beforeMount() {
        if (this.$props.idTutorial != 0 && this.$props.idTutorial != null) {
            await this.obterTutoriais();
        }
    },
    methods: {
        async obterTutoriais() {
            this.$loading(true);

            await http
                .get(`/tutorial/obterTutorial/${this.$props.idTutorial}`)
                .then(
                    (success) => {
                        if (success.data) {
                            this.tutorial = success.data.dadosTutorial;
                            this.dataCriacao = this.formatDate(
                                success.data.dadosTutorial.dataCadastro
                            );
                            this.dataAtualizacao = this.formatDate(
                                success.data.dadosTutorial.dataAtualizacao
                            );


                            this.historico.items = success.data.dadosHistorico;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async editarDadosPlano() {
            this.$loading(true);
            await http
                .post(`/tutorial/editarTutorial/`, this.tutorial)
                .then(
                    (success) => {
                        if (success) {
                            this.$snotify.success("Tutorial editado com sucesso");
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async criarTutorial() {
            this.$loading(true);
            var dados = this.tutorial;
            await http
                .post(`/tutorial/CriarTutorial/`, dados)
                .then(
                    (success) => {
                        if (success) {
                            this.$snotify.success("Tutorial criado com sucesso");
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },

        voltarTela() {
            window.location.href = "/painel/admin/config/tutoriais";
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
    },
};
