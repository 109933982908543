import "./appMenu.scss";
import apiService from "@/_helper/api-services.js";

export default {
  name: "appMenu",
  data() {
    return {
      menus: Array(),
      dadosUser: {},
      showMenuUser: false,
      menuOpen: false,
      saldo: 0,
      xablau: true,
    };
  },
  props: {
    searchMenu: Boolean(),
    idUsuario: null,
    smallerThanLg: Boolean(),
    emAcessoRemoto: Boolean(),
  },
  watch: {
    $route(to, from) {
      if (this.smallerThanLg) {
        this.menuOpen = false;
        this.showMenuUser = false;
        this.menus.forEach((menu) => {
          menu.show = false;
        });
      }
    },
    // "dadosUser.urlImg"()
    // {
    //     this.obterMenu();
    // }
  },
  beforeMount() {
    this.$root.$on("alterar-menu", this.obterMenu);

    let user = this.$store.getters["autenticacao/getLogin"].user;
    if (user && user.status && user.data) this.dadosUser = user.data;
    else this.dadosUser = user;

    this.getSaldo();
    this.$root.$on("atualizarSaldo", this.getSaldo);

    if (localStorage.getItem("menu")) {
      this.menus = JSON.parse(localStorage.getItem("menu")).filter(
        (u) => !u.rotaPublica
      );
    } else
      apiService.get(`/geral/obterMenu/${this.dadosUser.perfil}`).then(
        (success) => {
          if (success.data) {
            this.menus = success.data.filter((u) => !u.rotaPublica);
            localStorage.setItem("menu", JSON.stringify(success.data));
          } else
            this.$snotify.error("Ocorreu um erro inesperado ao obter o menu.");
        },
        (error) => {
          for (const erro of apiService.mensagensErro(
            error,
            "Ocorreu um erro inesperado ao obter o menu."
          )) {
            this.$snotify.error(erro);
          }
        }
      );
  },
  methods: {
    ativarMenu(index, subMenu) {
      let close = false;
      if (!subMenu) {
        if (this.menus[index].show) close = true;

        this.menus.forEach((menu) => {
          menu.show = false;
        });

        if (!close) this.menus[index].show = true;
      } else {
        if (
          this.menus[index].subMenus &&
          this.menus[index].subMenus[subMenu].show
        )
          close = true;

        this.menus[index].subMenus[subMenu].show = false;

        if (!close) {
          this.menus[index].show = true;
          this.menus[index].subMenus[subMenu].show = true;
        }
      }
    },
    closeDrop() {
      this.open = false;
    },
    
    async getSaldo() {
      this.$loading(true);
      await apiService
        .get("/user/getSaldo")
        .then(
          (success) => {
            this.saldo = success.data.saldo;
          },
          (error) => {
            for (const erro of apiService.mensagensErro(error)) {
              this.$snotify.error(erro);
            }
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    getSaldoFormatado() {
      return this.saldo.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    obterMenu(perfil) {
      this.$loading(true);

      apiService.get(`/geral/obterMenu/${perfil}`).then(
        (success) => {
          if (success.data) {
            this.menus = success.data.filter((u) => !u.rotaPublica);
            localStorage.setItem("menu", JSON.stringify(success.data));
          } else
            this.$snotify.error("Ocorreu um erro inesperado ao obter o menu.");

          this.$loading(false);
        },
        (error) => {
          for (const erro of apiService.mensagensErro(error)) {
            this.$snotify.error(
              erro,
              "Ocorreu um erro inesperado ao obter o menu."
            );
          }
        }
      );
    },
  },
};
