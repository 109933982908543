export default {
    name: "tabelaBase",
    props: {
        title: { type: String, default: "", },
        fields: Array,
        items: { type: Array, default: () => { return []; }, },
        exportExcel: { type: Boolean, default: true, },
        exportFields: Object,
        sortBy: String,
        sortDesc: { type: Boolean, default: false, },
        sortDirection: { type: String, default: "asc", },
        totalRows: Number,
        perPage: { type: Number, default: 5, },
        currentPage: { type: Number, default: 1 },
        pageOptions: { type: Array, default: () => { return [5, 10, 15, 30, 50]; }, },
        filter: String,
        filterIncludedFields: Array,
        exportTable: { type: Array, default: () => { return []; } },
        autoPaging: { type: Boolean, default: true },
    },
    data() {
        return {
            filteredItems: null,
            perPageData:5,
            currentPageData: 1,
        };
    },
    beforeMount() {
        this.perPageData = this.perPage;
        this.currentPageData = this.currentPage;
    },
    computed: {
        getTotalRows() {
            if (this.totalRows) {
                return this.totalRows;
            }
            else if (this.filteredItems) {
                return this.filteredItems.length;
            }
            else {
                return this.items.length;
            }
        },
        getExportfields() {
            if (this.exportExcel && !this.exportFields) {
                if (this.fields) {
                    let defaultExportFields = {};
                    for (let field in this.fields) {
                        if (field.formatter) {
                            this.defaultExportFields[field.label] = { field: field.key, callback: field.formatter };
                        }
                        else {
                            this.defaultExportFields[field.label] = field.key;
                        }
                    }
                    return defaultExportFields;
                }
            }
            else {
                return exportFields;
            }
        }
    },
    methods: {
        onFiltered(filteredItems) {
            this.filteredItems = filteredItems;
            this.currentPageData = 1;
        },
        fetchExportTable() {
            this.exportTable = [];
            for (let i in this.listDados) {
                this.exportTable.push(this.listDados[i]);
            }
        },
    },
};
