import http from "@/_helper/api-services";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import Ecossistemas from "./Ecossistemas.vue";

export default {
    name: "Ecossistemas",
    components: { tabelaBase },
    data() {
        return {
            ecossistemas: {
                items: [],
                title: "Ecossistemas",
                fields: [
                    {
                        key: "nome",
                        label: "Nome",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "regiao",
                        label: "Região",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "dataCadastro",
                        label: "Data de criação",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "idUsuarioGerente",
                        label: "Usuário Gerente",
                        sortable: true,
                        class: "text-center",                        
                    },
                    {
                        key: "ativo",
                        label: "Status",
                        sortable: true,
                        class: "text-center",
                        formatter: (val) => {
                            if (val) return "Ativo";
                            return "Inativo";
                        },
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                        sortable: false,
                    },
                ],
                sortBy: "nome",
                sortDesc: false,
                exportExcel: false,
                perPage: 10,
            },
            filtro: {                
                nome: String(),
                ativo: true,
            },
            exportFields: {
                Nome: "nome",
                "Nome Pai": "nomePai",
                "Data Criação": "dataCriacao",
                "Última Atualizaçãp": "dataAtualizacao",
                Status: {
                    field: "ativo",
                    callback: (value) => {
                        return value ? "Ativo" : "Inativo";
                    },
                },
            },
            exportTable: [],
            idEcossistemaExclusao: null,
        };
    },
    async beforeMount() {
        await this.buscarEcossistemas();
    },
    methods: {
        async buscarEcossistemas() {    
            await http
                .get(`/Ecossistema/obterEcossistemas`, this.filtro)
                .then((response) => {  
                    console.log(response);                  
                    this.ecossistemas.items = response.data;

                    console.log(this.ecossistemas);
                    this.exportTable = response.data;
                });
        },
        criarEcossistema() {
            window.location.href = "/painel/admin/ecossistemas/criar-ecossistema";
        },
        editarEcossistema(idEcossistema) {
            window.location.href =
                "/painel/admin/ecossistemas/editar-ecossistema?idEcossistema=" +
                idEcossistema;
        },
        modalDelete(idEcossistema) {
            this.idEcossistemaExclusao = idEcossistema;
            this.$bvModal.show("confirmDelete");
        },
        excluirEcossistema() {
            this.$loading(true);
            http.delete(
                `/Ecossistema/deletarEcossistema/${this.idEcossistemaExclusao}`
            )
                .then(
                    (success) => {
                        this.$snotify.success(
                            "Ecossistema excluído com sucesso."
                        );
                        this.obterParceiros();
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(
                            error,
                            "Erro ao excluir ecossistema."
                        )) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                    this.$bvModal.hide("confirmDelete");
                });
        },
        // mapearCategorias() {
        //     window.location.href =
        //         "/painel/admin/categorias/mapear-categorias";
        // },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
    },
};
