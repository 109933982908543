import http from "@/_helper/api-services.js";
import DatePicker from 'vue2-datepicker';
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
  name: "relatorioDeLojasFisicas",
  data() {
    return {
      anunciantes: {
        fields: [
          { key: "idCredenciamento", label: "IdCredenciamento", sortable: true, class: "text-center" },
          { key: "estabelecimento", label: "Estabelecimento", sortable: true, class: "text-center" },
          { key: "telefone", label: "Telefone", class: "text-center", sortable: true },
          { key: "percentualCashback", label: "Percentual Cashback", class: "text-center", sortable: true },
          { key: "dataAtualizacao", label: "Última atualização", sortable: true, class: "text-center", formatter: val => { if (!val) return ""; return new Date(val).toLocaleDateString(); } },
          { key: "acoes", label: "Ações" },
        ],
        items: [],
        perPage: 10,
      },
      exportFields: {
        Nome: "nome",
        Status: "status",
        "Afiliado": "conexao",
        "Tipo de cashback": "tipoCashback",
        Moeda: "moeda",
        "Cashback Mínimo": "cashbackMin",
        "Cashback Máximo": "cashbackMax",
        "Última atualização": "dataAtualizacao",
      },
      search: {
        estabelecimento: null
      },
      lang: {
        days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      },
      sortBy: String(),
      sortDesc: true,
      showIconsToChangePage: false,
      totalPages: 0,
      itemCount: 0
    };
  },
  components: {
    DatePicker,
    tabelaBase
  },
  computed: {
  },
  watch: {
    async 'search.pagina'() {
      await this.getAnunciantes(true);
    },
  },
  async mounted() {
    await this.getAnunciantes();
  },
  methods: {
    async getAnunciantes() {
      this.$loading(true);
      await http.post("/Credenciamento/RelatorioAnunciantes", this.search).then(
        success => {
          this.anunciantes.items = [];
          success.data.forEach(item => {
            this.anunciantes.items.push({
              idCredenciamento: item.idCredenciamento,
              url: item.imagemUrl,
              estabelecimento: item.estabelecimento,
              telefone: item.telefone,
              dataAtualizacao: item.dataAtualizacao,
              percentualCashback: item.percentualCashback,
            });
          });
        },
        error => {
          if (error.response && error.response.data && error.response.data.errors)
            error.response.data.errors.forEach(e => this.$snotify.error(e));
        }
      );
      this.$loading(false);
    },
    formatValue(value) {
      if (!value) return "";
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    },
    async alterarPagina(page) {
      this.search.pagina = page;
      await this.getAnunciantes();
    },
    editarLoja(idCredenciamento) {
      this.$router.push("/painel/admin/editar-loja-credenciado?idCredenciamento=" + idCredenciamento);
    },
  }
}
