import http from "@/_helper/api-services.js";
import graduacao from "@/components/Graduacao/Graduacao.vue";

export default {
    name: "Performance",
    data() {
        return {
            fields: [
                {
                    key: "login",
                    label: "Login",
                    sortable: true,
                    sortDirection: "desc",
                },
                {
                    key: "graduacao",
                    label: "Graduação",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "pontuacao",
                    label: "Pontuação",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "consumo",
                    label: "Consumo",
                    sortable: true,
                    class: "text-center",
                    formatter: (value) => {
                        return value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        });
                    },
                },
            ],
            listDados: [],
            filter: String(),
            perPage: 10,
            pageOptions: [5, 10, 15],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filtro: {
                ordenacao: String(),
                login: String(),
            },
            exportFields: {
                'Login': "login",
                'Graduação': "graduacao",
                'Pontuação': "pontuacao",
                'Consumo': "consumo",
            },
            exportTable: [],
        };
    },
    watch: {
        "filtro.login": function(element) {
            if (element && this.filtro.ordenacao) this.filtro.ordenacao = "";
        },
        "filtro.ordenacao": function(element) {
            if (element && this.filtro.login) this.filtro.login = "";
        },
    },
    components: {
        graduacao,
    },
    mounted() {
        // this.listarDados();
    },
    methods: {
        listarDados() {
            this.$loading(true);
            this.listDados = [];
            http.post("/user/obterRankUsuarioFiltrado", this.filtro)
                .then(
                    (success) => {
                        success.data.forEach((item) => {
                            this.listDados.push({
                                login: item.login,
                                graduacao: item.graduacao,
                                pontuacao: item.pontuacao,
                                consumo: item.consumo,
                            });
                        });
                        this.exportTable = [];
                        for (let i in this.listDados) {
                            this.exportTable.push(this.listDados[i]);
                        }
                    },
                    () => {
                        this.$snotify.error("Erro ao obter dados.");
                    }
                )
                .finally(() => this.$loading(false));
        },
        formatDate(date, showTime) {
            if (!showTime) return new Date(date).toLocaleDateString();
            else
                return `${new Date(date).toLocaleDateString()} - ás ${new Date(
                    date
                ).toLocaleTimeString()}`;
        },
    },
};
