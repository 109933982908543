import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "EditarPedido",
    data() {
        return {
            pedido: {},
            pagamentos: {
                fields: [{
                    key: "numeroParcela",
                    label: "#",
                    class: "text-center small",
                },
                {
                    key: "dataValidade",
                    label: "Data de validade",
                    class: "text-center small",
                },
                {
                    key: "dataPagamento",
                    label: "Data de pagamento",
                    class: "text-center small",
                },
                {
                    key: "valor",
                    label: "Valor",
                    class: "text-left small",
                    formatter: (value) => {
                        return value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        });
                    },
                },
                {
                    key: "acoes",
                    label: "Ações",
                    class: "text-left small",
                },
                ],
                items: [],
                sortBy: "numeroParcela",
                perPage: null,
                sortDesc: false,
                exportExcel: false,
            },
            updatePagamento: {
                idPedido: 0,
                numeroParcela: 0,
                acao: 0,
                distribuirNaRede: false,
                valor: null,
                dataReferencia: null
            },
            confirmacaoAcao: false,
            idParcelaValor: 0
        };
    },
    components: {
        tabelaBase
    },
    computed: {},
    async beforeMount() {
        this.$loading(true);
        await this.obterDadosPedido();
        this.$loading(false);
    },
    methods: {
        async obterDadosPedido() {
            http.get("/pedidos/detalheseditar/" + this.$route.query.idPedido).then(
                (success) => {
                    this.pedido.idPedido = this.$route.query.idPedido;
                    this.updatePagamento.idPedido = +this.$route.query.idPedido;
                    this.pedido = success.data;
                    this.pagamentos.items = [];
                    success.data.parcelas.forEach(pgto => {
                        this.pagamentos.items.push({
                            idPagamento: pgto.idPagamento,
                            numeroParcela: pgto.numeroParcela,
                            dataValidade: this.formatDate(pgto.dataValidade),
                            dataPagamento: pgto.dataPagamento ? this.formatDate(pgto.dataPagamento) : "Aguardando pagamento",
                            valor: pgto.valor,
                            pago: pgto.pago
                        });
                    });
                },
                () => {
                    this.$snotify.error("Erro ao obter pedido.");
                }
            );
        },
        async handleSubmit() {
            this.fecharModal();
            this.$snotify.success("Atualização iniciada, ela será concluída em alguns momentos.");

            this.$loading(true);
            this.pedido.numeroParcelas = +this.pedido.numeroParcelas;
            this.pedido.valorPedido = +this.pedido.valorPedido;
            this.pedido.status = this.pedido.status == "Ativo" ? 1 : 0;
            await http.post("/pedidos/atualizarPedido/", this.pedido).then(
                (success) => {
                    this.$snotify.success("Pedido atualizado com sucesso.");
                    this.fecharModal();
                },
                () => {
                    this.$snotify.error("Erro ao atualizar pedido.");
                }
            );
            window.location.reload();
            this.$loading(false);

            // window.location.href = "/painel/admin/pedidos";
        },
        modalPagar(numeroParcela) {
            this.confirmacaoAcao = false;
            this.updatePagamento.numeroParcela = numeroParcela;
            this.updatePagamento.acao = 1;
            this.$bvModal.show("confirm-pagamento");
        },
        modalRefresh(numeroParcela) {
            this.confirmacaoAcao = false;
            this.updatePagamento.numeroParcela = numeroParcela;
            this.updatePagamento.acao = 2;
            this.$bvModal.show("confirm-refresh");
        },
        modalDelete(numeroParcela) {
            this.confirmacaoAcao = false;
            this.updatePagamento.numeroParcela = numeroParcela;
            this.updatePagamento.acao = 3;
            this.$bvModal.show("confirm-delete");
        },
        modalValor(numeroParcela) {
            this.confirmacaoAcao = false;
            this.updatePagamento.numeroParcela = numeroParcela;
            this.updatePagamento.acao = 4;
            this.$bvModal.show("confirm-update-parcela");
        },
        modalUpdatePedido() {
            this.confirmacaoAcao = false;
            this.updatePagamento.acao = 0;
            this.$bvModal.show("confirm-update-pedido");
        },
        fecharModal() {
            this.$bvModal.hide("confirm-update-pedido");
            this.$bvModal.hide("confirm-delete");
            this.$bvModal.hide("confirm-refresh");
            this.$bvModal.hide("confirm-pagamento");
            this.$bvModal.hide("confirm-valor");
        },
        async atualizarParcela() {
            this.fecharModal();
            this.$snotify.success("Atualização iniciada, ela será concluída em alguns momentos.");

            this.$loading(true);
            this.updatePagamento.valor = +this.updatePagamento.valor;
            await http.post("/pedidos/atualizarParcela/", this.updatePagamento).then(
                (success) => {
                    this.$snotify.success("Pedido atualizado com sucesso.");
                    this.fecharModal();
                    window.location.reload();
                },
                () => {
                    this.$snotify.error("Erro ao obter pedido.");
                }
            );

            this.$loading(false);
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        verificarParcelaPaga(numeroParcela) {
            if (numeroParcela == 1) {
                return true;
            }
            var parcelaAnterior = this.pagamentos.items.find(element => element.numeroParcela == numeroParcela - 1 && element.pago == true);
            if (parcelaAnterior) {
                return true;

            } else {
                return (false);
            }
        },
        voltarTela() {
            this.$router.go(-1);
        }
    },
};