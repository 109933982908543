import http from "@/_helper/api-services.js";
import DatePicker from 'vue2-datepicker';
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
  name: "relatorioDeAnunciantes",
  data() {
    return {
      anunciantes: {

        fields: [
          { key: "nome", label: "Nome", sortable: true, class: "text-center" },
          { key: "conexao", label: "Afiliado", class: "text-center", sortable: true },
          { key: "status", label: "Status", sortable: true, class: "text-center" },
          { key: "dataAtualizacao", label: "Última atualização", sortable: true, class: "text-center", formatter: val => { if (!val) return ""; return new Date(val).toLocaleDateString(); } },
          { key: "acoes", label: "Ações" },
        ],
        items: [],
        perPage: 10,
      },
      exportFields: {
        Nome: "nome",
        Status: "status",
        "Afiliado": "conexao",
        "Tipo de cashback": "tipoCashback",
        Moeda: "moeda",
        "Cashback Mínimo": "cashbackMin",
        "Cashback Máximo": "cashbackMax",
        "Última atualização": "dataAtualizacao",
      },
      search: {
        nome: String(),
        moeda: null,
        status: null,
        tipoCashback: null,
        conexao: null,
        pagina: 1,
        quantidadePorPagina: 10,
        ordenacao: null,
        asc: true,
        obterTodos: false
      },
      lang: {
        days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      },
      sortBy: String(),
      sortDesc: true,
      showIconsToChangePage: false,
      totalPages: 0,
      itemCount: 0
    };
  },
  components: {
    DatePicker,
    tabelaBase
  },
  computed: {
  },
  watch: {
    async 'search.pagina'() {
      await this.getAnunciantes(true);
    },
    async sortDesc() {
      this.search.asc = this.$data.sortDesc;
      await this.getAnunciantes();
    },
    async sortBy() {
      switch (this.$data.sortBy) {
        case "nome":
          this.search.ordenacao = 0;
          break;
        case "status":
          this.search.ordenacao = 1;
          break;
        case "cashbackMin":
          this.search.ordenacao = 2;
          break;
        case "cashbackMax":
          this.search.ordenacao = 3;
          break;
        case "tipoCashback":
          this.search.ordenacao = 4;
          break;
        case "Moeda":
          this.search.ordenacao = 5;
          break;
      }

      await this.getAnunciantes();
    }
  },
  async mounted() {
    await this.getAnunciantes();
  },
  methods: {
    async getAnunciantes() {
      this.$loading(true);
      this.search.valorInicial = !this.search.valorInicial ? null : parseFloat(this.search.valorInicial);
      this.search.valorFinal = !this.search.valorFinal ? null : parseFloat(this.search.valorFinal);
      this.search.obterTodos = true;
      this.search.pagina = 1;
      await http.post("/Admin/RelatorioAnunciantes", this.search).then(
        success => {
          this.totalPages = success.data.totalPages;
          this.anunciantes.items = [];
          success.data.anunciantesFiltrados.forEach(item => {
            this.anunciantes.items.push({
              idAnunciante: item.idAnunciante,
              url: item.imagemUrl,
              nome: item.nome,
              moeda: item.moeda,
              status: item.ativo ? "Ativo" : "Inativo",
              tipoCashback: item.tipoCashback,
              dataAtualizacao: item.dataAtualizacao,
              cashbackMax: item.cashbackMax,
              cashbackMin: item.cashbackMin,
              conexao: item.idAwin,
            });
          });
          this.itemCount = success.data.quantidadeTotal;
        },
        error => {
          if (error.response && error.response.data && error.response.data.errors)
            error.response.data.errors.forEach(e => this.$snotify.error(e));
        }
      );
      this.$loading(false);
    },
    formatValue(value) {
      if (!value) return "";
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    },
    async alterarPagina(page) {
      this.search.pagina = page;
      await this.getAnunciantes();
    },
    editarLoja(idAnunciante) {
      window.location.href = "/painel/admin/config/editar-loja?idAnunciante=" + idAnunciante;
    },
  }
}
