import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import http from "@/_helper/api-services";

export default {
    name: "DesempenhoDetalhado",
    components: {
        tabelaBase,
    },
    props: {
    },
    data() {
        return {
            redeDireto: {
                items: [
                ],
                fields: [
                    { key: 'nome', label: "Nome", sortable: true, },
                    { key: 'login', label: "Login", sortable: true, },
                    { key: 'nomeProduto', label: "Produto Ativo", sortable: true, class: "text-center", },
                    { key: 'diretos', label: "Diretos", sortable: true, class: "text-center", },
                    { key: 'valorGerado', label: "Valor Gerado", sortable: true, class: "text-center", },
                    { key: "acoes", label: "Ações", class: "text-center", },
                ],
            },
            slide: 0,
            sliding: null,
            storeSelected: 0,
            idUsuario: "",
            dadosUsuario: {
                nome: String(),
            },
        }

    },
    watch: {
        $route() {
            this.recarrega();
        },
    },
    async beforeMount() {

        this.$loading(true);
        this.idUsuario = this.$route.query.idUsuario;
        await this.obterPerformanceRedeUsuario();
        await this.obterDadosUsuario();

    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        async obterPerformanceRedeUsuario() {
            await http.get(`/user/obterPerformanceRedeDireto/${this.idUsuario}`).then(
                (response) => {
                    this.redeDireto.items = []
                    response.data.forEach((item) => {
                        this.redeDireto.items.push({
                            idUsuario: item.idUsuario,
                            nome: item.nome,
                            nomeProduto: item.nomeProduto,
                            imgUrl: item.urlimg,
                            login: item.login,
                            diretos: item.direto,
                            valorGerado: item.valorGerado,
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async obterDadosUsuario() {
            await http.get(`/user/obterDadosUsuarioRede/${this.idUsuario}`).then(
                (response) => {
                    this.dadosUsuario.nome = response.data.nome;
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        detalharRedeUsuario(idUsuario) {
            this.$router.push("/painel/desempenho-detalhado?idUsuario=" + idUsuario);
            location.reload();
        },
        voltar() {
            window.location.href = "/painel/desempenho"
        },
        recarrega() {
            window.location.reload();
        }
    }
}
