import "./painelAdmin.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "painelAdmin",
    data() {
        return {
            totalConsumo: 0,
            totalCashback: {
                cashback: 0,
                cashbackResidual: 0,
                total: 0,
                bigcash: 0,
            },
            totalConsumoPlanos: {
                total: 0,
                planoSubsidiada: 0,
                planoPresente: 0,
                compraPlano: 0,
            },
            totalSacado: 0,
            usuariosAtivos: 0,
            usuariosInativos: 0,
            keyUsuarios: 0,
            seriesUsuarios: [],
            chartUsuariosOptions: {
                chart: {
                    type: "area",
                    shadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 1,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                colors: ["rgba(71, 180, 141, 1)"],
                stroke: {
                    curve: "smooth",
                },
                markers: {
                    size: 1,
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    min: 0,
                },
            },
            fieldsGraduacao: [
                { key: "nome", label: "Graduação" },
                {
                    key: "quantidadeUsuarios",
                    label: "Quantidade de usuários",
                    class: "text-center",
                },
            ],
            listGraduacao: [],
            fieldsCashbackGerado: [
                { key: "nome", label: "Lojas" },
                {
                    key: "comissaoTotal",
                    label: "Cashback gerado",
                    class: "text-center",
                    formatter: (value) => this.formatValue(value),
                },
                { key: "status", label: "Status", class: "text-center" },
            ],
            listCashbackGerado: [],
            fieldsDistribuicaoDetalhada: [
                { key: "nome", label: "Tipo" },
                {
                    key: "valor",
                    label: "Valor total",
                    class: "text-center",
                    formatter: (value) => this.formatValue(value),
                },
            ],
            listDistribuicaoDetalhada: [],
        };
    },
    beforeMount() {
        this.$loading(true);
        this.getResumoValores();
        this.getGraficoUsuarios();
        this.getQuantidadeUsuariosPorGraduacao();
        this.getResumoIntegracaoApi();
        this.getDistribuicaoDetalhada();
    },
    methods: {
        formatValue(value) {
            return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        getGraficoUsuarios() {
            http.get("/Admin/RelatorioCadastrosMes").then(
                (success) => {
                    let monthNames = [
                        "Jan",
                        "Fev",
                        "Mar",
                        "Abr",
                        "Mai",
                        "Jun",
                        "Jul",
                        "Ago",
                        "Set",
                        "Out",
                        "Nov",
                        "Dez",
                    ];
                    if (success && success.data) {
                        success.data.forEach((s) => {
                            s.data = new Date(s.data + "/01");
                        });
                        this.seriesUsuarios.push({
                            name: "Cadastros",
                            data: success.data.map((s) => s.quantidade),
                        });
                        this.chartUsuariosOptions.xaxis.categories = success.data.map(
                            (d) => {
                                return monthNames[d.data.getMonth()];
                            }
                        );
                        this.chartUsuariosOptions.yaxis.max =
                            Math.max(
                                ...success.data.map((d) => {
                                    return d.quantidade;
                                })
                            ) + 5;
                        this.keyUsuarios++;
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        getQuantidadeUsuariosPorGraduacao() {
            http.get("/Admin/TotalUsuariosGraduacao").then(
                (success) => {
                    this.listGraduacao = success.data;
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        getResumoIntegracaoApi() {
            http.get("/Admin/ResumoIntegracaoApi").then(
                (success) => {
                    success.data.forEach((s) => {
                        this.listCashbackGerado.push({
                            nome: s.loja.nome,
                            comissaoTotal: s.valorTotalComissao,
                            status: s.loja.ativo,
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        getDistribuicaoDetalhada() {
            http.get("/Admin/DistribuicaoDetalhada").then(
                (success) => {
                    this.listDistribuicaoDetalhada = success.data;
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        getResumoValores() {
            http.get("/Admin/ObterResumoValores").then(
                (success) => {
                    this.totalConsumo = success.data.totalConsumo;
                    this.totalCashback = success.data.totalCashback;
                    this.totalConsumoPlanos = success.data.totalConsumoPlanos;
                    this.totalSacado = success.data.totalSacado;
                    this.usuariosAtivos = success.data.usuariosAtivos;
                    this.usuariosInativos = success.data.usuariosInativos;

                    this.$loading(false);
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
    },
};
