import "./boxAssinatura.scss";
import http from "@/_helper/api-services.js";
import pagarme from "pagarme";

export default {
  name: "boxAssinatura",
  data() {
    return {
      id: String(),
      paymentForms: [],
      opcoesPagamento: [],
      paymentForm: 0,
      numParcelas: 1,
      urlBoleto: String(),
      status: Boolean(),
      card: {
        card_number: "",
        card_holder_name: "",
        card_expiration_month: "",
        card_expiration_year: "",
        card_cvv: "",
      },
    };
  },
  props: {
    product: Object(),
    paymentFormsAlreadyLoaded: Object(),
    planoAtual: Object(),
  },
  computed: {
    listPaymentForms() {
      if (this.$props.paymentFormsAlreadyLoaded) {
        let retorno = [];
        Object.keys(this.$props.paymentFormsAlreadyLoaded).forEach(
          (nameIndex) => {
            retorno.push({
              text: nameIndex,
              value: this.$props.paymentFormsAlreadyLoaded[nameIndex],
            });
          }
        );
        return retorno;
      } else return this.$data.paymentForms;
    },
  },

  beforeMount() {
    if (!this.$props.paymentFormsAlreadyLoaded) {
      this.getPaymentsForms();
    }

    this.obterParcelas();
  },
  mounted() {
    this.id = this._uid.toString();
    console.log("product", this.product);
  },
  methods: {
    formattedDate() {
      const date = new Date(this.product.dataAssinatura);
  
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      const hours = date.getHours();
      const minutes = date.getMinutes();
  
      const formattedDate = `Sua assinatura foi realizada em ${day}/${month}/${year} às ${hours}:${minutes}`;
  
      return formattedDate;
  },
  
    validateName() {
      // Remove todos os caracteres que não sejam letras
      this.card.card_holder_name = this.card.card_holder_name.replace(
        /[^A-Za-z\s]/g,
        ""
      );
    },

    validateCardNumber() {
      // Remove todos os caracteres não numéricos
      this.card.card_number = this.card.card_number.replace(/\D/g, "");
      this.card.card_number = this.card.card_number.substring(0, 16);
    },

    validateMonth() {
      // Remove todos os caracteres não numéricos
      let month = this.card.card_expiration_month.toString().replace(/\D/g, "");

      // Limita o número de dígitos para no máximo 2
      month = month.substring(0, 2);

      // Converte para número inteiro
      month = parseInt(month);

      // Verifica se o valor está dentro do intervalo válido (1-12)
      if (month < 1) {
        month = 1;
      } else if (month > 12) {
        month = 12;
      }

      // Atualiza o valor do mês
      this.card.card_expiration_month = month.toString();
    },

    getMonthName(month) {
      const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return months[month - 1];
    },

    validateYear() {
      // Remove todos os caracteres não numéricos e limita a quatro dígitos
      this.card.card_expiration_year = this.card.card_expiration_year
        .replace(/\D/g, "")
        .substring(0, 4);
    },

    validateCVV() {
      // Remove todos os caracteres não numéricos e limita a três dígitos
      this.card.card_cvv = this.card.card_cvv
        .replace(/\D/g, "")
        .substring(0, 3);
    },

    formatValor(valor) {
      return valor.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    obterParcelas() {
      this.opcoesPagamento = [
        {
          text: "1x de " + this.formatValor(this.$props.product.valor),
          value: 1,
        },
      ];

      var i;
      for (i = 2; i <= this.$props.product.parcelas; i++) {
        this.opcoesPagamento.push({
          text: i + "x de " + this.formatValor(this.$props.product.valor / i),
          value: i,
        });
      }
    },

    openBuyConfirmation() {
      this.$bvModal.show(this.id);
    },

    getPaymentsForms() {
      http.get("/compra/obterMetodosDeCompra").then(
        (success) => {
          let data = success.data;
          Object.keys(data).forEach((nameIndex) => {
            this.paymentForms.push({
              text: nameIndex,
              value: data[nameIndex],
            });
          });
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },

    handleReset() {
      this.paymentForm = null;
      this.password = String();
    },

    async confirmarCancelamento() {
      this.cancelarAssinatura();
    },

    async cancelarAssinatura() {
      this.$loading(true);
      await http.post("/pedidos/cancelarAssinatura").then(
        (success) => {
          this.$snotify.success("Assinatura Cancelada");
          this.$emit("assinaturaAdquirida");
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );

      this.$loading(false);
    },

    async abrirModalDeConfirmacao() {
      // Abre a modal de confirmação
      await this.$refs.confirmModal.show();
    },

    closeModal() {
      this.card.card_number = "";
      this.card.card_holder_name = "";
      this.card.card_expiration_month = "";
      this.card.card_expiration_year = "";
      this.card.card_cvv = "";

      this.$bvModal.hide(this.id);
      this.urlBoleto = null;
    },

    handleSubmit() {
      var card = this.card;
      var cardValidations = pagarme.validate({ card: card });

      if (!cardValidations.card.card_number) {
        console.log("Oops, número de cartão incorreto");
        this.$snotify.error("Os dados do cartão estão incorretos");
        return;
      }

      this.adquirirPlanoMensal();

      // pagarme.client
      //   .connect({ encryption_key: "ek_test_BTcSaxNbj8t6KUHcbtBXgzxg56uhAZ" })
      //   .then((client) => client.security.encrypt(card))
      //   .then((card_hash) => {
      //     console.log(card_hash);
      //     this.adquirirPlanoMensal(card_hash);
      //   });
      // o próximo passo aqui é enviar o card_hash para seu servidor, e
      // em seguida criar a transação/assinatura

      this.closeModal();
    },

    async adquirirPlanoMensal() {
      this.$loading(true);
      (this.paymentForm = 9),
        await http
          .post("/pedidos/adquirirPlanoMensal", {
            idProduto: this.$props.product.idProduto,
            formaPagamento: this.paymentForm,
            numParcelas: this.numParcelas,
            aceiteTermos: this.status,
            card: this.card,
          })
          .then(
            (success) => {
              if (success.data.assinaturaAtiva) {
                this.$snotify.error("Você já possui uma assinatura ativa");
              } else {
                this.$snotify.success("Assinatura realizada");
                this.$emit("assinaturaAdquirida");
              }
            },
            (error) => {
              for (const erro of http.mensagensErro(error)) {
                this.$snotify.error(erro);
                console.log("error", error);
              }
            }
          );

      this.$loading(false);
    },
  },
};
