import http from "@/_helper/api-services.js";
import DatePicker from 'vue2-datepicker';
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";


export default {
    name: "cuponCashbackUsuario",
    data() {
        return {
            cupons: {

                fields: [
                    { key: "token", label: "Cupon", sortable: true, class: "text-center" },
                    { key: "valor", label: "Valor", class: "text-center", sortable: true },
                    { key: "nomeComerciante", label: "Comerciante", class: "text-center", sortable: true },
                    { key: "percentualCahback", label: "Cashback", sortable: true, class: "text-center" },
                    { key: "dataCompra", label: "Data Compra", sortable: true, class: "text-center", formatter: val => { if (!val) return ""; return new Date(val).toLocaleDateString(); } },
                    { key: "acoes", label: "Ações",class: "text-center" },
                ],
                items: [],
                perPage: 10,
            },
            exportFields: {
             
            },
            search: {
                
            },
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            sortBy: String(),
            sortDesc: true,
            showIconsToChangePage: false,
            totalPages: 0,
            itemCount: 0
        };
    },
    components: {
        DatePicker,
        tabelaBase
    },
    computed: {
    
    },
    watch: {
        
    },
    async mounted() {
    },
    methods: {
        
    }
}