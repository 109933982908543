import http from "@/_helper/api-services.js";
import "./PedidoPlanos.scss";

export default {
    name: "PedidoPlanos",
    data() {
        return {
            fields: [{
                    key: "login",
                    label: "Login",
                    sortable: true,
                    sortDirection: "desc",
                    class: "small",
                },
                {
                    key: "celular",
                    label: "Telefone",
                    sortable: false,
                    class: "small",
                },
                {
                    key: "email",
                    label: "E-mail",
                    sortable: true,
                    class: "small",
                },
                {
                    key: "loginPatrocinador",
                    label: "Patrocinador",
                    sortable: true,
                    class: "small",
                },
                {
                    key: "produto",
                    label: "Produto",
                    sortable: false,
                    class: "small",
                },
                {
                    key: "codigo",
                    label: "Código Pedido",
                    sortable: false,
                    class: "small",
                },
                {
                    key: "valor",
                    label: "Valor",
                    sortable: true,
                    class: "text-center small",
                    formatter: (value) =>
                        value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        }),
                },
                {
                    key: "data",
                    label: "Data Pedido",
                    sortable: true,
                    class: "text-center small",
                    formatter: (value) =>
                        `${new Date(value).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`,
                },
                {
                    key: "dataReferencia",
                    label: "Data Referencia",
                    sortable: true,
                    class: "text-center small",
                    formatter: (value) =>
                        `${new Date(value).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`,
                },
                {
                    key: "status",
                    label: "Status",
                    sortable: true,
                    class: "text-center small",
                },
                { key: "acoes", label: "", sortable: false },
                { key: "excluir", label: "", sortable: false },
            ],
            items: [],
            sortBy: "",
            sortDesc: false,
            filtro: {
                loginEmail: String(),
                idStatus: null,
                codigoPedido: String(),
                page: 1,
                perPage: 10,
                ordenacao: 4,
                orderDesc: true,
            },
            options: [{ value: null, text: "Todos" }],
            pageOptions: [10, 20, 50],
            showIconsToChangePage: false,
            totalPages: 1,
            exportFields: {
                Login: "login",
                Telefone: "celular",
                "E-mail": "email",
                Patrocinador: "loginPatrocinador",
                Produto: "produto",
                "Código Pedido": "codigo",
                Valor: "valor",
                "Data Pedido": "data",
                Status: 'status',
                //Status: {field: 'status.nome', },
            },
            exportTable: [],
        };
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPages <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPages;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPages - this.filtro.page;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPages - 2; i <= this.totalPages; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPages - 1)
                    return [1, 2, 3];
                else
                    return [
                        this.filtro.page - 1,
                        this.filtro.page,
                        this.filtro.page + 1,
                    ];
            }
        },
    },
    watch: {
        "filtro.loginEmail": function(element) {
            if (!element &&
                !this.filtro.codigoPedido &&
                this.filtro.pago == null
            )
                this.getPurchaseHistory();
        },
        "filtro.codigoPedido": function(element) {
            if (!element && !this.filtro.loginEmail && this.filtro.pago == null)
                this.getPurchaseHistory();
        },
        "filtro.perPage" () {
            this.filtro.page = 1;
            this.$loading(true);
            this.getPurchaseHistory();
            this.$loading(false);
        },
        async sortDesc() {
            this.filtro.orderDesc = this.$data.sortDesc;
            this.filtro.page = 1;
            this.$loading(true);
            await this.getPurchaseHistory();
            this.$loading(false);
        },
        async sortBy() {
            this.filtro.page = 1;
            switch (this.$data.sortBy) {
                case "login":
                    this.filtro.ordenacao = 0;
                    this.$loading(true);
                    this.getPurchaseHistory();
                    this.$loading(false);
                    break;
                case "email":
                    this.filtro.ordenacao = 1;
                    this.$loading(true);
                    this.getPurchaseHistory();
                    this.$loading(false);
                    break;
                case "patrocinador":
                    this.filtro.ordenacao = 2;
                    this.$loading(true);
                    this.getPurchaseHistory();
                    this.$loading(false);
                    break;
                case "valor":
                    this.filtro.ordenacao = 3;
                    this.$loading(true);
                    this.getPurchaseHistory();
                    this.$loading(false);
                    break;
                case "status":
                    this.filtro.ordenacao = 5;
                    this.$loading(true);
                    this.getPurchaseHistory();
                    this.$loading(false);
                    break;
                default:
                    this.filtro.ordenacao = 4;
                    this.$loading(true);
                    this.getPurchaseHistory();
                    this.$loading(false);
                    break;
            }
        },
    },
    async beforeMount() {
        this.$loading(true);
        await this.getStatusList();
        await this.getPurchaseHistory();
        this.$loading(false);
    },
    methods: {
        async getStatusList() {
            http.get("/geral/status").then(
                (success) => {
                    success.data.forEach((item) => {
                        this.options.push({
                            value: item.idStatus,
                            text: item.nome,
                        });
                    });
                },
                () => {
                    this.$snotify.error("Erro ao obter status.");
                }
            );
        },
        async getPurchaseHistory(event) {
            if (event) {
                event.preventDefault();
                this.$loading(true);
                this.filtro.page = 1;
            }

            await http
                .post("/Admin/obterPedidosPlanos", this.filtro)
                .then(
                    (success) => {
                        this.totalPages = success.data.totalPages;
                        this.items = [];
                        success.data.pedidosFiltrados.forEach((item) => {
                            if(item.dataReferencia == null){
                                this.items.push({
                                    idPedido: item.idPedido,
                                    login: item.login,
                                    email: item.email,
                                    codigo: item.codigo,
                                    valor: item.valorPedido,
                                    data: item.dataPedido,
                                    dataReferencia: item.dataPedido,
                                    urlBoleto: item.urlBoleto,
                                    produto: item.produto,
                                    celular: item.celular,
                                    idStatus: item.idStatus,
                                    status: item.status,
                                    ativo: item.ativo,
                                    loginPatrocinador: item.loginPatrocinador,
                                    
                                });
                            }else{
                                this.items.push({
                                    idPedido: item.idPedido,
                                    login: item.login,
                                    email: item.email,
                                    codigo: item.codigo,
                                    valor: item.valorPedido,
                                    data: item.dataPedido,
                                    dataReferencia: item.dataReferencia,
                                    urlBoleto: item.urlBoleto,
                                    produto: item.produto,
                                    celular: item.celular,
                                    idStatus: item.idStatus,
                                    status: item.status,
                                    ativo: item.ativo,
                                    loginPatrocinador: item.loginPatrocinador,
                                    
                                });
                            }
                            
                        });
                        this.exportTable = [];
                        for (let i in this.items) {
                            this.exportTable.push(this.items[i]);
                        }
                    },
                    () => {
                        this.$snotify.error("Erro ao obter dados.");
                    }
                )
                .finally(() => {
                    if (event) this.$loading(false);
                });
        },
        async alterarPagina(pagina) {
            this.filtro.page = pagina;
            this.$loading(true);
            await this.getPurchaseHistory();
            this.$loading(false);
        },
        editarPedido(idPedido) {
            this.$router.push({
                path: "/painel/admin/pedidos/editar",
                query: { idPedido: idPedido }
            });
        },
        cancelarPedido(idPedido) {
            this.$loading(true);
            http.post("/admin/cancelarPedido", { idPedido })
                .then(
                    async(success) => {
                        this.$snotify.success("Pedido cancelado com sucesso.");
                        await this.getPurchaseHistory();
                    },
                    () => {
                        this.$snotify.error("Erro ao cancelar pedido.");
                    }
                )
                .finally(() => this.$loading(false));
        },
    },
};