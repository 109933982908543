import "./cupons.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "cupons",
    data() {
        return {
            anuncianteId: String(),
        };
    },
    beforeMount() {
        this.anuncianteId = this.$route.params.idEmpresa;
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        GetUrlProgramZanox() {
            this.$loading(true);
            http.get("/Anunciante/getUrlProgramZanox/" + this.anuncianteId)
                .then(
                    (response) => {
                        window.open(response.data);
                    },
                    () => {
                        this.$snotify.error("Faça login para acessar!");
                        window.scrollTo(0, 0);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
    },
};
