import "./CarrosselPerformance.scss";
import carousel from "vue-owl-carousel";
import BoxPerformance from "@/components/BoxPerformance/BoxPerformance.vue";

export default {
	name: "CarrosselPerformance",
	data() {
		return {
			windowWidth: window.innerWidth,
			numCards: 0,
			size: 0,
			padding: 0
		}
	},
	beforeMount() {
		this.redimensiona();
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth
			// this.redimensiona();

			window.location.reload();
		}
	},
	created() {
		window.addEventListener("resize", this.redimensiona);
	},
	destroyed() {
		window.removeEventListener("resize", this.redimensiona);
	},
	props: {
		items: { type: Array, default: () => { return []; }, },
	},
	watch: {
		items(data) {
			var owl = $('.owl-carousel');
			owl.owlCarousel();
			// Go to the next item
			owl.trigger('replace.owl.carousel',data);

		}
	},
	components: {
		carousel,
		BoxPerformance
	},
	methods: {
		redimensiona() {
			this.size = this.calculaLargura();
			this.numCards = this.numeroCards();
			this.padding = this.calculaPadding();
		},
		numeroCards() {
			if (this.windowWidth > 900) {
				return 3;
			}

			return 1;
		},
		calculaPadding() {
			if (this.windowWidth > 1400) {
				return "px-2";
			}
			if (this.windowWidth > 1200) {
				return "px-2";
			}
			else if (this.windowWidth > 1000) {
				return "px-2";
			}
			else if (this.windowWidth > 800) {
				return "px-2";
			}
			else if (this.windowWidth > 400) {
				return "px-2";
			}

			return "px-2"
		},
		calculaLargura() {
			if (this.windowWidth > 1800) {
				return this.windowWidth - 700;
			}
			else if (this.windowWidth > 1600) {
				return this.windowWidth - 700;
			}
			else if (this.windowWidth > 1500) {
				return this.windowWidth - 600;
			}
			else if (this.windowWidth > 1400) {
				return this.windowWidth - 450;
			}
			else if (this.windowWidth > 1300) {
				return this.windowWidth - 20;
			}
			else if (this.windowWidth > 1000) {
				return this.windowWidth - 20;
			}
			else if (this.windowWidth > 850) {
				return this.windowWidth - 18;
			}
			else if (this.windowWidth > 500) {
				return this.windowWidth - 20;
			}

			return this.windowWidth;
		},
	}
}