import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import http from "@/_helper/api-services";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import CarrosselPerformance from "@/components/CarrosselPerformance/CarroselPerformance.vue";
import "./Desempenho.css";

export default {
    name: "Desempenho",
    components: {
        tabelaBase,
        BarraIndicacao,
        CarrosselPerformance
    },
    data() {
        return {
            slide: 0,
            sliding: null,
            storeSelected: 0,

            dadosCarrosselOpiniao: {
                items: [],
            },
            lancamentosRede: {
                items: [],
                fields: [
                    { key: 'nome', label: "Nome", sortable: true },
                    { key: 'login', label: "Login", sortable: true },
                    { key: 'valor', label: "Valor", sortable: true },
                    { key: 'dataLancamento', label: "Data Lancamento", sortable: true },
                    { key: 'descricao', label: 'Descricao', sortable: true }
                ],
            },
            filtro: {
                nome: "",
                login: "",
            }
        };
    },
    watch: {
        $route() {
            this.recarrega();
        },
    },
    async beforeMount() {
        this.$loading(true);
        await this.obterLancamentoRede();
        await this.obterPerformanceRede();
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
        async obterPerformanceRede() {
            await http.get(`/user/obterPerformanceRede/?nome=${this.filtro.nome}&login=${this.filtro.login}`).then(
                (response) => {
                    var result = [];
                    response.data.forEach((item) => {
                        result.push({
                            idUsuario: item.idUsuario,
                            nome: item.nome,
                            nomeProduto: item.nomeProduto,
                            imgUrl: item.urlimg,
                            login: item.login,
                            diretos: item.direto,
                            valorGerado: item.valorGerado.toFixed(2),
                            pontuacao: item.pontuacao
                        });

                    });
                    this.dadosCarrosselOpiniao = { ...this.dadosCarrosselOpiniao, items: result };
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async obterLancamentoRede() {
            await http.get("/user/obterLancamentoRede/").then(
                (response) => {
                    response.data.forEach((item) => {
                        this.lancamentosRede.items.push({
                            idUsuario: item.idUsuario,
                            nome: item.nome,
                            login: item.login,
                            valor: "R$ " + item.valor.toFixed(2),
                            dataLancamento: item.dataLancamento,
                            descricao: item.descricao
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        detalharRedeUsuario(idUsuario) {
            window.location.href = "/painel/desempenho-detalhado?idUsuario=" + idUsuario;
        },
        filtrar() {
            window.location.href = "/painel/desempenho?nome=" + this.filtro.nome + "&login=" + this.filtro.login;
        },
        recarrega() {
            window.location.reload();
        }
    },
};