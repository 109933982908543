import http from "@/_helper/api-services.js";

export default {
    name: "CuponsCompraUsuario",
    data() {
        return {
            filtroVenda: {
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                tipoPagamento: null,
                pagina: 1,
                quantidadePorPagina: 5,
                ordenacao: null,
                asc: null,
            },
            pageOptions: [5, 10, 15, 20],
            totalRows: 0,
            totalPages: 0,
            opcoesTipoPagamento: [
                { text: "Selecione o tipo de pagamento", value: null },
                { text: "Saldo", value: 10 },
                { text: "Dinheiro", value: 11 },
            ],
            fieldsUltimasVendas: [
                {
                    key: "usuario",
                    label: "Usuário",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "comerciante",
                    label: "Comerciante",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "data",
                    label: "Data venda",
                    sortable: true,
                    class: "text-center",
                    formatter: (val) => new Date(val).toLocaleDateString(),
                },
                {
                    key: "pagamento",
                    label: "Forma de pagamento",
                    sortable: true,
                    class: "text-center",
                },
                { key: "descricao", label: "Descrição", class: "text-center" },
                {
                    key: "valor",
                    label: "Valor pago pelo usuário",
                    sortable: true,
                    class: "text-center",
                    formatter: (val) => this.formatValue(val),
                },
                {
                    key: "valorRecebido",
                    class: "text-center",
                    sortable: true,
                    formatter: (val) => this.formatValue(val),
                },
                {
                    key: "valorCashbackUsuario",
                    class: "text-center",
                    sortable: true,
                    formatter: (val) => this.formatValue(val),
                },
                {
                    key: "percentualCashback",
                    class: "text-center",
                    sortable: true,
                    formatter: (val) => (val ? `${val}%` : ""),
                },
                {
                    key: "acoes",
                    class: "text-center",
                    label: "Ações",
                },
            ],
            listUltimasVendas: [],
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            sortBy: String(),
            sortDesc: true,
            showIconsToChangePage: false,
            exportFields: {
                Login: "usuario",
                Data: "data",
                Pagamento: "pagamento",
                Valor: "valor",
                "Valor recebido": "valorRecebido",
                "Valor cashback usuário": "valorCashbackUsuario",
                "Percentual Cashback": "percentualCashback",
            },
            exportTable: [],
            itemCount: 0,
            fotoComprovante: null,
        };
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPages <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPages;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPages - this.filtroVenda.pagina;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPages - 2; i <= this.totalPages; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPages - 1)
                    return [1, 2, 3];
                else
                    return [
                        this.filtroVenda.pagina - 1,
                        this.filtroVenda.pagina,
                        this.filtroVenda.pagina + 1,
                    ];
            }
        },
    },
    async beforeMount() {
        this.$loading(true);
        //await this.getTodasVendas();
        await this.buscarCupom();
        this.$loading(false);
    },
    watch: {
        async "filtroVenda.pagina"() {
            await this.buscarCupom(true);
        },
        async "filtroVenda.quantidadePorPagina"() {
            await this.buscarCupom(true);
        },
        async sortDesc() {
            this.filtroVenda.asc = this.$data.sortDesc;
            await this.buscarCupom(true);
        },
        async sortBy() {
            switch (this.$data.sortBy) {
                case "usuario":
                    this.filtroVenda.ordenacao = 0;
                    break;
                case "data":
                    this.filtroVenda.ordenacao = 1;
                    break;
                case "pagamento":
                    this.filtroVenda.ordenacao = 2;
                    break;
                case "valor":
                    this.filtroVenda.ordenacao = 3;
                    break;
                case "valorRecebido":
                    this.filtroVenda.ordenacao = 4;
                    break;
                case "valorCashbackUsuario":
                    this.filtroVenda.ordenacao = 5;
                    break;
                case "percentualCashback":
                    this.filtroVenda.ordenacao = 6;
                    break;
            }

            await this.buscarCupom(true);
        },
    },
    methods: {
        formatValue(value) {
            if (!value) return "";
            return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        modalDelete(value) {
            console.log(value);
            this.fotoComprovante = value
            this.$bvModal.show("confirmDelete");
        },
        async getTodosCupoms() {
            // this.getTodosCupomsUsuario();
            // const response = await http
            //     .post("/Credenciamento/GetVendas", this.filtroVenda)
            //     .then(
            //         (success) => {
            //             let dataForTableExport = [];
            //             success.data.vendasFiltradas.forEach((item) => {
            //                 dataForTableExport.push({
            //                     usuario: item.usuario,
            //                     data: item.dataPedido
            //                         ? new Date(
            //                               item.dataPedido
            //                           ).toLocaleDateString()
            //                         : "",
            //                     pagamento: item.tipoPagamento,
            //                     valor: item.valorPago
            //                         ? this.formatValue(item.valorPago)
            //                         : "",
            //                     valorRecebido: item.valorRecebido
            //                         ? this.formatValue(item.valorRecebido)
            //                         : "",
            //                     percentualCashback: item.percentualCashback,
            //                     valorCashbackUsuario: item.valorCashbackUsuario
            //                         ? this.formatValue(
            //                               item.valorCashbackUsuario
            //                           )
            //                         : "",
            //                     descricao: item.descricao,
            //                     comprovante: item.comprovanteCompra,
            //                     token: item.token
            //                 });
            //             });
            //             return dataForTableExport;
            //         },
            //         () => {
            //             return [];
            //         }
            //     );
            // return response;
        },
        async buscarCupom(needLoading) {
            if (needLoading) this.$loading(true);
            await http.post("/Admin/ObterCuponsCompraUsuario", this.filtroVenda).then(
                (success) => {
                    let dataForTable = [];
                    this.totalRows = success.data.result.totalRegistros;
                    this.totalPages = success.data.result.totalPages;
                    console.log(success);
                    success.data.result.data.forEach((item) => {
                        dataForTable.push({
                            usuario: item.usuario,
                            comerciante: item.nome,
                            data: item.dataCompra,
                            pagamento: item.meioPagamento,
                            valor: item.valor
                                ? this.formatValue(item.valor)
                                : "",
                            valorRecebido: item.valorRecebido
                                ? this.formatValue(item.valorRecebido)
                                : "",
                            percentualCashback: item.percentualCashback,
                            valorCashbackUsuario: item.valorCashbackUsuario,
                            descricao: item.descricao,
                            comprovante: item.comprovanteCompra,
                            token: item.token,
                            aprovado: item.compraUsuarioAprovada == true ? "Sim" : "Não",
                            status: item.status
                        });
                    });
                    this.listUltimasVendas = dataForTable;
                    this.itemCount = dataForTable.length;
                },
                () => {
                    this.$snotify.error("Erro ao obter as vendas.");
                }
            );
            if (needLoading) this.$loading(false);
        },
        async aprovarCupom(token, aprovacao, status) {
            console.log(token);
            console.log(aprovacao);
            this.$loading(true);
            const aprovarCupom = {};
            aprovarCupom.token = token;
            aprovarCupom.aprovado = aprovacao;
            aprovarCupom.status = status;

            await http.post("/Venda/aprovarCupom" , aprovarCupom).then(
                (success) => {
                    this.$snotify.success("Cupom aprovado com sucesso");
                    this.$loading(false);

                },
                () => {
                    this.$snotify.error("Erro ao aprovar cupom");
                    this.$loading(false);

                }
            );
            this.$loading(false);
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        alterarPagina(page) {
            this.filtroVenda.pagina = page;
        },
        startDownload() {},
        finishDownload() {},        
    },
};
