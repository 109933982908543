import http from "@/_helper/api-services.js";

export default {
  name: "TreeviewItem",
  data() {
    return {
      aberto: false,
      classe: "plus",
      temFilhos: true,
    };
  },
  components: {
    treeviewItem: () => import("@/components/TreeviewItem/TreeviewItem.vue"),
  },
  props: ["item", "itemFilho", "nivel"],
  beforeMount() {},
  methods: {
    expand() {
      this.$loading(true);
      if (!this.item.filhos || this.item.filhos.length == 0) {
        http.get("/rede/obterDiretos/" + this.item.idUsuario).then(
          (response) => {
            this.item.filhos = response.data;
            this.$loading(false);
            this.aberto = true;
            this.classe = "minus";
          },
          () => {
            this.$snotify.error("Ocorreu um erro inesperado");
            this.$loading(false);
          }
        );
      } else {
        this.aberto = !this.aberto;
        this.classe = this.aberto ? "minus" : "plus";
        this.$loading(false);
      }
    },
  },
};
