import "./formSuporte.scss";
import http from "@/_helper/api-services.js";
import inputContato from "./TipoInput/inputContato.vue";
import inputCashback from "./TipoInput/inputCashback.vue";
import inputPedido from "./TipoInput/inputPedido.vue";

export default {
    name: "formSuporte",
    props: {
        formData: { type: Object, default: null },
        tiposContato: {
            type: Array,
            default: () => [1, 2],
        },
    },
    components: {
        inputContato,
        inputCashback,
        inputPedido,
    },
    data() {
        return {
            loggedIn: false,
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            listaTipoContato: [
                { text: "Escolha o tipo de solicitação", value: null },
                { text: "Contato", value: 1 },
                { text: "Cashback não pago", value: 2 },
                { text: "Cancelar parcelamento", value: 3 },
                { text: "Reativar parcelamento", value: 4 },
            ],
            form: {
                tipoContato: null,
            },
            inputProps: {
                "1": ["nome", "email", "mensagem"],
                "2": [
                    "dataCompra",
                    "siteCompra",
                    "numeroPedido",
                    "valorPedido",
                    "observacao",
                    "tipoContato",
                    "urlComprovante",
                ],
                "3": [
                    "dataCompra",
                    "siteCompra",
                    "numeroPedido",
                    "valorPedido",
                    "observacao",
                    "tipoContato",
                    "urlComprovante",
                ],
            },
        };
    },
    watch: {
        "form.arquivo"(e) {
            let reader = new FileReader();
            reader.readAsDataURL(e);
            reader.onload = () => {
                this.form.urlComprovante = reader.result;
            };
        },
    },
    beforeMount() {
        const dadosUser = this.$store.getters["autenticacao/getLogin"].user;
        if (dadosUser) {
            this.loggedIn = true;
            this.form.nome = dadosUser.username;
            this.form.email = dadosUser.email;
        } else {
            this.form.tipoContato = 1;
        }

        if (this.formData) {
            for (const prop in this.formData) {
                this.form[prop] = this.formData[prop];
            }
        }

        this.listaTipoContato = this.listaTipoContato.filter(
            (tipo) =>
                tipo.value == null ||
                tipo.value == this.form.tipoContato ||
                this.tiposContato.some((value) => tipo.value == value)
        );
    },
    methods: {
        async handleSubmit(event) {
            this.$loading(true);
            let valid = true;
            event.preventDefault();

            try {
                switch (+this.form.tipoContato) {
                    case 1:
                        this.enviarSolicitacao(
                            "/contato/EnviarContato",
                            "Contato enviado com sucesso.",
                            "Não foi possível enviar o contato, tente novamente."
                        );
                        break;
                    case 2:
                        if (!this.form.urlComprovante) {
                            this.$snotify.error(
                                'O campo "Anexar nota fiscal" é obrigatório.'
                            );
                            valid = false;
                        }
                        if (!this.form.dataCompra) {
                            this.$snotify.error(
                                'O campo "Data da compra" é obrigatório.'
                            );
                            valid = false;
                        }

                        if (valid) {
                            this.form.valorPedido = parseFloat(
                                this.form.valorPedido
                            );
                            this.enviarSolicitacao("/contato/EnviarSuporte");
                        } else {
                            this.$loading(false);
                        }
                        break;
                    case 3:
                        this.form.numeroPedido = this.form.idPedido;
                        this.form.siteCompra = this.form.codigo;
                        this.enviarSolicitacao(`/contato/EnviarSuporte`);
                        break;
                    case 4:
                        this.enviarSolicitacao(
                            `/pedidos/reativar/${this.form.idPedido}`
                        );
                        break;
                    default:
                        // tipo não definido
                        this.$loading(false);
                        break;
                }
            } catch (error) {
                this.$loading(false);
            }
        },
        async enviarSolicitacao(url, mensagemSucesso, mensagemErro) {
            const requestData = this.selectProperties(
                this.form,
                this.inputProps[+this.form.tipoContato]
            );

            await http
                .post(url, this.form)
                .then(
                    (success) => {
                        if (mensagemSucesso) {
                            this.$snotify.success(mensagemSucesso);
                        } else {
                            this.$snotify.success(
                                "Solicitação enviada com sucesso."
                            );
                        }
                        this.$bvModal.hide("modalActions");
                        this.$loading(false);
                        window.location.href = "/painel";
                    },
                    () => {
                        this.$snotify.error(
                            "Não foi possível enviar a solicitação, tente novamente mais tarde."
                        );
                    }
                )
                .finally(() => this.$loading(false));
        },
        selectProperties(object, properties) {
            var selected = {};

            if (properties) {
                for (const property of properties) {
                    selected[property] = object[property];
                }
            }
            return selected;
        },
    },
};
