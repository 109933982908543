import http from '@/_helper/api-services';
import { decimal } from "vuelidate/lib/validators";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import "./DetalhesSuporte.css";

export default {
    name: "DetalhesSuporte",
    props: {
        idSuporte: String(),
        isAdmin: false
    },
    data() {
        return {
            solicitacao:{
                idStatus: 0,
                valorPedido: decimal(),
                dataAtualizacao: new Date(),
                dataSolicitacao: new Date(),
                DataCompra: new Date(),
                numeroPedido: String(),
                nomeUsuario: String(),
                loginUsuario: String(),
                loginPatrocinador: String(),
                tipoContato: 0,
                urlComprovante: String(),
                observacao: String(),
                observacaoAdmin: String(),
                historicoSuporte: []
            },
            historico:{
                fields: [
                    {
                        key: "dataUpdate",
                        label: "Data",
                        class: "text-center",
                    },
                    {
                        key: "observacaoAdmin",
                        label: "Observação",
                        class: "obsAdmin",
                    },
                ],
                items: [],
                sortBy: "",
                sortDesc: false,
                exportExcel: false,
            },
            acao: String(),
            idSuporteSelecionado: null,
            idStatusSelecionado: null,
            observacaoAdmin: String(),
        };
    },
    async beforeMount() {
        await this.buscarDetalhes();
    },
    methods: {
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        formatMoney(value) {
            if (value) {
                return value.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                });
            }
        },
        async buscarDetalhes()
        {
            await http.get(`/Contato/detalhesSolicitacao/${this.$props.idSuporte}`)
                .then((response) => {
                    
                    this.solicitacao = response.data;
                    this.historico.items = response.data.historicoSuporte;
            })

            this.$loading(false);
        },
        abrirModal(url, observacao) {
            this.$bvModal.show("modal-comprovante");
            this.imagemComprovante = url;
            this.observacao = observacao;
        },
        abrirModalAcao(idSuporte, acao, idStatus) {
            idSuporte = this.$props.idSuporte
            this.idSuporteSelecionado = idSuporte;
            this.idStatusSelecionado = idStatus;
            this.acao = acao;
            this.$bvModal.show('modal-acao');
        },
        verificaUrl(url) {
            return url.indexOf(".pdf") !== -1;
        },
        salvarAlteracao() {
            
            this.$loading(true);
            let obj = {
                idSuporte: this.idSuporteSelecionado,
                idStatus: this.idStatusSelecionado,
                observacaoAdmin: this.observacaoAdmin
            }

            http.post("/Admin/atualizarStatusSuporte", obj).then(success => {
                this.$snotify.success(success.data);
                this.$bvModal.hide('modal-acao');
                this.solicitacao.observacaoAdmin = '';
                this.buscarDetalhes();
            }, () => {
                this.$snotify.error("Erro ao atualizar suporte!");
            });
            this.$loading(false);
        },
        cancelarPedido()
        {
            this.$loading(true);
            http.post("/Pedidos/cancelar/" + this.solicitacao.numeroPedido).then(success => {
                this.$snotify.success("Atualizado com sucesso!");
                this.solicitacao.observacaoAdmin = '';
                
                this.idSuporteSelecionado = this.idSuporte;
                this.idStatusSelecionado = 2
                this.observacaoAdmin = "Solicitação aprovada.";

                this.salvarAlteracao();
                this.buscarDetalhes();
            }, () => {
                this.$snotify.error("Erro ao atualizar suporte!");
            });
            this.$loading(false);
        },
        reabrirPedido()
        {
            this.$loading(true);
            http.post("/Pedidos/reativar/" + this.solicitacao.numeroPedido).then(success => {
                this.$snotify.success(success.data);
                this.solicitacao.observacaoAdmin = '';
                this.buscarDetalhes();
            }, () => {
                this.$snotify.error("Erro ao atualizar suporte!");
            });
            this.$loading(false);
        },
        reabrirPedido()
        {
            this.$loading(true);
            http.post("/Admin/reabrirPedido/" + this.solicitacao.numeroPedido).then(success => {
                this.$snotify.success(success.data);
                this.solicitacao.observacaoAdmin = '';
                this.buscarDetalhes();
            }, () => {
                this.$snotify.error("Erro ao atualizar suporte!");
            });
            this.$loading(false);
        }
    },  
    components:
    {
        tabelaBase
    }  
};
