import "./DivulgacaoApp.scss";
import http from "@/_helper/api-services";
import CarrosselOpiniao from "@/components/CarrosselOpiniao/CarrosselOpiniao.vue";
import CarrosselScreenshots from "@/components/CarrosselScreenshots/CarrosselScreenshots.vue";
import SliderCelular from "@/components/SliderCelular/SliderCelular.vue";

export default {
    name: "DivulgacaoApp",
    components: {
        CarrosselScreenshots,
        CarrosselOpiniao,
        SliderCelular,
    },
    data() {
        return {
            storeSelected: 0,
            urlAndroid: String,
            urlApple: String,
            imagensSlider: Array,
            imagensCarrossel: Array,
            screenshotsSlider: {
                imagens: [],
            },
            screenshotsCarrossel: {
                items: [
                    // { url: "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png" },
                    // { url: "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png" },
                    // { url: "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png" },
                    // { url: "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png" },
                    // { url: "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png" },
                ],
            },
            dadosCarrosselOpiniao: {
                items: [
                    // {
                    //     estrelas: [
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png")
                    //     ],
                    //     nome: "Thiago que Pula",
                    //     texto: "Lorem ipsum at ipsum ipsum at ipsum lorem Lorem ipsum at ipsum lorem",
                    //     imgUrl: "https://play-lh.googleusercontent.com/a-/AOh14GjKaWoR_tiBnaVlyCPsPF_Gf38IPPma_QgXYdVAzpw=w48-h48-n-rw"
                    // },
                    // {
                    //     estrelas: [
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png")
                    //     ],
                    //     nome: "Anselmo Repula",
                    //     texto: "Lorem ipsum at ipsum lorem Lorem ipsum at ipsulorem Lorem ipsum at ipsum lorem",
                    //     imgUrl: require("@/assets/imgs/Andrea-goncalves.png"),
                    // },
                    // {
                    //     estrelas: [
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png")
                    //     ],
                    //     nome: "Daniel que não pula",
                    //     texto: "Lorem ipsum at ipsum lorem Lorum at ipsum lorem Lorem ipsum at ipsum lorem",
                    //     imgUrl: require("@/assets/imgs/Marcio-lopes.png"),
                    // },
                    // {
                    //     estrelas: [
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png")
                    //     ],
                    //     nome: "Pedrinho",
                    //     texto: "Lorem ipsum at ipsum lorem Lorem ip at ipsum lorem Lorem ipsum at ipsum lorem Lorem ipsum at ipsum lorem",
                    //     imgUrl: require("@/assets/imgs/Rogerio-manhaes.png"),
                    // },
                    // {
                    //     estrelas: [
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png")
                    //     ],
                    //     nome: "Pedrinho",
                    //     texto: "Lorem ipsum at ipsum lorem Lorem ip at ipsum lorem Lorem ipsum at ipsum lorem Lorem ipsum at ipsum lorem",
                    //     imgUrl: require("@/assets/imgs/Rogerio-manhaes.png"),
                    // },
                    // {
                    //     estrelas: [
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png"),
                    //         require("@/assets/imgs/estrela-cinza.png")
                    //     ],
                    //     nome: "Pedrinho",
                    //     texto: "Lorem ipsum at ipsum lorem Lorem ip at ipsum lorem Lorem ipsum at ipsum lorem Lorem ipsum at ipsum lorem",
                    //     imgUrl: require("@/assets/imgs/Rogerio-manhaes.png"),
                    // },
                ],
            },
        };
    },
    async beforeMount() {
        this.$loading(true);
        await this.obterDados();
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        async obterDados() {
            await http.get("/Geral/obterDadosApp/").then(
                (response) => {
                    this.urlAndroid = response.data.urlAndroid;
                    this.urlApple = response.data.urlApple;
                    this.imagensSlider = response.data.imagensSlider;
                    this.imagensCarrossel = response.data.imagensCarrossel;

                    response.data.imagensSlider.forEach((item) => {
                        this.screenshotsSlider.imagens.push({
                            url: item,
                        });
                    });

                    response.data.imagensCarrossel.forEach((item) => {
                        this.screenshotsCarrossel.items.push({
                            url: item,
                        });
                    });

                    response.data.comentarios.forEach((item) => {
                        this.dadosCarrosselOpiniao.items.push({
                            nome: item.nome,
                            texto: item.texto,
                            imgUrl: item.imgUrl,
                            estrelas: [
                                require("@/assets/imgs/estrela-cinza.png"),
                                require("@/assets/imgs/estrela-cinza.png"),
                                require("@/assets/imgs/estrela-cinza.png"),
                                require("@/assets/imgs/estrela-cinza.png"),
                                require("@/assets/imgs/estrela-cinza.png"),
                            ],
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        switchStore(store) {
            this.storeSelected = store;
        },
        redirectStore() {
            if (this.storeSelected == 0) {
                window.open(this.urlAndroid);
            } else {
                window.open(this.urlApple);
            }
        },
        imagemStore() {
            if (this.storeSelected == 0) {
                return require("@/assets/imgs/google_play.jpeg");
            } else {
                return require("@/assets/imgs/app_store.jpeg");
            }
        },
        imagemQRCode() {
            if (this.storeSelected == 0) {
                return require("@/assets/imgs/qr-code-google.png");
            } else {
                return require("@/assets/imgs/qr-code-apple.png");
            }
        },
        copiarLink() {
            const el = document.createElement("textarea");

            if (this.storeSelected == 0) {
                el.value = this.urlAndroid;
            } else {
                el.value = this.urlApple;
            }

            el.setAttribute("readonly", "");
            el.style.position = "absolute";
            el.style.left = "-9999px";
            document.body.appendChild(el);
            const selected =
                document.getSelection().rangeCount > 0
                    ? document.getSelection().getRangeAt(0)
                    : false;
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }

            this.$snotify.success("Link copiado.");
        },
    },
};
