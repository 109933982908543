import "./DetalhamentoPlano.scss";
import http from "@/_helper/api-services.js";
import listagemProdutos from "@/components/ListagemProdutos/listagemProdutos.vue";
import { decimal } from "vuelidate/lib/validators";

export default {
    name: "DetalhamentoPlano",
    components: {
        listagemProdutos
    },
    data() {
        return {
            total: decimal(),
            planoAtivo: null,
            distribuicao: [],
            fields: [
                {
                    key: "descricao",
                    label: "Descrição",
                },
                {
                    key: "valor",
                    label: "Valor",
                },
                {
                    key: "validade",
                    label: "Validade",
                    formatter: (val) => !val ? "" : val + " dias"
                },
            ],
            items: [],
        };
    },
    async beforeMount() {
        await this.getPlanoAtivoUsuario();
        await this.getDistribuicao();
    },
    methods: {
        upgrade()
        {
            window.location.href = "/painel/planos";
        },
        async getPlanoAtivoUsuario() {
            await http.get("/user/obterPlanoAtivo").then(
                success => {
                    if (success.data)
                        this.planoAtivo = success.data.produto;
                },
                () => {
                    this.$snotify.error("Erro ao obter plano ativo.");
                }
            )
        },
        async getDistribuicao()
        {
            await http.get("/user/obterDistribuicao").then(
                success => {
                    
                    if (success.data)
                    {
                        this.distribuicao = success.data.lancamentos;
                        this.total = success.data.total;
                    }
                },
                () => {
                    this.$snotify.error("Erro ao obter plano ativo.");
                }
            );
        }
    }
}