import "./MaterialApoio.scss";
import http from "@/_helper/api-services.js";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "MaterialApoio",
    components: {
        BarraIndicacao,
        tabelaBase,
    },
    data() {
        return {
            mapoio: {
                items: [],
                title: " ",
                fields: [
                    {
                        key: "nome",
                        label: "Nome",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "descricao",
                        label: "Descrição",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                        class: "text-center",
                    }
                ],
                exportExcel: false,
                perPage: 10,
                pageOpitons: [10, 20, 50],
                sortBy: "nome",
                sortDesc: false,
            }
        };
    },
    beforeMount() {
        this.obterMaterial();
    },
    methods: {
        obterMaterial() {
            this.$loading(true);
            http
                .get("/materialapoio/buscarMaterialUsuario")
                .then(
                    (success) => {
                        if (success.data) {
                            this.mapoio.items = success.data;
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        baixarMaterial(urlMaterial) {
            location.href = urlMaterial;
        },

    }
}