import http from "@/_helper/api-services.js";
import Compress from "compress.js";

import "./ConfigTelaApp.scss";

const compress = new Compress();

export default {
  name: "TelaApp",
  components: {},
  props: {
    qtde: { type: Number, default: 3 },
  },
  data() {
    return {
      imagensCarrossel: Array,
      imagensSlider: Array,
      screenshotsSlider: {
        imagens: [],
      },
      screenshotsCarrossel: {
        items: [
          // {
          //   url:
          //     "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png",
          // },
          // {
          //   url:
          //     "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png",
          // },
          // {
          //   url:
          //     "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png",
          // },
          // {
          //   url:
          //     "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png",
          // },
          // {
          //   url:
          //     "https://bigcash.blob.core.windows.net/screenshots-app-slider/forma-foto-app3.png",
          // },
        ],
      },
      imagemRequest: {
        base64: String(),
        nome: String(),
        diretorio: "screenshots-app-carrossel",
      },
      file1: null,
    };
  },

  async beforeMount() {
    this.$loading(true);
    await this.obterDados();
    this.$loading(false);
  },
  async mounted() { },
  methods: {
    uploadImagem() {
      this.$loading(true);
      http.post("/Config/UploadImagem", this.imagemRequest).then(
        (success) => {
          this.$snotify.success("Imagem salva com sucesso.");
          location.reload();
        },
        (error) => {
          //Erro quando a imagem for maior do que o tamanho definido, porém o tamanho ainda não foi escolhido pelo cliente.
          this.$snotify.error("Imagem com resolução não permitida");
        }
      );
      this.$loading(false);
    },

    onSelectFile(evt) {
      let input = this.$refs.fileInput;
      const files = input.files;

      if (files && files[0]) {
        this.imagemRequest.nome = input.files[0].name;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);

        compress
          .attach("#logo", {
            size: 1,
            quality: 0.75,
          })
          .then((data) => {
            this.imagemRequest.base64 = data[0].data;
            this.imageShow = data[0].prefix + data[0].data;
          });
      }
    },
    deletarImagem(item) {
      http.post("/Config/DeletarImagem/", item).then((success) => {
        this.$snotify.success("Imagem excluida com sucesso.");
        location.reload();
      });
    },
    async obterDados() {
      await http.get("/Config/ImagensApp/").then(
        (response) => {
          this.imagensSlider = response.data.imagensS;
          this.imagensCarrossel = response.data.imagensC;

          response.data.imagensSlider.forEach((item) => {
            this.screenshotsSlider.imagens.push({
              url: item,
            });
          });
          response.data.imagensC.forEach((item) => {
            this.screenshotsCarrossel.items.push({
              url: item.url,
              diretorio: item.diretorio,
              nome: item.nome,
            });
          });
        },
        (error) => {
          for (const erro in http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },
  },
};
