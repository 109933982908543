<template>
  <div class="relative mb-0">
    <div class="relative mb-0">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <font-awesome-icon
              style="color: #388695"
              :icon="['fas', 'search']"
            />
          </div>
        </div>
        <input
          type="text"
          :id="id"
          ref="input"
          :value="keyword"
          class="form-control"
          :class="inputClassList"
          :placeholder="placeholder"
          @input="onInput($event.target.value)"
          @blur="onBlur"
          @keydown="onKeydown"
          autocomplete="off"
        />
      </div>
      <!-- <button
        v-if="keyword"
        type="button"
        class="absolute right-0 top-0 inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-400 focus:outline-none hover:text-gray-400"
        @click="onClear()"
      >
        <svg
          class="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path
            stroke-linecap="round"
            stroke-width="1.5"
            d="M1 1l6 6m0-6L1 7"
          />
        </svg>
      </button> -->
    </div>
    <div
      v-show="mutableOptions.length"
      class="absolute right-0 mt-2 w-full rounded-md shadow-lg z-999 overflow-auto"
      style="max-height: 200px"
    >
      <ul class="py-1 rounded-md bg-white shadow-xs">
        <li
          v-for="(opt, index) in mutableOptions"
          :key="opt[valueKey]"
          :ref="`option_${index}`"
          class="autocomplete-item block px-4 py-2 text-sm leading-5 text-gray-700 cursor-pointer"
          :class="{ 'bg-gray-200': arrowCounter === index }"
          tabindex="0"
          @click="onSelect()"
          @mouseover="setArrowCounter(index)"
        >
          <span
            class="font-normal"
            v-html="opt[`${labelKey}_highlighted`] || opt[labelKey]"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AutoComplete",

  props: {
    id: {
      type: String,
      default: "",
    },

    value: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    options: {
      type: Array,
      default: () => [],
    },

    labelKey: {
      type: String,
      default: "label",
    },

    valueKey: {
      type: String,
      default: "id",
    },

    searchMinLength: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      keyword: "",
      arrowCounter: 0,
      originalOptions: [],
      mutableOptions: [],
    };
  },

  computed: {
    inputClassList() {
      return [
        "appearance-none w-full text-uppercase transition duration-150 ease-in-out",
        this.getTextSizeClass,
        this.getTextColorClass,
        this.getBorderColorClass,
        this.getPaddingClass,
      ];
    },

    getTextSizeClass() {
      return "text-sm leading-5";
    },

    getTextColorClass() {
      return "text-gray-800 placeholder-gray-400";
    },

    getBorderColorClass() {
      return "focus:outline-none border border-gray-40 focus:border-blue-400";
    },

    getPaddingClass() {
      return "h-10 pr-6 pl-8";
    },
  },

  watch: {
    value(value) {
      this.keyword = value;
    },

    options() {
      this.cloneOptions();
    },
  },

  created() {
    this.keyword = this.value;

    if (this.options.length) {
      this.cloneOptions();
    }
  },

  methods: {
    removeDiacritics(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
    },
    onInput(vl) {
      this.keyword = this.removeDiacritics(vl);
      this.emitInput();

      if (vl.length >= this.searchMinLength) {
        if (!this.originalOptions.length) {
          this.$emit("shouldSearch", this.removeDiacritics(vl));
        } else {
          this.searchInternally();
        }
      } else {
        this.resetOptions();
      }
    },

    searchInternally() {
      const search = this.keyword;

      this.mutableOptions = this.originalOptions.filter(
        (o) => o[this.labelKey].toLowerCase().search(search.toLowerCase()) >= 0
      );
      this.highlightOptions();
    },

    highlightOptions() {
      const search = this.keyword;
      const query = new RegExp(search, "i");

      this.mutableOptions.forEach((o) => {
        this.$set(
          o,
          `${this.labelKey}_highlighted`,
          o[this.labelKey].replace(query, '<span class="font-bold">$&</span>')
        );
      });
    },

    cloneOptions() {
      this.originalOptions = JSON.parse(JSON.stringify(this.options));
      this.mutableOptions = JSON.parse(JSON.stringify(this.options));
      this.searchInternally();
    },

    resetOptions() {
      this.originalOptions = [];
      this.mutableOptions = [];
    },

    onKeydown(evt) {
      if (!this.mutableOptions.length) {
        return;
      }

      switch (evt.code) {
        case "ArrowDown":
          evt.preventDefault();
          this.onArrowDown();
          break;
        case "ArrowUp":
          evt.preventDefault();
          this.onArrowUp();
          break;
        case "Enter":
          this.onSelect();
          break;
        case "Escape":
          this.onEsc();
          break;
      }
    },

    onEsc() {
      this.$refs.input.blur();
      this.resetArrowCounter();
      this.resetOptions();
    },

    onArrowDown() {
      if (this.arrowCounter < this.mutableOptions.length - 1) {
        this.arrowCounter += 1;
      }

      this.fixScrolling();
    },

    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
      }

      this.fixScrolling();
    },

    onBlur(evt) {
      const tgt = evt.relatedTarget;
      if (tgt && tgt.classList.contains("autocomplete-item")) {
        return;
      }

      this.resetOptions();
      this.resetArrowCounter();
    },

    setArrowCounter(number) {
      this.arrowCounter = number;
    },

    fixScrolling() {
      this.$refs[`option_${this.arrowCounter}`][0].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    },

    resetArrowCounter() {
      this.arrowCounter = 0;
    },

    onSelect() {
      const selected = this.mutableOptions[this.arrowCounter];
      const selectedOption = this.options.find(
        (o) => o[this.valueKey] === selected[this.valueKey]
      );

      if (selectedOption) {
        this.$emit("select", selectedOption);
        this.keyword = selectedOption[this.labelKey];
        this.emitInput();
        this.resetOptions();
        this.resetArrowCounter();
      }
    },

    emitInput() {
      this.$emit("input", this.keyword);
    },

    resetKeyword() {
      this.keyword = "";
      this.emitInput();
    },

    onClear() {
      this.$emit("select", null);
      this.resetKeyword();
      this.resetOptions();
    },
  },
};
</script>

<style scoped>
.z-999 {
  z-index:  999 !important;
}

li > span {
  padding: 2px 10px;
  display: block;
  font-size: .85rem;
}

li > span:hover {
  background-color: #1e5d68;
  cursor: pointer;
  color: white;
  
}

input[type=text]::placeholder {
  text-transform: none;
}
</style>