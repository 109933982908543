import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "minhasFaturas",
    components: { tabelaBase },
    data() {
        return {
            filtroCashback: {
                ateData: new Date(),
            },
            totalCashback: "-",
            pedidosAguardandoFatura: {
                items: [],
                title: "Pedidos com cashback a pagar",
                fields: [
                    {
                        key: "codigo",
                        label: "Codigo",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "dataPedido",
                        label: "DataPedido",
                        sortable: true,
                        class: "text-center",
                        formatter: (val) =>
                            !val ? "" : new Date(val).toLocaleDateString(),
                    },
                    {
                        key: "valorPedido",
                        label: "Valor",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatValue(value),
                    },
                    {
                        key: "meioPagamento",
                        label: "MeioPagamento",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "percentualCashback",
                        label: "PercentualCashback",
                        sortable: true,
                        class: "text-center",
                    },
                ],
                sortBy: "DataPedido",
                sortDesc: false,
                exportExcel: true,
                perPage: 10,
            },
            filtroFatura: {
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                pagina: 1,
                quantidadePorPagina: 5,
                status: null,
            },
            opcoesStatus: [
                { text: "Selecione o status da fatura", value: null },
                { text: "Em aberto", value: 0 },
                { text: "Fechada", value: 1 },
                { text: "Paga", value: 2 },
            ],
            faturas: {
                items: [],
                title: "Pedidos com cashback a pagar",
                fields: [
                    // {
                    //     key: "CashbackAte",
                    //     label: "CashbackAte",
                    //     sortable: true,
                    //     class: "text-center",
                    // },
                    {
                        key: "dataPedido",
                        label: "Data criada",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) =>
                            value ? new Date(value).toLocaleDateString() : "-",
                    },
                    {
                        key: "dataPagamento",
                        label: "Data de pagamento",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) =>
                            value ? new Date(value).toLocaleDateString() : "-",
                    },
                    {
                        key: "dataVencimento",
                        label: "Data de vencimento",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) =>
                            value ? new Date(value).toLocaleDateString() : "-",
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "valorPedido",
                        label: "Valor",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatValue(value),
                    },
                    {
                        key: "Opcoes",
                        label: "Boleto",
                        sortable: true,
                        class: "text-center",
                    },
                ],
                sortBy: "DataPedido",
                sortDesc: false,
                exportExcel: true,
                perPage: 10,
            },
            tipoPagamento: {
                1: "Bitcoin",
                2: "Cristalpay",
                3: "Manual",
                4: "Voucher",
                5: "VoucherComPontos",
                6: "Iugu",
                7: "PagSeguro",
                8: "Pay4You",
                9: "Boleto", // "Pagar.me",
                10: "Saldo",
                11: "Dinheiro",
                12: "Outro",
                13: "Cartão de débito",
                14: "Cartão de crédito",
                15: "PIX",
                16: "TED",
                17: "DOC",
                18: "Boleto",
            },
        };
    },
    async beforeMount() {
        this.$loading(true);
        await this.buscarFaturas();
        this.buscarCashbackNaoPago();
        this.$loading(false);
    },
    methods: {
        async buscarFaturas() {
            this.$loading(true);
            await http.get("/fatura/obterFaturas").then(
                (success) => {
                    this.$loading(false);
                    this.faturas.items = success.data.map((m) => {
                        if (m.status == 4 || m.status == 6) {
                            m.status = "Pago";
                        } else if (m.status == 1) {
                            m.status = "Cancelado";
                        } else if (m.DataVencimento < new Date()) {
                            m.status = "Vencido";
                        } else {
                            m.status = "Aguardando pagamento";
                        }

                        return m;
                    });
                },
                (error) => {
                    this.$loading(false);
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        buscarCashbackNaoPago() {
            this.$loading(true);
            const dataString =
                this.filtroCashback.ateData instanceof Date
                    ? this.filtroCashback.ateData.toISOString()
                    : this.filtroCashback.ateData;
            http.get(
                `/fatura/obterPedidosAguardandoFatura?ateData=${dataString}`
            ).then(
                (success) => {
                    this.$loading(false);
                    this.pedidosAguardandoFatura.items = success.data;
                    this.totalCashback = success.data.reduce(
                        (total, current) =>
                            total +
                            current.valorPedido *
                            (current.percentualCashback / 100),
                        0
                    );
                    if (this.totalCashback) {
                        this.totalCashback = (this.totalCashback + 3.8).toFixed(
                            2
                        );
                    } else {
                        this.totalCashback = "-";
                    }
                },
                (error) => {
                    this.$loading(false);
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        criarFatura() {
            this.$loading(true);
            const model = {
                ateData:
                    this.filtroCashback.ateData instanceof Date
                        ? this.filtroCashback.ateData.toISOString()
                        : this.filtroCashback.ateData,
                tipoPagamento: 9, // pagar.me
            };
            http.post("/fatura/criarFatura?", model).then(
                (success) => {
                    this.$snotify.success("Fatura criada!");
                    this.buscarCashbackNaoPago();
                    this.buscarFaturas();
                },
                (error) => {
                    this.$loading(false);
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        gerarBoleto(idPedido) {
            this.$loading(true);
            const model = {
                IdPedido: idPedido,
                NumeroParcela: 1
            };
            http.post("/pagamento/gerarBoleto", model).then(
                (success) => {
                    this.buscarFaturas();
                    window.open(success.data.urlBoleto, "_blank")
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        formatValue(value) {
            if (!value) return "";
            return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
    },
};
