import "./produtos.scss";
import http from "@/_helper/api-services.js";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import listagemProdutos from "@/components/ListagemProdutos/listagemProdutos.vue";

export default {
    name: "produtos",
    components: {
        BarraIndicacao,
        listagemProdutos
    },
    data() {
        return {
            planoAtivo: null
        };
    },
    async beforeMount() {
        await this.getPlanoAtivoUsuario();
    },
    methods: {
        async getPlanoAtivoUsuario() {
            await http.get("/user/obterPlanoAtivo").then(
                success => {
                    if (success.data)
                        this.planoAtivo = success.data.produto;
                },
                () => {
                    this.$snotify.error("Erro ao obter plano ativo.");
                }
            )
        }
    }
}