import http from "@/_helper/api-services";
import buscaCep from "@/_helper/busca-cep";
import Compress from "compress.js";
import validador from "@/_helper/validacoes";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
const compress = new Compress();

export default {
    name: "maisVendasLogado",
    data() {
        return {
            history: [],
            imageShow: String(),
            fields: [
                {
                    key: "estabelecimento",
                    label: "Estabelecimento",
                    sortable: true,
                },
                { key: "login", label: "Login comerciante", sortable: true },
                { key: "categoria", label: "Categoria", sortable: true },
                { key: "cidade", label: "Cidade", sortable: true },
                { key: "bairro", label: "Bairro", sortable: true },
                { key: "numero", label: "Numero", sortable: true },
                { key: "cnpj", label: "CNPJ", sortable: true },
                { key: "statusDesc", label: "Status", sortable: true },
            ],
            cadastrar: false,
            dddResponsavel: String(),
            novoCredenciamento: {
                estabelecimento: String(),
                email: String(),
                idCategoria: null,
                idEstado: null,
                cep: String(),
                idCidade: null,
                bairro: String(),
                rua: String(),
                numero: String(),
                documento: String(),
                celularContato: String(),
                complemento: String(),
                confirmEmail: String(),
                nomeResponsavel: String(),
                percentualCashback: Number(),
                latitude: String(),
                longitude: String(),
            },
            categorias: [{ text: "Selecione a categoria", value: null }],
            estados: [{ text: "Selecione o estado", value: null }],
            cidades: [{ text: "Selecione a cidade", value: null }],
            status: [{ text: "Todos", value: null }],
            exportFields: {
                Estabelecimento: "estabelecimento",
                "Login credenciado": "login",
                Categoria: "categoria",
                Cidade: "cidade",
                Bairro: "bairro",
                Numero: "numero",
                CNPJ: "cnpj",
                Status: "statusDesc",
            },
            exportTable: [],
            formSearch: {
                idCategoria: null,
                idStatus: null,
                estabelecimento: String(),
            },
        };
    },
    components: {
        BarraIndicacao
    },
    watch: {
        async "novoCredenciamento.idEstado"() {
            if (this.novoCredenciamento.idEstado)
                await this.getCidades(this.novoCredenciamento.idEstado);
        },
    },
    computed: {
        emailValid() {
            if (
                this.novoCredenciamento.email ==
                this.novoCredenciamento.confirmEmail
            )
                return true;
            return false;
        },
        cashbackValid() {
            if (this.novoCredenciamento.percentualCashback > 0) return true;
            return false;
        },
    },
    async beforeMount() {
        this.$loading(true);
        await this.getCredenciamentosUsuario();
        await this.getCategorias();
        await this.getEstados();
        await this.getStatus();
        this.$loading(false);
    },
    methods: {
        validaTelefoneResponsavel() {
            if (
                !validador.telefoneCompletoValido(
                    this.novoCredenciamento.celularContato
                )
            ) {
                this.$snotify.error("Informe o telefone do responsável.");
                return false;
            }

            return true;
        },
        validaCashback() {
            if (
                !validador.cashbackValido(
                    this.novoCredenciamento.percentualCashback
                )
            ) {
                this.$snotify.error(
                    "Percentual de cashback não pode ser menor que 1%."
                );
                return false;
            }

            return true;
        },
        validaCep() {
            if (!this.novoCredenciamento.cep) {
                this.$snotify.error("Informe o CEP.");
                return false;
            }

            if (!validador.cepValido(this.novoCredenciamento.cep)) {
                this.$snotify.error("Informe um CEP válido.");
                return false;
            }
            return true;
        },
        validaEmail() {
            if (!validador.emailFormatoValido(this.novoCredenciamento.email)) {
                this.$snotify.error(
                    'E-mail inválido - E-mails devem ser terminados em: ".com" ou ".com.br"'
                );
                return false;
            }

            if (!validador.emailDominioValido(this.novoCredenciamento.email)) {
                this.$snotify.error(
                    'E-mail inválido - Não são permitidos emails contendo: ".gov", ".gov.br", "hotmail.com", "msn.com", "yahoo.com", "yahoo.com.br", "globo.com", "icloud.com"'
                );
                return false;
            }

            return true;
        },
        checkForm() {
            return (
                this.validaTelefoneResponsavel() &&
                this.validaCep() &&
                this.validaEmail() &&
                this.validaCashback
            );
        },
        async getStatus() {
            await http.get("/Credenciamento/GetStatus").then(
                (success) => {
                    Object.keys(success.data).forEach((s) => {
                        this.status.push({
                            text: s,
                            value: success.data[s],
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async getEstados() {
            await http.get("/Geral/buscaEstado").then((response) => {
                response.data.forEach((element) => {
                    this.estados.push({
                        value: element.idEstado,
                        text: element.nome,
                        key: element.uf,
                    });
                });
            });
        },
        async getCidades(cidade) {
            this.$loading(true);
            this.cidades = [{ text: "Selecione a cidade", value: null }];
            http.get("/Geral/buscaCidade/" + this.novoCredenciamento.idEstado)
                .then(
                    (response) => {
                        response.data.forEach((element) => {
                            if (cidade == element.nome)
                                this.novoCredenciamento.idCidade =
                                    element.idCidade;

                            this.cidades.push({
                                value: element.idCidade,
                                text: element.nome,
                            });
                        });
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async getCategorias() {
            await http.get("/Geral/ObterCategorias").then(
                (success) => {
                    success.data.forEach((s) => {
                        this.categorias.push({
                            value: s.idCategoria,
                            text: s.nome,
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async getCredenciamentosUsuario() {
            this.$loading(true);
            await http
                .post("/Credenciamento/meusCredenciamentos", this.formSearch)
                .then(
                    (success) => {
                        this.history = success.data;
                        this.exportTable = [];
                        for (let i in this.history) {
                            this.exportTable.push(this.history[i]);
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                );
            this.$loading(false);
        },
        buscaCep() {
            if (
                this.novoCredenciamento.cep &&
                this.novoCredenciamento.cep.length == 9
            ) {
                this.$loading(true);

                buscaCep
                    .get(this.novoCredenciamento.cep)
                    .then(
                        (response) => {
                            if (response.data.erro) {
                                this.$snotify.error("CEP não encontrado");
                                return;
                            }
                            this.novoCredenciamento.rua =
                                response.data.logradouro;
                            this.novoCredenciamento.bairro =
                                response.data.bairro;
                            let data = this.estados.filter(
                                (f) => f.key == response.data.uf
                            );
                            this.novoCredenciamento.idEstado = data[0].value;

                            this.getCidades(response.data.localidade);
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => this.$loading(false));
            }
        },
        handleSubmit(event) {
            event.preventDefault();
            this.novoCredenciamento.percentualCashback = parseFloat(
                this.novoCredenciamento.percentualCashback
            );

            if (this.checkForm()) {
                this.$loading(true);
                http.post(
                    "/Credenciamento/CadastroLogado",
                    this.novoCredenciamento
                )
                    .then(
                        () => {
                            this.novoCredenciamento = {
                                estabelecimento: String(),
                                email: String(),
                                idCategoria: null,
                                idEstado: null,
                                cep: String(),
                                idCidade: null,
                                bairro: String(),
                                rua: String(),
                                numero: String(),
                                documento: String(),
                                celularContato: String(),
                                complemento: String(),
                            };
                            this.$snotify.success(
                                "Credenciamento enviado com sucesso."
                            );
                            this.cadastrar = false;
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
            this.$loading(false);
        },
        removeNonNumeric(text) {
            if (!text) return "";

            return text.match(/\d+/g).join("");
        },
        onSelectFile(evt) {
            let input = this.$refs.fileInput;
            const files = input.files;

            if (files && files[0]) {
                if (files[0].size > 1024 * 1024) {
                    this.$snotify.error(
                        "Logo inválida: Arquivo maior do que o permitido (1MB)"
                    );
                } else {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.imageData = e.target.result;
                    };
                    reader.readAsDataURL(files[0]);
                    this.$emit("input", files[0]);

                    compress
                        .attach("#logo", {
                            size: 1,
                            quality: 0.75,
                        })
                        .then((data) => {
                            this.novoCredenciamento.imageBase64 = data[0].data;
                            this.imageShow = data[0].prefix + data[0].data;
                        });
                }
            }
        },
    },
};
