import "./banner.scss";
import { Carousel, Slide } from 'vue-carousel';

export default {
	name: "banner",
	data() {
		return {
		};
	},
	methods: {
		
	},
	components: {
		Carousel,
		Slide
	}
}