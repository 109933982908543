import "./tabelaMovimentacoes.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "tabelaMovimentacoes",
    data() {
        return {
            locale: "pt",
            search: "",
            fields: [
                {
                    key: "tipo",
                    label: "Tipo",
                    sortable: true,
                    sortDirection: "desc",
                },
                {
                    key: "data",
                    label: "Data",
                    sortable: true,
                    class: "text-center",
                    formatter: (value) => {
                        if (value)
                            return `${new Date(
                                value
                            ).toLocaleDateString()} ${new Date(
                                value
                            ).toLocaleTimeString()}`;
                        return "";
                    },
                },
                {
                    key: "descricao",
                    label: "Descrição",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "valor",
                    label: "Valor",
                    sortable: true,
                    // class: "text-center",
                },
                {
                    key: "retido",
                    label: "Retido",
                    sortable: true,
                    class: "text-center",
                },
            ],
            dadosResumoCashBack: {},
            listDados: [],
            listTipoFiltro: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            tipoFilter: null,
            dataInicialFilter: this.dataMenos30(),
            dataFinalFilter: new Date(),
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            exportFields: {
                Tipo: "tipo",
                Data: {
                    field: "data",
                    callback: (value) => {
                        if (value)
                            return `${new Date(
                                value
                            ).toLocaleDateString()} ${new Date(
                                value
                            ).toLocaleTimeString()}`;
                        return "";
                    },
                },
                Descrição: "descricao",
                Valor: "valor",
                Status: "status",
                Retido: "retido",
            },
            exportTable: [],
        };
    },
    async mounted() {
        await this.listarDados();
        await this.tipoFiltro();
    },
    filters: {
        formatMoeda: function(valor) {
            return parseFloat(valor).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return { text: f.label, value: f.key };
                });
        },
    },
    methods: {
        async listarDados(event) {
            if (event) event.preventDefault();

            var filter = {
                dataInicio: this.dataInicialFilter,
                dataFim: this.dataFinalFilter,
                chave: this.tipoFilter,
            };
            this.listDados = [];
            http.post("/Extrato/BuscarExtrato", filter).then(
                (success) => {
                    success.data.forEach((item) => {
                        this.listDados.push({
                            tipo: item.tipo.descricao,
                            data: item.dataLancamento,
                            descricao: item.descricao,
                            valor:
                                item.tipo.chave == "PLCD"
                                    ? "-----"
                                    : item.valor.toLocaleString("pt-br", {
                                          style: "currency",
                                          currency: "BRL",
                                      }),
                            retido:
                                item.lancamentoRetido[0] != null
                                    ? item.lancamentoRetido[0].valor.toLocaleString(
                                          "pt-br",
                                          { style: "currency", currency: "BRL" }
                                      )
                                    : "-----",
                        });
                        this.totalRows = this.listDados.length;
                    });
                    this.exportTable = [];
                    for (let i in this.listDados) {
                        this.exportTable.push(this.listDados[i]);
                    }
                },
                () => {
                    this.$snotify.error("Erro ao obter dados.");
                }
            );
        },
        async tipoFiltro() {
            await http.get("/Geral/tipo/cc").then(
                (success) => {
                    this.listTipoFiltro.push({
                        value: null,
                        text: "Selecione um tipo",
                    });
                    success.data.forEach((item) => {
                        this.listTipoFiltro.push({
                            value: item.chave,
                            text: item.descricao,
                        });
                    });
                },
                () => {
                    this.$snotify.error("Erro ao obter dados.");
                }
            );
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
    },
};
