import "./adicionarSaldo.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "AdicionarSaldo",
    props: {},
    data() {
        return {
            formasPagamento: [
                {
                    value: 9,
                    text: "Pagar.me",
                },
            ],
            taxa: NaN,
            form: {
                valor10: 0,
                valor25: 0,
                valor50: 0,
                formaPagamento: 9,
                aceiteTermos: false,
            },
        };
    },
    computed: {
        valor() {
            const valor =
                this.form.valor10 * 10 +
                this.form.valor25 * 25 +
                this.form.valor50 * 50;
            return valor ? valor + this.taxaCompra : undefined;
        },
        taxaCompra() {
            // if (this.valor && this.taxa) {
            //     return this.valor * this.taxa;
            // } else {
            //     return undefined;
            // }
            return this.taxa;
        },
        saldoReceber() {
            if (this.valor && this.taxaCompra) {
                return this.valor - this.taxaCompra;
            } else {
                return undefined;
            }
        },
    },
    beforeMount() {
        this.buscarTaxa();
    },
    methods: {
        adquirirSaldo() {
            this.$loading(true);
            http.post("/Pedidos/adquirirSaldo", {
                ...this.form,
                saldoReceber: this.saldoReceber,
            }).then(
                (success) => {
                    this.$loading(false);
                    if (success.data) {
                        location.href = success.data.urlBoleto;
                    }
                },
                (error) => {
                    this.$loading(false);
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        adiciona10() {
            this.form.valor10++;
        },
        adiciona25() {
            this.form.valor25++;
        },
        adiciona50() {
            this.form.valor50++;
        },
        handleReset() {
            this.form.valor10 = this.form.valor25 = this.form.valor50 = 0;
            this.form.valor = undefined;
            this.form.formaPagamento = 9;
            this.form.aceiteTermos = false;
        },
        buscarTaxa() {
            http.get("/Pedidos/taxaSaldo").then(
                (success) => {
                    if (success.data) {
                        this.taxa = success.data;
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
    },
};
