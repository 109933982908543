import "./PerfilPainel.scss";
import http from "@/_helper/api-services";

export default {
    name: "PerfilPainel",
    props: ["propBarraStatus"],
    data() {
        return {
            dadosPerfilPainel: {
                planoAtivo: String(),
                pessoasAtivas: Number(),
                ganho: Number(),
                saques: Number(),
                investimento: Number(),
                indireto: Number(),
                total: Number(),
            },
        };
    },
    watch: {
        propBarraStatus(el) {
            if (el.idUsuario == null || el.idUsuario == undefined)
                this.obterPerfilPainel();
            else this.dadosPerfilPainel = el;
        },
    },
    async beforeMount() {
        if (!this.$props.propBarraStatus) {
            await this.obterPerfilPainel();
        }
    },
    methods: {
        obterPerfilPainel() {
            this.$loading(true);
            http.get("/user/obterPerfilPainel/").then(
                (response) => {
                    this.dadosPerfilPainel = response.data;
                    this.$loading(false);
                },
                () => {
                    this.$snotify.error("Erro ao obter dados.");
                }
            );
        },
    },
};
