import http from "@/_helper/api-services";
import "./DadosBancarios.scss";

export default {
    name: "DadosBancarios",
    data() {
        return {
            fields: [
                {
                    key: "nomeConta",
                    label: "Nome",
                    sortable: true,
                    sortDirection: "desc",
                },
                { key: "banco", label: "Banco", sortable: true },
                {
                    key: "agencia",
                    label: "Agência",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "cpfCnpj",
                    label: "CPF/CNPJ",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "conta",
                    label: "Conta",
                    sortable: true,
                    class: "text-center azul",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    sortable: true,
                    class: "text-center azul",
                },
                { key: "opcoes", label: "", sortable: false },
            ],
            idContaExclusao: null,
            listDados: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            tipoConta: [],
            opcoesDeBanco: [{ value: null, text: "Banco" }],
            usuarioBanco: {
                IdUsuarioBanco: 0,
                IdBanco: null,
                Agencia: null,
                NomeConta: String(),
                DigitoAgencia: null,
                Conta: null,
                DigitoConta: null,
                CpfCnpj: "",
                IdTipo: null,
            },
            erroForm: false,
            exportFields: {
                Nome: "nomeConta",
                Banco: "banco",
                Agência: "agencia",
                "CPF/CNP": "cpfCnpj",
                Conta: "conta",
                Tipo: "tipo",
            },
            exportTable: [],
        };
    },
    components: {},
    async beforeMount() {
        this.$loading(true);
        await this.obterBancos();
        await this.obterTipoConta();
        await this.obterDadosBancarios();
        this.$loading(false);
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async obterBancos() {
            await http.get("/Banco/listarBancos/").then((response) => {
                response.data.forEach((item) => {
                    this.opcoesDeBanco.push({
                        value: item.idBanco,
                        text:
                            ("000" + item.febraban.toString()).slice(-3) +
                            " - " +
                            item.nome.trim(),
                    });
                });
            });
        },
        async obterTipoConta() {
            await http.get("/Geral/tipo/TPC").then((response) => {
                response.data.forEach((item) => {
                    this.tipoConta.push({
                        value: item.idTipo,
                        text: item.descricao,
                    });
                });

                this.usuarioBanco.IdTipo = response.data[0].idTipo;
            });
        },
        async obterDadosBancarios() {
            await http
                .get("/UsuarioBanco/obterDadosBancarios/")
                .then((response) => {
                    this.listDados = [];
                    response.data.forEach((item) => {
                        this.listDados.push({
                            banco: item.banco.nome,
                            agencia: `${item.agencia}${
                                item.digitoAgencia
                                    ? "-" + item.digitoAgencia
                                    : ""
                            }`,
                            conta: item.conta + "-" + item.digitoConta,
                            tipo: item.tipo.descricao,
                            cpfCnpj: item.cpfcnpj,
                            nomeConta: item.nomeConta,
                            idUsuarioBanco: item.idUsuarioBanco,
                        });
                    });
                    this.exportTable = [];
                    for (let i in this.listDados) {
                        this.exportTable.push(this.listDados[i]);
                    }
                });
        },
        salvarUsuarioBanco(event) {
            event.preventDefault();

            if (this.checkForm()) {
                this.$loading(true);
                http.post(
                    "/UsuarioBanco/cadastrarUsuarioBanco",
                    this.usuarioBanco
                )
                    .then(
                        (response) => {
                            if (response.data == true) {
                                this.$snotify.success(
                                    "Banco cadastrado com sucesso."
                                );
                                this.obterDadosBancarios();
                                this.usuarioBanco = {
                                    IdUsuarioBanco: 0,
                                    IdBanco: null,
                                    Agencia: null,
                                    NomeConta: String(),
                                    DigitoAgencia: null,
                                    Conta: null,
                                    DigitoConta: null,
                                    CpfCnpj: "",
                                    IdTipo: null,
                                };
                            }
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(
                                error,
                                "Erro ao cadastrar banco."
                            )) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => this.$loading(false));
            }
        },
        checkForm() {
            if (
                !this.usuarioBanco.IdBanco ||
                !this.usuarioBanco.Agencia ||
                this.usuarioBanco.Agencia.length < 4 ||
                !this.usuarioBanco.Conta ||
                !this.usuarioBanco.DigitoConta ||
                !this.usuarioBanco.CpfCnpj ||
                (!this.usuarioBanco.CpfCnpj.length == 14 &&
                    !this.usuarioBanco.CpfCnpj.length == 18) ||
                !this.usuarioBanco.IdTipo
            ) {
                this.erroForm = true;
                return false;
            }

            return true;
        },
        handleReset() {
            this.usuarioBanco.CpfCnpj = this.usuarioBanco.Agencia = this.usuarioBanco.DigitoAgencia = this.usuarioBanco.Conta = this.usuarioBanco.DigitoConta =
                "";
        },
        modalConfirmarExclusao(idUsuarioBanco) {
            this.idContaExclusao = idUsuarioBanco;
            this.$bvModal.show("confirmar-exclusao");
        },
        excluirContaBancaria(idUsuarioBanco) {
            this.$loading(true);
            http.delete(`/UsuarioBanco/ExcluirContaBancaria/${idUsuarioBanco}`)
                .then(
                    () => {
                        this.$snotify.success("Conta excluida com sucesso.");
                        this.obterDadosBancarios();
                    },
                    () => {
                        this.$snotify.error("Erro ao excluir conta.");
                    }
                )
                .finally(() => {
                    this.$loading(false);
                    this.$bvModal.hide("confirmar-exclusao");
                });
        },
        goBack() {
            return this.$router.go(-1);
        },
    },
};
