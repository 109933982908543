import "./historicoSaque.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "historicoSaque",
    data() {
        return {
            fields: [
                {
                    key: "nomeConta",
                    label: "Conta",
                    sortable: true,
                    sortDirection: "desc",
                },
                {
                    key: "dataSolicitacao",
                    label: "Data solicitação",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `${new Date(
                            value
                        ).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`;
                    },
                },
                {
                    key: "dataAprovacao",
                    label: "Data aprovação",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `${new Date(
                            value
                        ).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`;
                    },
                },
                { key: "status", label: "Status", sortable: true },
                {
                    key: "valor",
                    label: "Valor",
                    sortable: true,
                    formatter: (value) => {
                        return value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        });
                    },
                },
            ],
            history: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            exportFields: {
                Conta: "nomeConta",
                "Data solicitação": "dataSolicitacao",
                "Data aprovação": "dataAprovacao",
                Status: "status",
                Valor: "valor",
            },
            exportTable: [],
        };
    },
    props: ["auxReload"],
    watch: {
        auxReload() {
            this.getWithdrawalHistory();
        },
    },
    beforeMount() {
        this.getWithdrawalHistory();
    },
    methods: {
        getWithdrawalHistory() {
            http.get("/saque/saquesSolicitados").then(
                (success) => {
                    this.history = [];
                    if (success.data) {
                        success.data.forEach((d) => {
                            this.history.push({
                                dataAprovacao: d.dataAprovacao,
                                dataSolicitacao: d.dataSolicitacao,
                                status: d.status,
                                valor: d.valor,
                                nomeConta: d.nomeConta,
                            });
                        });
                        this.exportTable = [];
                        for (let i in this.history) {
                            this.exportTable.push(this.history[i]);
                        }
                        this.totalRows = success.data.length;
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(
                        error,
                        "Erro ao buscar histórico"
                    )) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
    },
};
