import http from "@/_helper/api-services";
import tabelaBase from "../TabelaBase/tabelaBase.vue";

export default {
    name: "detalhesCompra",
    components: { tabelaBase },
    props: {
        codigo: String,
    },
    data() {
        return {
            pedido: {},
            parcelas: {
                items: [],
                title: "Parcelas",
                fields: [
                    {
                        key: "dataValidade",
                        label: "Validade",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "dataPagamento",
                        label: "Data do pagamento",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "valorParcela",
                        label: "Valor",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatMoney(value),
                    },
                    {
                        key: "url",
                        label: "Boleto",
                        sortable: true,
                        class: "text-center",
                    },
                ],
                sortBy: "NumeroParcela",
                sortDesc: false,
                exportExcel: false,
                perPage: null,
            },
        };
    },
    methods: {
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        formatMoney(value) {
            if (value) {
                return value.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                });
            }
        },
        buscarDetalhes() {
            http.get(`/Pedidos/detalhes/${this.$props.codigo}`).then(
                (response) => {
                    this.pedido = response.data;
                    this.parcelas.items = response.data.parcelas;
                }
            );
        },
        buscarDistribuicaoCashback() {
            http.get(
                `/Pedidos/obterDistribuicaoCashback/${this.idPedido}`
            ).then(
                (success) => {
                    if (success.data) {
                        (this.produto = success.data.produto), this;

                        this.distribuicaoCompra.items = response.data;
                    }
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        gerarBoleto(numeroParcela) {
            this.$loading(true);
            const model = {
                IdPedido: this.pedido.idPedido,
                NumeroParcela: numeroParcela
            };
            http.post("/pagamento/gerarBoleto", model).then(
                (success) => {
                    this.buscarFaturas();
                    window.open(success.data.urlBoleto, "_blank");
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
    },
    beforeMount() {
        this.buscarDetalhes();
    },
};
