import http from "@/_helper/api-services";
import TreeviewItem from "@/components/TreeviewItem/TreeviewItem.vue";

export default {
  name: "Rede",
  data() {
    return {
      listDados: [],
      filter: String(),
      filtro: String(),
      exportFields: {
        Nível: "nivel",
        Login: "login",
        "Login Filho": "loginFilho",
        Nome: "nome",
        "E-mail": "email",
        Celular: "celular",
        Graduação: "graduacao",
        "Data Cadastro": "dataCadastro",
        Pontuação: "pontuacao",
      },
      exportTable: [],
    };
  },
  components: {
    TreeviewItem,
  },
  computed: {
    listaFilter: function () {
      return this.listDados.filter(
        (f) => f.nome.toLowerCase().indexOf(this.filter.toLowerCase()) != -1
      );
    },
  },
  filters: {
    dataFormatada: function (valor) {
      return moment(String(valor)).format("DD/MM/YYYY");
    },
  },
  methods: {
    listarDados() {
      this.$loading(true);
      this.listDados = [];

      http.get("/Admin/obterRedeUsuarioEspecifico/" + this.filtro).then(
        (responde) => {
          responde.data.forEach((item) => {
            this.listDados.push({
              idUsuario: item.idUsuario,
              nome: item.nome,
              login: item.login,
              graduacao: item.graduacao,
              celular: item.celular,
              email: item.email,
              urlImg: item.urlImg,
              temFilhos: item.temFilhos,
              pontuacao: item.pontuacao,
            });
          });

          http
            .get("/Admin/ObterRedeUsuarioEspecificoExport/" + this.filtro)
            .then(
              (respondeExport) => {
                this.exportTable = [];
                for (let i in respondeExport.data) {
                  this.exportTable.push(respondeExport.data[i]);
                }
              },
              () => {
                this.$loading(false);
              }
            );
          this.$loading(false);
        },
        () => {
          this.$loading(false);
        }
      );
    },
  },
};
