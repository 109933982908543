import "./resumoAssinaturas.scss";
import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
  name: "resumoAssinaturas",
  components: {
    tabelaBase,
  },
  data() {
    return {
      minhasAssinaturas: {
        title: "Resumo de Assinaturas",
        // items: [{ codigo: 'pedidão', status: 'emPagamento', valorPedido: 10, dataPedido: new Date(), pago: true, urlBoleto: 'www.google.com', acoes:['soliciarCancelamento','solicitarReabertura'], }],
        fields: [
          {
            key: "dataPedido",
            label: "Data Pedido",
            sortable: true,
            class: "text-center",
            formatter: (value) => this.formatDate(value),
          },
          {
            key: "nomeProduto",
            label: "Produto",
            sortable: true,
            class: "text-center",
            sortDirection: "desc",
          },
          {
            key: "valorPedido",
            label: "Valor",
            sortable: true,
            class: "text-center",
            formatter: (value) => this.formatMoney(value),
          },
          {
            key: "pago",
            label: "Status",
            sortable: true,
            sortDirection: "desc",
            class: "text-center",
          },
          {
            key: "acoes",
            label: "Ações",
            sortable: false,
            class: "text-center",
          },
        ],
        sortBy: "dataPedido",
        sortDesc: true,
        exportExcel: false,
        perPage: null,
      },
    };
  },
  props: {
    categoria: String(),
  },
  async beforeMount() {
    this.getPurchaseHistory();
  },
  methods: {
    formatDate(data) {
      let dateTime = new Date(data);
      return (
        dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
      );
    },
    formatMoney(value) {
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    getPurchaseHistory() {
      http.get(`/Pedidos/listaPedidosNovo?categoria=${this.categoria}`).then(
        (success) => {
          this.minhasAssinaturas.items = success.data;
          for (var item of this.minhasAssinaturas.items) {
            item.acoes = [];
            if (
              !item.pago &&
              item.pagamentos.some((pagamento) => pagamento.pago)
            ) {
              if (item.ativo) {
                // Em parcelamento
                item.acoes.push("soliciarCancelamento");
              } else if (!item.cancelado) {
                // Inativo
                item.acoes.push("solicitarReabertura");
              }
            }
          }
          this.minhasAssinaturas = { ...this.minhasAssinaturas };
          console.log("minhas compras=>", this.minhasAssinaturas);
        },
        (/*error*/) => {
          this.$snotify.error("Erro ao obter o resumo de assinaturas.");
        }
      );
    },
    redirectDetalhes(url) {
      window.location.href = url;
    },
  },
};
