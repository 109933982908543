import "./comunicados.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "comunicados",
    data() {
        return {
            fields: [
                // { key: "icone", label: "Ícone", sortable: false },
                { key: "titulo", label: "Titulo", sortable: true },
                {
                    key: "dataInicio",
                    label: "Data inicio",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `${new Date(
                            value
                        ).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`;
                    },
                },
                {
                    key: "dataFim",
                    label: "Data fim",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `${new Date(
                            value
                        ).toLocaleDateString()} ${new Date(
                            value
                        ).toLocaleTimeString()}`;
                    },
                },
                { key: "actions", label: "", sortable: false },
            ],
            items: Array(),
            perPage: 5,
            perPageOptions: [5, 10, 15],
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
        };
    },
    beforeMount() {
        this.getComunicados();
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        getComunicados() {
            http.get("/Comunicado/ListaComunicados").then(
                (success) => {
                    if (success && success.data)
                        success.data.forEach((d) => {
                            this.items.push({
                                idMensagem: d.idMensagem,
                                titulo: d.titulo,
                                dataInicio: d.dataInicio,
                                dataFim: d.dataFim,
                                icone: d.urlArquivo,
                                ativo: d.ativo,
                            });
                        });
                },
                (error) => {
                    for (const erro of http.mensagensErro(
                        error,
                        "Ocorreu um erro ao obter os comunicados."
                    )) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        ativaDesativaComunicado(idMensagem) {
            this.$loading(true);
            http.update("/Comunicado/AtivarDesativarMensagem", { idMensagem })
                .then(
                    (success) => {
                        this.$snotify.success(success.data.message);
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(
                            error,
                            "Ocorreu um erro ao atualizar o comunicado"
                        )) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        comunicadoSelecionado(record) {
            this.$router.push(
                `/painel/admin/comunicados/cadastrar/${record.idMensagem}`
            );
        },
    },
};
