import "./BarraIndicacao.scss";
import http from '@/_helper/api-services';

export default {
    name: 'BarraIndicacao',
    props: ['dadosUsuario'],
    data() {
        return {
            dadosUsuario: {},
            BarraIndicacao: 'https://bigcash.me/',
            value: 45,
            max: 100
        }
    },
    mounted() {

    },
    beforeMount() {
        this.obterDadosUsuario();
    },
    methods: {
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/obterBarraStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
                this.$loading(false);
            });
        },
        onCopy(e) {
            this.$snotify.success("Link copiado com sucesso.");
        },
        onError(e) {
            this.$snotify.error("Ocorreu um erro inesperado.");
        },
        alterarBinario(posicao) {
            let model = {
                posicaoBinario: posicao
            };

            if (this.$props.dadosUsuario.posicaoBinario != posicao) {
                this.$loading(true);
                http.update('/Rede/alterarPosicaoBinario/', model).then((responde) => {
                    this.$props.dadosUsuario.posicaoBinario = posicao;
                    this.$loading(false);
                });
            }
        }
    }
}