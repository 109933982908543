import http from "@/_helper/api-services.js";
import buscaCep from "@/_helper/busca-cep";
import Compress from "compress.js";
import validador from "@/_helper/validacoes";
import Axios from "axios";
import axios from "axios";
const compress = new Compress();

export default {
    name: "DadosCredenciamento",
    data() {
        return {
            dadosCredenciamento: Object(),
            estados: [],
            categorias: [],
            cidades: [],
            nome: '',
            tipo: '',
            localizacao: '',
            caracteristicas: '',
        };
    },
    async mounted() {
        this.$loading(true);
        await this.getCategorias();
        await this.buscarEstados();
        await this.obterDadosCredenciamento();
        this.$loading(false);
    },
    watch: {
        "dadosCredenciamento.idEstado"() {
            this.buscarCidades(true, this.dadosCredenciamento.idCidade);
        },
    },
    methods: {
        validaTelefoneResponsavel() {
            if (
                !validador.telefoneCompletoValido(
                    this.dadosCredenciamento.celularContato
                )
            ) {
                this.$snotify.error("Informe o telefone do responsável.");
                return false;
            }

            return true;
        },
        validaTelefoneEstabelecimento() {
            if (
                !validador.telefoneCompletoValido(
                    this.dadosCredenciamento.telefone
                )
            ) {
                this.$snotify.error("Informe o telefone do estabelecimento.");
                return false;
            }

            return true;
        },
        validaCashback() {
            if (
                !validador.cashbackValido(
                    this.dadosCredenciamento.percentualCashback
                )
            ) {
                this.$snotify.error(
                    "Percentual de cashback não pode ser menor que 1%."
                );
                return false;
            }

            return true;
        },
        validaCep() {
            if (!this.dadosCredenciamento.cep) {
                this.$snotify.error("Informe o CEP.");
                return false;
            }

            if (!validador.cepValido(this.dadosCredenciamento.cep)) {
                this.$snotify.error("Informe um CEP válido.");
                return false;
            }
            return true;
        },
        validaCNPJ() {
            if (!validador.cnpjValido(this.dadosCredenciamento.cnpj)) {
                this.$snotify.error("Informe um CPF/CNPJ válido.");
                return false;
            }

            return true;
        },
        validaEmail() {
            if (!validador.emailFormatoValido(this.dadosCredenciamento.email)) {
                this.$snotify.error(
                    'E-mail inválido - E-mails devem ser terminados em: ".com" ou ".com.br"'
                );
                return false;
            }

            if (!validador.emailDominioValido(this.dadosCredenciamento.email)) {
                this.$snotify.error(
                    'E-mail inválido - Não são permitidos emails contendo: ".gov", ".gov.br", "hotmail.com", "msn.com", "yahoo.com", "yahoo.com.br", "globo.com", "icloud.com"'
                );
                return false;
            }

            return true;
        },
        checkForm() {
            return (
                this.validaTelefoneEstabelecimento() &&
                this.validaTelefoneResponsavel() &&
                this.validaCNPJ() &&
                this.validaCep() &&
                this.validaEmail() &&
                this.validaCashback
            );
        },
        formatValue(value) {
            return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        async getCategorias() {
            await http.get("/Geral/ObterCategorias").then(
                (success) => {
                    success.data.forEach((s) => {
                        this.categorias.push({
                            value: s.idCategoria,
                            text: s.nome,
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async obterDadosCredenciamento() {
            await http.get("/Credenciamento/ObterDadosCredenciamento").then(
                (response) => {
                    this.dadosCredenciamento = response.data;
                    this.dadosCredenciamento.dataCadastro = this
                        .dadosCredenciamento.dataCadastro
                        ? `${new Date(
                            this.dadosCredenciamento.dataCadastro
                        ).toLocaleDateString()}`
                        : "";
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async buscarEstados() {
            await http.get("/Geral/buscaEstado").then((response) => {
                response.data.forEach((element) => {
                    this.estados.push({
                        value: element.idEstado,
                        text: element.nome,
                        key: element.uf,
                    });
                });
            });
        },
        buscaCep() {
            if (this.dadosCredenciamento.cep) {
                this.$loading(true);

                buscaCep
                    .get(this.dadosCredenciamento.cep)
                    .then(
                        (response) => {
                            if (response.data.erro) {
                                this.$snotify.error("CEP não encontrado");
                                return;
                            }
                            this.dadosCredenciamento.rua =
                                response.data.logradouro;
                            this.dadosCredenciamento.bairro =
                                response.data.bairro;
                            let data = this.estados.filter(
                                (f) => f.key == response.data.uf
                            );
                            if (data.length > 0) {
                                this.dadosCredenciamento.idEstado =
                                    data[0].value;
                                this.buscarCidades(
                                    true,
                                    response.data.localidade
                                );
                            }
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => this.$loading(false));
            }
        },
        buscarCidades(setarCidade, cidade) {
            // this.dadosCredenciamento.idCidade = null;
            this.$loading(true);

            if (this.dadosCredenciamento.idEstado) {
                http.get(
                    "/Geral/buscaCidade/" + this.dadosCredenciamento.idEstado
                )
                    .then(
                        (response) => {
                            response.data.forEach((element) => {
                                if (setarCidade && element.nome == cidade)
                                    this.dadosCredenciamento.idCidade =
                                        element.idCidade;

                                this.cidades.push({
                                    value: element.idCidade,
                                    text: element.nome,
                                });
                            });
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        onSelectFile(evt) {
            let input = this.$refs.fileInput;
            const files = input.files;

            if (files && files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageData = e.target.result;
                };
                reader.readAsDataURL(files[0]);
                this.$emit("input", files[0]);

                compress
                    .attach("#logo", {
                        size: 1,
                        quality: 0.75,
                    })
                    .then((data) => {
                        this.dadosCredenciamento.imageBase64 = data[0].data;
                        this.imageShow = data[0].prefix + data[0].data;
                    });
            }
        },
        checarForm() {
            if (
                !this.dadosCredenciamento.celularContato ||
                this.removeNonNumeric(this.dadosCredenciamento.celularContato)
                    .length < 10
            ) {
                this.$snotify.error("Informe o telefone.");
                return false;
            }

            if (
                !this.dadosCredenciamento.telefone ||
                this.removeNonNumeric(
                    this.dadosCredenciamento.telefone
                ).length < 10
            ) {
                this.$snotify.error("Informe o telefone.");
                return false;
            }

            if (this.dadosCredenciamento.percentualCashback <= 0) {
                this.$snotify.error(
                    "Percentual de cashback não pode ser igual ou menor que zero."
                );
                return false;
            }

            return true;
        },
        removeNonNumeric(text) {
            if (!text) return "";

            return text.match(/\d+/g).join("");
        },
        async submit() {
            this.$loading(true);
            this.dadosCredenciamento.percentualCashback = parseFloat(
                this.dadosCredenciamento.percentualCashback
            );
            if (this.checkForm()) {
                await http
                    .update(
                        "/Credenciamento/AtualizarDadosCredenciamento",
                        this.dadosCredenciamento
                    )
                    .then(
                        (success) => {
                            let user = this.$store.getters[
                                "autenticacao/getLogin"
                            ].user;
                            user.perfil = "C";
                            user.logoUrl = success.data.url;
                            localStorage.removeItem("user");
                            localStorage.setItem("user", JSON.stringify(user));

                            this.$snotify.success(
                                "Credenciamento atualizado com sucesso."
                            );
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(
                                error,
                                "Falha ao atualizar dados."
                            )) {
                                this.$snotify.error(erro);
                            }

                            this.$loading(false);
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                        }
                    );
            }
            else {
                this.$loading(false);
            }
        },
        async gerarDescricoes() {
            this.closeModal()
            this.$loading(true);
            const apiKey = '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';// Substitua pela sua chave de API
            console.log(apiKey);
          
            const prompt = `
              Forneça uma breve descrição e uma descrição completa sobre o estabelecimento a seguir. 
              Inclua palavras-chave relevantes.
          
              Nome do estabelecimento: ${this.nome}
              Tipo: ${this.tipo}
              Localização: ${this.localizacao}
              Características: ${this.caracteristicas}
          
              Breve Descrição:
              Descrição Completa:
            `;
          
            try {
              const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                  model: 'gpt-4',
                  messages: [{ role: 'user', content: prompt }],
                  max_tokens: 500,
                  n: 1,
                  stop: null,
                  temperature: 0.7,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${apiKey}`,
                  },
                }
              );
          
              const descricaoGerada = response.data.choices[0].message.content.trim();
              const partes = descricaoGerada.split('\n\n');
              this.dadosCredenciamento.breveDescricao = partes[0].replace('Breve Descrição:', '').trim();
              this.dadosCredenciamento.descricaoCompleta = partes[1].replace('Descrição Completa:', '').trim();
              console.log(descricaoGerada);
              this.$loading(false);
            } catch (error) {
                this.$loading(false);
              console.error('Erro:', error);
            }
            this.$loading(false);
          },
          openModal() {
            this.$bvModal.show("modal-descricoes");
          },
          closeModal() {
            this.$bvModal.hide("modal-descricoes");
          },
    },
};
