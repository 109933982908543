import "./formRecuperarSenha.scss";
import usuarioService from "@/_helper/usuario-service.js";

export default {
  name: "formRecuperarSenha",
  data() {
    return {
      email: String(),
      emailSent: false,
      emailSentMessage: String(),
      error: false,
      errorMessage: String(),
    };
  },
  methods: {
    handleSubmit(event) {
      this.$loading(true);
      event.preventDefault();
      this.error = false;
      usuarioService
        .resetPassword(this.email)
        .then(
          (success) => {
            this.emailSent = true;
            this.emailSentMessage = success.data.message;
          },
          (error) => {
            for (const erro of http.mensagensErro(error)) {
              this.$snotify.error(erro);
            }
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    switchTo(action) {
      this.$emit("switchPanel", action);
    },
  },
};
