var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"carrossel"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"p-0 size-banner",class:_vm.calculaPadding(),attrs:{"cols":"12"}},[_c('b-row',{staticClass:"justify-content-center",style:({ 
              'background-color': _vm.corFundo, 
              'width': (_vm.size) + 'px !important'
            })},[_c('h3',{staticClass:"pt-3 carrossel-nome"},[_vm._v(_vm._s(_vm.nome))]),_c('carousel',{key:"windowWidth",staticClass:"h-100 align-center justify-content-center",style:({ 
              'background-color': _vm.corFundo, 
              'width': (_vm.size) + 'px !important', 
            }),attrs:{"autoplay":true,"mouseDrag":false,"touchDrag":false,"dots":true,"items":_vm.numCards}},_vm._l((_vm.anunciantes),function(anunciante){return _c('div',{key:anunciante.idAnunciante,staticClass:"mt-3 align-items-center item-banner"},[_c('BoxLoja',_vm._b({},'BoxLoja',anunciante,false))],1)}),0)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }