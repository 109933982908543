import http from "@/_helper/api-services.js";
import "./ParceiroDireto.scss";
import BarraIndicacao from "@/components/BarraIndicacao/BarraIndicacao.vue";
import graduacao from "@/components/Graduacao/Graduacao.vue";
export default {
  name: "ParceiroDireto",
  data() {
    return {
      dadosUser: this.$store.getters["autenticacao/getLogin"].user,
      fields: [
        {
          key: "login",
          label: "Login",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "graduacao",
          label: "Graduação",
          sortable: true,
          class: "text-center",
        },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
          class: "text-center",
        },
        {
          key: "pontuacao",
          label: "Pontuação",
          sortable: true,
          class: "text-center",
        },
        {
          key: "dataCadastro",
          label: "Data Cadastro",
          sortable: true,
          class: "text-center",
        },
      ],
      listDados: [],
      totalRows: 1,
      currentPage: 1,
      filter: String(),
      perPage: 10,
      pageOptions: [10, 20, 50],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      exportFields: {
        Login: "login",
        Graduação: "graduacao",
        "E-mail": "email",
        "Data Cadastro": "dataCadastro",
      },
      exportTable: [],
    };
  },
  components: {
    BarraIndicacao,
    graduacao,
  },
  mounted() {
    this.listarDados();
  },
  methods: {
    listarDados() {
      this.$loading(true);
      http.get("/user/diretosUsaurioLogado").then(
        (success) => {
          if (success.data.length > 0)
            success.data.forEach((item) => {
              this.listDados.push({
                login: item.login,
                graduacao: item.graduacao != null ? item.graduacao.nome : "",
                email: item.email,
                dataCadastro: this.formatDate(item.dataCadastro),
                pontuacao: item.pontuacao,
              });
              this.exportTable = [];
              for (let i in this.listDados) {
                this.exportTable.push(this.listDados[i]);
              }
            });

          this.totalRows = this.listDados.length;
          this.$loading(false);
        },
        () => {
          this.$snotify.error("Erro ao obter dados.");
        }
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(date, showTime) {
      if (!showTime) return new Date(date).toLocaleDateString();
      else
        return `${new Date(date).toLocaleDateString()} - ás ${new Date(
          date
        ).toLocaleTimeString()}`;
    },
  },
};
