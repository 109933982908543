export default {
    emailDominioValido(email) {
        if (email.includes(".gov") ||
            email.includes(".gov.br") ||
            email.includes("hotmail.com") ||
            email.includes("msn.com") ||
            email.includes("yahoo.com") ||
            email.includes("yahoo.com.br") ||
            email.includes("globo.com") ||
            email.includes("icloud.com")) {
            return false;
        }

        return true;
    },
    emailFormatoValido(email) {
        if (!email)
            return false;

        if (!email.includes(".com") || !email.includes("@")) {
            return false;
        }

        return true;
    },
    cepValido(cep) {
        if (!cep || cep.length < 8) {
            return false;
        }
        return true;
    },
    cpfValido(cpf) {
        var Soma = 0
        var Resto

        var strCPF = String(cpf).replace(/[^\d]/g, '')

        if (strCPF.length !== 11)
            return false

        if ([
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999',
        ].indexOf(strCPF) !== -1)
            return false

        for (let i = 1; i <= 9; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

        Resto = (Soma * 10) % 11

        if ((Resto == 10) || (Resto == 11))
            Resto = 0

        if (Resto != parseInt(strCPF.substring(9, 10)))
            return false

        Soma = 0

        for (let i = 1; i <= 10; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)

        Resto = (Soma * 10) % 11

        if ((Resto == 10) || (Resto == 11))
            Resto = 0

        if (Resto != parseInt(strCPF.substring(10, 11)))
            return false

        return true
    },
    cnpjValido(cnpj) {
        if (!cnpj)
            return false;

        let testCnpj, tamanho, numeros, digitos, soma, pos, resultado;
        if (cnpj != undefined && cnpj.replace(/[^\d]+/g, '').length == 14) {
            testCnpj = cnpj.replace(/[^\d]+/g, '');

            // Elimina CNPJs invalidos conhecidos
            if (testCnpj == "00000000000000" || testCnpj == "11111111111111" ||
                testCnpj == "22222222222222" || testCnpj == "33333333333333" ||
                testCnpj == "44444444444444" || testCnpj == "55555555555555" ||
                testCnpj == "66666666666666" || testCnpj == "77777777777777" ||
                testCnpj == "88888888888888" || testCnpj == "99999999999999") {
                return false;
            }

            // Valida DVs
            tamanho = testCnpj.length - 2;
            numeros = testCnpj.substring(0, tamanho);
            digitos = testCnpj.substring(tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0)) {
                return false;
            }

            tamanho = tamanho + 1;
            numeros = testCnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado != digitos.charAt(1)) {
                return false;
            }

            return true;
        }
        else {
            cnpj = cnpj.replace(/\D/g, '');
            if (cnpj.toString().length != 11 || /^(\d)\1{10}$/.test(cnpj)) return false;
            var result = true;
            [9, 10].forEach(function (j) {
                var soma = 0, r;
                cnpj.split(/(?=)/).splice(0, j).forEach(function (e, i) {
                    soma += parseInt(e) * ((j + 2) - (i + 1));
                });
                r = soma % 11;
                r = (r < 2) ? 0 : 11 - r;
                if (r != cnpj.substring(j, j + 1)) result = false;
            });
            return result;
        }
        return false;
    },
    loginValido(login) {

        if (!login)
            return false;

        if (login.includes("bigcash") ||
            login.includes("bigcashme") ||
            login.includes("cashme") ||
            login.includes("big") ||
            login.includes("cash") ||
            login.includes("admin") ||
            login.includes("admins")) {

            return false;
        }

        if (/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(login) || login == " ")
            return false;

        return true;
    },
    senhaValida(senha) {

        var regexNumero = /\d/g;
        var regexLetra = /[a-zA-Z]/g;

        if (senha.length < 8 || !regexNumero.test(senha) || !regexLetra.test(senha))
            return false;

        return true;
    },
    telefoneCompletoValido(telefone) {

        if (!telefone || this.removeNonNumeric(telefone).length < 10 || this.removeNonNumeric(telefone).length > 11)
            return false;

        return true;
    },
    dddValido(ddd) {

        if (!ddd || this.removeNonNumeric(ddd).length < 2 || this.removeNonNumeric(ddd).length > 3)
            return false;

        return true;
    },
    telefoneValido(telefone) {

        if (!telefone || this.removeNonNumeric(telefone).length < 8 || this.removeNonNumeric(telefone).length > 9)
            return false;

        return true;
    },
    cashbackValido(percentualCashback) {
        if (percentualCashback < 1) {
            return false;
        }
        return true;
    },
    removeNonNumeric(text) {
        if (!text)
            return "";

        const match = text.match(/\d+/g);
        if (match) {
            return match.join("");
        }
        else {
            return "";
        };
    }
};