import './BaseImageInput.scss';
import Compress from 'compress.js'
const compress = new Compress();

export default {
    name: 'BaseImageInput',
    props: ['fotoPerfil'],
    data () {
      return {
        //imageData: require("@/assets/imgs/selfie.svg"),
        imageData: this.$props.fotoPerfil.urlImg,
        file: null,
        base64str: null,
        imgExt: null
      }
    },
    components: {
        
    },
    mounted() {

    },
    methods: {
      chooseImage (evt) {
        this.$refs.fileInput.click();
      },
      onSelectFile (evt) {
        const input = this.$refs.fileInput;
        const files = input.files;

        if (files && files[0]) {
          const reader = new FileReader
          reader.onload = e => {
            this.imageData = e.target.result;
          }
          reader.readAsDataURL(files[0])
          this.$emit('input', files[0])

          compress.attach('#baseImageInput', {
            size: 1,
            quality: .75
          }).then((data) => {
            this.base64str = data[0].data;
            this.imgExt = data[0].ext;
            this.file = Compress.convertBase64ToFile(this.base64str, this.imgExt);
            this.$props.fotoPerfil.file = this.file;
          });

        }
      }
    }
  }
  