import "./usuarios.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "usuarios",
    data() {
        return {
            search: {
                login: String(),
                nome: String(),
                idProduto: null,
                celular: String(),
                email: String(),
                patrocinador: String(),
                credenciamento: Boolean(),
                ativo: true,
                quantidade: 20,
                page: 1,
                ordenacaoUsuarios: 0,
                orderDesc: false,
                idGraduacao: null,
            },
            fields: [{
                key: "nome",
                label: "Nome",
                sortable: true,
                sortDirection: "desc",
                class: "small",
            },
            { key: "login", label: "Login", sortable: true },
            { key: "email", label: "Email", sortable: true },
            {
                key: "credenciamento",
                label: "Credenciado",
                sortable: true,
                formatter: (value) => (value ? "Sim" : "Não"),
                class: "text-center",
            },
            {
                key: "loginPatrocinador",
                label: "Patrocinador",
                sortable: true,
            },
            { key: "dataCadastro", label: "Data Cadastro", sortable: true, formatter: "formatDateAssigned" },
            {
                key: "graduacao",
                label: "Graduação",
                class: "small",
                sortable: false,
            },
            {
                key: "ativo",
                label: "Status",
                sortable: false,
                formatter: (value) => (value ? "Ativo" : "Inativo"),
                class: "text-center",
            },
            { key: "opcoes", label: "Opções", class: "text-center" },
            ],
            history: [],
            totalPages: 0,
            perPage: 20,
            pageOptions: [20, 50, 100],
            sortBy: "nome",
            sortDesc: false,
            filter: null,
            filterOn: [],
            graduacoes: [{ text: "Graduação", value: null }],
            usuarioConfiguracao: {
                idUsuarioConfiguracao: 0,
                nomeUsuario: String(),
                idUsuario: String(),
                taxaSaque: Number(),
            },
            link: "",
            showIconsToChangePage: false,
            creditoManual: true, // true = credito, false = debito
            formLancamentoManual: {
                valor: 0,
                login: String(),
                descricao: String(),
                maximoDistribuido: 0,
                distribuirRede: false,
            },
            modalUpgradeManual: false,
            formUpgradeManual: {
                idUsuario: null,
                idProduto: null,
            },
            optionsPlanos: [{ value: null, text: "Selecione o plano" }],
            optionsPresente: [{ value: null, text: "Selecione o plano" }],
            planoAtualUsuario: null,
            ativacaoSubsidiada: Boolean(),
            exportFields: {
                Nome: "nome",
                Login: "login",
                Email: "email",
                Patrocinador: "loginPatrocinador",
                "Data Cadastro": "dataCadastro",
                Graduação: "graduacao",
                Status: {
                    field: "ativo",
                    callback: (value) => {
                        return value ? "Ativo" : "Inativo";
                    },
                },
            },
            exportTable: [],
        };
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPages <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPages;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPages - this.search.page;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPages - 2; i <= this.totalPages; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPages - 1)
                    return [1, 2, 3];
                else
                    return [
                        this.search.page - 1,
                        this.search.page,
                        this.search.page + 1,
                    ];
            }
        },
    },
    async beforeMount() {
        await this.searchUsers();
        await this.buscarPlanosAtivos();
        this.obterGraduacoes();
    },
    watch: {
        perPage() {
            this.search.page = 1;
            this.searchUsers();
        },
        async "search.page"() {
            await this.searchUsers();
        },
        async sortDesc() {
            this.search.orderDesc = this.$data.sortDesc;
            await this.searchUsers();
        },
        async sortBy() {
            switch (this.$data.sortBy) {
                case "nome":
                    this.search.ordenacaoUsuarios = 0;
                    break;
                case "login":
                    this.search.ordenacaoUsuarios = 1;
                    break;
                case "email":
                    this.search.ordenacaoUsuarios = 2;
                    break;
                case "loginPatrocinador":
                    this.search.ordenacaoUsuarios = 3;
                    break;
                case "dataCadastro":
                    this.search.ordenacaoUsuarios = 4;
                    break;
            }
            this.searchUsers();
        },
    },
    methods: {
        formatDateAssigned(value) {
            return new Date(value).toLocaleDateString();
        },

        async searchUsers(event) {
            this.history = [];
            if (event) {
                event.preventDefault();
                this.search.page = 1;
            }
            this.$loading(true);
            this.search.quantidade = this.perPage;
            await http
                .post("/admin/filtrarUsuarios", this.search)
                .then(
                    (success) => {
                        if (success && success.data) {
                            this.totalPages = success.data.totalPages;
                            success.data.usuariosFiltrados.forEach((d) => {
                                this.history.push({
                                    nome: d.nome,
                                    login: d.login,
                                    email: d.email,
                                    idProduto: d.nomeProduto,
                                    celular: d.celular,
                                    idUsuario: d.idUsuario,
                                    credenciamento: d.credenciamento,
                                    ativo: d.ativo,
                                    bloqueado: d.bloqueado,
                                    graduacao: d.graduacao,
                                    emailConfirmado: d.emailConfirmado,
                                    loginPatrocinador: d.loginPatrocinador,
                                    dataCadastro: d.dataCadastro
                                    ,
                                });
                            });
                            this.exportTable = [];
                            for (let i in this.history) {
                                this.exportTable.push(this.history[i]);
                            }
                            this.totalRows = success.data.length;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => this.$loading(false));
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        redefinirSenhaAcesso(idUsuario) {
            this.$loading(true);
            http.get("/admin/redefinirSenhaUsuairo/" + idUsuario)
                .then(
                    (success) => {
                        if (success.data) {
                            this.opemModalLinkRedefinicaoSenha(success.data);
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        reenviarSenhaAtivacao(idUsuario) {
            this.$loading(true);
            http.get("/user/reenviarativacao/" + idUsuario)
                .then(
                    (success) => {
                        this.$snotify.success("Email enviado com sucesso!");
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        opemModalLinkRedefinicaoSenha(link) {
            this.$root.$emit("bv::hide::popover");
            this.link = link;
            this.$bvModal.show("modal-redefinir-senha");
        },
        close() {
            this.$bvModal.hide("modal-redefinir-senha");
        },
        bloquearUsuario(idUsuario, bloqueado) {
            this.$loading(true);
            http.get("/admin/bloquearUsuairoAdmin/" + idUsuario)
                .then(
                    (success) => {
                        if (success.data) {
                            this.searchUsers(event);
                            if (!bloqueado)
                                this.$snotify.success("Usuário Bloqueado");
                            else this.$snotify.success("Usuário Desbloqueado");
                        } else {
                            this.$snotify.error("Erro ao bloquear o usuário!");
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        redirectNovoPedido(login) {
            window.location.href = "/painel/admin/usuarios/gerar-pedido?login=" + login;
        },
        ativarDesativarUsuario(idUsuario, hibrido) {
            this.$loading(true);

            http.post("/admin/AtivarDesativarUsuario", { idUsuario })
                .then(
                    (success) => {
                        this.$snotify.success(
                            "Usuário atualizado com sucesso."
                        );
                        this.history.filter(
                            (us) => us.idUsuario == idUsuario
                        )[0].ativo = success.data;
                    },
                    (error) => {
                        this.$snotify.error("Ocorreu um erro inesperado.");
                    }
                )
                .finally(() => this.$loading(false));
        },
        async abrirModalAtivarDesativarHibrido(idUsuario) {
            this.$bvModal.show("ativarDesativarHibrido");
        },
        gerarLancamentoManual(e) {
            e.preventDefault();
            if (this.creditoManual && this.formLancamentoManual.valor < 0) {
                this.$snotify.error("Valor deve ser positivo!");
                return false;
            } else if (!this.creditoManual &&
                this.formLancamentoManual.valor > 0
            ) {
                this.$snotify.error("Valor deve ser negativo!");
                return false;
            }
            this.$loading(true);
            http.post("/admin/gerarLancamentoManual", this.formLancamentoManual)
                .then(
                    (success) => {
                        this.$snotify.success(success.data.message);
                        this.formLancamentoManual = {
                            valor: 0,
                            login: String(),
                            descricao: String(),
                            maximoDistribuido: 0,
                            distribuirRede: false,
                        };
                        this.modalLancamentoManual = false;
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => this.$loading(false));
        },
        async abrirModalUpgrade(idUsuario, subsidiada) {
            this.formUpgradeManual.idUsuario = idUsuario;
            this.ativacaoSubsidiada = subsidiada;
            await this.buscarPlanoAtivoUsuario(idUsuario);
            this.$bvModal.show("modal-upgrade-manual");
        },
        upgradeManual(e) {
            e.preventDefault();
            this.$loading(true);
            if (this.ativacaoSubsidiada)
                http.post("/admin/UpgradeManual", this.formUpgradeManual)
                    .then(
                        (success) => {
                            this.$snotify.success("Plano ativado com sucesso.");
                            this.$bvModal.hide("modal-upgrade-manual");
                        },
                        (error) => {
                            if (error.response && error.response.data)
                                this.$snotify.error(
                                    error.response.data.message
                                );
                            else this.$snotify.error("Erro ao ativar plano.");
                        }
                    )
                    .finally(() => this.$loading(false));
            else
                http.post("/admin/UpgradePresente", this.formUpgradeManual)
                    .then(
                        (success) => {
                            this.$snotify.success("Plano ativado com sucesso.");
                            this.$bvModal.hide("modal-upgrade-manual");
                        },
                        (error) => {
                            if (error.response && error.response.data)
                                this.$snotify.error(
                                    error.response.data.message
                                );
                            else this.$snotify.error("Erro ao ativar plano.");
                        }
                    )
                    .finally(() => this.$loading(false));
        },
        async buscarPlanosAtivos() {
            await http.get("/produto/buscarAtivos").then(
                (success) => {
                    success.data.forEach((p) => {
                        this.optionsPlanos.push({
                            value: p.idProduto,
                            text: `${p.nome} - R$ ${p.valor}`,
                        });
                    });
                    success.data.forEach((p) => {
                        this.optionsPresente.push({
                            value: p.idProduto,
                            text: `${p.nome}`,
                        });
                    });
                },
                (error) => {
                    this.$snotify.error("Erro ao buscar planos.");
                }
            );
        },
        async buscarPlanoAtivoUsuario(idUsuario) {
            this.$loading(true);
            await http
                .get(`/admin/obterPlanoAtivoUsuario/${idUsuario}`)
                .then(
                    (success) => {
                        if (success.data) this.planoAtualUsuario = success.data;
                    },
                    (error) => {
                        this.$snotify.error(
                            "Erro ao obter plano atual do usuário."
                        );
                    }
                )
                .finally(() => this.$loading(false));
        },
        async abrirModalLancamentoManual(creditoOudebito, login) {
            this.creditoManual = creditoOudebito;
            this.formLancamentoManual.valor = 0;
            this.formLancamentoManual.descricao = "";
            this.formLancamentoManual.login = login;
            this.formLancamentoManual.maximoDistribuido = 0;
            this.formLancamentoManual.distribuirRede = false;
            this.$bvModal.show("lancamentoManual");
        },
        formatDate(date, showTime) {
            if (!showTime) return new Date(date).toLocaleDateString();
            else
                return `${new Date(date).toLocaleDateString()} - ás ${new Date(
                    date
                ).toLocaleTimeString()}`;
        },
        efetuarLoginRemoto(idUsuario) {
            this.$loading(true);
            http.get(`/admin/efetuarAcessoRemoto/${idUsuario}`).then(
                (success) => {
                    localStorage.removeItem("menu");
                    localStorage.setItem(
                        "userAdmin",
                        localStorage.getItem("user")
                    );
                    localStorage.setItem("user", JSON.stringify(success.data));
                    this.$router.push("/painel");
                    window.location.reload();
                },
                (error) => {
                    for (const erro of http.mensagensErro(
                        error,
                        "Erro ao efetuar acesso remoto."
                    )) {
                        this.$snotify.error(erro);
                    }
                    this.$loading(false);
                }
            );
        },
        obterGraduacoes() {
            http.get("/geral/obterGraduacoes/").then((success) => {
                success.data.forEach((item) => {
                    this.graduacoes.push({
                        text: item.nome,
                        value: item.idGraduacao,
                    });
                });
            });
        },
    },
};