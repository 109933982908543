import "./formCadastro.scss";
import usuarioService from "@/_helper/usuario-service.js";
import validador from "@/_helper/validacoes";
import http from "@/_helper/api-services.js";

export default {
  name: "formCadastro",
  data() {
    return {
      name: String(),
      cpf: String(),
      document: String(),
      email: String(),
      confirmEmail: String(),
      login: String(),
      password: String(),
      confirmPassword: String(),
      sponsor: String(),
      acceptTerms: false,
      acceptPolicy: false,
      ddd: String(),
      phone: String(),
      disabled: false,
      registrationSuccessful: false,
    };
  },
  computed: {
    senhaCorreta() {
      if (this.password == this.confirmPassword) return true;
      return false;
    },
  },
  props: ["sponsorP"],
  mounted() {
    if (this.$props.sponsorP != null && this.$props.sponsorP != "") {
      usuarioService.checkSponsor(this.$props.sponsorP).then(
        (success) => {
          this.sponsor = this.$props.sponsorP;
          this.disabled = true;
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
          this.$bvModal.hide("modalActions");
          this.$router.push("/");
        }
      );
    }
  },
  methods: {
    googleCadastroUri() {
      if (!this.acceptTerms || !this.acceptPolicy) {
        this.$snotify.error("Aceite todos os termos de uso.");
        return false;
      }
      if (!validador.loginValido(this.login)) {
        this.$snotify.error(
          'Login inválido - Não é possível criar um login com as palavras: "bigcash", "bigcashme", "cashme", "big", "cash", "admin", "admins" e caracteres especiais. e caracteres especiais.'
        );
        return false;
      }
      if (!this.checkForm()) {
        return false;
      }
      if (!this.name || this.name == null) {
        this.$snotify.error("Informe o nome.");
        return false;
      }
      this.login = this.login.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      var form = {
        nome: this.name,
        documento: this.cpf,
        login: this.login,
        loginPatrocinador: this.sponsor,
        celular:
          this.removeNonNumeric(this.ddd) + this.removeNonNumeric(this.phone),
        senha: this.password,
      };
      var formJson = JSON.stringify(form);
      this.$cookies.set("Cadastro", formJson, "10MIN");
      location.href =
        "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=" +
        encodeURIComponent(location.origin + "/cadastro-social/google") +
        "&prompt=consent&response_type=code&client_id=1078310793621-450mdiqeov6fp2rj66smesc3qmio1c6p.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline";
    },
    switchTo(action) {
      this.$emit("switchPanel", action);
    },
    removeNonNumeric(text) {
      if (text == "" || text == null) return "";
      return text.match(/\d+/g).join("");
    },
    removeEspecialCharacter() {
      this.login = this.login.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
    },
    validaLogin() {
      if (!validador.loginValido(this.login)) {
        this.$snotify.error(
          'Login inválido - Não é possível criar um login com as palavras: "bigcash", "bigcashme", "cashme", "big", "cash", "admin", "admins" e caracteres especiais. e caracteres especiais.'
        );
        return false;
      }

      return true;
    },
    validaTelefone() {
      if (!validador.dddValido(this.ddd)) {
        this.$snotify.error("Informe o DDD.");
        return false;
      }

      if (!validador.telefoneValido(this.phone)) {
        this.$snotify.error("Informe o telefone.");
        return false;
      }

      return true;
    },
    validaCNPJ() {
      if (!validador.cnpjValido(this.cpf)) {
        this.$snotify.error("Informe um CPF/CNPJ válido.");
        return false;
      }

      return true;
    },
    validaSenha() {
      if (!validador.senhaValida(this.password)) {
        this.$snotify.error(
          "Senha deve conter no mínimo 8 caracteres sendo pelo menos um número e um letra."
        );
        return false;
      }
      if (!this.senhaCorreta) {
        this.$snotify.error("As senhas não conferem.");
        return false;
      }
      return true;
    },
    checkForm() {
      return (
        this.validaTelefone() &&
        this.validaCNPJ() &&
        this.validaSenha() &&
        this.validaLogin()
      );
    },
    handleSubmit() {
      if (!this.checkForm()) {
        return false;
      }

      if (!this.acceptTerms || !this.acceptPolicy) {
        this.$snotify.error("Aceite todos os termos de uso.");
        return false;
      }
      var form = {
        nome: this.name,
        documento: this.cpf,
        login: this.login,
        email: this.email,
        confirmEmail: this.confirmEmail,
        loginPatrocinador: this.sponsor,
        celular:
          this.removeNonNumeric(this.ddd) + this.removeNonNumeric(this.phone),
        senha: this.password,
      };
      http
        .post("/user/registrar", form)
        .then(
          () => {
            this.registrationSuccessful = true;
          },
          (error) => {
            for (const erro of http.mensagensErro(error)) {
              this.$snotify.error(erro);
            }
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    closeModal() {
      this.$bvModal.hide("modalActions");
    },
    getFile() {
      window.open("/QB-LGPD.pdf", "_blank");
    },
  },
};
