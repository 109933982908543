import "./FormCredenciamento.scss";
import http from "@/_helper/api-services";
import buscaCep from "@/_helper/busca-cep";
import Compress from "compress.js";
import validador from "@/_helper/validacoes";
const compress = new Compress();

export default {
    name: "FormCredenciamento",
    data() {
        return {
            imageShow: String(),
            credenciamento: {
                estabelecimento: String(),
                idCategoria: null,
                cep: String(),
                rua: String(),
                telefone: String(),
                celularContato: String(),
                bairro: null,
                complemento: String(),
                numero: String(),
                idEstado: null,
                idCidade: null,
                faturamentoMensal: Number(),
                contactado: Boolean(),
                loginPatrocinador: String(),
                cnpj: String(),
                email: String(),
                confirmEmail: String(),
                loginResponsavel: String(),
                nomeResponsavel: String(),
                senhaResponsavel: String(),
                confirmSenhaResponsavel: String(),
                percentualCashback: Number(),
                latitude: String(),
                longitude: String(),
            },
            ddd: String(),
            telefoneSemDDD: String(),
            dddResponsavel: String(),
            telefoneResponsavelSemDDD: String(),
            faturamento: String(),
            acceptTerms: Boolean(),
            registrationSuccessful: Boolean(),
            errors: [],
            cidades: [{ value: null, text: "Selecione a cidade" }],
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                suffix: " (Faturamento mensal)",
                precision: 2,
                masked: false,
                allowBlank: true,
            },
            estados: [{ value: null, text: "Selecione o estado" }],
            erroForm: false,
            categorias: [
                {
                    value: null,
                    text: "Selecione a categoria",
                },
            ],
        };
    },
    computed: {
        emailValid() {
            if (this.credenciamento.email == this.credenciamento.confirmEmail)
                return true;
            return false;
        },
        passwordValid() {
            if (
                this.credenciamento.senhaResponsavel ==
                this.credenciamento.confirmSenhaResponsavel
            )
                return true;
            return false;
        },
        cashbackValid() {
            if (this.credenciamento.percentualCashback > 0) return true;
            return false;
        },
    },
    async beforeMount() {
        this.$loading(true);
        await this.buscarEstados();
        await this.buscarCategorias();
        this.$loading(false);
    },
    mounted() {
        this.credenciamento.estado = null;
        this.credenciamento.faturamentoMensal = 0;
        this.acceptTerms = false;
        this.acceptPolicy = false;
        this.registrationSuccessful = false;
        this.contactado = false;
    },
    methods: {
        validaTermos() {
            if (!this.acceptTerms || !this.acceptPolicy) {
                this.$snotify.error("Aceite todos os termos de uso.");
                return false;
            }

            return true;
        },
        validaLogin() {
            if (!validador.loginValido(this.credenciamento.loginResponsavel)) {
                this.$snotify.error(
                    'Login inválido - Não é possível criar um login com as palavras: "bigcash", "bigcashme", "cashme", "big", "cash", "admin", "admins" e caracteres especiais.'
                );
                return false;
            }
            return true;
        },
        validaTelefoneResponsavel() {
            if (!validador.dddValido(this.dddResponsavel)) {
                this.$snotify.error("Informe o DDD do responsável.");
                return false;
            }

            if (!validador.telefoneValido(this.telefoneResponsavelSemDDD)) {
                this.$snotify.error("Informe o telefone do responsável.");
                return false;
            }

            return true;
        },
        validaTelefoneEstabelecimento() {
            if (!validador.telefoneValido(this.telefoneSemDDD)) {
                this.$snotify.error("Informe o telefone do credenciado.");
                return false;
            }

            if (!validador.dddValido(this.ddd)) {
                this.$snotify.error("Informe o DDD do credenciado.");
                return false;
            }

            return true;
        },
        validaCashback() {
            if (
                !validador.cashbackValido(
                    this.credenciamento.percentualCashback
                )
            ) {
                this.$snotify.error(
                    "Percentual de cashback não pode ser menor que 1%."
                );
                return false;
            }

            return true;
        },
        validaCep() {
            if (!this.credenciamento.cep) {
                this.$snotify.error("Informe o CEP.");
                return false;
            }

            if (!validador.cepValido(this.credenciamento.cep)) {
                this.$snotify.error("Informe um CEP válido.");
                return false;
            }
            return true;
        },
        validaCNPJ() {
            if (!validador.cnpjValido(this.credenciamento.cnpj)) {
                this.$snotify.error("Informe um CPF/CNPJ válido.");
                return false;
            }

            return true;
        },
        validaEmail() {
            if (!validador.emailFormatoValido(this.credenciamento.email)) {
                this.$snotify.error(
                    'E-mail inválido - E-mails devem ser terminados em: ".com" ou ".com.br"'
                );
                return false;
            }

            if (!validador.emailDominioValido(this.credenciamento.email)) {
                this.$snotify.error(
                    'E-mail inválido - Não são permitidos emails contendo: ".gov", ".gov.br", "hotmail.com", "msn.com", "yahoo.com", "yahoo.com.br", "globo.com", "icloud.com"'
                );
                return false;
            }

            return true;
        },
        validaSenha() {
            if (!validador.senhaValida(this.credenciamento.senhaResponsavel)) {
                this.$snotify.error(
                    "Senha deve conter no mínimo 8 caracteres sendo pelo menos um número e um letra."
                );
                return false;
            }

            return true;
        },
        checkForm() {
            return (
                this.validaLogin() &&
                this.validaTelefoneEstabelecimento() &&
                this.validaTelefoneResponsavel() &&
                this.validaCNPJ() &&
                this.validaCep() &&
                this.validaEmail() &&
                this.validaSenha() &&
                this.validaTermos() &&
                this.validaCashback()
            );
        },
        async buscarCategorias() {
            await http.get("/Geral/ObterCategorias").then(
                (success) => {
                    success.data.forEach((categoria) => {
                        this.categorias.push({
                            text: categoria.nome,
                            value: categoria.idCategoria,
                        });
                    });
                },
                () => {
                    this.$snotify.error("Erro ao obter segmentos.");
                }
            );
        },
        async buscarEstados() {
            await http.get("/Geral/buscaEstado").then(
                (success) => {
                    success.data.forEach((element) => {
                        this.estados.push({
                            value: element.idEstado,
                            text: element.nome,
                            key: element.uf,
                        });
                    });
                },
                () => {
                    this.$snotify.error("Erro ao obter cidades.");
                }
            );
        },
        buscarCidades(setarCidade, cidade) {
            this.cidades = [{ value: null, text: "Cidade" }];
            this.credenciamento.idCidade = null;
            this.$loading(true);

            if (this.credenciamento.idEstado) {
                http.get(
                    "/Geral/buscaCidade/" + this.credenciamento.idEstado
                ).then((response) => {
                    response.data.forEach((element) => {
                        if (setarCidade && element.nome == cidade)
                            this.credenciamento.idCidade = element.idCidade;

                        this.cidades.push({
                            value: element.idCidade,
                            text: element.nome,
                        });
                    });
                });
            }
        },
        removeNonNumeric(text) {
            if (!text) return "";

            return text.match(/\d+/g).join("");
        },
        handleSubmit() {
            this.credenciamento.percentualCashback = parseFloat(
                this.credenciamento.percentualCashback
            );

            if (!this.checkForm()) {
                return false;
            }

            this.$loading(true);
            this.credenciamento.telefone =
                this.removeNonNumeric(this.ddd) +
                this.removeNonNumeric(this.telefoneSemDDD);
            this.credenciamento.celularContato =
                this.removeNonNumeric(this.dddResponsavel) +
                this.removeNonNumeric(this.telefoneResponsavelSemDDD);

            http.post("/Credenciamento", this.credenciamento)
                .then(
                    () => {
                        this.registrationSuccessful = true;
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        buscaCep() {
            if (
                this.credenciamento.cep &&
                this.credenciamento.cep.length == 9
            ) {
                this.$loading(true);

                buscaCep
                    .get(this.credenciamento.cep)
                    .then(
                        (response) => {
                            if (response.data.erro) {
                                this.$snotify.error("CEP não encontrado");
                                return;
                            }
                            this.credenciamento.rua = response.data.logradouro;
                            this.credenciamento.bairro = response.data.bairro;
                            let data = this.estados.filter(
                                (f) => f.key == response.data.uf
                            );
                            if (data.length > 0) {
                                this.credenciamento.idEstado = data[0].value;
                                this.buscarCidades(
                                    true,
                                    response.data.localidade
                                );
                            }
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => this.$loading(false));
            }
        },
        onSelectFile(evt) {
            let input = this.$refs.fileInput;
            const files = input.files;

            if (files && files[0]) {
                if (files[0].size > 1024 * 1024) {
                    this.$snotify.error(
                        "Logo inválida: Arquivo maior do que o permitido (1MB)"
                    );
                } else {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.imageData = e.target.result;
                    };
                    reader.readAsDataURL(files[0]);
                    this.$emit("input", files[0]);

                    compress
                        .attach("#logo", {
                            size: 1,
                            quality: 0.75,
                        })
                        .then((data) => {
                            this.credenciamento.imageBase64 = data[0].data;
                            this.imageShow = data[0].prefix + data[0].data;
                        });
                }
            }
        },
        getFile() {
            window.open("/QB-LGPD.pdf", "_blank");
        },
    },
};
