import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import Compress from "compress.js";
const compress = new Compress();

export default {
  name: "FormLojasFisicas",
  components: {
    tabelaBase,
  },
  props: {
    idCredenciamento: { type: String, default: 0 },
  },
  data() {
    return {
      loja: {
        estabelecimento: String(),
        telefone: String(),
        logoUrl: String(),
        bairro: String(),
        rua: String(),
        numero: String(),
        cep: String(),
        complemento: String(),
        latitude: String(),
        longitude: String(),
        percentualCashback: Number(),
        imageBase64: String(),
      },
      imagemRequest: {
        base64: String(),
        nome: String(),
        diretorio: "logos-empresas",
      },
      imageShow: String(),
      dataCriacao: null,
      dataAtualizacao: null,
      mainProps: { width: 150, height: 150 }
    };
  },
  computed: {},
  async beforeMount() {
    if (this.$props.idCredenciamento != 0 && this.$props.idCredenciamento != null) {
      await this.obterDadosCredenciado();
    }
  },
  methods: {
    async obterDadosCredenciado() {
      this.$loading(true);

      await http
        .get(`/credenciamento/obterDadosLojaCredenciada/${this.$props.idCredenciamento}`)
        .then(
          (success) => {
            if (success.data) {
              this.loja.estabelecimento = success.data.estabelecimento;
              this.loja.telefone = success.data.telefone;
              this.loja.logoUrl = success.data.logoUrl;
              this.loja.bairro = success.data.bairro;
              this.loja.rua = success.data.rua;
              this.loja.numero = success.data.rua;
              this.loja.cep = success.data.cep;
              this.loja.complemento = success.data.complemento;
              this.loja.latitude = success.data.latitude;
              this.loja.longitude = success.data.longitude;
              this.loja.percentualCashback = success.data.percentualCashback;
              this.dataAtualizacao = success.data.dataAtualizacao;
            }
          },
          (error) => {
            if (error.response && error.response.data)
              this.$snotify.error(error.response.data.message);
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    editarDadosloja() {
      this.$loading(true);
      http
        .post(`/credenciamento/editarCredenciado/${this.$props.idCredenciamento}`, this.loja)
        .then(
          (success) => {

            if (success) {
              this.$snotify.success("Loja editada com sucesso");
            }
          },

          (error) => {
            if (error.response && error.response.data)
              this.$snotify.error(error.response.data.message);
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },

    onSelectFile(evt) {
      let input = this.$refs.fileInput;
      const files = input.files;

      if (files && files[0]) {
        if (files[0].size > 1024 * 1024) {
          this.$snotify.error(
            "Logo inválida: Arquivo maior do que o permitido (1MB)"
          );
        } else {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageData = e.target.result;
          };
          reader.readAsDataURL(files[0]);
          this.$emit("input", files[0]);

          compress
            .attach("#logo", {
              size: 1,
              quality: 0.75,
            })
            .then((data) => {
              this.loja.imageBase64 = data[0].data;
              this.imageShow = data[0].prefix + data[0].data;
            });
        }
      }
    },
    deletarImagem() {
      this.loja.url = null

      this.editarDadosloja()
    },
    voltarTela() {
      window.location.href = "/painel/admin/lojas-credenciados";
    },
    formatDate(data) {
      if (data) {
        let dateTime = new Date(data);
        return (
          dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
        );
      }
    },
  },
};
