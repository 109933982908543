import "./boxProduto.scss";
import http from "@/_helper/api-services.js";

export default {
  name: "boxProduto",
  data() {
    return {
      id: String(),
      paymentForms: [],
      opcoesPagamento: [],
      paymentForm: 0,
      numParcelas: 1,
      urlBoleto: String(),
      status: Boolean(),
    };
  },
  props: {
    product: Object(),
    paymentFormsAlreadyLoaded: Object(),
    planoAtual: Object(),
  },
  computed: {
    listPaymentForms() {
      if (this.$props.paymentFormsAlreadyLoaded) {
        let retorno = [];
        Object.keys(this.$props.paymentFormsAlreadyLoaded).forEach(
          (nameIndex) => {
            retorno.push({
              text: nameIndex,
              value: this.$props.paymentFormsAlreadyLoaded[nameIndex],
            });
          }
        );
        return retorno;
      } else return this.$data.paymentForms;
    },
  },
  beforeMount() {
    if (!this.$props.paymentFormsAlreadyLoaded) {
      this.getPaymentsForms();
    }

    this.obterParcelas();
  },
  mounted() {
    this.id = this._uid.toString();
  },
  methods: {
    formatValor(valor) {
      return valor.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    obterParcelas() {
      this.opcoesPagamento = [
        {
          text: "1x de " + this.formatValor(this.$props.product.valor),
          value: 1,
        },
      ];

      var i;
      for (i = 2; i <= this.$props.product.parcelas; i++) {
        this.opcoesPagamento.push({
          text: i + "x de " + this.formatValor(this.$props.product.valor / i),
          value: i,
        });
      }

      // if(this.$props.product.idProduto >= 2)
      // {
      //     this.opcoesPagamento.push({ text: "2x de " + this.formatValor(this.$props.product.valor / 2), value: 2 });
      // }

      // if(this.$props.product.idProduto >= 3)
      // {
      //     this.opcoesPagamento.push({ text: "3x de" + this.formatValor(this.$props.product.valor / 3), value: 3 });
      // }

      // if(this.$props.product.idProduto >= 4)
      // {
      //     this.opcoesPagamento.push({ text: "4x de" + this.formatValor(this.$props.product.valor / 4), value: 4 });
      //     this.opcoesPagamento.push({ text: "5x de" + this.formatValor(this.$props.product.valor / 5), value: 5 });
      // }

      // if(this.$props.product.idProduto >= 5)
      // {
      //     this.opcoesPagamento.push({ text: "6x de" + this.formatValor(this.$props.product.valor / 6), value: 6 });
      //     this.opcoesPagamento.push({ text: "7x de" + this.formatValor(this.$props.product.valor / 7), value: 7 });
      // }

      // if(this.$props.product.idProduto == 6)
      // {
      //     this.opcoesPagamento.push( { text: "8x de" + this.formatValor(this.$props.product.valor / 8), value: 8 } );
      //     this.opcoesPagamento.push( { text: "9x de" + this.formatValor(this.$props.product.valor / 9), value: 9 } );
      //     this.opcoesPagamento.push( { text: "10x de" + this.formatValor(this.$props.product.valor / 10), value: 10 } );
      // }
    },
    openBuyConfirmation() {
      this.$bvModal.show(this.id);
    },
    getPaymentsForms() {
      http.get("/compra/obterMetodosDeCompra").then(
        (success) => {
          let data = success.data;
          Object.keys(data).forEach((nameIndex) => {
            this.paymentForms.push({
              text: nameIndex,
              value: data[nameIndex],
            });
          });
        },
        (error) => {
          for (const erro of http.mensagensErro(error)) {
            this.$snotify.error(erro);
          }
        }
      );
    },
    handleReset() {
      this.paymentForm = null;
      this.password = String();
    },
    async handleSubmit() {
      this.$loading(true);

      await http
        .post("/pedidos/adquirirPlano", {
          idProduto: this.$props.product.idProduto,
          formaPagamento: this.paymentForm,
          numParcelas: this.numParcelas,
          aceiteTermos: this.status,
        })
        .then(
          (success) => {
            this.$snotify.success("Comprado com sucesso");
            this.urlBoleto = success.data.urlBoleto;
          },
          (error) => {
            for (const erro of http.mensagensErro(error)) {
              this.$snotify.error(erro);
            }
          }
        );

      this.$loading(false);
    },
    closeModal() {
      this.$bvModal.hide(this.id);
      this.urlBoleto = null;
    },
  },
};
