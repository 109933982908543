import http from "@/_helper/api-services.js";
import DatePicker from 'vue2-datepicker';
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "relatorioDeAnunciantes",
    data() {
        return {
            anunciantes: {

                fields: [
                    { key: "nome", label: "Nome", sortable: true, class: "text-center" },
                    { key: "moeda", label: "Moeda", sortable: true, class: "text-center" },
                    { key: "tipoCashback", label: "Tipo de cashback", sortable: true, class: "text-center", formatter: val => { if (val && val == "percentage") return "Porcentagem"; if (val && val == "amount") return "Dinheiro"; return "" } },
                    { key: "cashbackMin", label: "Cashback mínimo", class: "text-center", sortable: true },
                    { key: "cashbackMax", label: "Cashback máximo", class: "text-center", sortable: true },
                    { key: "conexao", label: "Afiliado", class: "text-center", sortable: true },
                    { key: "ativo", label: "Status", sortable: true, class: "text-center", formatter: val => { if (val) return "Ativo"; return "Inativo" } },
                    { key: "dataAtualizacao", label: "Última atualização", sortable: true, class: "text-center", formatter: val => { if (!val) return ""; return new Date(val).toLocaleDateString(); } },
                ],
                items: [],
                perPage: 10,
            },
            exportFields: {
                Nome: "nome",
                Status: "status",
                "Afiliado": "conexao",
                "Tipo de cashback": "tipoCashback",
                Moeda: "moeda",
                "Cashback Mínimo": "cashbackMin",
                "Cashback Máximo": "cashbackMax",
                "Última atualização": "dataAtualizacao",
            },
            opcoesStatus: [
                { text: "Todos", value: null },
                { text: "Ativo", value: 0 },
                { text: "Inativo", value: 1 },
            ],
            opcoesCashback: [
                { text: "Todos", value: null },
                { text: "Percentual", value: 0 },
                { text: "Dinheiro", value: 1 },
            ],
            opcoesConexao: [
                { text: "Todos", value: null },
                { text: "Awin", value: 0 }
                // { text: "Afilio", value: 1 },
            ],
            opcoesMoeda: [
                { text: "Todos", value: null },
                { text: "BRL", value: "BRL" },
                { text: "USD", value: "USD" },
            ],
            search: {
                nome: String(),
                moeda: null,
                status: null,
                tipoCashback: null,
                conexao: null,
                pagina: 1,
                quantidadePorPagina: 20,
                ordenacao: null,
                asc: true,
                obterTodos: false
            },
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            usuarioFatura: String(),
            usuarioFaturaTodos: false,
            sortBy: String(),
            sortDesc: true,
            showIconsToChangePage: false,
            totalPages: 0,
            itemCount: 0
        };
    },
    components: {
        DatePicker,
        tabelaBase
    },
    computed: {
    },
    watch: {
        async 'search.pagina'() {
            await this.buscarAnunciantes(true);
        },
        async sortDesc() {
            this.search.asc = this.$data.sortDesc;
            await this.getAnunciantes();
        },
        async sortBy() {
            switch (this.$data.sortBy) {
                case "nome":
                    this.search.ordenacao = 0;
                    break;
                case "status":
                    this.search.ordenacao = 1;
                    break;
                case "cashbackMin":
                    this.search.ordenacao = 2;
                    break;
                case "cashbackMax":
                    this.search.ordenacao = 3;
                    break;
                case "tipoCashback":
                    this.search.ordenacao = 4;
                    break;
                case "Moeda":
                    this.search.ordenacao = 5;
                    break;
            }

            await this.getAnunciantes();
        }
    },
    async mounted() {
        await this.getAnunciantes();
    },
    methods: {
        async getAnunciantes() {
            this.$loading(true);
            this.search.valorInicial = !this.search.valorInicial ? null : parseFloat(this.search.valorInicial);
            this.search.valorFinal = !this.search.valorFinal ? null : parseFloat(this.search.valorFinal);
            this.search.obterTodos = false;
            this.search.pagina = 1;
            await http.post("/Admin/RelatorioAnunciantes", this.search).then(
                success => {
                    this.totalPages = success.data.totalPages;
                    this.anunciantes.items = [];
                    success.data.anunciantesFiltrados.forEach(item => {
                        this.anunciantes.items.push({
                            nome: item.nome,
                            moeda: item.moeda,
                            status: item.ativo ? "Ativo" : "Inativo",
                            tipoCashback: item.tipoCashback,
                            dataAtualizacao: item.dataAtualizacao,
                            cashbackMax: item.cashbackMax,
                            cashbackMin: item.cashbackMin,
                            conexao: item.idAwin,
                            //conexao: item.idAwin ? "Awin" : "Afilio",
                        });
                    });
                    this.itemCount = success.data.quantidadeTotal;
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => this.$snotify.error(e));
                }
            );
            this.$loading(false);
        },
        formatValue(value) {
            if (!value) return "";
            return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        },
        async getAnunciantesExport() {
            this.search.obterTodos = true;
            const result = await http.post("/Admin/RelatorioAnunciantes", this.search).then(
                success => {
                    let dataForTableExport = [];
                    success.data.anunciantesFiltrados.forEach(item => {
                        dataForTableExport.push({
                            nome: item.nome,
                            moeda: item.moeda,
                            status: item.ativo ? "Ativo" : "Inativo",
                            tipoCashback: item.tipoCashback && item.tipoCashback == "percentage" ? "Porcentagem" : item.tipoCashback == "amount" ? "Dinheiro" : "Não informado",
                            dataAtualizacao: item.dataAtualizacao,
                            cashbackMax: item.cashbackMax,
                            cashbackMin: item.cashbackMin,
                            conexao: item.idAwin,
                        });
                    });
                    return dataForTableExport;
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => this.$snotify.error(e));
                }
            );
            return result;
        },
        async alterarPagina(page) {
            this.search.pagina = page;
            await this.getAnunciantes();
        },
        startDownload() {
            this.$loading(true);
        },
        finishDownload() {
            this.$loading(false);
        }
    }
}