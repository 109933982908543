import http from "@/_helper/api-services.js";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "MapearCategorias",
    components: {
        tabelaBase,
    },
    data() {
        return {
            map: {
                idCategoria: null,
                idAnunciante: null,
            },
            opcoesCategorias: [],
            opcoesCategoriasFiltro: [],
            opcoesLojas: [],
            filtro: {
                nomeAnunciante: String(),
                idCategoria: null,
            },
            mapeamentos: {
                items: [],
                title: "Mapeamentos",
                fields: [
                    {
                        key: "nomeAnunciante",
                        label: "Anunciante",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "nomeCategoria",
                        label: "Categoria",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                        sortable: false,
                    },
                ],
                sortBy: "nomeAnunciante",
                sortDesc: false,
                exportExcel: false,
                perPage: 5,
            },
            idMapeamentoExclusao: 0,
        };
    },
    async beforeMount() {
        await this.obterDadosCategorias();
    },
    methods: {
        async obterDadosCategorias() {
            this.$loading(true);

            await http
                .get("/categoria/obterDadosMapeamentoCategorias/")
                .then(
                    (success) => {
                        if (success.data) {
                            this.mapeamentos.items = success.data.mapeamentos;

                            success.data.categorias.forEach((item) => {
                                this.opcoesCategorias.push({
                                    value: item.key,
                                    text: item.value,
                                });
                            });

                            this.opcoesCategoriasFiltro.push({
                                value: null,
                                text: "Todos",
                            });

                            success.data.categorias.forEach((item) => {
                                this.opcoesCategoriasFiltro.push({
                                    value: item.key,
                                    text: item.value,
                                });
                            });

                            success.data.anunciantes.forEach((item) => {
                                this.opcoesLojas.push({
                                    value: item.key,
                                    text: item.value,
                                });
                            });
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        async handleSubmit() {
            this.$loading(true);
            await http
                .post("/categoria/Mapear/", this.map)
                .then(
                    (success) => {
                        this.mapeamentos.items.push(success.data);
                        this.$snotify.success("Categoria mapeada com sucesso!");
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        modalDelete(idMap) {
            this.idMapeamentoExclusao = idMap;
            this.$bvModal.show("confirmDelete");
        },
        async removerMapeamento() {
            this.$loading(true);
            http.delete(
                `/categoria/removerMapeamento/${this.idMapeamentoExclusao}`
            )
                .then(
                    (success) => {
                        this.$snotify.success(
                            "Mapeamento excluido com sucesso."
                        );
                        this.filtrarMapeamentos();
                    },
                    () => {
                        this.$snotify.error("Erro ao excluir mapeamento.");
                    }
                )
                .finally(() => {
                    this.$loading(false);
                    this.$bvModal.hide("confirmDelete");
                });
        },
        async filtrarMapeamentos() {
            this.$loading(true);

            await http
                .post("/categoria/filtrarMapeamentos/", this.filtro)
                .then(
                    (success) => {
                        if (success.data) {
                            this.mapeamentos.items = success.data;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        removeEspecialCharacter(texto) {
            return texto.replace(
                /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                ""
            );
        },
        voltarTela() {
            window.location.href = "/painel/parceiros";
        },
    },
};
