import "./comoFunciona.scss";
import http from "@/_helper/api-services.js";
import carousel from "vue-owl-carousel";

export default {
    name: "comoFunciona",
    data() {
        return {
            listaAnunciantes: [],
            configCarousel: {
                autoHeight: true,
                responsive: {
                    0: {
                        items: 1,
                        margin: 5,
                        loop: false,
                    },
                    425: {
                        items: 2,
                        margin: 10,
                        loop: false,
                    },
                    768: {
                        items: 3,
                        margin: 10,
                        loop: false,
                    },
                    992: {
                        items: 4,
                        margin: 15,
                        loop: false,
                    },
                    1200: {
                        items: 5,
                        autoplay: true,
                        margin: 25,
                        loop: false,
                    },
                },
            },
        };
    },
    components: {
        carousel,
    },
    async mounted() {
        this.$loading(false);
        await this.obterAnunciantesAleatorio();
    },
    methods: {
        async obterAnunciantesAleatorio() {
            await http.get("/Anunciante/obterAnunciantesAleatorio/").then(
                (response) => {
                    this.listaAnunciantes = [];
                    this.listaAnunciantes = response.data;
                },
                () => {
                    this.$snotify.error("Erro ao obter dados.");
                }
            );
        },
        getRand() {
            return Math.random();
        },
    },
};
