import http from "@/_helper/api-services.js";
import DatePicker from "vue2-datepicker";
import TabelaBase from "@/components/TabelaBase/tabelaBase.vue";

export default {
    name: "relatorioDeFaturas",
    data() {
        return {
            exportFields: {
                Patrocinador: "patrocinador",
                Login: "login",
                Nome: "nome",
                Percentual: "cashback",
                Valor: "valorPedido",
                Categoria: "categoria",
                Cidade: "cidade",
                Status: "status",
                "Data fatura": "dataPedido",
                "Data pagamento": "dataPagamento",
            },
            fields: [
                {
                    key: "patrocinador",
                    label: "Patrocinador",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "login",
                    label: "Login",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "nome",
                    label: "Nome",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "cashback",
                    label: "Percentual",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "categoria",
                    label: "Categoria",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "cidade",
                    label: "Cidade",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "dataPedido",
                    label: "Data fatura",
                    sortable: true,
                    class: "text-center",
                    formatter: (val) => {
                        if (!val) return "";
                        return new Date(val).toLocaleDateString();
                    },
                },
                {
                    key: "dataPagamento",
                    label: "Data pagamento",
                    sortable: true,
                    class: "text-center",
                    formatter: (val) => {
                        if (!val) return "";
                        return new Date(val).toLocaleDateString();
                    },
                },
                {
                    key: "valorPedido",
                    label: "Valor",
                    class: "text-center",
                    sortable: true,
                    formatter: (val) =>
                        val.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        }),
                },
                {
                    key: "status",
                    label: "Status",
                    sortable: true,
                    class: "text-center",
                    formatter: (val) => this.statusPedido[val]
                },
                {
                    key: "acoes",
                    label: "Ações",
                    sortable: true,
                    class: "text-center",
                },
            ],
            items: [],
            opcoesStatus: [
                { text: "Selecione um status", value: null },
                { text: "Em aberto", value: 2 },
                { text: "Fechada", value: 1 },
                { text: "Paga", value: 6 },
            ],
            search: {
                loginPatrocinador: String(),
                loginCredenciado: String(),
                idCidade: null,
                dataInicial: null,
                dataFinal: null,
                status: 2,
                valorInicial: null,
                valorFinal: null,
                pagina: 1,
                quantidadePorPagina: 50,
            },
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            usuarioFatura: String(),
            usuarioFaturaTodos: false,
            sortBy: String(),
            sortDesc: true,
            showIconsToChangePage: false,
            quantidadeTotal: 0,
            itemCount: 0,
            statusPedido: {
                1: "Cancelado",
                2: "Aguardando pagamento",
                3: "Em pagamento",
                4: "Pago",
                5: "Aguardando cashback",
                6: "Processado",
                7: "Aguardando fatura credenciado",
            }
        };
    },
    components: {
        DatePicker,
        TabelaBase,
    },
    watch: {
        async "search.quantidadePorPagina"() {
            await this.getFaturas();
        },
        async "search.pagina"() {
            await this.getFaturas();
        },
        async sortDesc() {
            this.search.asc = this.$data.sortDesc;
            await this.getFaturas();
        },
        async sortBy() {
            switch (this.$data.sortBy) {
                case "patrocinador":
                    this.search.ordenacao = 0;
                    break;
                case "login":
                    this.search.ordenacao = 1;
                    break;
                case "nome":
                    this.search.ordenacao = 2;
                    break;
                case "cashback":
                    this.search.ordenacao = 3;
                    break;
                case "categoria":
                    this.search.ordenacao = 4;
                    break;
                case "cidade":
                    this.search.ordenacao = 5;
                    break;
                case "dataPedido":
                    this.search.ordenacao = 6;
                    break;
                case "dataPagamento":
                    this.search.ordenacao = 7;
                    break;
                case "valorPedido":
                    this.search.ordenacao = 8;
                    break;
                case "status":
                    this.search.ordenacao = 9;
                    break;
            }

            await this.getFaturas();
        },
    },
    async mounted() {
        await this.getFaturas();
    },
    methods: {
        async getFaturas() {
            this.$loading(true);
            this.search.valorInicial = !this.search.valorInicial
                ? null
                : parseFloat(this.search.valorInicial);
            this.search.valorFinal = !this.search.valorFinal
                ? null
                : parseFloat(this.search.valorFinal);
            this.search.obterTodos = false;
            const data = this;
            await http.post("/Admin/ObterFaturas", this.search).then(
                (success) => {
                    data.quantidadeTotal = success.data.quantidadeTotal;
                    data.items = success.data.faturasFiltradas;
                    data.itemCount = success.data.quantidadeTotal;
                    data.search.pagina = success.data.pagina;
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
            this.$loading(false);
        },
        formatValue(value) {
            if (!value) return "";
            return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        async getFaturasExport() {
            this.search.valorInicial = !this.search.valorInicial
                ? null
                : parseFloat(this.search.valorInicial);
            this.search.valorFinal = !this.search.valorFinal
                ? null
                : parseFloat(this.search.valorFinal);
            this.search.obterTodos = true;
            const result = await http
                .post("/Admin/ObterFaturas", this.search)
                .then(
                    (success) => {
                        let dataForTableExport = [];
                        success.data.faturasFiltradas.forEach((item) => {
                            dataForTableExport.push({
                                idPedido: item.idPedido,
                                patrocinador: item.patrocinador,
                                login: item.login,
                                nome: item.nome,
                                cashback: item.cashback,
                                categoria: item.categoria,
                                cidade: item.cidade,
                                status: item.status,
                                dataPedido: item.dataPedido,
                                dataPagamento: item.dataPagamento,
                                valorPedido: item.valorPedido
                                    ? this.formatValue(item.valorPedido)
                                    : "",
                            });
                        });
                        return dataForTableExport;
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                );
            return result;
        },
        editarPedido(idPedido) {
            this.$router.push({
                path: "/painel/admin/pedidos/editar",
                query: { idPedido: idPedido }
            });
        },
        cancelarPedido(idPedido) {
            this.$loading(true);
            http.post("/admin/cancelarPedido", { idPedido })
                .then(
                    async (success) => {
                        this.$snotify.success("Pedido cancelado com sucesso.");
                        await this.getPurchaseHistory();
                    },
                    () => {
                        this.$snotify.error("Erro ao cancelar pedido.");
                    }
                )
                .finally(() => this.$loading(false));
        },
        startDownload() {
            this.$loading(true);
        },
        finishDownload() {
            this.$loading(false);
        },
    },
};
