import "./quemSomos.scss";

export default {
    name: "quemSomos",
    data() {
        return {
            // team: [
            //     {
            //         image: require("@/assets/imgs/Ana-candida.png"),
            //         nome: "Ana Candida",
            //         telefone: "(22) 9 9785-7381",
            //         telefoneLink: "5522997857381"
            //     },
            //     {
            //         image: require("@/assets/imgs/Amaury-rea.png"),
            //         nome: "Amaury Réa",
            //         telefone: "(41) 9 9625-1652",
            //         telefoneLink: "5541996251652"
            //     },
            //     {
            //         image: require("@/assets/imgs/Andrea-goncalves.png"),
            //         nome: "Andrea Gonçalves",
            //         telefone: "(21) 98172-8823",
            //         telefoneLink: "5521981728823"
            //     },
            //     {
            //         image: require("@/assets/imgs/Ariane-walter.png"),
            //         nome: "Ariane Walter",
            //         telefone: "(21) 96416-0149",
            //         telefoneLink: "5521964160149"
            //     },
            //     {
            //         image: require("@/assets/imgs/Braulio-santos.png"),
            //         nome: "Braulio Santos",
            //         telefone: "(21) 9 8132-2546",
            //         telefoneLink: "5521981322546"
            //     },
            //     {
            //         image: require("@/assets/imgs/Marcio-lopes.png"),
            //         nome: "Márcio Lopes",
            //         telefone: "(21) 9 6574-3889",
            //         telefoneLink: "5521965743889"
            //     },
            //     {
            //         image: require("@/assets/imgs/Silvana-carvalho.png"),
            //         nome: "Silvana Carvalho",
            //         telefone: "(21) 9 6421-7401",
            //         telefoneLink: "5521964217401"
            //     },
            //     {
            //         image: require("@/assets/imgs/Rogerio-manhaes.png"),
            //         nome: "Rogério Manhães",
            //         telefone: "(21) 9 7013-3197",
            //         telefoneLink: "5521970133197"
            //     },
            //     {
            //         image: require("@/assets/imgs/Daniel-almeida.png"),
            //         nome: "Daniel Almeida",
            //         telefone: "(21) 9 9971-1276",
            //         telefoneLink: "5521999711276"
            //     },
            //     {
            //         image: require("@/assets/imgs/Eduardo-brandao.png"),
            //         nome: "Eduardo Brandão",
            //         telefone: "(21) 9 8308-7007",
            //         telefoneLink: "5521983087007"
            //     },
            //     {
            //         image: require("@/assets/imgs/Leonardo-ramos.png"),
            //         nome: "Leonardo Ramos",
            //         telefone: "(21) 9 8400-6399",
            //         telefoneLink: "5521984006399"
            //     },
            //     {
            //         image: require("@/assets/imgs/Luzeni-lemos.png"),
            //         nome: "Luzeni lemos",
            //         telefone: "(21) 99613-4338",
            //         telefoneLink: "5521996134338"
            //     },
            //     {
            //         image: require("@/assets/imgs/Reginah-araujo.png"),
            //         nome: "Reginah Araújo",
            //         telefone: "(21) 9 9595-5908",
            //         telefoneLink: "5521995955908"
            //     },
            //     {
            //         image: require("@/assets/imgs/Renato-santos.png"),
            //         nome: "Renato Santos",
            //         telefone: "(21) 9 9714-4449",
            //         telefoneLink: "5521997144449"
            //     },
            //     {
            //         image: require("@/assets/imgs/Rita-franca.png"),
            //         nome: "Rita Franca",
            //         telefone: "(21) 9 7112-1984",
            //         telefoneLink: "5521971121984"
            //     },
            //     {
            //         image: require("@/assets/imgs/Saulo-souza.png"),
            //         nome: "Saulo Souza",
            //         telefone: "(21) 9 6728-5165",
            //         telefoneLink: "5521967285165"
            //     }
            // ]
        };
    },
    mounted() {
        this.$loading(false);
    }
}