import "./SliderCelular.scss";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: "SliderCelular",
    props: {
        imagens: { type: Array, default: () => { return []; }, },
    },
	data() {
		return {
        };
	},
	components: {
        VueSlickCarousel
    },
    beforeMount(){
    },
    mounted() {
	},
    methods:{
        
    }
}