import { render, staticRenderFns } from "./relatorioDeFaturas.vue?vue&type=template&id=ef8074c8&scoped=true&"
import script from "./relatorioDeFaturas.js?vue&type=script&lang=js&"
export * from "./relatorioDeFaturas.js?vue&type=script&lang=js&"
import style0 from "./relatorioDeFaturas.vue?vue&type=style&index=0&id=ef8074c8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef8074c8",
  null
  
)

export default component.exports