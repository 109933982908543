import 'animate.css';
import 'leaflet/dist/leaflet.css';

import validador from "@/_helper/validacoes";
import http from "@/_helper/api-services";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/pt_BR.json';
import { configure } from 'vee-validate';

const config = {
    classes: {
        valid: ['is-valid'],
        invalid: ['is-invalid']
    },
    bails: true,
    skipOptional: true,
    //mode: 'eager',
    useConstraintAttrs: true
};

configure(config);

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule],
        message: messages[rule]
    });
});

extend('cnpj', value => {
    const cnpj = value.replace(/\D/g, '');

    if (validador.cnpjValido(cnpj) && cnpj.length == 14)
        return true;

    return 'Informe um CNPJ válido';
});

extend('cpf', value => {
    const cpf = value.replace(/\D/g, '');

    if (validador.cpfValido(cpf) && cpf.length == 11)
        return true;

    return 'Informe um CPF válido';
});

extend('estado', value => {
    if (value > 0)
        return true;

    return 'Selecione o estado';
});

extend('cidade', value => {
    if (value > 0)
        return true;

    return 'Selecione a cidade';
});

extend('categoria', value => {
    if (value > 0)
        return true;

    return 'Selecione a categoria';
});

extend('cashback', value => {
    if (value > 0 && value <= 100)
        return true;

    return 'O valor do cashback deve ser de pelo menos 1%, e no máximo 100%';
});

extend('termos', value => {
    return value || 'Você deve aceitar os termos e condições';
});

extend('birthday', value => {
    const [day, month, year] = value.split('/').map(Number);
    const birthDate = new Date(year, month - 1, day);

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    const d = today.getDate() - birthDate.getDate();

    if (m < 0 || (m === 0 && d < 0))
        age--;

    if (m === 0 && d === 0)
        age++;

    return age >= 18 || 'Você deve ter pelo menos 18 anos';
});

extend('validDate', {
    validate(value) {
        if (value) {
            const regex = /^\d{2}\/\d{2}\/\d{4}$/;

            if (!regex.test(value))
                return 'Formato de data inválido';

            const [day, month, year] = value.split('/').map(Number);

            if (!day || !month || !year)
                return 'Data inválida';

            const date = new Date(year, month - 1, day);

            const isValid =
                date.getFullYear() === year &&
                date.getMonth() === month - 1 &&
                date.getDate() === day;

            if (!isValid)
                return 'Data inválida';
        }

        return true;
    },
    message: 'Data inválida'
});


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    name: 'Credenciamento',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            cnpj: String(),
            pessoaJuridica: false,
            exibirOpcoes: true,
            exibirConsulta: false,
            exibirFormulario: false,
            empresa: {
                cnpj: String(),
                nomeFantasia: String(),
                idEstado: -1,
                idCidade: -1,
                CEP: String(),
                logradouro: String(),
                numero: String(),
                complemento: String(),
                bairro: String(),
                logomarca: String(),
                nomeResponsavel: String(),
                dataNascimento: Date(),
                telefoneResponsavel: String(),
                cpfResponsavel: String(),
                senhaResponsavel: String(),
                emailResponsavel: String(),
                percentualCashback: '10',
                telefoneEmpresa: String(),
                idCategoria: -1,
                latitude: Number(),
                longitude: Number(),
                indicador: String(),
                termos: false
            },
            idEstado: -1,
            estados: [],
            cidades: [],
            categorias: [],
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 13,
            center: [-15.793889, -47.882778],
            markerLatLng: [-15.793889, -47.882778]
        };
    },
    watch: {
        cnpj(newVal) {
            const numericValue = newVal.replace(/\D/g, '');

            if (numericValue.length === 11)
                this.pessoaJuridica = false;
            else if (numericValue.length === 14)
                this.pessoaJuridica = true;
            else
                this.pessoaJuridica = false;
        },
        'empresa.percentualCashback'(newValue) {
            const newValueNumber = parseFloat(newValue);

            if (isNaN(newValueNumber)) {
                this.empresa.percentualCashback = 10;
                return;
            }

            if (newValue < 1)
                this.empresa.percentualCashback = 10;
            else if (newValue > 100)
                this.empresa.percentualCashback = 100;
        },
    },
    mounted() {
        this.$loading(false);

        this.scrollDiv('switch');

        const captchaInstance = this.$recaptchaInstance;

        if (captchaInstance)
            captchaInstance.hideBadge();

        if (this.$route.params.login) {
            const indicador = this.$route.params.login;

            if (indicador)
                this.empresa.indicador = indicador;
        }
        const today = new Date();
        const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
        const month = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
        const year = today.getFullYear() - 18;

        this.empresa.dataNascimento = `${day}/${month}/${year}`;
    },
    methods: {
        validarCNPJ() {
            if (this.cnpj && !validador.cnpjValido(this.cnpj)) {
                this.$snotify.error("Informe um CNPJ válido.");
                return false;
            }

            return true;
        },
        validarCPF() {
            if (this.cnpj && !validador.cpfValido(this.cnpj)) {
                this.$snotify.error("Informe um CPF válido.");
                return false;
            }

            return true;
        },
        consultarCNPJ() {
            this.$loading(true);

            if (!this.cnpj) {
                this.$loading(false);
                return;
            }

            const url = `https://brasilapi.com.br/api/cnpj/v1/${this.cnpj.replace(/\D/g, '')}`;

            fetch(url)
                .then(async response => {
                    const json = await response.json();

                    if (response.ok)
                        return json;
                    else {
                        this.$snotify.error(json.message);

                        return;
                    }
                })
                .then(async data => {
                    if (data) {
                        this.empresa.cnpj = data.cnpj;
                        this.empresa.nomeFantasia = data.nome_fantasia;
                        this.empresa.razaoSocial = data.razao_social;
                        this.empresa.CEP = data.cep.replace(/\D/g, '');
                        this.empresa.logradouro = `${data.descricao_tipo_de_logradouro} ${data.logradouro}`;
                        this.empresa.numero = data.numero;
                        this.empresa.complemento = data.complemento;
                        this.empresa.bairro = data.bairro;
                        this.empresa.nomeResponsavel = data.qsa && data.qsa.length > 0 ? data.qsa[0].nome_socio : "";
                        this.empresa.telefoneResponsavel = data.ddd_telefone_1.replace(/\D/g, '');
                        this.empresa.email = data.email === null ? '' : data.email;

                        if (this.empresa.telefoneResponsavel.length < 11)
                            this.empresa.telefoneResponsavel = this.empresa.telefoneResponsavel.slice(0, 2) + '9' + this.empresa.telefoneResponsavel.slice(2);

                        await this.buscarEstados();
                        this.buscarEstadoPorUF(data.uf);

                        await this.buscarCidades();
                        this.buscarCidadePeloNome(data.municipio)

                        await this.buscarCategorias();

                        await this.consultarCEP();

                        this.exibirConsulta = false;
                        this.exibirFormulario = true;

                        this.scrollDiv('container-dados');

                        setTimeout(() => {
                            this.$refs.form.validate().then(success => {
                                // if (!success) {
                                //     return;
                                // }
                            });
                        }, 50);
                    }
                })
                .catch(error => {
                    this.$snotify.error("Não foi possível realizar a consulta do CNPJ. Preeencha os dados manualmente. " + error);
                })
                .finally(() => {
                    this.$loading(false);
                });
        },
        async consultarCEP() {
            this.$loading(true);

            if (!this.empresa.CEP) {
                this.$loading(false);
                return;
            }

            const urlCEP = `https://brasilapi.com.br/api/cep/v2/${this.empresa.CEP}`;

            fetch(urlCEP)
                .then(async response => {
                    const json = await response.json();

                    if (response.ok)
                        return json;
                    else {
                        this.$snotify.error("Não foi possível realizar a consulta do CEP. Preeencha os dados manualmente.");
                        return;
                    }
                })
                .then(async data => {
                    if (data) {
                        const latitude = data.location.coordinates.latitude;
                        const longitude = data.location.coordinates.longitude;

                        this.empresa.logradouro = data.street;
                        this.empresa.bairro = data.neighborhood;

                        this.buscarEstadoPorUF(data.state);
                        await this.buscarCidades();
                        this.buscarCidadePeloNome(data.city);


                        if (latitude && longitude) {
                            this.empresa.latitude = latitude;
                            this.empresa.longitude = longitude;
                            this.center = [];
                            this.markerLatLng = [];
                            this.center = [latitude, longitude];
                            this.markerLatLng = [latitude, longitude];
                        } else {
                            const endereco = `${data.street}, ${data.neighborhood}, ${data.city}, ${data.state}, Brasil`

                            await this.fetchLocationData(endereco);
                        }
                    }
                })
                .catch(error => {
                    this.$snotify.error("Não foi possível realizar a consulta do CEP. Preeencha os dados manualmente.");
                })
                .finally(() => {
                    this.$loading(false);
                });
        },
        async buscarCategorias() {
            this.categorias = [];

            await http.get("/Geral/ObterCategorias").then(
                (success) => {
                    success.data.forEach((categoria) => {
                        this.categorias.push({
                            text: categoria.nome,
                            value: categoria.idCategoria,
                        });
                    });
                },
                () => {
                    this.$snotify.error("Erro ao obter segmentos.");
                }
            );
        },
        buscarEstadoPorUF(uf) {
            this.idEstado = this.estados.find(estado => estado.key === uf.toUpperCase()).value;
            this.empresa.idEstado = this.idEstado;
        },
        async buscarEstados() {
            this.estados = [];
            this.idEstado = -1;

            await http.get("/Geral/buscaEstado").then(
                (success) => {
                    success.data.forEach((element) => {
                        this.estados.push({
                            value: element.idEstado,
                            text: element.nome,
                            key: element.uf,
                        });
                    });
                },
                () => {
                    this.$snotify.error("Erro ao obter cidades.");
                }
            );
        },
        buscarCidadePeloNome(nome) {
            const cidade = this.cidades.find(cidade => cidade.text.toUpperCase() === nome.toUpperCase());

            if (cidade) this.empresa.idCidade = cidade.value;
        },
        async buscarCidades() {
            this.cidades = [];
            this.empresa.idCidade = -1;

            this.$loading(true);

            if (this.idEstado) {
                await http.get(
                    "/Geral/buscaCidade/" + this.idEstado
                ).then((response) => {
                    response.data.forEach((element) => {
                        const existe = this.cidades.some(cidade => cidade.value === element.idCidade);

                        if (!existe) {
                            this.cidades.push({
                                value: element.idCidade,
                                text: element.nome,
                            });
                        }
                    });
                }).finally(() => {
                    this.$loading(false);
                });
            }
        },
        voltar() {
            this.exibirFormulario = false;
            this.exibirConsulta = false;
            this.exibirOpcoes = true;

            this.resetForm();
        },
        async onSubmitSearch() {
            //if (await this.recaptcha())
            this.consultarCNPJ();
        },
        onSubmit(event) {
            event.preventDefault();

            this.$loading(true);

            this.empresa.cnpj = this.empresa.cnpj.replace(/\D/g, '');
            this.empresa.CEP = this.empresa.CEP.replace(/\D/g, '');
            this.empresa.telefoneResponsavel = this.empresa.telefoneResponsavel.replace(/\D/g, '');
            this.empresa.cpfResponsavel = this.empresa.cpfResponsavel.replace(/\D/g, '');
            this.empresa.telefoneEmpresa = this.empresa.telefoneEmpresa.replace(/\D/g, '');

            const [day, month, year] = this.empresa.dataNascimento.split('/').map(Number);
            this.empresa.dataNascimento = new Date(year, month - 1, day);

            http
                .post("/credenciamento/v2/credenciar", this.empresa)
                .then(
                    (response) => {
                        if (response.status == 200) this.$snotify.success("Credenciamento realizado com sucesso");

                        setTimeout(() => {
                            this.resetForm();

                            this.exibirOpcoes = true;
                            this.exibirConsulta = false;
                            this.exibirFormulario = false;
                        }, 500);
                    },

                )
                .catch((error) => {
                    let errorMessage = "Não foi possível realizar seu credenciamento. Tente novamente ou entre em contato com o suporte técnico.";

                    if (error.response && error.response.data && error.response.data.message) {
                        errorMessage += " " + error.response.data.message;
                    }

                    this.$snotify.error(errorMessage);

                    // for (const erro of http.mensagensErro(error)) {
                    //     this.$snotify.error(erro);
                    // }

                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });



                    this.$loading(false);
                });
        },
        async recaptcha() {
            if (process.env.NODE_ENV !== 'development') {
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded()

                // Execute reCAPTCHA with action "login".
                const token = await this.$recaptcha('login')

                // Do stuff with the received token.
                alert(token)

                return this.checkCaptcha(token)
            }

            return true;
        },
        checkCaptcha(token) {
            console.log('🚀 ~ token', token)

            const secret = process.env.GOOGLE_reCAPTCHA_SECRET;

            const data = {
                secret: secret,
                response: token
            };

            fetch("https://www.google.com/recaptcha/api/siteverify", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                body: JSON.stringify(data)
            })
                .then(response => {
                    if (response.ok)
                        return response.json();
                })
                .then(response => {
                    if (response.score >= 0.7) {
                        console.log("Passed the token successfully");
                    }
                })
                .catch(error => {
                    console.error("Erro:", error);
                });


            return false;
        },
        scrollDiv(div = 'container') {
            setTimeout(() => {
                let containerDiv = document.getElementById(div);

                if (containerDiv) {
                    containerDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    let containerPosition = containerDiv.getBoundingClientRect();

                    window.scrollTo({
                        top: containerPosition.top + containerPosition.x + 240,
                        behavior: 'smooth'
                    });
                }
            }, 50);
        },
        validateCashbackInput(event) {
            let value = event.target.value;

            value = value.replace(/\D/g, '');

            if (!value)
                return;

            if (value !== '')
                value = Math.min(Math.max(parseInt(value, 10), 1), 100);

            if (value.length > 3) {
                this.empresa.percentualCashback = 100;

                return;
            }

            this.empresa.percentualCashback = value;
        },
        async handleFileChange(event) {
            const file = event.target.files[0];

            if (file) {
                if (!file.type.startsWith('image/')) {
                    this.$snotify.error("Por favor, selecione uma imagem válida (JPEG ou PNG).");

                    event.target.value = '';

                    return;
                }

                if (file.size > 1024 * 1024) {
                    this.$snotify.error("O arquivo deve ter no máximo 1MB.");

                    event.target.value = '';

                    return;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    const img = new Image();

                    img.onload = () => {
                        if (img.width < 500 || img.height < 500) {
                            this.$snotify.error("A imagem deve ter no mínimo 500x500 pixels.");
                            return;
                        }

                        if (img.width !== img.height) {
                            this.$snotify.error("A imagem deve ser quadrada. Exemplo: 500x500px");

                            event.target.value = '';

                            return;
                        }

                        this.empresa.logomarca = reader.result;
                    };

                    img.src = reader.result;
                };

                reader.readAsDataURL(file);
            }
        },
        exibirPessoaFisica() {
            this.resetForm();

            this.exibirConsulta = false;
            this.exibirOpcoes = false;
            this.exibirFormulario = true;
            this.scrollDiv();
            this.buscarEstados();
            this.buscarCategorias();

            setTimeout(() => {
                this.$refs.form.validate().then(success => {
                    // if (!success) {
                    //     return;
                    // }
                });
            }, 150);
        },
        async fetchLocationData(address) {
            const apiKey = '5a882080157b49e59b925f96afcd6511';
            const formattedAddress = encodeURIComponent(address);
            const url = `https://api.opencagedata.com/geocode/v1/json?key=${apiKey}&q=${formattedAddress}&pretty=1&no_annotations=1`;

            try {
                const response = await fetch(url);
                if (response.ok) {
                    const json = await response.json();

                    if (json && json.results && json.results.length > 0) {
                        const location = json.results[0].geometry;
                        const latitude = location.lat;
                        const longitude = location.lng;

                        this.empresa.latitude = latitude;
                        this.empresa.longitude = longitude;
                        this.center = [];
                        this.markerLatLng = [];
                        this.center = [latitude, longitude];
                        this.markerLatLng = [latitude, longitude];
                    }
                }
            } catch (error) {

            }
        },
        resetForm() {
            this.cnpj = String();
            this.idEstado = -1;
            this.estados = [];
            this.cidades = [];
            this.categorias = [];
            this.empresa.cnpj = String();
            this.empresa.nomeFantasia = String();
            this.empresa.razaoSocial = String();
            this.empresa.idEstado = -1;
            this.empresa.idCidade = -1;
            this.empresa.CEP = String();
            this.empresa.logradouro = String();
            this.empresa.numero = String();
            this.empresa.complemento = String();
            this.empresa.bairro = String();
            this.empresa.logomarca = String();
            this.empresa.nomeResponsavel = String();
            this.empresa.telefoneResponsavel = String();
            this.empresa.cpfResponsavel = String();
            this.empresa.senhaResponsavel = String();
            this.empresa.emailResponsavel = String();
            this.empresa.percentualCashback = '10';
            this.empresa.telefoneEmpresa = String();
            this.empresa.idCategoria = -1;
            this.empresa.latitude = Number();
            this.empresa.longitude = Number();
            this.empresa.indicador = String();

            if (this.$route.params.login) {
                const indicador = this.$route.params.login;

                if (indicador)
                    this.empresa.indicador = indicador;
            }

            this.center = [-15.793889, -47.882778];
            this.markerLatLng = [-15.793889, -47.882778];
        }
    }
}