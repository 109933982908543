import "./Faq.scss";
import http from "@/_helper/api-services";

export default {
    name: "Faq",
    data() {
        return {
            pesquisa: String(),
            listaFaq: [],
            // listapergunta: [
            //     {idFaq: 1, pergunta: "Afinal, quanto tempo leva para minha compra ser identificada?", resposta: "O prazo pode se estender por alguns dias, mas geralmente as lojas levam até 48 horas para nos avisar das compras."},
            //     {idFaq: 2, pergunta: "Esqueci de ativar o dinheiro de volta, ainda posso receber algo?", resposta: "Infelizmente, isso não é possível. E acontece porque se o dinheiro de volta não foi ativado antes, a loja não conseguirá identificar que a compra veio da gente. Neste caso, não recebemos remuneração e não conseguimos te pagar o dinheiro de volta."}
            // ]
        };
    },
    mounted() {
        this.buscarFaq();
    },
    computed: {
        consultarPergunta: function() {
            return this.listaFaq.filter(
                (f) =>
                    f.pergunta
                        .toLowerCase()
                        .includes(this.pesquisa.toLowerCase()) ||
                    f.resposta
                        .toLowerCase()
                        .includes(this.pesquisa.toLowerCase())
            );
        },
    },
    methods: {
        async buscarFaq() {
            await http
                .get("/faq/buscarFaq")
                .then(
                    (response) => {
                        response.data.forEach((element) => {
                            this.listaFaq.push({
                                idFaq: element.idFaq,
                                pergunta: element.pergunta,
                                resposta: element.resposta,
                            });
                        });
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
    },
};
