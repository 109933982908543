import { decimal, required } from "vuelidate/lib/validators";
import { useVuelidate } from "@vuelidate/core";
import { v4 as uuidv4 } from 'uuid';
import http from "@/_helper/api-services.js";
import "./Vendas.scss";
export default {
    name: "Vendas",
    data() {
        return {
            isDisabled: true,
            isLoading: false,
            isSearch: false,
            existCpfCnpj: false,
            isSuccess: false,
            isError: false,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                precision: 2,
                masked: false,
            },
            percent: {
                decimal: ",",
                thousands: ".",
                suffix: " %",
                precision: 2,
                masked: false,
            },
            erroForm: false,
            tipoSelecionado: { value: 0, text: "Selecione o meio de pagamento" },
            venda: {
                valor: { value: 0, clicked: false },
                percentualCashback: { value: 0, clicked: false },
                documento: { value: "", clicked: false },
                dataVenda: { value: new Date(), clicked: false },
                descricao: { value: "", clicked: false },
                meioPagamento: {
                    value: 0,
                    text: "Selecione o meio de pagamento",
                    clicked: false,
                },
            },
            tipoPagamento: [
                { value: 0, text: "Selecione o meio de pagamento" },
                { value: 14, text: "Cartão de crédito" },
                { value: 13, text: "Cartão de débito" },
                { value: 11, text: "Dinheiro" },
                { value: 15, text: "PIX" },
            ],
        };
    },
    mounted() {
        // this.sortTipoPagamento();
    },
    methods: {
        handleBlur() {
            this.checkCnpj(this.venda.documento.value)
        },
        sortTipoPagamento() {
            this.tipoPagamento.sort((a, b) => {
                if (a.text < b.text) {
                    return -1;
                } else if (a.text > b.text) {
                    return 1;
                }
                return 0;
            });
        },
        disabled() {
            this.isDisabled =
                !this.venda.valor ||
                // !this.venda.percentualCashback ||
                !this.venda.documento ||
                !this.venda.dataVenda ||
                !this.venda.descricao ||
                !this.existCpfCnpj ||
                this.venda.meioPagamento.text !== "Selecione o meio de pagamento";
        },
        checkCnpj(ev) {
            if (!ev)
                return;

            this.isSearch = true;
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const cpfCnpj = ev.match(/\d/g, "").join("");

            if (cpfCnpj === user.documento) {
                this.$snotify.error("Não é possivel registrar venda com o mesmo email");
            }

            if (cpfCnpj.length === 0) {
                this.isSearch = false;
                this.existCpfCnpj = false;
            }

            if (cpfCnpj.length >= 11 && cpfCnpj.length <= 14) {
                http
                    .get(`/user/checkCpfCnpj/${cpfCnpj}`)
                    .then((existsEmail) => {
                        if (existsEmail.data) {
                            setTimeout(() => {
                                this.isSuccess = true;
                            }, 2000);
                            this.existCpfCnpj = existsEmail.data;
                        } else throw error();
                    })
                    .catch((error) => {
                        this.$snotify.error("Conta não encontrada");
                        setTimeout(() => {
                            this.isError = true;
                        }, 2000);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isSearch = false;
                            this.isSuccess = false;
                            this.isError = false;
                        }, 5000);
                    });
            }
        },
        async submit() {
            this.isLoading = true;

            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const newVenda = {};
            Object.keys(this.venda).forEach((m) => {
                newVenda[m] = this.venda[m].value;
            });
            
            newVenda.valor =
                parseFloat(
                    this.venda.valor.value
                        .replace("R$", "")
                        .replace(".", "")
                        .replace(",", ".")
                );

            newVenda.percentualCashback =
                parseFloat(
                    this.venda.percentualCashback.value
                        .replace("%", "")
                        .replace(".", "")
                        .replace(",", ".")
                );


            let objectToQrCode = {
                idComerciante: user.id,
                descricao: newVenda.descricao
                    .normalize("NFD")
                    .replace(/[^a-z\d\s\.,]+/gi, "")
                    .toString(),
                valor: newVenda.valor,
                nomeComerciante: user.username
                    .normalize("NFD")
                    .replace(/[^a-z\d\s\.,]+/gi, "")
                    .toString(),
                id: uuidv4(),
            };

            let buffer = new Buffer(JSON.stringify(objectToQrCode));
            newVenda.token = buffer.toString("base64");

            await http.post("/venda/criarVenda", newVenda)
                .then(data => {
                    if (data.status === 201) {
                        this.$snotify.success("Venda registrada com sucesso!");

                        setTimeout(() => {
                            location.reload();
                        }, 2000);
                    }
                    else
                        this.$snotify.error("Ocorreu um erro ao registrar essa venda!");

                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response)
                        this.$snotify.error(error.response.data.erros[0].mensagem);

                    this.isLoading = false;
                });
        },
    },
};
