import "./logout.scss";

export default {
    name: "logout",
    beforeMount() {
        this.$root.$emit('bv::hide::popover');
        this.$emit("logoutUser");
        const { dispatch } = this.$store;
        dispatch("autenticacao/logout").finally(() => { this.$router.push("/"); window.location.reload(); });
    }
}